import gql from 'graphql-tag';

import SIMPLE_AGREEMENT_CONTRACT_FRAGMENT from '../../fragments/SimpleAgreementContractFragment';
import {
  EDIT_SIMPLE_AGREEMENT_CONTRACT as EDIT_SIMPLE_AGREEMENT_CONTRACT_Result,
  EDIT_SIMPLE_AGREEMENT_CONTRACTVariables,
} from '../../generated/generated';

export type EditSimpleAgreementContractMutationVariables =
  EDIT_SIMPLE_AGREEMENT_CONTRACTVariables;
export type EditSimpleAgreementContractMutationPayload =
  EDIT_SIMPLE_AGREEMENT_CONTRACT_Result;

export const isEditSimpleAgreementContractMutationSuccessResponse = (
  editSimpleAgreementContract?: EDIT_SIMPLE_AGREEMENT_CONTRACT_Result['editSimpleAgreementContract']
): editSimpleAgreementContract is EDIT_SIMPLE_AGREEMENT_CONTRACT_Result['editSimpleAgreementContract'] =>
  !!editSimpleAgreementContract &&
  editSimpleAgreementContract.__typename ===
    'EditSimpleAgreementContractPayload';

export const EDIT_SIMPLE_AGREEMENT_CONTRACT = gql`
  ${SIMPLE_AGREEMENT_CONTRACT_FRAGMENT}
  mutation EDIT_SIMPLE_AGREEMENT_CONTRACT(
    $input: EditSimpleAgreementContractInput!
  ) {
    editSimpleAgreementContract(input: $input) {
      contract {
        ...SimpleAgreementContractFragment
        newAgreement {
          id
        }
        oldAgreement {
          id
        }
      }
    }
  }
`;
