import { ApolloCache, makeReference } from '@apollo/client';

import {
  addJobToCachedJobList,
  findJobListDestinations,
  removeJobFromCachedJobList,
} from '../../utils/cache';
import JobListId from '../../utils/constants/jobListIds';
import { RESTORE_JOB } from '../../utils/generated/generated';
import { JobListOrJobQueryItem } from '../../utils/interfaces/graphql/JobListOrJobQueryItem.interface';
import { JobsListQueryVariables } from '../../utils/query/JobsList/JobsListQuery';

const restoreJobUpdate =
  (
    job: JobListOrJobQueryItem,
    currentlyOpenedJobsListRestorePositionInfo?: {
      listId: JobsListQueryVariables['listId'];
      page: JobsListQueryVariables['page'];
      count: JobsListQueryVariables['count'];
      index: number;
    }
  ) =>
  (
    cache: ApolloCache<RESTORE_JOB>,
    { data }: { data?: RESTORE_JOB | null }
  ) => {
    if (data?.restoreJob.success) {
      cache.modify({
        id: cache.identify({
          jobId: job.id,
          __typename: 'JobBusinessRelationState',
        }),
        fields: {
          isDeleted() {
            return false;
          },
        },
      });

      const jobListDestinations: JobListId[] = findJobListDestinations(job);

      cache.modify({
        id: cache.identify(makeReference('ROOT_QUERY')),
        fields: {
          jobList(existingJobList, { readField }) {
            // remove job from deleted job list
            if (existingJobList.listId === JobListId.Deleted) {
              return {
                ...existingJobList,
                jobConnection: removeJobFromCachedJobList(
                  existingJobList.jobConnection,
                  job.id,
                  readField
                ),
              };
            }
            if (jobListDestinations.includes(existingJobList.listId)) {
              // add job to other job lists (included in jobListDestinations)
              return {
                ...existingJobList,
                jobConnection:
                  currentlyOpenedJobsListRestorePositionInfo &&
                  currentlyOpenedJobsListRestorePositionInfo.listId ===
                    existingJobList.listId
                    ? addJobToCachedJobList(
                        existingJobList.jobConnection,
                        job,
                        readField,
                        existingJobList.listId === JobListId.Answered,
                        ((currentlyOpenedJobsListRestorePositionInfo.page ||
                          0) -
                          1) *
                          (currentlyOpenedJobsListRestorePositionInfo.count ||
                            0) +
                          currentlyOpenedJobsListRestorePositionInfo.index
                      )
                    : addJobToCachedJobList(
                        existingJobList.jobConnection,
                        job,
                        readField,
                        existingJobList.listId === JobListId.Answered
                      ),
              };
            }
            return existingJobList;
          },
        },
      });

      // update counters for lists in jobListDestination
      jobListDestinations.forEach((jobListId) =>
        cache.modify({
          id: cache.identify({ id: jobListId, __typename: 'JobListFilter' }),
          fields: {
            count(currentCount) {
              return currentCount + 1;
            },
          },
        })
      );

      // update deleted list counter
      cache.modify({
        id: cache.identify({
          id: JobListId.Deleted,
          __typename: 'JobListFilter',
        }),
        fields: {
          count(currentCount) {
            return currentCount - 1 >= 0 ? currentCount - 1 : 0;
          },
        },
      });
    }
  };

const restoreJobOptimisticResponse: RESTORE_JOB = {
  restoreJob: {
    __typename: 'RestoreJobPayload',
    success: true,
  },
};

export { restoreJobUpdate, restoreJobOptimisticResponse };
