import { AuthCallbackUrlStatus } from '@internals/business-shared/src/hooks/mutation/useRequestSecureIdAuthenticationUrl';
import { Params } from '@router/paths';

export enum ModalState {
  SELECT_PACKAGE = 'SELECT_PACKAGE',
  CONFIRM_PURCHASE = 'CONFIRM_PURCHASE',
  REDIRECTION = 'REDIRECTION',
  SUMMARY = 'SUMMARY',
  NOT_COMPLETED = 'NOT_COMPLETED',
}

export enum TriggerSource {
  JOB_LIST = 'JobList',
  JOB_CARD = 'JobCard',
}

export enum AnalyticsEvent {
  ALERT_JOB_LIST_CLICKED = 'ALERT_JOB_LIST_CLICKED',
  ALERT_JOB_CARD_CLICKED = 'ALERT_JOB_CARD_CLICKED',
  PACKAGE_SELECTED = 'PACKAGE_SELECTED',
  PURCHASE_CONFIRMED = 'PURCHASE_CONFIRMED',
  PURCHASE_SUCCESS = 'PURCHASE_SUCCESS',
  PURCHASE_CANCELED = 'PURCHASE_CANCELED',
  PURCHASE_FAILED = 'PURCHASE_FAILED',
  RETRY_ATTEMPTED = 'RETRY_ATTEMPTED',
  CUSTOMER_SERVICE_OPENED = 'CUSTOMER_SERVICE_OPENED',
  ONBOARDING_BOOKED = 'ONBOARDING_BOOKED',
  MODAL_CLOSED = 'MODAL_CLOSED',
}

export enum BusinessStatus {
  NEW = 'new',
  REACTIVATION = 'reactivation',
  UNVERIFIED = 'unverified',
  VERIFIED = 'verified',
}

export const SUBSCRIPTION_PARAMS = {
  MODAL: Params.BuySubscriptionModal,
  SELECTED_PACKAGE_SLUG: Params.SelectedPackage,
  AUTH_STATUS: AuthCallbackUrlStatus.Name,
  AUTH_SESSION_ID: 'sessionId',
  PAYMENT_RESULT: 'resultCode',
};

export const SUBSCRIPTION_REQUEST_CONTACT = 'subscription-request-contact';
export const PAYMENT_RESULT_SUCCESS_CODE = '10';
