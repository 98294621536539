import gql from 'graphql-tag';

import EVALUATION_FRAGMENT from '../../fragments/EvaluationFragment';
import FILE_FRAGMENT from '../../fragments/FileFragment';
import IMAGE_FRAGMENT from '../../fragments/ImageFragment';
import JOB_BUSINESS_RELATION_STATE_FRAGMENT from '../../fragments/JobBusinessRelationStateFragment';
import JOB_CHAT_META_FRAGMENT from '../../fragments/JobChatMetaFragment';
import JOB_DETAILS_FRAGMENT from '../../fragments/JobDetailsFragment';
import JOB_META_FRAGMENT from '../../fragments/JobMetaFragment';
import {
  JOB_DETAILS_QUERY as JOB_DETAILS_QUERY_Result,
  JOB_DETAILS_QUERYVariables,
  JOB_DETAILS_QUERY_job_Job,
} from '../../generated/generated';

export type JobDetailsQueryPayload = JOB_DETAILS_QUERY_Result;
export type JobDetailsQueryVariables = JOB_DETAILS_QUERYVariables;

export const isJobDetailsQuerySuccessResponse = (
  job?: JOB_DETAILS_QUERY_Result['job']
): job is JOB_DETAILS_QUERY_job_Job => !!(job && job.__typename === 'Job');

export const JOB_DETAILS_QUERY = gql`
  ${JOB_DETAILS_FRAGMENT}
  ${JOB_CHAT_META_FRAGMENT}
  ${JOB_META_FRAGMENT}
  ${IMAGE_FRAGMENT}
  ${FILE_FRAGMENT}
  ${EVALUATION_FRAGMENT}
  ${JOB_BUSINESS_RELATION_STATE_FRAGMENT}

  query JOB_DETAILS_QUERY($id: ID!) {
    job(id: $id) {
      ... on Job {
        ...JobDetailsFragment
      }
      ... on JobAccessDeniedError {
        message
        reasonCode
      }
      ... on JobNotFoundError {
        message
      }
    }
    jobChatMeta(jobId: $id) {
      ...JobChatMetaFragment
    }
    jobMeta(jobId: $id) {
      ...JobMetaFragment
    }
    jobFiles(input: { tenderId: $id }) {
      ...FileFragment
    }
    jobImages(input: { tenderId: $id }) {
      ...ImageFragment
    }
    jobEvaluation(jobId: $id) {
      ...EvaluationFragment
    }
    jobBusinessRelationState(jobId: $id) {
      ...JobBusinessRelationStateFragment
    }
  }
`;
