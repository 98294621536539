import { FC } from 'react';

import JobLabelComponent from '@components/base/JobLabel';
import { getInitials } from '@internals/business-shared/src/utils/getInitials';
import { JobTagLabel } from '@internals/business-shared/src/utils/JobTags';
import { Div, Text, Avatar } from '@schibsted-smb/fireball';
import { useTheme } from 'styled-components';

interface AvatarHeaderProps {
  mobileView: boolean;
  name: string;
  score: number;
  address: string;
}

const AvatarHeader: FC<AvatarHeaderProps> = ({
  mobileView,
  name,
  score,
  address,
}) => {
  const themeContext = useTheme();

  return (
    <Div
      display="flex"
      alignItems="center"
      px={6}
      py={mobileView ? 6 : 4}
      borderBottom={
        mobileView ? null : `1px solid ${themeContext.colors.black.black3}`
      }
      width="100%"
    >
      <Avatar initials={getInitials(name)} mr={4} />
      <Div>
        <Div display="flex" flexDirection="row" alignItems="center" mb={2}>
          <Text.span fontSize={2} mb={0} color="black.black9" fontWeight={600}>
            {name}
          </Text.span>
          {score && (
            <JobLabelComponent
              key={`EVALUATION-${score}`}
              labelName={JobTagLabel.EVALUATION}
              evaluationValue={score}
              isGreenEvaluation
            />
          )}
        </Div>
        <Text.span fontSize={1} color="black.black7" my={0}>
          {address}
        </Text.span>
      </Div>
    </Div>
  );
};

export default AvatarHeader;
