import { useCallback } from 'react';

import { TriggerSource } from '@components/elements/BoostCreditsModal/constants';
import { useBoostCreditsModalContext } from '@components/elements/BoostCreditsModal/ModalContext';
import useAnalytics from '@hooks/useAnalytics';
import { useCookieFlag } from '@hooks/useCookieFlag';
import { useCompanyWantsContactAboutProduct } from '@internals/business-shared/src/hooks/mutation/useCompanyWantsContactAboutProduct';
import {
  ANEvent,
  ANEventSpace,
  ANObject,
  ANPage,
} from '@internals/business-shared/src/utils/analyticsNamespace';
import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import { ProductType } from '@internals/business-shared/src/utils/ProductUtils';
import Paths from '@router/paths';
import { ButtonProps } from '@schibsted-smb/fireball';
import { CompanyStateAction } from '@utils/companyState/getCompanyStateBannerData';
import { getContactAboutProductRequestDetails } from '@utils/getContactAboutProductRequestDetails';
import { t } from '@utils/i18n';
import { bugsnagClient } from '@utils/initBugsnag';
import ToastMessage from '@utils/ToastMessage';

export interface ActionLinkProps {
  text: string;
  icon?: IconPickerName;
  link: string;
  isExternal?: boolean;
}
export interface ActionButtonProps
  extends Pick<ButtonProps, 'onClick' | 'variant' | 'isLoading' | 'disabled'> {
  text: string;
  icon?: IconPickerName;
}
export interface ActionTypeLink {
  type: 'link';
  props: ActionLinkProps;
}
export interface ActionTypeButton {
  type: 'button';
  props: ActionButtonProps;
}
export type ActionType = ActionTypeLink | ActionTypeButton;

const JOB_PACKAGE_REQUEST_CONTACT = 'job-package-request-contact';

export const useRequestJobPackageAction = (
  jobId?: string,
  onSuccess?: () => void
): ActionButtonProps => {
  const [requestContact, { loading }] = useCompanyWantsContactAboutProduct();
  const [contactAlreadyRequested, saveContactRequested] = useCookieFlag(
    JOB_PACKAGE_REQUEST_CONTACT
  );
  const action = useCallback(
    () =>
      requestContact(
        ProductType.JobPackage,
        getContactAboutProductRequestDetails({
          jobId,
          buttonText: t('general.action.contactMe'),
        }),
        {
          onCompleted: () => {
            ToastMessage(
              t('company.state.common.action.requestContact.success'),
              'success'
            );
            saveContactRequested();
            onSuccess?.();
          },
          onError: (errorResponse) => {
            ToastMessage(
              t('company.state.common.action.requestContact.error'),
              'danger'
            );
            bugsnagClient.notify(
              new Error(
                `Request for contact failed with error: ${errorResponse.message}`
              )
            );
          },
        }
      ),
    [requestContact, saveContactRequested, onSuccess]
  );
  if (contactAlreadyRequested) {
    return {
      text: t('general.action.requestContactSent'),
      disabled: true,
    };
  }
  return {
    onClick: action,
    text: t('general.action.contactMe'),
    isLoading: loading,
  };
};

export const useCompanyStateAction = (): ((
  action: CompanyStateAction,
  paymentLink?: string | null
) => ActionType) => {
  const { track } = useAnalytics();
  const { handleModalOpen } = useBoostCreditsModalContext();

  const onRequestContactSuccess = () => {
    track(
      ANEventSpace(
        ANEvent.Clicked,
        ANObject.AlertBannerContactRequest,
        ANPage.JobList
      )
    );
  };

  const requestContactActionProps = useRequestJobPackageAction(
    undefined,
    onRequestContactSuccess
  );

  const openBoostModalActionProps: ActionButtonProps = {
    text: t('general.action.goToSelfServicePurchase'),
    variant: 'primary',
    onClick: () => handleModalOpen(TriggerSource.JOB_LIST),
  };

  const visitCustomerService: ActionTypeLink = {
    type: 'link',
    props: {
      link: Paths.SettingsCustomerService,
      text: t('general.action.contactMe'),
    },
  };
  return (action: CompanyStateAction, paymentLink?: string | null) => {
    switch (action) {
      case CompanyStateAction.OpenBoostModal: {
        return {
          type: 'button',
          props: openBoostModalActionProps,
        };
      }
      case CompanyStateAction.RequestContact: {
        return {
          type: 'button',
          props: requestContactActionProps,
        };
      }
      case CompanyStateAction.GoToPayment: {
        if (!paymentLink) return visitCustomerService;
        return {
          type: 'link',
          props: {
            link: paymentLink,
            isExternal: true,
            text: t('general.action.makePayment'),
            icon: IconPickerName.CreditCard,
          },
        };
      }
      case CompanyStateAction.OpenInvoice: {
        if (!paymentLink) return visitCustomerService;
        return {
          type: 'link',
          props: {
            link: paymentLink,
            isExternal: true,
            text: t('company.state.common.action.showInvoice.link'),
            icon: IconPickerName.CreditCard,
          },
        };
      }
      case CompanyStateAction.GoToCustomerService:
      default: {
        return {
          type: 'link',
          props: {
            link: Paths.SettingsCustomerService,
            text: t('general.action.contactMe'),
          },
        };
      }
    }
  };
};
