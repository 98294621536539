import { ApolloCache, makeReference } from '@apollo/client';

import {
  addJobToCachedJobList,
  ChatListQueryJobExtendedType,
} from '../../utils/cache';
import JobListId from '../../utils/constants/jobListIds';
import { FAVOURITE_JOB } from '../../utils/generated/generated';
import { JobListOrJobQueryItem } from '../../utils/interfaces/graphql/JobListOrJobQueryItem.interface';

const favouriteJobUpdate =
  (job: JobListOrJobQueryItem | ChatListQueryJobExtendedType) =>
  (
    cache: ApolloCache<FAVOURITE_JOB>,
    { data }: { data?: FAVOURITE_JOB | null }
  ) => {
    if (data?.favouriteJob.success) {
      cache.modify({
        id: cache.identify({
          jobId: job.id,
          __typename: 'JobBusinessRelationState',
        }),
        fields: {
          isFavourite() {
            return true;
          },
        },
      });
      // Add job to favourite job list
      cache.modify({
        id: cache.identify(makeReference('ROOT_QUERY')),
        fields: {
          jobList(existingJobList, { readField }) {
            if (JobListId.Favourite === existingJobList.listId) {
              return {
                ...existingJobList,
                jobConnection: addJobToCachedJobList(
                  existingJobList.jobConnection,
                  job,
                  readField,
                  false
                ),
              };
            }
            return existingJobList;
          },
        },
      });

      // update favourite job list counter in job list filter
      cache.modify({
        id: cache.identify({
          id: JobListId.Favourite,
          __typename: 'JobListFilter',
        }),
        fields: {
          count(currentCount) {
            return currentCount + 1;
          },
        },
      });
    }
  };

const favouriteJobOptimisticResponse: FAVOURITE_JOB = {
  favouriteJob: {
    __typename: 'FavouriteJobPayload',
    success: true,
  },
};

export { favouriteJobUpdate, favouriteJobOptimisticResponse };
