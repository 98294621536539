import { FC, useEffect, useState } from 'react';

import { useQuery } from '@apollo/client';
import { FeatureFlag } from '@components/elements/FeatureFlag';
import NotFound from '@components/elements/NotFound';
import SettingsContainer from '@components/elements/SettingsContainer';
import SettingsSection from '@components/elements/SettingsSection';
import SettingsLoading from '@components/layout/Settings/SettingsLoading';
import { useUserBusinessId } from '@contexts/UserContext';
import { usePageAnalytics } from '@hooks/useAnalytics';
import { useCompanyWantsContactAboutProduct } from '@internals/business-shared/src/hooks/mutation/useCompanyWantsContactAboutProduct';
import {
  ANObject,
  ANPage,
  ANSpace,
} from '@internals/business-shared/src/utils/analyticsNamespace';
import FeatureFlags from '@internals/business-shared/src/utils/constants/FeatureFlags';
import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import {
  BusinessProductsResponseError,
  GQLErrorState,
} from '@internals/business-shared/src/utils/errors';
import { isError } from '@internals/business-shared/src/utils/isError';
import {
  ProductDisplayType,
  ProductType,
  setProducts,
} from '@internals/business-shared/src/utils/ProductUtils';
import {
  isProductsQuerySuccessResponse,
  ProductsQueryPayload,
  ProductsQueryVariables,
  PRODUCTS_QUERY,
} from '@internals/business-shared/src/utils/query/Products/ProductsQuery';
import Paths from '@router/paths';
import { getContactAboutProductRequestDetails } from '@utils/getContactAboutProductRequestDetails';
import { getProduct } from '@utils/product';
import ToastMessage from '@utils/ToastMessage';
import { useTranslation } from 'react-i18next';

import ProductsSubsection from './ProductsSubsection';

const Products: FC = () => {
  const businessId = useUserBusinessId();
  const { t } = useTranslation();
  const { name: productName } = getProduct();
  const { loading, data, refetch } = useQuery<
    ProductsQueryPayload,
    ProductsQueryVariables
  >(PRODUCTS_QUERY, {
    variables: { id: businessId },
    notifyOnNetworkStatusChange: true,
  });

  const [productsErrors, setProductsErrors] = useState<GQLErrorState>({});
  const [companyWantsContactAboutProduct] =
    useCompanyWantsContactAboutProduct();
  const [productDisplay, setProductDisplay] = useState<ProductDisplayType>(
    setProducts(undefined)
  );

  const onContact = (type: ProductType, buttonText: string) => {
    setProductDisplay((prevState) => ({
      ...prevState,
      [type]: { ...prevState[type], loading: true },
    }));
    companyWantsContactAboutProduct(
      type,
      getContactAboutProductRequestDetails({ buttonText })
    )
      .then(() => {
        setProductDisplay((prevState) => ({
          ...prevState,
          [type]: {
            ...prevState[type],
            loading: false,
            success: true,
            display: false,
          },
        }));
      })
      .catch(() => {
        ToastMessage(t('settings.products.contactError'), 'warning');
        setProductDisplay((prevState) => ({
          ...prevState,
          [type]: {
            ...prevState[type],
            loading: false,
            success: false,
            display: true,
          },
        }));
      });
  };

  useEffect(() => {
    if (data && isProductsQuerySuccessResponse(data?.business)) {
      setProductDisplay(setProducts(data?.business?.products ?? undefined));
    } else if (data) {
      const productsResponseError = new BusinessProductsResponseError(
        'Business'
      );
      productsResponseError.hasResponseError(data?.business, setProductsErrors);
    }
  }, [data]);

  usePageAnalytics(ANSpace(ANObject.Products, ANPage.Settings));

  const shouldDisplayError = !loading && isError(productsErrors);

  return (
    <SettingsContainer>
      {loading && <SettingsLoading />}
      {shouldDisplayError && (
        <NotFound
          title={t('settings.error.title')}
          text={t('settings.error.text')}
          link={{
            url: Paths.SettingsCustomerService,
            text: t('general.label.customerService'),
          }}
          button={{
            text: t('general.error.tryAgain'),
            onClick: () => refetch(),
          }}
        />
      )}
      {!loading && data && (
        <SettingsSection
          icon={IconPickerName.Products}
          title={t('settings.products.title')}
          testId="products"
        >
          <ProductsSubsection
            icon={IconPickerName.Products}
            title={t('settings.products.subSection.basicPackage.title')}
            description={t(
              'settings.products.subSection.basicPackage.description'
            )}
            productType={ProductType.BasicPackage}
            productDisplay={productDisplay}
            onActionButtonClick={() =>
              onContact(ProductType.BasicPackage, t('general.action.contactMe'))
            }
            actionButtonText={t('general.action.contactMe')}
            testId="basic-package"
          />
          <ProductsSubsection
            icon={IconPickerName.Products}
            title={t('settings.products.subSection.jobsPackage.title')}
            description={t(
              'settings.products.subSection.jobsPackage.description'
            )}
            productType={ProductType.JobPackage}
            productDisplay={productDisplay}
            onActionButtonClick={() =>
              onContact(ProductType.JobPackage, t('general.action.contactMe'))
            }
            actionButtonText={t('general.action.contactMe')}
            testId="job-package"
          />
          <FeatureFlag name={FeatureFlags.BizProductsSearch}>
            <ProductsSubsection
              icon={IconPickerName.Search}
              title={t('settings.products.subSection.companySearch.title')}
              description={t(
                'settings.products.subSection.companySearch.description',
                { productName }
              )}
              productType={ProductType.CompanySearch}
              productDisplay={productDisplay}
              onActionButtonClick={() =>
                onContact(
                  ProductType.CompanySearch,
                  t('general.action.contactMe')
                )
              }
              actionButtonText={t('general.action.contactMe')}
              testId="company-search"
            />
          </FeatureFlag>
          <ProductsSubsection
            icon={IconPickerName.Subscription}
            title={t('settings.products.subSection.clips.title')}
            description={t('settings.products.subSection.clips.description')}
            productType={ProductType.Clips}
            productDisplay={productDisplay}
            onActionButtonClick={() =>
              onContact(ProductType.Clips, t('general.action.contactMe'))
            }
            actionButtonText={t('general.action.contactMe')}
            testId="clips"
          />
        </SettingsSection>
      )}
    </SettingsContainer>
  );
};

export default Products;
