import {
  FC,
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { useFlagEnabled } from '@components/base/CheckFlag';
import { useRequestContact } from '@hooks/useRequestContact';
import { useResetAllParams } from '@hooks/useResetAllParams';
import useSetSearchParams from '@hooks/useSetSearchParams';
import { useInitiateBoostCreditsPayment } from '@internals/business-shared/src/hooks/mutation/useInitiateBoostCreditsPayment';
import { useBoostCreditsProductDetailsQuery } from '@internals/business-shared/src/hooks/query/useBoostCreditsProductDetailsQuery';
import { useBoostCreditsProductsPairQuery } from '@internals/business-shared/src/hooks/query/useBoostCreditsProductsPairQuery';
import { useCardListLazyQuery } from '@internals/business-shared/src/hooks/query/useCardListLazyQuery';
import { usePrimaryBusinessContactLazyQuery } from '@internals/business-shared/src/hooks/query/usePrimaryBusinessContactLazyQuery';
import useBoolean from '@internals/business-shared/src/hooks/useBoolean';
import FeatureFlags from '@internals/business-shared/src/utils/constants/FeatureFlags';
import { formatCardNumber } from '@internals/business-shared/src/utils/formatCardNumber';
import { SelfServiceProductName } from '@internals/business-shared/src/utils/generated/generated';
import { ProductType } from '@internals/business-shared/src/utils/ProductUtils';
import { BoostCreditsProductDetails } from '@internals/business-shared/src/utils/query/BoostCreditsProductDetails/BoostCreditsProductDetails';
import { BoostCreditsProductsPair } from '@internals/business-shared/src/utils/query/BoostCreditsProductsPair/BoostCreditsProductsPair';
import { CardList } from '@internals/business-shared/src/utils/query/CardList/CardList';
import { PrimaryBusinessContact } from '@internals/business-shared/src/utils/query/PrimaryBusinessContact/PrimaryBusinessContact';
import Paths from '@router/paths';
import { bugsnagClient } from '@utils/initBugsnag';
import { useNavigate } from 'react-router-dom';

import {
  AnalyticsEvent,
  BOOST_PARAMS,
  BOOST_REQUEST_CONTACT,
  ModalState,
  PAYMENT_RESULT_SUCCESS_CODE,
  PAYMENT_RESULT_SUCCESS_NAME,
  TriggerSource,
} from './constants';
import { useBoostAnalytics } from './hooks/useBoostAnalytics';

export type PackageItem = BoostCreditsProductsPair | BoostCreditsProductDetails;

interface ModalContextType {
  cardList: CardList[];
  isOpen: boolean;
  loading: boolean;
  modalState: ModalState;
  packageList: PackageItem[];
  primaryContact: PrimaryBusinessContact;
  selectedCard: CardList;
  selectedPackage: PackageItem;
  handleCheckout: VoidFunction;
  handleModalClose: VoidFunction;
  handleModalOpen: (
    triggerSource: TriggerSource,
    jobId?: string
  ) => Promise<void>;
  handleOpenCustomerService: VoidFunction;
  handlePayment: VoidFunction;
  handleRetryAttempt: VoidFunction;
  handleSelectPackage: (slug: SelfServiceProductName) => void;
  setSelectedCard: (selectedCard: CardList) => void;
}

const ModalContext = createContext<ModalContextType>({
  cardList: [],
  isOpen: false,
  loading: false,
  modalState: null,
  packageList: [],
  primaryContact: null,
  selectedCard: null,
  selectedPackage: null,
  handleSelectPackage: () => null,
  handleCheckout: () => null,
  handleModalClose: () => null,
  handleModalOpen: () => null,
  handleOpenCustomerService: () => null,
  handlePayment: () => null,
  handleRetryAttempt: () => null,
  setSelectedCard: () => null,
});

export const BoostCreditsModalProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const [
    fetchPrimaryContact,
    { data: primaryContact, loading: primaryContactLoading },
  ] = usePrimaryBusinessContactLazyQuery();
  const [fetchCardList, { data: cardList, loading: cardListLoading }] =
    useCardListLazyQuery();
  const [initiatePayment, { loading: paymentLoading }] =
    useInitiateBoostCreditsPayment();
  const [isOpen, openModal, closeModal] = useBoolean(false);
  const [modalState, setModalState] = useState<ModalState>(
    ModalState.SELECT_PACKAGE
  );
  const [selectedCard, setSelectedCard] = useState<CardList>(null);
  const [selectedPackage, setSelectedPackage] = useState<PackageItem>(null);
  const [setSearchParams, , { searchParams }] = useSetSearchParams();
  const handleBoostAnalytics = useBoostAnalytics();
  const navigate = useNavigate();
  const isBoostCreditsChoosePackageFlagEnabled = useFlagEnabled(
    FeatureFlags.BizBoostCreditsChoosePackage
  );
  const isTestBuyMoreClipsFlagEnabled = useFlagEnabled(
    FeatureFlags.BizTestBuyMoreClips
  );
  const { requestContact, requestContactLoading } = useRequestContact(
    ProductType.Boost,
    BOOST_REQUEST_CONTACT
  );
  const resetAllParams = useResetAllParams(BOOST_PARAMS);

  const { data: packageList, loading: packageListLoading } =
    isBoostCreditsChoosePackageFlagEnabled
      ? useBoostCreditsProductsPairQuery()
      : useBoostCreditsProductDetailsQuery();

  const getPackageBySlug = useCallback(
    (slug: SelfServiceProductName) =>
      packageList.find((packageItem) => packageItem.slug === slug),
    [packageList]
  );

  const handleSetModalState = useCallback(
    (state: ModalState) => {
      setModalState(state);
      handleBoostAnalytics(state);
    },
    [handleBoostAnalytics]
  );

  const handleSelectPackage = useCallback(
    (slug: SelfServiceProductName) => {
      const newSelectedPackage = getPackageBySlug(slug);
      setSelectedPackage(newSelectedPackage);
      setSearchParams(
        BOOST_PARAMS.SELECTED_PACKAGE_SLUG,
        newSelectedPackage.slug
      );
    },
    [getPackageBySlug, setSearchParams]
  );

  const handleSelectLastPackage = useCallback(() => {
    const lastPackageItem = packageList[packageList.length - 1];
    setSelectedPackage(lastPackageItem);
    setSearchParams(BOOST_PARAMS.SELECTED_PACKAGE_SLUG, lastPackageItem?.slug);
  }, [packageList, setSearchParams]);

  const handleCheckout = useCallback(() => {
    handleSetModalState(ModalState.CONFIRM_PURCHASE);
    handleBoostAnalytics(AnalyticsEvent.PACKAGE_SELECTED, { selectedPackage });
  }, [handleBoostAnalytics, handleSetModalState, selectedPackage]);

  const handlePaymentResult = useCallback(
    (resultCode: string) => {
      if (
        resultCode === PAYMENT_RESULT_SUCCESS_NAME ||
        resultCode === PAYMENT_RESULT_SUCCESS_CODE
      ) {
        handleSetModalState(ModalState.SUMMARY);
        handleBoostAnalytics(AnalyticsEvent.PURCHASE_SUCCESS);
      } else {
        handleSetModalState(ModalState.NOT_COMPLETED);
        handleBoostAnalytics(AnalyticsEvent.PURCHASE_FAILED);
      }
    },
    [handleBoostAnalytics, handleSetModalState]
  );

  const handlePayment = useCallback(async () => {
    try {
      const response = await initiatePayment({
        cardId: selectedCard?.id ?? null,
        returnUrl: window.location.href,
        ...(isBoostCreditsChoosePackageFlagEnabled
          ? { productName: selectedPackage?.slug }
          : {}),
      });
      const { resultCode, returnUrl } =
        response?.data?.initiateBoostCreditsPayment || {};

      if (returnUrl) {
        window.open(returnUrl, '_self');
        handleBoostAnalytics(AnalyticsEvent.PURCHASE_CONFIRMED);
      } else {
        handlePaymentResult(resultCode);
      }
    } catch (e) {
      handleSetModalState(ModalState.NOT_COMPLETED);
      bugsnagClient.notify(`Boost credits payment failed: ${e as string}`);
    }
  }, [
    handleBoostAnalytics,
    handlePaymentResult,
    handleSetModalState,
    initiatePayment,
    isBoostCreditsChoosePackageFlagEnabled,
    selectedCard?.id,
    selectedPackage?.slug,
  ]);

  const handleRetryAttempt = useCallback(() => {
    resetAllParams(BOOST_PARAMS.MODAL);
    handleSetModalState(ModalState.SELECT_PACKAGE);
    handleSelectLastPackage();
    handleBoostAnalytics(AnalyticsEvent.RETRY_ATTEMPTED);
  }, [
    handleSelectLastPackage,
    handleBoostAnalytics,
    handleSetModalState,
    resetAllParams,
  ]);

  const handleModalOpen = useCallback(
    async (triggerSource: TriggerSource, jobId?: string) => {
      try {
        if (!isTestBuyMoreClipsFlagEnabled) {
          return;
        }
        await fetchPrimaryContact();

        const { data: cardListData } = await fetchCardList();
        const fetchedCardList = cardListData?.cardList || [];
        const newSelectedCard =
          fetchedCardList.length > 0
            ? {
                ...fetchedCardList[0],
                maskedCardNumber: formatCardNumber(
                  fetchedCardList[0].maskedCardNumber
                ),
              }
            : null;

        setSelectedCard(newSelectedCard);
        handleSelectLastPackage();
        setSearchParams(BOOST_PARAMS.MODAL, triggerSource);
        handleSetModalState(ModalState.SELECT_PACKAGE);
        openModal();
        handleBoostAnalytics(
          {
            [TriggerSource.JOB_LIST]: AnalyticsEvent.ALERT_JOB_LIST_CLICKED,
            [TriggerSource.JOB_CARD]: AnalyticsEvent.ALERT_JOB_CARD_CLICKED,
            [TriggerSource.AUTO_OPEN]: AnalyticsEvent.AUTO_OPENED,
          }[triggerSource],
          { hasCard: fetchedCardList.length > 0, jobId }
        );
      } catch (e) {
        handleBoostAnalytics(AnalyticsEvent.PURCHASE_CANCELED);
        bugsnagClient.notify(`Error opening modal: ${e as string}`);
      } finally {
        await requestContact();
      }
    },
    [
      isTestBuyMoreClipsFlagEnabled,
      fetchPrimaryContact,
      fetchCardList,
      handleSelectLastPackage,
      setSearchParams,
      handleSetModalState,
      openModal,
      handleBoostAnalytics,
      requestContact,
    ]
  );

  const handleModalClose = useCallback(() => {
    resetAllParams();
    closeModal();
    handleBoostAnalytics(AnalyticsEvent.MODAL_CLOSED);
    if (modalState !== ModalState.SUMMARY) {
      handleBoostAnalytics(AnalyticsEvent.PURCHASE_CANCELED);
    }
  }, [resetAllParams, closeModal, handleBoostAnalytics, modalState]);

  const handleOpenCustomerService = useCallback(() => {
    handleModalClose();
    navigate(Paths.SettingsCustomerService);
    handleBoostAnalytics(AnalyticsEvent.CUSTOMER_SERVICE_OPENED);
  }, [handleModalClose, navigate, handleBoostAnalytics]);

  useEffect(() => {
    if (selectedPackage) {
      return;
    }

    const selectedPackageSlug = searchParams.get(
      BOOST_PARAMS.SELECTED_PACKAGE_SLUG
    ) as SelfServiceProductName;

    if (selectedPackageSlug) {
      const newSelectedPackage = getPackageBySlug(selectedPackageSlug);
      setSelectedPackage(newSelectedPackage);
    }
  }, [getPackageBySlug, searchParams, selectedPackage]);

  useEffect(() => {
    if (isOpen) {
      return;
    }

    const isBoostModal = searchParams.has(BOOST_PARAMS.MODAL);
    const paymentResult = searchParams.get(BOOST_PARAMS.PAYMENT_RESULT);

    if (isBoostModal) {
      if (paymentResult) {
        handlePaymentResult(paymentResult);
        openModal();
      } else {
        handleModalClose();
      }
    }
  }, [handleModalClose, handlePaymentResult, isOpen, openModal, searchParams]);

  const contextValue = useMemo(
    () => ({
      cardList,
      isOpen,
      loading:
        cardListLoading ||
        packageListLoading ||
        paymentLoading ||
        primaryContactLoading ||
        requestContactLoading,
      modalState,
      packageList,
      primaryContact,
      selectedCard,
      selectedPackage,
      handleCheckout,
      handleModalClose,
      handleModalOpen,
      handleOpenCustomerService,
      handlePayment,
      handleRetryAttempt,
      handleSelectPackage,
      setSelectedCard,
    }),
    [
      cardList,
      isOpen,
      cardListLoading,
      packageListLoading,
      paymentLoading,
      primaryContactLoading,
      requestContactLoading,
      modalState,
      packageList,
      primaryContact,
      selectedCard,
      selectedPackage,
      handleCheckout,
      handleModalClose,
      handleModalOpen,
      handleOpenCustomerService,
      handlePayment,
      handleRetryAttempt,
      handleSelectPackage,
    ]
  );

  return (
    <ModalContext.Provider value={contextValue}>
      {children}
    </ModalContext.Provider>
  );
};

export const useBoostCreditsModalContext = () => {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error(
      'useBoostCreditsModalContext must be used within a BoostCreditsModalProvider'
    );
  }
  return context;
};
