import gql from 'graphql-tag';

import FILE_FRAGMENT from './FileFragment';
import IMAGE_FRAGMENT from './ImageFragment';
import JOB_BUSINESS_RELATION_STATE_FRAGMENT from './JobBusinessRelationStateFragment';

const JOB_BASIC_DATA_FRAGMENT = gql`
  ${IMAGE_FRAGMENT}
  ${FILE_FRAGMENT}
  ${JOB_BUSINESS_RELATION_STATE_FRAGMENT}

  fragment JobBasicDataFragment on Job {
    addressHint {
      city
      id
      lat
      lon
      postalCode
    }
    approvedAt
    description
    files {
      ...FileFragment
    }
    id
    images {
      ...ImageFragment
    }
    isFree
    jobBusinessRelationState {
      ...JobBusinessRelationStateFragment
    }
    folders {
      id
      title
      color
    }
    size
    title
  }
`;
export default JOB_BASIC_DATA_FRAGMENT;
