import { ApolloCache, makeReference } from '@apollo/client';

import { removeJobFromCachedJobList } from '../../utils/cache';
import JobListId from '../../utils/constants/jobListIds';
import { UNFAVOURITE_JOB } from '../../utils/generated/generated';
import { JobListOrJobQueryItem } from '../../utils/interfaces/graphql/JobListOrJobQueryItem.interface';

const unfavouriteJobUpdate =
  (jobId: JobListOrJobQueryItem['id']) =>
  (
    cache: ApolloCache<UNFAVOURITE_JOB>,
    { data }: { data?: UNFAVOURITE_JOB | null }
  ) => {
    if (data?.unfavouriteJob.success) {
      cache.modify({
        id: cache.identify({ jobId, __typename: 'JobBusinessRelationState' }),
        fields: {
          isFavourite() {
            return false;
          },
        },
      });
      // remove job from favourite job list
      cache.modify({
        id: cache.identify(makeReference('ROOT_QUERY')),
        fields: {
          jobList(existingJobList, { readField }) {
            if (existingJobList.listId === JobListId.Favourite) {
              return {
                ...existingJobList,
                jobConnection: removeJobFromCachedJobList(
                  existingJobList.jobConnection,
                  jobId,
                  readField
                ),
              };
            }
            return existingJobList;
          },
        },
      });

      // update favourite job list counter in job list filter
      cache.modify({
        id: cache.identify({
          id: JobListId.Favourite,
          __typename: 'JobListFilter',
        }),
        fields: {
          count(currentCount) {
            return currentCount - 1 >= 0 ? currentCount - 1 : 0;
          },
        },
      });
    }
  };

const unfavouriteJobOptimisticResponse: UNFAVOURITE_JOB = {
  unfavouriteJob: {
    __typename: 'UnfavouriteJobPayload',
    success: true,
  },
};

export { unfavouriteJobUpdate, unfavouriteJobOptimisticResponse };
