import { CreateContractFormShape } from '../../types/FormShape/CreateContract';
import { CHAT_JOB_QUERY_job_Job } from '../generated/generated';
import { CreateSimpleAgreementContractMutationVariables } from '../mutation/CreateSimpleAgreementContract/CreateSimpleAgreementContractMutation';
import { isNull } from '../objectUtils';
import { rawDateToTimestamp } from '../rawDateToTimestamp';

export const CreateSimpleAgreementContractAdapter = (
  formikValues: CreateContractFormShape,
  jobId: CHAT_JOB_QUERY_job_Job['id']
): CreateSimpleAgreementContractMutationVariables['input'] => ({
  jobId,
  priceType: formikValues.priceType,
  description: formikValues.description,
  sign: true,

  ...(formikValues.price && {
    price: parseInt(formikValues.price.toString(), 10),
  }),
  ...(isNull(formikValues.start)
    ? { startDate: null }
    : { startDate: rawDateToTimestamp(formikValues.start) }),
  ...(isNull(formikValues.end)
    ? { endDate: null }
    : { endDate: rawDateToTimestamp(formikValues.end) }),

  businessEmail: formikValues.businessEmail,
  businessPhone: formikValues.businessPhone,
});
