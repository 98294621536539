import gql from 'graphql-tag';

import { JobsSavedFilterFragment } from '../generated/generated';

export type JobsSavedFilter = JobsSavedFilterFragment;

export const JOBS_SAVED_FILTER_FRAGMENT = gql`
  fragment JobsSavedFilterFragment on JobWatchlist {
    id
    title
    filters {
      countyId
      districtId
      industryIds
      municipalityCode
      query
      sizes
      worktypeIds
      worktypeSubsetGroups
      worktypeSubsets
    }
    notificationSettings {
      id
      email
      push
    }
    readTime
    latestJobTime
  }
`;
