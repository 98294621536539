import {
  formatZipCode as formatPostalCode,
  addressString as createAddressString,
  businessAddressString as createBusinessAddressString,
  addressStringShort as createShortAddressString,
  getJobMapAddress as createJobMapAddress,
  jobMapAddressToString as convertJobMapAddressToString,
  BusinessAddressKind,
  JobMapAddress,
  JobWithAddress,
  BasicPostalAddressType,
  CountryName,
  getAddressForMaps as createAddressForMaps,
} from '@internals/business-shared/src/utils/address';
import { getProductZipCodeLength } from '@internals/business-shared/src/utils/constants/addressFormat';
import { AddressFragment } from '@internals/business-shared/src/utils/generated/generated';
import { CompanyInfoQueryBusiness } from '@internals/business-shared/src/utils/query/CompanyInfo/CompanyInfoQuery';
import { getProduct, getProductId } from '@utils/product';

export const getAddressForMaps = (
  city?: string | null,
  postalCode?: string | null,
  street?: string | null
): string => {
  const { countryName } = getProduct();
  return createAddressForMaps({
    city,
    zip: postalCode,
    street,
    country: countryName,
  });
};

export const getZipCodeLength = (): number =>
  getProductZipCodeLength(getProductId());

export const getFormattedZipCode = (zipCode: string): string => {
  const productId = getProductId();
  return formatPostalCode(zipCode, productId);
};

export const getAddressString = (
  address?: Pick<AddressFragment, 'street' | 'zip' | 'city'>
): string => {
  const productId = getProductId();
  return createAddressString(productId, address);
};

export const getBusinessAddressString = (
  addresses: CompanyInfoQueryBusiness['addresses'],
  type: BusinessAddressKind
): string => {
  const productId = getProductId();
  return createBusinessAddressString(addresses, type, productId);
};

export const getShortAddressString = (
  address: AddressFragment | BasicPostalAddressType
): string => {
  const productId = getProductId();
  return createShortAddressString(address, productId);
};

export const getJobMapAddress = (
  job: JobWithAddress,
  countryName: CountryName
): JobMapAddress | null => {
  const productId = getProductId();
  return createJobMapAddress(job, countryName, productId);
};

export const getJobMapAddressString = (mapAddress: JobMapAddress): string => {
  const productId = getProductId();
  return convertJobMapAddressToString(mapAddress, productId);
};
