import { FC } from 'react';

import JobLabelComponent from '@components/base/JobLabel';
import { FolderValues } from '@components/elements/JobFolders';
import { JOBS_LIST_QUERY_jobList_JobListPayload_jobConnection_edges_node } from '@internals/business-shared/src/utils/generated/generated';
import {
  DisplayedJobTagLabel,
  JobTagLabel,
} from '@internals/business-shared/src/utils/JobTags';
import { JobFolder } from '@internals/business-shared/src/utils/query/JobFolders/JobFoldersQuery';
import { Div } from '@schibsted-smb/fireball';
import { FlexWrapProps } from 'styled-system';

interface JobLabelsProps {
  labels: DisplayedJobTagLabel[] | JobTagLabel[];
  folders?: (JobFolder | FolderValues)[] | null;
  evaluation?: JOBS_LIST_QUERY_jobList_JobListPayload_jobConnection_edges_node['evaluation'];
  isGray?: boolean;
  isSmall?: boolean;
  flexWrap?: FlexWrapProps['flexWrap'];
}

const JobLabels: FC<JobLabelsProps> = ({
  labels,
  folders,
  evaluation,
  isGray,
  isSmall,
  flexWrap = 'wrap',
}) => (
  <Div display="flex" flexWrap={flexWrap}>
    {labels.map((label) => (
      <JobLabelComponent
        key={label}
        labelName={label}
        isGray={isGray}
        isSmall={isSmall}
      />
    ))}
    {!!evaluation && (
      <JobLabelComponent
        labelName={JobTagLabel.EVALUATION}
        evaluationValue={evaluation.score}
        isGray={isGray}
        isSmall={isSmall}
      />
    )}
    {folders &&
      folders.map((folder) => (
        <JobLabelComponent
          key={folder.id}
          labelName={JobTagLabel.FOLDER}
          folderName={folder.title}
          folderColor={folder.color || ''}
          isGray={isGray}
          isSmall={isSmall}
        />
      ))}
  </Div>
);

export default JobLabels;
