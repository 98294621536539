import { useCallback } from 'react';

import { useCompanyWantsContactAboutProduct } from '@internals/business-shared/src/hooks/mutation/useCompanyWantsContactAboutProduct';
import { ProductType } from '@internals/business-shared/src/utils/ProductUtils';

import { useCookieFlag } from './useCookieFlag';

type UseRequestContactType = (
  productType: ProductType,
  cookiePrefix: string
) => {
  requestContact: () => Promise<void>;
  requestContactLoading: boolean;
};

export const useRequestContact: UseRequestContactType = (
  productType,
  cookiePrefix
) => {
  const [companyWantsContactAboutProduct, { loading }] =
    useCompanyWantsContactAboutProduct();
  const [isRequestContactSent, setRequestContact] = useCookieFlag(cookiePrefix);

  const requestContact = useCallback(async () => {
    if (!isRequestContactSent) {
      await companyWantsContactAboutProduct(productType);
      setRequestContact();
    }
  }, [
    companyWantsContactAboutProduct,
    isRequestContactSent,
    productType,
    setRequestContact,
  ]);

  return { requestContact, requestContactLoading: loading };
};
