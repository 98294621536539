import { Children, FC, ReactNode, useCallback, useMemo, useRef } from 'react';

import { Pagination } from '@egjs/flicking-plugins';
import Flicking, { FlickingProps, ViewportSlot } from '@egjs/react-flicking';
import { Div } from '@schibsted-smb/fireball';
import { bugsnagClient } from '@utils/initBugsnag';

import '@egjs/flicking-plugins/dist/pagination.css';
import '@egjs/react-flicking/dist/flicking.css';
import { PaginationContainer } from './styled/PaginationContainer';

interface CarouselProps {
  children: ReactNode;
  defaultIndex?: number;
  onIndexChanged?: (index: number) => void;
}

export const Carousel: FC<CarouselProps> = ({
  children,
  defaultIndex,
  onIndexChanged,
}) => {
  const childArray = Children.toArray(children);
  const flickingRef = useRef<Flicking>(null);
  const plugins = useMemo(() => [new Pagination({ type: 'bullet' })], []);

  const handleMoveTo = useCallback(async (index: number) => {
    try {
      await flickingRef.current?.moveTo(index);
    } catch (e) {
      bugsnagClient.notify(
        `Handle move to selected package failed: ${e as string}`
      );
    }
  }, []);

  return (
    <Flicking
      circularFallback="bound"
      defaultIndex={defaultIndex}
      inputType={['pointer']}
      moveType="strict"
      plugins={plugins}
      preventDefaultOnDrag
      ref={flickingRef}
      onChanged={(e) => onIndexChanged(e.index)}
      useFindDOMNode
    >
      {childArray.map((child, index) => (
        // eslint-disable-next-line react/no-array-index-key, @typescript-eslint/no-misused-promises
        <Div key={index} onClick={() => handleMoveTo(index)}>
          {child}
        </Div>
      ))}
      <ViewportSlot>
        <PaginationContainer className="flicking-pagination" />
      </ViewportSlot>
    </Flicking>
  );
};
