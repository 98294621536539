import { FC, useMemo, useCallback } from 'react';

import { Carousel } from '@components/base/Carousel';
import { useFlagEnabled } from '@components/base/CheckFlag';
import { useIsMobile } from '@contexts/DeviceSizeContext';
import FeatureFlags from '@internals/business-shared/src/utils/constants/FeatureFlags';
import { Column } from '@schibsted-smb/fireball';

import '@egjs/flicking-plugins/dist/pagination.css';
import '@egjs/react-flicking/dist/flicking.css';
import { useBoostCreditsModalContext } from '../../ModalContext';
import { PackageCard } from '../PackageCard';
import { PackageContainer } from './styled/PackageContainer';

export const Packages: FC = () => {
  const {
    packageList,
    selectedPackage: { discount } = {},
    handleSelectPackage,
  } = useBoostCreditsModalContext();
  const isBoostCreditsDiscountFlagEnabled = useFlagEnabled(
    FeatureFlags.BizBoostCreditsDiscount
  );
  const isMobile = useIsMobile();
  const isCarousel = isMobile && packageList.length > 1;

  const renderedPackages = useMemo(
    () =>
      packageList.map(({ id, ...rest }) => (
        <PackageContainer
          isDiscount={isBoostCreditsDiscountFlagEnabled && !!discount}
          key={id}
        >
          <PackageCard {...rest} />
        </PackageContainer>
      )),
    [packageList, isBoostCreditsDiscountFlagEnabled, discount]
  );

  const handleIndexChanged = useCallback(
    (index: number) => handleSelectPackage(packageList[index].slug),
    [handleSelectPackage, packageList]
  );

  return (
    <Column
      px={0}
      mb={isCarousel && 6}
      width={isCarousel ? 1 : 'auto'}
      display={isCarousel ? 'block' : 'flex'}
    >
      {isCarousel ? (
        <Carousel
          defaultIndex={packageList.length - 1}
          onIndexChanged={handleIndexChanged}
        >
          {renderedPackages}
        </Carousel>
      ) : (
        renderedPackages
      )}
    </Column>
  );
};
