import { EditContractFormShape } from '../../types/FormShape/EditContract';
import { SimpleAgreementPriceType } from '../generated/generated';
import { EditSimpleAgreementContractMutationVariables } from '../mutation/EditSimpleAgreementContract/EditSimpleAgreementContract';
import { isNull, isNullOrUndefined } from '../objectUtils';
import { SimpleContract } from '../query/SimpleContract/SimpleContractQuery';
import rawDateFromTimestamp from '../rawDateFromTimestamp';
import { rawDateToTimestamp } from '../rawDateToTimestamp';

export const EditSimpleAgreementContractAdapter = (
  formikValues: EditContractFormShape,
  jobId: SimpleContract['job']['id']
): EditSimpleAgreementContractMutationVariables['input'] => ({
  jobId,
  description: formikValues.description,
  price: formikValues.price
    ? parseInt(formikValues.price.toString(), 10)
    : undefined,
  priceType: formikValues.priceType,

  ...(isNull(formikValues.start)
    ? { startDate: null }
    : { startDate: rawDateToTimestamp(formikValues.start) }),
  ...(isNull(formikValues.end)
    ? { endDate: null }
    : { endDate: rawDateToTimestamp(formikValues.end) }),
  sign: true,
  businessEmail: formikValues.businessEmail,
  businessPhone: formikValues.businessPhone,
});

export const EditSimpleAgreementContractFormikAdapter = (
  contractItem: SimpleContract
): EditContractFormShape =>
  ({
    id: contractItem.id,
    price: contractItem.price,
    priceType: contractItem.priceType as unknown as SimpleAgreementPriceType,
    description: contractItem.description,
    start: isNullOrUndefined(contractItem.startDate)
      ? null
      : rawDateFromTimestamp(contractItem.startDate),
    end: isNullOrUndefined(contractItem.endDate)
      ? null
      : rawDateFromTimestamp(contractItem.endDate),
    businessEmail: contractItem?.businessEmail ?? '',
    businessPhone: contractItem?.businessPhone ?? '',
  } as EditContractFormShape);
