import { makeReference, Reference } from '@apollo/client';
import { ReadFieldFunction } from '@apollo/client/cache/core/types/common';

import JobListId from './constants/jobListIds';
import {
  CHAT_LIST_QUERY_chatList_ChatListPayload_chatConnection_edges_node_job,
  PageInfoFragment,
} from './generated/generated';
import { JobListOrJobQueryItem } from './interfaces/graphql/JobListOrJobQueryItem.interface';
import { ChatListEdge } from './query/ChatList/ChatListQuery';
import { Job } from './query/Job/JobQuery';

type nodeRef = { node: Reference };
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type cachedConnection = { pageInfo: PageInfoFragment[]; edges: any[] };
type cachedPageInfo = cachedConnection['pageInfo'];
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type dataConnection = { pageInfo: PageInfoFragment; edges: any[] };
export type MergeDirection = 'ASC' | 'DESC';

export interface MergeOptions {
  readField: ReadFieldFunction;
  sortDirection?: MergeDirection;
  sortField?: string;
}

export const JOBS_LIST_DEFAULT_PAGE_COUNT = 25;

const calculateOffset = (page: number, count: number): number =>
  (page - 1) * count;
const calculateTotalPages = (totalCount: number, count: number): number =>
  Math.ceil(totalCount / count);
const getElementsForPage = <T>(
  elementList: T[],
  count: number,
  offset: number
): T[] => elementList.slice(offset, offset + count);

export const getTimeStampFromRef = (
  ref: nodeRef,
  readField: ReadFieldFunction,
  timeStampField: string
): number => {
  const value = readField(timeStampField, ref.node);
  return typeof value === 'number' ? value : 0;
};

// As a workaround using underscore comparison, because of current limitations with the readField method.
// It behaves unexpectedly for some queries since returns undefined for following incoming cache data.
// Issue observed on Apollo Client version (3.7.1). Not yet resolved issue is placed here: https://github.com/apollographql/apollo-client/issues/9315
const getEdgeRefs = (edges: nodeRef[]) =>
  edges.reduce((set: Set<string>, edge: nodeRef) => {
    if (edge?.node) {
      // eslint-disable-next-line no-underscore-dangle
      set.add(edge.node.__ref);
    }
    return set;
  }, new Set());

export const removeIncomingEdgeDuplicates = ({
  existingEdges,
  incomingEdges,
}: {
  existingEdges: nodeRef[];
  incomingEdges: nodeRef[];
}) => {
  const existingEdgeRefs = getEdgeRefs(existingEdges);
  // eslint-disable-next-line no-underscore-dangle
  return incomingEdges.filter(
    (edge: nodeRef) => !existingEdgeRefs.has(edge?.node.__ref)
  );
};

export const sortEdges = (
  edges: nodeRef[],
  readField: ReadFieldFunction,
  sortDirection: MergeDirection = 'ASC',
  sortByField = 'regTs'
) =>
  [...edges].sort((a, b) => {
    const aTimeStamp = getTimeStampFromRef(a, readField, sortByField);
    const bTimeStamp = getTimeStampFromRef(b, readField, sortByField);
    return sortDirection === 'ASC'
      ? aTimeStamp - bTimeStamp
      : bTimeStamp - aTimeStamp;
  });

export const sortDataByRegTs = <T extends { regTs: number }>(
  data: T[],
  direction: MergeDirection
): T[] =>
  [...data].sort((a, b) =>
    direction === 'ASC' ? a.regTs - b.regTs : b.regTs - a.regTs
  );

export const mergeConnections = (
  existingConnection: cachedConnection | undefined,
  incomingConnection: dataConnection,
  page: number | null,
  limit: number | null
): cachedConnection => {
  // Make copy shallow copy for safe use or return an empty array if no data is present.
  const mergedEdges = [...(existingConnection?.edges || [])];
  const mergedPageInfo = [...(existingConnection?.pageInfo || [])];
  const incomingEdges = [...(incomingConnection?.edges || [])];
  const incomingPageInfo = incomingConnection?.pageInfo;

  if (!page || typeof limit !== 'number') {
    return { edges: incomingEdges, pageInfo: [incomingPageInfo] };
  }

  // Make sure that incoming edges are not already in the cache.
  const uniqueIncomingEdges = removeIncomingEdgeDuplicates({
    existingEdges: mergedEdges,
    incomingEdges,
  });

  // Calculate offset and limit for the incoming data.
  const offset = calculateOffset(page, limit);
  const end = offset + Math.min(limit, uniqueIncomingEdges.length);

  // Insert the incoming elements in the right places of cached list, according to page.
  for (let i = offset; i < end; ++i) {
    mergedEdges[i] = uniqueIncomingEdges[i - offset];
  }

  // Add the incoming page info to the right cache place, according to page.
  mergedPageInfo[page - 1] = incomingPageInfo;

  return { edges: mergedEdges, pageInfo: mergedPageInfo };
};

export const readConnections = (
  existing: cachedConnection | undefined,
  page?: number | null,
  count?: number | null
): dataConnection | undefined => {
  const existingEdges = existing?.edges || [];
  const existingPageInfo = existing?.pageInfo || [];

  if (typeof count !== 'number' || !page) return undefined;

  // get from cache only elements for requested page
  const offset = calculateOffset(page, count);
  const edges = getElementsForPage(existingEdges, count, offset);

  return {
    edges,
    pageInfo: existingPageInfo[page - 1],
  };
};

const calculateNewTotalCount = (
  existingPageInfo: PageInfoFragment,
  isAddition?: boolean
) => {
  if (isAddition) {
    const existingTotalCount = existingPageInfo?.totalCount || 0;
    return existingTotalCount + 1;
  }
  const existingTotalCount = existingPageInfo?.totalCount;
  return existingTotalCount && existingTotalCount > 0
    ? existingTotalCount - 1
    : 0;
};

const recalculatePageInfo = (existing: cachedPageInfo, isAddition: boolean) => {
  const [existingPageInfo] = existing;
  const newTotalCount = calculateNewTotalCount(existingPageInfo, isAddition);
  const newTotalPages =
    typeof existingPageInfo?.pageSizeLimit === 'number'
      ? calculateTotalPages(newTotalCount, existingPageInfo.pageSizeLimit)
      : null;

  // assign new pageInfo data to all elements in cached list pages collection
  return existing.map((info) =>
    info
      ? {
          ...info,
          totalCount: newTotalCount,
          totalPages: newTotalPages,
        }
      : info
  );
};

export const recalculatePageInfoAfterRemoval = (existing: cachedPageInfo) =>
  recalculatePageInfo(existing, false);
export const recalculatePageInfoAfterAddition = (existing: cachedPageInfo) =>
  recalculatePageInfo(existing, true);

export const readJobListPage = (
  existing: { jobConnection: cachedConnection } | undefined,
  page?: number | null,
  count?: number | null
): { jobConnection: dataConnection } | undefined => {
  const jobConnection = readConnections(existing?.jobConnection, page, count);
  if (!jobConnection || typeof count !== 'number' || !page) return undefined;

  const { edges, pageInfo } = jobConnection;
  // when manipulating on job items (moving them between list when favouring/deleting etc), there might be less elements on page than expected
  // here is the check if list from cache has necessary amount of items. If not, return `undefined` to force network request for the data
  const totalCount = pageInfo?.totalCount || 0;
  const offset = calculateOffset(page, count);
  const isPageIncomplete =
    edges.length < count && offset + edges.length < totalCount;
  if (isPageIncomplete) return undefined;

  return {
    ...existing,
    jobConnection,
  };
};

export const readInfiniteScrollConnections = (
  existing: cachedConnection | undefined,
  options?: MergeOptions
): dataConnection | undefined => {
  if (!existing) return undefined;
  const existingEdges = existing.edges || [];
  const existingPageInfo = existing.pageInfo || [];

  return {
    edges: options
      ? sortEdges(
          existingEdges,
          options.readField,
          options.sortDirection,
          options.sortField
        )
      : existingEdges,
    pageInfo: existingPageInfo[existingPageInfo.length - 1],
  };
};

export const mergeInfiniteScrollConnections = (
  existingConnection: cachedConnection | undefined,
  incomingConnection: dataConnection,
  options: MergeOptions
): cachedConnection => {
  // Make copy shallow copy for safe use or return an empty array if no data is present.
  const existingEdges = [...(existingConnection?.edges || [])];
  const incomingEdges = [...(incomingConnection?.edges || [])];
  const incomingPageInfo = incomingConnection?.pageInfo;
  const { readField, sortField, sortDirection } = options;

  // Make sure that incoming edges are not already in the cache.
  const uniqueIncomingEdges = removeIncomingEdgeDuplicates({
    existingEdges,
    incomingEdges,
  });
  // Concatenate the incoming edges with the existing edges.
  const mergedEdges = [...existingEdges, ...uniqueIncomingEdges];

  return {
    edges: sortDirection
      ? sortEdges(mergedEdges, readField, sortDirection, sortField)
      : mergedEdges,
    pageInfo: [incomingPageInfo],
  };
};

// Returns array of LIST ID, on which to job needs to be added. This way we follow backend behaviour
export const findJobListDestinations = (
  job: Pick<Job, 'isFree' | 'folders'> & {
    jobBusinessRelationState: Pick<
      Job['jobBusinessRelationState'],
      'isAnswered' | 'isWon' | 'isLost' | 'isFavourite' | 'isOneOnOneReceiver'
    >;
  } & { traits?: JobListOrJobQueryItem['traits'] }
) => {
  const { isAnswered, isWon, isLost, isFavourite, isOneOnOneReceiver } =
    job.jobBusinessRelationState;

  return [
    ...(!isAnswered && !isWon && !isLost ? [JobListId.Open] : []),
    ...(isFavourite ? [JobListId.Favourite] : []),
    ...(job.traits?.big ? [JobListId.Big] : []),
    ...(!isAnswered && !isWon && !isLost && job.isFree ? [JobListId.Free] : []),
    ...(isAnswered ? [JobListId.Answered] : []),
    ...(isWon ? [JobListId.Won] : []),
    ...(job.folders?.length ? [JobListId.Folder] : []),
    ...(isOneOnOneReceiver ? [JobListId.Direct] : []),
  ];
};

export const removeJobFromCachedJobList = (
  existingJobList: cachedConnection,
  jobId: JobListOrJobQueryItem['id'],
  readField: ReadFieldFunction
): cachedConnection => {
  // find index of removed job
  const removeJobIndex = existingJobList.edges.findIndex((edge: nodeRef) =>
    edge ? readField('id', edge.node) === jobId : false
  );
  // if job was not found, simply return already existing list
  if (removeJobIndex === -1) return existingJobList;

  // if job index found remove proper job from list
  const newJobListEdges = [...existingJobList.edges];
  newJobListEdges.splice(removeJobIndex, 1);
  // Recount amount of jobs and pages
  const newPageInfo = recalculatePageInfoAfterRemoval(existingJobList.pageInfo);

  // return updated data
  return {
    pageInfo: newPageInfo,
    edges: newJobListEdges,
  };
};

export interface ChatListQueryJobExtendedType
  extends CHAT_LIST_QUERY_chatList_ChatListPayload_chatConnection_edges_node_job {
  latestMessage: Pick<ChatListEdge['node']['latestMessage'], 'id' | 'regTs'>;
}

export const addJobToCachedJobList = (
  existingJobList: cachedConnection,
  job: JobListOrJobQueryItem | ChatListQueryJobExtendedType,
  readField: ReadFieldFunction,
  sortByLatestMessage: boolean,
  expectedPosition?: number
) => {
  // if job is already on list, simply return the list
  const jobAlreadyOnList = existingJobList.edges.some(
    (edge: nodeRef | undefined) => edge && readField('id', edge.node) === job.id
  );
  if (jobAlreadyOnList) return existingJobList;

  // find proper timestamp value for sort (and display) messages by time
  const getLatestTimestamp = (readJob: nodeRef) => {
    if (sortByLatestMessage) {
      // get latest message timestamp
      return readField('regTs', readField('latestMessage', readJob.node)) ?? 0;
    }
    // get job approval timestamp
    return readField('approvedAt', readJob.node) ?? 0;
  };

  // reducer - compare job items and find the oldest/newest one
  const reduceJobsByTs =
    (type: 'newest' | 'oldest') =>
    (previousJob: nodeRef | undefined, currentJob: nodeRef | undefined) => {
      if (currentJob) {
        const previousTs = previousJob ? getLatestTimestamp(previousJob) : 0;
        const currentTs = getLatestTimestamp(currentJob);
        if (type === 'newest') {
          return currentTs >= previousTs ? currentJob : previousJob;
        }
        return currentTs <= previousTs || previousTs === 0
          ? currentJob
          : previousJob;
      }
      return previousJob;
    };

  const findJobIndexOnList = (job: nodeRef, list: (nodeRef | undefined)[]) =>
    list.findIndex((edge: nodeRef | undefined) =>
      edge && job
        ? readField('id', edge.node) === readField('id', job.node)
        : false
    );

  const newJobListEdges = [...existingJobList.edges];

  // Create cache object, and reference to job cache item
  const jobCacheItem = {
    __typename: 'JobEdge',
    node: makeReference(`Job:${job.id}`),
  };
  const jobTs = getLatestTimestamp(jobCacheItem);

  // find closest newer job
  const closestNewerJob = existingJobList.edges
    .filter(
      (checkedJob: nodeRef | undefined) =>
        checkedJob && getLatestTimestamp(checkedJob) > jobTs
    )
    .reduce(reduceJobsByTs('oldest'), null);
  // find closest older job
  const closestOlderJob = existingJobList.edges
    .filter(
      (checkedJob: nodeRef | undefined) =>
        checkedJob && getLatestTimestamp(checkedJob) < jobTs
    )
    .reduce(reduceJobsByTs('newest'), null);
  const closestNewerJobIndex = closestNewerJob
    ? findJobIndexOnList(closestNewerJob, existingJobList.edges)
    : null;
  const closestOlderJobIndex = closestOlderJob
    ? findJobIndexOnList(closestOlderJob, existingJobList.edges)
    : null;

  // add job to proper place in the list
  if (expectedPosition) {
    // final position is known (when deleting element is still accessible on list to restore)
    newJobListEdges.splice(expectedPosition, 0, jobCacheItem);
  } else if (closestNewerJobIndex === null) {
    // there is no `newest` job, insert to the beginning
    newJobListEdges.unshift(jobCacheItem);
  } else if (closestOlderJobIndex === null) {
    // there is no `older` job, insert to the end
    newJobListEdges.push(jobCacheItem);
  } else {
    // insert before the first older job item
    newJobListEdges.splice(closestOlderJobIndex, 0, jobCacheItem);
  }

  // re-calculate data for page info
  const newPageInfo = recalculatePageInfoAfterAddition(
    existingJobList.pageInfo
  );

  return {
    pageInfo: newPageInfo,
    edges: newJobListEdges,
  };
};

// storeFieldName looks like 'chatMessages:{"input":{"chatId":"1111"}}' or 'chatMessages:{"input":{"chatId":"1a-1b-c1"}}' when using mock server
export const getChatIdFromCachedChatMessages = (
  storeFieldName: unknown
): null | string => {
  if (typeof storeFieldName !== 'string') return null;
  const match = storeFieldName.match(/"chatId":"([\d\w-]+)"/);
  return match ? match[1] : null;
};

export default {
  mergeConnections,
  readConnections,
  readInfiniteScrollConnections,
  findJobListDestinations,
  removeJobFromCachedJobList,
  addJobToCachedJobList,
  getChatIdFromCachedChatMessages,
};
