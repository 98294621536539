import { FC, ReactNode, useEffect } from 'react';

import { useFlagExcludedJobLists } from '@hooks/useFlagExcludedJobLists';
import {
  useJobListActiveId,
  useJobListAllFilters,
} from '@hooks/useJobListAllFilters';
import { useRouteChangedToJobs } from '@hooks/useRouteChanged';
import {
  SavedFiltersContextProvider as SharedSavedFiltersContextProvider,
  useSavedFiltersContext,
} from '@internals/business-shared/src/components/SavedFilters/context';
import { useTranslation } from 'react-i18next';

interface SavedFiltersContextProviderProps {
  children: ReactNode;
}

const SavedFiltersRefetchComponent: FC<
  Pick<SavedFiltersContextProviderProps, 'children'>
> = ({ children }) => {
  const routeChangedToJobs = useRouteChangedToJobs();
  const { refetchSavedFilters } = useSavedFiltersContext();

  useEffect(() => {
    if (routeChangedToJobs) {
      refetchSavedFilters();
    }
  }, [routeChangedToJobs]);
  return <>{children}</>;
};

export const SavedFiltersContextProvider: FC<
  SavedFiltersContextProviderProps
> = ({ children }) => {
  const excludedJobListIds = useFlagExcludedJobLists();
  const activeFilters = useJobListAllFilters();
  const activeListId = useJobListActiveId();
  const { i18n } = useTranslation();

  return (
    <SharedSavedFiltersContextProvider
      activeFilters={activeFilters}
      activeListId={activeListId}
      excludedJobListIds={excludedJobListIds}
      currentLanguage={i18n.language}
    >
      <SavedFiltersRefetchComponent>{children}</SavedFiltersRefetchComponent>
    </SharedSavedFiltersContextProvider>
  );
};
