import {
  FC,
  ReactElement,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { JobTraits } from '@components/layout/Job/JobTraits';
import { FOOTER_BOX_SHADOW } from '@components/layout/Job/styled/constants';
import { useIsTabletOrMobile } from '@contexts/DeviceSizeContext';
import { isBoligmappaRequestedForJob } from '@internals/business-shared/src/utils/BoligMappaUtils';
import FeatureFlags from '@internals/business-shared/src/utils/constants/FeatureFlags';
import {
  FileFragment,
  ImageFragment,
  JOB_OWNER_QUERY_job_Job,
  JOB_OWNER_QUERY_job_Job_contactInfo,
  JOB_QUERY_jobMeta,
} from '@internals/business-shared/src/utils/generated/generated';
import { isNullOrUndefined } from '@internals/business-shared/src/utils/objectUtils';
import { Job } from '@internals/business-shared/src/utils/query/Job/JobQuery';
import { JobTraitsQueryData } from '@internals/business-shared/src/utils/query/JobTraits/JobTraits';
import { Row, Column, Div } from '@schibsted-smb/fireball';
import { Layer } from '@utils/layer';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import { useFlagEnabled } from '../../base/CheckFlag';
import { JobCarSpec } from './JobCarSpec';
import JobContentOwnerInfo from './JobContentOwnerInfo';
import { JobDataItem } from './JobDataItem';
import { JobDetails } from './JobDetails';
import { JobFiles } from './JobFiles';
import { JobGenericsMetadata } from './JobGenericsMetadata';
import { JobHtmlContentRow } from './JobHtmlContentRow';
import { JobImages } from './JobImages';
import { JobRejection } from './JobRejection';
import { JobRejectionFooter } from './JobRejectionFooter';
import { JobTransportAddress } from './JobTransportAddress';
import { JobContentSection } from './styled/JobContentSection';

export interface JobContentProps {
  job: Pick<
    Job,
    | 'answerCount'
    | 'answerCountLimit'
    | 'description'
    | 'address'
    | 'addressHint'
    | 'files'
    | 'images'
    | 'id'
    | 'jobBusinessRelationState'
  >;
  meta: JOB_QUERY_jobMeta;
  traits: JobTraitsQueryData | null;
  owner?: JOB_OWNER_QUERY_job_Job['owner'];
  contactDetails: JOB_OWNER_QUERY_job_Job_contactInfo;
  isLoadingOwnerDetails: boolean;
  alert: ReactElement;
  answerForm: ReactElement;
  allowAnswer: boolean;
  answerInProgress: boolean;
  onClickAttachment?: (file: ImageFragment | FileFragment) => void;
  chatId?: string;
}

const JobContent: FC<JobContentProps> = ({
  job,
  meta,
  traits,
  owner,
  isLoadingOwnerDetails,
  alert,
  answerForm,
  allowAnswer,
  contactDetails,
  answerInProgress,
  onClickAttachment,
}) => {
  const {
    answerCount,
    answerCountLimit,
    description,
    address,
    addressHint,
    files,
    images,
    id,
    jobBusinessRelationState,
  } = job;
  const { t } = useTranslation();
  const themeContext = useTheme();
  const [showRejectionFooter, setShowRejectionFooter] = useState(false);
  const isTabletOrMobile = useIsTabletOrMobile();
  const [footerHeight, setFooterHeight] = useState(0);
  const footerRef = useRef<HTMLDivElement>(null);
  const { isAnswered, isRejected, isOneOnOneReceiver } =
    jobBusinessRelationState;
  const isRejectionEnabled = useFlagEnabled(
    FeatureFlags.BizRejectionOneToOneJobs
  );
  const footer = useMemo(() => {
    if (showRejectionFooter) {
      return (
        <JobRejectionFooter
          toggleRejectionFeedback={setShowRejectionFooter}
          jobId={id}
        />
      );
    }
    return allowAnswer ? answerForm : alert;
  }, [showRejectionFooter, allowAnswer, answerForm, alert]);

  useLayoutEffect(() => {
    if (footerRef?.current) {
      setFooterHeight(footerRef.current.clientHeight);
    }
  }, [footerRef, footer]);

  return (
    <Row
      overflow="auto"
      position="relative"
      height={isTabletOrMobile ? 'auto' : '100%'}
    >
      <Column
        width={[1, null, null, null, null, null, 8 / 12]}
        p={0}
        height="100%"
        borderRight="1px solid"
        borderColor="black.black3"
      >
        <Row pb={isTabletOrMobile ? '0' : footerHeight}>
          <Div
            display="flex"
            flexDirection="column"
            px={6}
            pt={6}
            width="100%"
            data-testid="job-card-description"
          >
            {meta.toAddress && (
              <JobContentSection>
                <JobTransportAddress
                  address={address}
                  addressHint={addressHint}
                  destinationAddress={meta.toAddress}
                  showFullAddress={isAnswered}
                />
              </JobContentSection>
            )}
            {description && (
              <JobContentSection>
                <JobHtmlContentRow>{description}</JobHtmlContentRow>
              </JobContentSection>
            )}
            <JobContentSection>
              <JobDetails
                contactPreference={meta.contactPreference}
                answerCount={answerCount}
                answerCountLimit={answerCountLimit}
                customerType={meta.customerType}
                requestedStart={meta.requestedStart}
              />
            </JobContentSection>
            {!!images?.length && (
              <JobContentSection data-testid="job-card-image-section">
                <JobImages images={images} onClick={onClickAttachment} />
              </JobContentSection>
            )}
            {!!files?.length && (
              <JobContentSection data-testid="job-card-file-section">
                <JobFiles files={files} onClick={onClickAttachment} />
              </JobContentSection>
            )}
            {meta.car && (
              <JobContentSection data-testid="job-card-car-section">
                <JobCarSpec
                  carData={meta.car}
                  showSensitiveInfo={!answerInProgress && isAnswered}
                />
              </JobContentSection>
            )}
            <JobContentSection>
              {!!meta.generics.length && (
                <JobGenericsMetadata meta={meta.generics} />
              )}
              {!isNullOrUndefined(traits) && <JobTraits traits={traits} />}
              <JobDataItem title={t('job.item.id')} text={id} />
            </JobContentSection>
            {!!isOneOnOneReceiver && isRejectionEnabled && (
              <JobContentSection>
                <JobRejection
                  isRejected={isRejected}
                  openRejectionFeedback={setShowRejectionFooter}
                  jobId={job.id}
                />
              </JobContentSection>
            )}
          </Div>
        </Row>
        <Row>
          <Div
            position="fixed"
            width={[1, null, null, null, null, null, 8 / 12]}
            zIndex={Layer.fixed}
            bottom={0}
            left={0}
            ref={footerRef}
            borderRight={isTabletOrMobile ? 'none' : '1px solid transparent'}
            px={6}
            boxShadow={allowAnswer && FOOTER_BOX_SHADOW}
            borderTop={
              allowAnswer && `1px solid ${themeContext.colors.black.black3}`
            }
          >
            {footer}
          </Div>
        </Row>
      </Column>
      <Column
        width={[1, null, null, null, null, null, 4 / 12]}
        px={6}
        position={['static', null, null, null, null, null, 'sticky']}
        top={6}
        alignSelf="flex-start"
        mb={!isTabletOrMobile ? '0' : footerHeight}
      >
        <JobContentSection>
          <JobContentOwnerInfo
            isAnswered={!answerInProgress && isAnswered}
            owner={owner}
            address={address}
            addressHint={addressHint}
            showAddressAndMap={!meta.toAddress}
            isLoading={isLoadingOwnerDetails}
            contactDetails={contactDetails}
            contactPreference={meta.contactPreference}
            withHeader={!isTabletOrMobile}
            jobId={id}
            traits={traits}
            isBoligmappaRequested={isBoligmappaRequestedForJob(meta)}
          />
        </JobContentSection>
      </Column>
    </Row>
  );
};

export default JobContent;
