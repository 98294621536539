import { Params } from '@router/paths';

export enum ModalState {
  SELECT_PACKAGE = 'SELECT_PACKAGE',
  CONFIRM_PURCHASE = 'CONFIRM_PURCHASE',
  SUMMARY = 'SUMMARY',
  NOT_COMPLETED = 'NOT_COMPLETED',
}

export enum TriggerSource {
  JOB_LIST = 'JobList',
  JOB_CARD = 'JobCard',
  AUTO_OPEN = 'AutoOpen',
}

export enum HasCard {
  YES = 'Yes',
  NO = 'No',
}

export enum AnalyticsEvent {
  ALERT_JOB_LIST_CLICKED = 'ALERT_JOB_LIST_CLICKED',
  ALERT_JOB_CARD_CLICKED = 'ALERT_JOB_CARD_CLICKED',
  AUTO_OPENED = 'AUTO_OPENED',
  PACKAGE_SELECTED = 'PACKAGE_SELECTED',
  PURCHASE_CONFIRMED = 'PURCHASE_CONFIRMED',
  PURCHASE_SUCCESS = 'PURCHASE_SUCCESS',
  PURCHASE_CANCELED = 'PURCHASE_CANCELED',
  PURCHASE_FAILED = 'PURCHASE_FAILED',
  RETRY_ATTEMPTED = 'RETRY_ATTEMPTED',
  CUSTOMER_SERVICE_OPENED = 'CUSTOMER_SERVICE_OPENED',
  MODAL_CLOSED = 'MODAL_CLOSED',
}

export const BOOST_PARAMS = {
  MODAL: Params.BoostCreditsModal,
  SELECTED_PACKAGE_SLUG: Params.SelectedPackage,
  PAYMENT_RESULT: 'resultCode',
};

export const BOOST_REQUEST_CONTACT = 'boost-request-contact';
export const PAYMENT_RESULT_SUCCESS_CODE = '10';
export const PAYMENT_RESULT_SUCCESS_NAME = 'PAID';

export const CUSTOM_BLUE_600 = '#365FC0';
export const CUSTOM_GREEN_50 = '#ECF4EE';
export const CUSTOM_GREEN_700 = '#016B50';
export const CUSTOM_GREEN_800 = '#28473B';
export const CUSTOM_GREY_600 = '#58627A';
export const CUSTOM_YELLOW_200 = '#F6D9A1';
export const CUSTOM_BLUE_900 = '#11203F';
export const CUSTOM_YELLOW_100 = '#F6E6C8';
