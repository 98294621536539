import gql from 'graphql-tag';

import {
  MARK_SAVED_FILTER_JOB_LIST_AS_READ as MARK_SAVED_FILTER_JOB_LIST_AS_READ_Result,
  MARK_SAVED_FILTER_JOB_LIST_AS_READVariables,
} from '../../generated/generated';

export type MarkSavedFilterJobListAsReadPayload =
  MARK_SAVED_FILTER_JOB_LIST_AS_READ_Result;
export type MarkSavedFilterJobListAsReadVariables =
  MARK_SAVED_FILTER_JOB_LIST_AS_READVariables;

export const MARK_SAVED_FILTER_JOB_LIST_AS_READ = gql`
  mutation MARK_SAVED_FILTER_JOB_LIST_AS_READ($id: ID!) {
    markJobWatchlistAsRead(id: $id)
  }
`;
