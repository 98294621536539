import { NavigationModel } from '@components/elements/Navigation';
import { PillNavigationItemType } from '@components/elements/PillNavigationItem';
import {
  COUNTY_PARAM_KEY,
  DISTRICT_PARAM_KEY,
  FOLDER_PARAM_KEY,
  INDUSTRY_PARAM_KEY,
  MUNICIPALITY_PARAM_KEY,
  PAGE_PARAM_KEY,
  SEARCH_PARAM_KEY,
  SIZE_PARAM_KEY,
  WORK_SUBSET_PARAM_KEY,
  WORK_TYPE_PARAM_KEY,
  WORK_TYPE_SUBSET_GROUP_PARAM_KEY,
} from '@hooks/useJobListSearchParams';
import { JobListFilter } from '@internals/business-shared/src/components/SavedFilters/types';
import {
  createJobListFilterNavId,
  isJobListSavedFilter,
} from '@internals/business-shared/src/components/SavedFilters/utils';
import JobListId from '@internals/business-shared/src/utils/constants/jobListIds';
import { JobSize } from '@internals/business-shared/src/utils/generated/generated';
import { filterOutEmptyValueKeys } from '@internals/business-shared/src/utils/objectUtils';
import { JobsListQueryVariables } from '@internals/business-shared/src/utils/query/JobsList/JobsListQuery';
import Paths, { Params } from '@router/paths';
import { t } from '@utils/i18n';
import { getUrlWithFilter } from '@utils/url';

const DEFAULT_LIST_ID = JobListId.Open;
// used primarily to display proper number of items in job list content loader
const DEFAULT_JOB_COUNT = 10;

export interface FilterCounter {
  id: number | string;
  name: string;
  count: number;
}

export type JobsNavigation = (Omit<NavigationModel, 'icon'> & { id: string })[];

export const getJobListMockedFilters = () => [
  { id: JobListId.Open, name: t('job.list.type_OPEN') },
  { id: JobListId.Favourite, name: t('job.list.type_FAVOURITE') },
  { id: JobListId.Big, name: t('job.list.type_BIG') },
  { id: JobListId.Free, name: t('job.list.type_FREE') },
  { id: JobListId.Answered, name: t('job.list.type_ANSWERED') },
  { id: JobListId.Won, name: t('job.list.type_WON') },
  { id: JobListId.Folder, name: t('job.list.type_FOLDERS') },
  { id: JobListId.Deleted, name: t('job.list.type_DELETED') },
];

export const getJobListMockedNavigation = (): JobsNavigation =>
  getJobListMockedFilters().map((filter) => ({
    id: filter.id,
    link: '',
    // icon: filter.type,
    label: filter.name,
  }));

export const getJobListMockedFiltersCounter = (): FilterCounter[] => {
  return getJobListMockedFilters().map((filter) => ({
    id: filter.id,
    name: filter.name,
    count: DEFAULT_JOB_COUNT,
  }));
};

export const getJobListFilterCounter = (
  jobFilters: FilterCounter[],
  id?: string | null
): FilterCounter => {
  const listId = id ?? DEFAULT_LIST_ID;
  const list = jobFilters.find((filter) => filter.id === listId);
  return {
    id: listId,
    name: list?.name || t('job.list.title'),
    count: list?.count ?? DEFAULT_JOB_COUNT,
  };
};

// used for replacing job list id with search query params
export const jobListIdToFilterMap: Partial<Record<JobListId, string>> = {
  [JobListId.Big]: getUrlWithFilter(`${Paths.JobsList}/${JobListId.Open}`, {
    [Params.Size]: JobSize.BIG,
  }),
};
export const EXCLUDED_NAVIGATION_ITEMS: JobListId[] = [
  // currently we're not able to replace folder list id with existing filters
  JobListId.Folder,
];
// predefined job search list in jobs navigation (will be extended in the future)
export const PREDEFINED_JOB_SEARCH: string[] = [
  ...Object.values(jobListIdToFilterMap),
];

export const getTreeViewJobListNavigation = (navigationItems: JobsNavigation) =>
  navigationItems
    .filter((item) => !EXCLUDED_NAVIGATION_ITEMS.includes(item.id as JobListId))
    .map((item) => {
      if (item.id in jobListIdToFilterMap) {
        return { ...item, link: jobListIdToFilterMap[item.id] };
      }
      return item;
    });

export const mapJobListVariablesToUrlParams = (
  jobListVariables: Partial<JobsListQueryVariables>
) => ({
  ...(jobListVariables.page && { [PAGE_PARAM_KEY]: jobListVariables.page }),
  ...(jobListVariables.query && { [SEARCH_PARAM_KEY]: jobListVariables.query }),
  ...(jobListVariables.sizes && { [SIZE_PARAM_KEY]: jobListVariables.sizes }),
  ...(jobListVariables.municipalityCode && {
    [MUNICIPALITY_PARAM_KEY]: jobListVariables.municipalityCode,
  }),
  ...(jobListVariables.districtId && {
    [DISTRICT_PARAM_KEY]: jobListVariables.districtId,
  }),
  ...(jobListVariables.countyId && {
    [COUNTY_PARAM_KEY]: jobListVariables.countyId,
  }),
  ...(jobListVariables.folderId && {
    [FOLDER_PARAM_KEY]: jobListVariables.folderId,
  }),
  ...(jobListVariables.worktypeIds && {
    [WORK_TYPE_PARAM_KEY]: jobListVariables.worktypeIds,
  }),
  ...(jobListVariables.worktypeSubsets && {
    [WORK_SUBSET_PARAM_KEY]: jobListVariables.worktypeSubsets,
  }),
  ...(jobListVariables.industryIds && {
    [INDUSTRY_PARAM_KEY]: jobListVariables.industryIds,
  }),
  ...(jobListVariables.worktypeSubsetGroups && {
    [WORK_TYPE_SUBSET_GROUP_PARAM_KEY]: jobListVariables.worktypeSubsetGroups,
  }),
});

interface CreateJobListFilterNavigationParams {
  jobListFilters: JobListFilter[];
  onClick: (filter: JobListFilter) => void;
  activeFilter?: JobListFilter;
  indicatorEnabled?: boolean;
}

export const createJobListFilterNavigation = ({
  jobListFilters,
  activeFilter,
  onClick,
  indicatorEnabled,
}: CreateJobListFilterNavigationParams): PillNavigationItemType[] =>
  jobListFilters.map((item) => ({
    id: createJobListFilterNavId(item.id, item.type),
    label: item.label,
    isActive: item.id === activeFilter?.id && item.type === activeFilter?.type,
    link:
      item.type === 'default' && !item.filters
        ? `${Paths.JobsList}/${item.id}`
        : getUrlWithFilter(
            `${Paths.JobsList}/${JobListId.Open}`,
            mapJobListVariablesToUrlParams(
              filterOutEmptyValueKeys(item.filters)
            )
          ),
    testId:
      item.type === 'default'
        ? `NAVIGATION_${item.listType}`
        : `NAVIGATION_SAVED_${item.id}`,
    onClick: () => onClick(item),
    withIndicator:
      indicatorEnabled && isJobListSavedFilter(item) && item.hasUnreadJobs,
  }));
