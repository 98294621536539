import {
  ChangeEvent,
  FC,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react';

import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import CenteredSpinnerContainer from '@components/base/CenteredSpinnerContainer';
import ErrorAlert from '@components/base/ErrorAlert';
import { IframeMultiMessage } from '@components/base/IframeTalk/IframeMessage';
import PhoneInput from '@components/base/PhoneInput';
import { UnsavedChangesModal } from '@components/elements/UnsavedChangesModal/UnsavedChangesModal';
import { OpeningHoursInput } from '@components/layout/Profile/ProfileCompanyInfo/OpeningHoursInput';
import SectionHeader from '@components/layout/Profile/SectionHeader';
import { useUserContext } from '@contexts/UserContext';
import useAnalytics, { usePageAnalytics } from '@hooks/useAnalytics';
import useBoolean from '@internals/business-shared/src/hooks/useBoolean';
import {
  ANEvent,
  ANEventSpace,
  ANObject,
  ANPage,
  ANSpace,
} from '@internals/business-shared/src/utils/analyticsNamespace';
import { DAY_KEYS } from '@internals/business-shared/src/utils/constants/days';
import {
  CompanyInfoResponseError,
  GQLErrorState,
  UpdateBusinessProfileInfoResponseError,
} from '@internals/business-shared/src/utils/errors';
import { PostalAddressResponseError } from '@internals/business-shared/src/utils/errors/PostalAddressResponseError';
import { COMPANY_INFO_QUERY_business_Business_addresses_visit } from '@internals/business-shared/src/utils/generated/generated';
import {
  UPDATE_BUSINESS_PROFILE_INFO,
  UpdateBusinessProfileInfoPayload,
  UpdateBusinessProfileInfoVariables,
} from '@internals/business-shared/src/utils/mutation/UpdateBusinessProfileInfo/UpdateBusinessProfileInfo';
import { isEmptyObject } from '@internals/business-shared/src/utils/objectUtils';
import { Omit } from '@internals/business-shared/src/utils/Omit';
import GQLBusiness2GQLUpdateBusinessProfileInput from '@internals/business-shared/src/utils/OpeningHours/GQLBusiness2GQLUpdateBusinessProfileInput';
import {
  OpeningHoursActions,
  OpeningHours,
} from '@internals/business-shared/src/utils/OpeningHours/OpeningHours.interface';
import {
  hasDifferentOpeningHours,
  hasWeekendsOpeningHours,
  resetOpeningHours,
  getOpeningHourErrorType,
} from '@internals/business-shared/src/utils/OpeningHours/OpeningHoursHelper';
import { openingHoursReducer } from '@internals/business-shared/src/utils/OpeningHours/OpeningHoursReducer';
import {
  COMPANY_INFO_QUERY,
  CompanyInfoQueryBusiness,
  CompanyInfoQueryData,
  CompanyInfoQueryPayload,
  CompanyInfoQuerySocialMedia,
  CompanyInfoQueryVariables,
  isCompanyInfoQuerySuccessResponse,
} from '@internals/business-shared/src/utils/query/CompanyInfo/CompanyInfoQuery';
import {
  PostalAddressQueryPayload,
  PostalAddressQueryVariables,
  POSTAL_ADDRESS_QUERY,
  isPostalAddressQuerySuccessResponse,
} from '@internals/business-shared/src/utils/query/PostalAddress/PostalAddressQuery';
import { isEmptyTrimmedString } from '@internals/business-shared/src/utils/types/StringUtils';
import { ProfileOutletContext } from '@pages/Profile/Profile';
import Paths from '@router/paths';
import {
  Div,
  Text,
  Input,
  Toggle,
  Spinner,
  Textarea,
  Heading,
} from '@schibsted-smb/fireball';
import { getFormattedZipCode } from '@utils/address';
import { useTranslation } from 'react-i18next';
import { useNavigate, useOutletContext } from 'react-router-dom';

import InputContainer from '../styled/InputContainer';

type Zip = COMPANY_INFO_QUERY_business_Business_addresses_visit['zip'];
type Street = COMPANY_INFO_QUERY_business_Business_addresses_visit['street'];
type City = COMPANY_INFO_QUERY_business_Business_addresses_visit['city'];

const SCROLL_INTO_OPENING_HOURS_DELAY = 500;

const ProfileCompanyInfo: FC = () => {
  const { t } = useTranslation();
  const { setIframeMessage } = useOutletContext<ProfileOutletContext>();

  const DAYS_OF_THE_WEEK: string[] = [
    t('general.dateTime.weekdays.monday'),
    t('general.dateTime.weekdays.tuesday'),
    t('general.dateTime.weekdays.wednesday'),
    t('general.dateTime.weekdays.thursday'),
    t('general.dateTime.weekdays.friday'),
    t('general.dateTime.weekdays.saturday'),
    t('general.dateTime.weekdays.sunday'),
    t('general.dateTime.weekdaysShort.mondayFriday'),
  ];
  const user = useUserContext();
  const { track } = useAnalytics();
  const [
    updateBusinessProfileInfo,
    {
      loading: updateBusinessProfileInfoLoading,
      data: updateBusinessProfileInfoData,
    },
  ] = useMutation<
    UpdateBusinessProfileInfoPayload,
    UpdateBusinessProfileInfoVariables
  >(UPDATE_BUSINESS_PROFILE_INFO);
  const { data, loading } = useQuery<
    CompanyInfoQueryPayload,
    CompanyInfoQueryVariables
  >(COMPANY_INFO_QUERY, {
    variables: { id: user?.operatingAs?.id ?? '' },
  });
  const [getPostal, { data: PostalData }] = useLazyQuery<
    PostalAddressQueryPayload,
    PostalAddressQueryVariables
  >(POSTAL_ADDRESS_QUERY);

  const [updateBusinessProfileErrors, setUpdateBusinessProfileErrors] =
    useState<GQLErrorState>({});
  const [companyInfoErrors, setCompanyInfoErrors] = useState<GQLErrorState>({});
  const [postalCodeError, setPostalCodeError] = useState<GQLErrorState>({});
  const [profileCompanyInfo, setProfileCompanyInfo] =
    useState<CompanyInfoQueryData>();
  const [differentOpeningHours, setDifferentOpeningHours] = useState(false);
  const [openOnWeekends, setOpenOnWeekends] = useState(false);
  const [openingHours, openingHoursDispatch] = useReducer(
    openingHoursReducer,
    resetOpeningHours({ DAYS_OF_THE_WEEK })
  );
  const [
    isUnsavedChangesModalOpen,
    openUnsavedChangesModal,
    closeUnsavedChangesModal,
  ] = useBoolean(false);
  const [isFormDirty, setFormDirtyTrue, setFormDirtyFalse] = useBoolean(false);
  const navigate = useNavigate();
  const openingHoursRef = useRef<HTMLDivElement>(null);

  const isProfileCompanyError = useMemo(
    () =>
      !isEmptyObject(updateBusinessProfileErrors) ||
      !isEmptyObject(postalCodeError) ||
      !isEmptyObject(companyInfoErrors),
    [updateBusinessProfileErrors, postalCodeError, companyInfoErrors]
  );
  const isOpeningHoursError = useMemo(
    () =>
      openingHours.some((hour) =>
        getOpeningHourErrorType(hour, updateBusinessProfileErrors)
      ),
    [openingHours]
  );

  const businessUpdate = (
    property: keyof CompanyInfoQueryBusiness,
    updatedValue: string
  ) => {
    setFormDirtyTrue();
    setProfileCompanyInfo((prevState) => {
      if (prevState) {
        return { ...prevState, [property]: updatedValue };
      }
      return prevState;
    });
  };

  const businessUpdateSocialMedia = (
    property: keyof CompanyInfoQuerySocialMedia,
    updatedValue: string
  ) => {
    setFormDirtyTrue();
    setProfileCompanyInfo((prevState): CompanyInfoQueryData => {
      if (prevState) {
        return {
          ...prevState,
          businessSocialMedia: {
            ...prevState.businessSocialMedia,
            [property]: updatedValue,
          },
        };
      }
      return prevState;
    });
  };

  // Gets the hours from openingHours and returns them in accepted Iframe format for openingHours.
  const getOpeningHours = () => {
    // creating an array of day: value
    const daysWithValue = DAY_KEYS.map((e, i) => {
      if (!openingHours[i].from || !openingHours[i].to) return { [e]: null };
      return { [e]: openingHours[i] };
    });

    // returning an object with days and values
    return Object.assign({}, ...daysWithValue);
  };

  const refreshData = () => {
    setIframeMessage(
      IframeMultiMessage([
        {
          name: 'profileDescription',
          value: profileCompanyInfo?.profileDescription ?? '',
        },
        { name: 'phone', value: profileCompanyInfo?.phone ?? '' },
        {
          name: 'website',
          value: profileCompanyInfo?.businessSocialMedia?.website ?? '',
        },
        {
          name: 'facebook',
          value: profileCompanyInfo?.businessSocialMedia?.facebook ?? '',
        },
        {
          name: 'instagram',
          value: profileCompanyInfo?.businessSocialMedia?.instagram ?? '',
        },
        {
          name: 'youtube',
          value: profileCompanyInfo?.businessSocialMedia?.youtube ?? '',
        },
        {
          name: 'tiktok',
          value: profileCompanyInfo?.businessSocialMedia?.tiktok ?? '',
        },
        { name: 'openingHours', value: getOpeningHours() },
        {
          name: 'addresses',
          value: {
            ...profileCompanyInfo?.addresses,
            visit: [
              {
                ...profileCompanyInfo?.addresses?.visit?.[0],
                street: profileCompanyInfo?.addresses?.visit?.[0]?.street ?? '',
                zip: profileCompanyInfo?.addresses?.visit?.[0]?.zip ?? '',
              },
            ],
          },
        },
      ])
    );
  };

  const handleChangeOpeningHours = (updatedHours: OpeningHours) =>
    openingHoursDispatch({
      type: OpeningHoursActions.UPDATE_HOURS,
      payload: {
        hours: updatedHours,
        sameOpeningHours: !differentOpeningHours,
      },
    });

  // after iFrame is loaded, set the values
  const onFrameLoaded = () => {
    if (!profileCompanyInfo) return; // skip if profileCompanyInfo hasn't loaded before the frame
    refreshData();
  };

  const validateStreetAndZip = (street: Street, zip: Zip) => {
    if (isEmptyTrimmedString(zip) && !isEmptyTrimmedString(street)) {
      setUpdateBusinessProfileErrors(
        (prev: GQLErrorState) =>
          ({
            ...prev,
            addressZip: {
              msg: t('general.form.validation.required'),
              variant: 'danger',
            },
          } as GQLErrorState)
      );
    } else if (!isEmptyTrimmedString(zip) && isEmptyTrimmedString(street)) {
      setUpdateBusinessProfileErrors(
        (prev: GQLErrorState) =>
          ({
            ...prev,
            addressStreet: {
              msg: t('general.form.validation.required'),
              variant: 'danger',
            },
          } as GQLErrorState)
      );
    } else {
      setUpdateBusinessProfileErrors((prev: GQLErrorState) =>
        Omit('addressZip', prev)
      );
      setUpdateBusinessProfileErrors((prev: GQLErrorState) =>
        Omit('addressStreet', prev)
      );
    }
  };

  const setZipForBusiness = (zip: Zip): void => {
    setFormDirtyTrue();

    // Zip and street need to be either both empty or both filled
    validateStreetAndZip(
      profileCompanyInfo?.addresses?.visit?.[0]?.street ?? '',
      zip
    );

    setProfileCompanyInfo((prevState): CompanyInfoQueryData => {
      return {
        ...prevState,
        addresses: {
          ...prevState?.addresses,
          visit: [
            {
              ...(prevState?.addresses?.visit ?? [])[0],
              zip: getFormattedZipCode(zip),
            },
            ...(prevState?.addresses?.visit ?? []),
          ],
        },
      };
    });
  };

  const setStreetForBusiness = (street: Street): void => {
    setFormDirtyTrue();
    // Zip and street need to be either both empty or both filled
    validateStreetAndZip(
      street,
      profileCompanyInfo?.addresses?.visit?.[0]?.zip ?? ''
    );

    setProfileCompanyInfo((prevState): CompanyInfoQueryData => {
      return {
        ...prevState,
        addresses: {
          ...prevState?.addresses,
          visit: [
            { ...(prevState?.addresses?.visit ?? [])[0], street },
            ...(prevState?.addresses?.visit ?? []),
          ],
        },
      };
    });
  };

  const setCityForBusiness = (city: City): void =>
    setProfileCompanyInfo((prevState): CompanyInfoQueryData => {
      return {
        ...prevState,
        addresses: {
          ...prevState?.addresses,
          visit: [
            { ...(prevState?.addresses?.visit ?? [])[0], city },
            ...(prevState?.addresses?.visit ?? []),
          ],
        },
      };
    });

  useEffect(() => {
    openingHoursDispatch({
      type: differentOpeningHours
        ? OpeningHoursActions.SAME_OPENING_HOURS_FALSE
        : OpeningHoursActions.SAME_OPENING_HOURS_TRUE,
      payload: { translations: DAYS_OF_THE_WEEK },
    });
  }, [differentOpeningHours]);

  useEffect(() => {
    openingHoursDispatch({
      type: openOnWeekends
        ? OpeningHoursActions.CLOSED_ON_WEEKENDS_FALSE
        : OpeningHoursActions.CLOSED_ON_WEEKENDS_TRUE,
      payload: {},
    });
  }, [openOnWeekends]);

  useEffect(() => {
    if (!data) {
      return;
    }

    if (isCompanyInfoQuerySuccessResponse(data?.business)) {
      setProfileCompanyInfo({
        ...data.business,
        businessSocialMedia: data.businessSocialMedia,
      });
      if (data.business.openingHours) {
        const hasDifferentHours = hasDifferentOpeningHours(
          data.business.openingHours
        );
        const hasOpenOnWeekends = hasWeekendsOpeningHours(
          data.business.openingHours
        );

        openingHoursDispatch({
          type: OpeningHoursActions.SET_ALL,
          payload: {
            all: data.business.openingHours,
            translations: DAYS_OF_THE_WEEK,
          },
        });

        openingHoursDispatch({
          type: hasDifferentHours
            ? OpeningHoursActions.SAME_OPENING_HOURS_FALSE
            : OpeningHoursActions.SAME_OPENING_HOURS_TRUE,
          payload: { translations: DAYS_OF_THE_WEEK },
        });

        openingHoursDispatch({
          type: hasOpenOnWeekends
            ? OpeningHoursActions.CLOSED_ON_WEEKENDS_FALSE
            : OpeningHoursActions.CLOSED_ON_WEEKENDS_TRUE,
          payload: {},
        });

        setDifferentOpeningHours(hasDifferentHours);
        setOpenOnWeekends(hasOpenOnWeekends);
        onFrameLoaded();
      }
    } else {
      const companyInfoResponseError = new CompanyInfoResponseError('Business');
      companyInfoResponseError.hasResponseError(
        data?.business,
        setCompanyInfoErrors
      );
    }
  }, [data]);

  useEffect(() => {
    refreshData();
  }, [profileCompanyInfo, openingHours]);

  // Zip debouncher that calls the postal query.
  useEffect(() => {
    // debouncher for adresses since it will call the Google api.
    const handler = setTimeout(() => {
      if (profileCompanyInfo?.addresses?.visit?.[0]?.zip) {
        getPostal({
          variables: { postalCode: profileCompanyInfo.addresses.visit[0].zip },
        });
      } else {
        setCityForBusiness('');
      }
    }, 1000);

    return () => {
      clearTimeout(handler);
    };
  }, [profileCompanyInfo?.addresses?.visit]);

  // Updates profileCompanyInfo with postalData city
  useEffect(() => {
    if (!PostalData) {
      return;
    }

    setProfileCompanyInfo((prevState): CompanyInfoQueryData => {
      if (isPostalAddressQuerySuccessResponse(PostalData?.postalAddress)) {
        setPostalCodeError({});
        setUpdateBusinessProfileErrors(
          (prevError) => Omit('addressZip', prevError) as GQLErrorState
        );

        return {
          ...prevState,
          addresses: {
            ...prevState?.addresses,
            visit: [
              {
                ...(prevState?.addresses?.visit ?? [])[0],
                city: PostalData.postalAddress?.city,
              },
              ...(prevState?.addresses?.visit ?? []),
            ],
          },
        };
      }

      const postalAddressResponseError = new PostalAddressResponseError(
        'PostalAddress'
      );
      if (
        postalAddressResponseError.hasResponseError(
          PostalData?.postalAddress,
          setPostalCodeError
        )
      ) {
        return {
          ...prevState,
          addresses: {
            ...prevState?.addresses,
            visit: [
              { ...(prevState?.addresses?.visit ?? [])[0], city: '' },
              ...(prevState?.addresses?.visit ?? []),
            ],
          },
        };
      }

      return prevState;
    });
  }, [PostalData]);

  const saveProfileData = async () => {
    if (!profileCompanyInfo || isProfileCompanyError) {
      return;
    }

    if (isOpeningHoursError) {
      // We need to make sure scrolling is work correctly after any renders, like closing unsaved changes modal
      setTimeout(() => {
        openingHoursRef?.current?.scrollIntoView({ behavior: 'smooth' });
      }, SCROLL_INTO_OPENING_HOURS_DELAY);
      return;
    }

    await updateBusinessProfileInfo({
      variables: {
        input: GQLBusiness2GQLUpdateBusinessProfileInput(
          { ...profileCompanyInfo },
          openingHours
        ),
      },
    });
    track(ANEventSpace(ANEvent.Clicked, ANObject.SaveInfo, ANPage.Profile));
    setFormDirtyFalse();
  };

  const handleBackButton = () => {
    if (!isFormDirty || updateBusinessProfileInfoLoading) {
      navigate(Paths.Profile);
      return;
    }

    openUnsavedChangesModal();
  };

  const handleModalNavigateBack = () => {
    closeUnsavedChangesModal();
    setFormDirtyFalse();
    navigate(Paths.Profile);
  };

  const handleModalSave = async () => {
    await saveProfileData();
    closeUnsavedChangesModal();

    if (!isOpeningHoursError) {
      navigate(Paths.Profile);
    }
  };

  useEffect(() => {
    if (!updateBusinessProfileInfoData) {
      return;
    }

    const updateBusinessProfileInfoResponseError =
      new UpdateBusinessProfileInfoResponseError('Business');
    updateBusinessProfileInfoResponseError.hasResponseError(
      updateBusinessProfileInfoData.updateBusinessProfileInfo,
      setUpdateBusinessProfileErrors
    );
  }, [updateBusinessProfileInfoData]);

  usePageAnalytics(ANSpace(ANObject.Company, ANPage.Profile));

  return (
    <Div px={6}>
      <SectionHeader
        title={t('profile.company.title')}
        buttonText={t('general.label.save')}
        isButtonInProgress={updateBusinessProfileInfoLoading}
        onButtonClick={saveProfileData}
        onBackButton={handleBackButton}
      />
      <ErrorAlert errors={updateBusinessProfileErrors} />
      <ErrorAlert errors={companyInfoErrors} />
      {loading && (
        <CenteredSpinnerContainer>
          <Spinner size={6} />
        </CenteredSpinnerContainer>
      )}
      {!loading && profileCompanyInfo && (
        <>
          <Heading.h4 mb={5}>{t('general.label.details')}</Heading.h4>
          <Input
            value={profileCompanyInfo?.name ?? ''}
            label={t('profile.company.name')}
            mb={5}
            type="text"
            disabled
            testId="profile-company-name"
          />
          <Input
            value={profileCompanyInfo?.orgNr ?? ''}
            label={t('profile.company.orgNumber')}
            mb={5}
            type="text"
            disabled
            testId="profile-company-org-nr"
          />
          <InputContainer>
            <PhoneInput
              error={updateBusinessProfileErrors?.phoneError}
              label={t('general.label.phone')}
              onChange={(
                event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
              ) => businessUpdate('phone', event.target.value)}
              onFocusOut={({ value }) => businessUpdate('phone', value)}
              onError={(phoneError) =>
                phoneError
                  ? setUpdateBusinessProfileErrors(
                      (prev: GQLErrorState) =>
                        ({ ...prev, phoneError } as GQLErrorState)
                    )
                  : setUpdateBusinessProfileErrors((prev: GQLErrorState) =>
                      Omit('phoneError', prev)
                    )
              }
              placeholder={t('general.label.phone')}
              testId="profile-company-phone"
              value={profileCompanyInfo?.phone ?? ''}
            />
          </InputContainer>
          <InputContainer>
            <Input
              value={profileCompanyInfo?.addresses?.visit?.[0]?.street ?? ''}
              onChange={(
                event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
              ) => setStreetForBusiness(event.target.value)}
              label={t('general.label.address')}
              placeholder={t('general.label.address')}
              type="text"
              error={updateBusinessProfileErrors?.addressStreet}
              testId="profile-company-visit-address"
            />
          </InputContainer>
          <InputContainer>
            <Input
              value={profileCompanyInfo.addresses?.visit?.[0]?.zip ?? ''}
              onChange={(
                event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
              ) => setZipForBusiness(event.target.value)}
              label={`${t('general.label.zip')} ${(profileCompanyInfo?.addresses
                ?.visit?.[0]?.city
                ? ` - ${profileCompanyInfo?.addresses?.visit?.[0]?.city}`
                : ''
              ).toLocaleUpperCase()}`}
              placeholder={t('general.label.zip')}
              type="string"
              error={
                postalCodeError?.general ||
                updateBusinessProfileErrors?.addressZip
              }
              testId="profile-company-visit-zip"
              preventNumberDefault
            />
          </InputContainer>
          <Heading.h4 mt={7} mb={5}>
            {t('profile.company.about')}
          </Heading.h4>
          <Textarea
            value={profileCompanyInfo?.profileDescription ?? ''}
            onChange={(
              event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            ) => businessUpdate('profileDescription', event.target.value)}
            label={t('general.label.description')}
            placeholder={t('general.label.description')}
            height="120px"
            textareaWrapperMarginBottom={5}
            testId="profile-company-description"
            resizable
            error={updateBusinessProfileErrors?.descriptionError}
          />

          <Heading.h4 mt={7} mb={5}>
            {t('profile.company.socialMedia.title')}
          </Heading.h4>
          <InputContainer>
            <Input
              value={profileCompanyInfo?.businessSocialMedia?.website ?? ''}
              onChange={(
                event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
              ) => businessUpdateSocialMedia('website', event.target.value)}
              label={t('profile.company.socialMedia.website')}
              placeholder={t('profile.company.socialMedia.website')}
              type="url"
              error={updateBusinessProfileErrors?.websiteError}
              testId="profile-company-website"
            />
          </InputContainer>
          <InputContainer>
            <Input
              value={profileCompanyInfo?.businessSocialMedia?.facebook ?? ''}
              onChange={(
                event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
              ) => businessUpdateSocialMedia('facebook', event.target.value)}
              label={t('profile.company.socialMedia.facebook')}
              placeholder={t('profile.company.socialMedia.facebook')}
              type="url"
              error={updateBusinessProfileErrors?.facebookError}
              testId="profile-company-facebook"
            />
          </InputContainer>
          <InputContainer>
            <Input
              value={profileCompanyInfo?.businessSocialMedia?.instagram ?? ''}
              onChange={(
                event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
              ) => businessUpdateSocialMedia('instagram', event.target.value)}
              label={t('profile.company.socialMedia.instagram')}
              placeholder={t('profile.company.socialMedia.instagram')}
              error={updateBusinessProfileErrors?.instagramError}
              type="url"
              testId="profile-company-instagram"
            />
          </InputContainer>
          <InputContainer>
            <Input
              value={profileCompanyInfo?.businessSocialMedia?.youtube ?? ''}
              onChange={(
                event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
              ) => businessUpdateSocialMedia('youtube', event.target.value)}
              label={t('profile.company.socialMedia.youtube')}
              placeholder={t('profile.company.socialMedia.youtube')}
              type="url"
              error={updateBusinessProfileErrors?.youtubeError}
              testId="profile-company-youtube"
            />
          </InputContainer>
          <InputContainer>
            <Input
              value={profileCompanyInfo?.businessSocialMedia?.tiktok ?? ''}
              onChange={(
                event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
              ) => businessUpdateSocialMedia('tiktok', event.target.value)}
              label={t('profile.company.socialMedia.tiktok')}
              placeholder={t('profile.company.socialMedia.tiktok')}
              type="url"
              error={updateBusinessProfileErrors?.tiktokError}
              testId="profile-company-tiktok"
            />
          </InputContainer>
          <Heading.h4 mt={7} mb={5}>
            {t('profile.company.openingHours.title')}
          </Heading.h4>
          <Div display="flex" justifyContent="space-between" mb={4}>
            <Text.p mb={0}>
              {t('profile.company.openingHours.differentEachDay')}
            </Text.p>
            <Toggle
              checked={differentOpeningHours}
              onChange={() => {
                setFormDirtyTrue();
                setDifferentOpeningHours(!differentOpeningHours);
              }}
              name="different-opening-hours"
              testId="opening-hours-different-hours"
            />
          </Div>
          <Div display="flex" justifyContent="space-between" mb="25px">
            <Text.p mb={0}>
              {t('profile.company.openingHours.openOnWeekends')}
            </Text.p>
            <Toggle
              checked={openOnWeekends}
              onChange={() => {
                setFormDirtyTrue();
                setOpenOnWeekends(!openOnWeekends);
              }}
              name="open-on-weekends"
              testId="opening-hours-weekends"
            />
          </Div>
          {openingHours.map(
            (openingHour) =>
              openingHour.visible && (
                <OpeningHoursInput
                  errorState={updateBusinessProfileErrors}
                  hour={openingHour}
                  key={openingHour.dayNumber}
                  onBlur={setFormDirtyTrue}
                  onChange={handleChangeOpeningHours}
                />
              )
          )}
          <Div ref={openingHoursRef} />
        </>
      )}
      <UnsavedChangesModal
        isOpen={isUnsavedChangesModalOpen}
        isLoading={updateBusinessProfileInfoLoading}
        onClose={closeUnsavedChangesModal}
        onSave={handleModalSave}
        onBackButton={handleModalNavigateBack}
      />
    </Div>
  );
};

export default ProfileCompanyInfo;
