import { JOB_TRAITS_QUERY_jobTraits_questionsRegForm as RegFormQuestions } from './generated/generated';
import { isEmptyTrimmedString } from './types/StringUtils';

export const getValidRegFormQuestions = (
  regFormQuestions: RegFormQuestions[] | null | undefined
): RegFormQuestions[] => {
  if (!regFormQuestions) return [];
  return regFormQuestions.filter(
    (question) =>
      !isEmptyTrimmedString(question.question) &&
      !isEmptyTrimmedString(question.answer)
  );
};

export const hasValidRegFormQuestions = (
  regFormQuestions: RegFormQuestions[] | null | undefined
): boolean => {
  if (!regFormQuestions) return false;
  return regFormQuestions.some(
    (question) =>
      !isEmptyTrimmedString(question.question) &&
      !isEmptyTrimmedString(question.answer)
  );
};
