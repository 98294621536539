import { useCallback } from 'react';

import { useUserBusinessId } from '@contexts/UserContext';
import { useCookies } from 'react-cookie';

type UseCookieFlagType = (cookiePrefix: string) => [boolean, VoidFunction];

const COOKIE_MAX_AGE_IN_SECONDS = 8 * 60 * 60; // 8 hours

export const useCookieFlag: UseCookieFlagType = (cookiePrefix) => {
  const [cookies, setCookie] = useCookies();
  const businessId = useUserBusinessId();
  const cookieName = `${cookiePrefix}-${businessId}`;
  const isCookieSet = cookieName in cookies;

  const setCookieFlag = useCallback(
    () => setCookie(cookieName, true, { maxAge: COOKIE_MAX_AGE_IN_SECONDS }),
    [cookieName, setCookie]
  );

  return [isCookieSet, setCookieFlag];
};
