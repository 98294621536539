import gql from 'graphql-tag';

import ADDRESS_FRAGMENT from '../../fragments/AddressFragment';
import BASIC_USER_DATA_FRAGMENT from '../../fragments/BasicUserDataFragment';
import EVALUATION_FRAGMENT from '../../fragments/EvaluationFragment';
import FILE_FRAGMENT from '../../fragments/FileFragment';
import IMAGE_FRAGMENT from '../../fragments/ImageFragment';
import JOB_BUSINESS_RELATION_STATE_FRAGMENT from '../../fragments/JobBusinessRelationStateFragment';
import {
  CHAT_JOB_QUERY as CHAT_JOB_QUERY_Result,
  CHAT_JOB_QUERYVariables,
  CHAT_JOB_QUERY_job_Job,
  CHAT_JOB_QUERY_simpleAgreementContractByJobId,
} from '../../generated/generated';

export type ChatJobQueryPayload = CHAT_JOB_QUERY_Result;
export type ChatJobQueryVariables = CHAT_JOB_QUERYVariables;
export type ChatJob = CHAT_JOB_QUERY_job_Job;
export type SimpleAgreementContract =
  CHAT_JOB_QUERY_simpleAgreementContractByJobId;

export const isChatJobQuerySuccessResponse = (
  job?: CHAT_JOB_QUERY_Result['job']
): job is CHAT_JOB_QUERY_job_Job => !!(job && job.__typename === 'Job');

export const CHAT_JOB_QUERY = gql`
  ${IMAGE_FRAGMENT}
  ${FILE_FRAGMENT}
  ${EVALUATION_FRAGMENT}
  ${ADDRESS_FRAGMENT}
  ${BASIC_USER_DATA_FRAGMENT}
  ${JOB_BUSINESS_RELATION_STATE_FRAGMENT}

  query CHAT_JOB_QUERY($id: ID!) {
    job(id: $id) {
      ... on Job {
        id
        title
        description
        evaluation @client {
          ...EvaluationFragment
        }
        isFree
        images @client {
          ...ImageFragment
        }
        files @client {
          ...FileFragment
        }
        address {
          ...AddressFragment
        }
        addressHint {
          city
          id
          lat
          lon
          postalCode
        }
        owner {
          ...BasicUserDataFragment
        }
        jobBusinessRelationState @client {
          ...JobBusinessRelationStateFragment
        }
        meta {
          contactPreference
        }
        contactInfo {
          phone
          email
        }
        workTypes {
          id
          industries {
            id
          }
        }
      }
      ... on JobAccessDeniedError {
        message
        reasonCode
      }
      ... on JobNotFoundError {
        message
      }
    }
    jobFiles(input: { tenderId: $id }) {
      ...FileFragment
    }
    jobImages(input: { tenderId: $id }) {
      ...ImageFragment
    }
    jobEvaluation(jobId: $id) {
      ...EvaluationFragment
    }
    jobBusinessRelationState(jobId: $id) {
      ...JobBusinessRelationStateFragment
    }
    simpleAgreementContractByJobId(jobId: $id) {
      id
      statusEnum
      type
    }
  }
`;
