import gql from 'graphql-tag';

import BASIC_USER_DATA_FRAGMENT from '../../fragments/BasicUserDataFragment';
import {
  CHAT_LIST_QUERY as CHAT_LIST_QUERY_Result,
  CHAT_LIST_QUERY_chatList_ChatListPayload,
  CHAT_LIST_QUERY_chatList_ChatListPayload_chatConnection_edges,
  CHAT_LIST_QUERYVariables,
} from '../../generated/generated';

export type ChatListQueryPayload = CHAT_LIST_QUERY_Result;
export type ChatListQueryVariables = CHAT_LIST_QUERYVariables;
export type ChatListEdge =
  CHAT_LIST_QUERY_chatList_ChatListPayload_chatConnection_edges;

export const isChatListQuerySuccessResponse = (
  chat?: CHAT_LIST_QUERY_Result['chatList']
): chat is CHAT_LIST_QUERY_chatList_ChatListPayload =>
  !!chat && chat.__typename === 'ChatListPayload';

export const CHAT_LIST_QUERY = gql`
  ${BASIC_USER_DATA_FRAGMENT}

  query CHAT_LIST_QUERY(
    $type: ChatListType
    $from: Int
    $count: Int
    $query: String
  ) {
    chatList(
      filter: { type: $type, from: $from, count: $count, query: $query }
    ) {
      ... on ChatListPayload {
        __typename
        listType
        chatConnection {
          edges {
            node {
              id
              isDialog
              isDeleted
              isEnded
              isUnread
              job {
                id
                title
                address {
                  street
                  id
                }
                evaluation {
                  id
                  score
                }
                jobBusinessRelationState {
                  jobId
                  clipsSpent
                  hasRequestedEvaluation
                  hasRequestedSelection
                  isAnswered
                  isDeleted
                  isFavourite
                  isLost
                  isRead
                  isWon
                  isFree
                  isOneOnOneReceiver
                }
              }
              latestMessage {
                id
                text
                type
                regTs
                writer {
                  ...BasicUserDataFragment
                }
              }
              latestUpdateTs
              participants {
                ... on User {
                  ...BasicUserDataFragment
                }
                ... on Business {
                  id
                }
              }
            }
          }
          pageInfo {
            endCursor
            hasNextPage
            hasPreviousPage
            pageSizeLimit
            startCursor
            totalCount
            totalPages
          }
        }
      }
      ... on ChatListFilterError {
        message
        typeError: type {
          message
        }
        countError: count {
          message
        }
        fromError: from {
          message
        }
        queryError: query {
          message
        }
        jobIdError: jobId {
          message
        }
        businessIdError: businessId {
          message
        }
      }
    }
  }
`;
