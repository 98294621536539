import { CreateContractFormShape } from '@internals/business-shared/src/types/FormShape/CreateContract';
import { SimpleAgreementPriceType } from '@internals/business-shared/src/utils/generated/generated';
import { isNullOrUndefined } from '@internals/business-shared/src/utils/objectUtils';
import { t } from '@utils/i18n';
import { getProduct } from '@utils/product';
import { parsePhoneNumber } from 'libphonenumber-js/core';
import * as Yup from 'yup';

export const HUNDRED_MILLION = 100000000;
const HUNDRED_MILLION_STRING = '100 000 000';

const { phoneFormat, phoneMetaData } = getProduct();
export const contractValidationSchema =
  Yup.object<CreateContractFormShape>().shape(
    {
      start: Yup.date().when('end', {
        is: (end: CreateContractFormShape['end']) => !isNullOrUndefined(end),
        then: (schema) =>
          schema
            .max(
              Yup.ref('end'),
              t('general.form.validation.date.mustBeEarlierThanEnd')
            )
            .required(t('general.form.validation.required')),
        otherwise: (schema) => schema.nullable(),
      }),

      end: Yup.date().when('start', {
        is: (start: CreateContractFormShape['start']) =>
          !isNullOrUndefined(start),
        then: (schema) =>
          schema
            .min(
              Yup.ref('start'),
              t('general.form.validation.date.mustBeLaterThanStart')
            )
            .required(t('general.form.validation.required')),
        otherwise: (schema) => schema.nullable(),
      }),
      priceType: Yup.mixed()
        .oneOf([
          SimpleAgreementPriceType.FIXED,
          SimpleAgreementPriceType.HOUR_COMPUTATION,
          SimpleAgreementPriceType.OTHER,
        ])
        .required(t('general.form.validation.required')),
      price: Yup.number().when('priceType', {
        is: (priceType: CreateContractFormShape['priceType']) =>
          priceType === SimpleAgreementPriceType.FIXED ||
          priceType === SimpleAgreementPriceType.HOUR_COMPUTATION,
        then: (schema) =>
          schema
            .required(t('general.form.validation.required'))
            .integer(t('general.form.validation.number.mustBeInteger'))
            .min(
              1,
              t('general.form.validation.number.mustBeGreaterThan', {
                value: '0',
              })
            )
            .max(
              HUNDRED_MILLION,
              t('general.form.validation.number.mustBeLessThan', {
                value: HUNDRED_MILLION_STRING,
              })
            ),
        otherwise: (schema) => schema.nullable().notRequired(),
      }),

      businessEmail: Yup.string()
        .required(t('general.form.validation.required'))
        .email(t('general.form.validation.email.invalidFormat')),

      businessPhone: Yup.string()
        .required(t('general.form.validation.required'))
        .test({
          name: 'phone',
          message: t('general.form.validation.phone.invalidFormat'),
          test: (value) => {
            if (!value) {
              return false;
            }
            try {
              const result = parsePhoneNumber(
                value,
                phoneFormat,
                phoneMetaData
              );
              return result.isValid();
            } catch (e) {
              return false;
            }
          },
        }),
      description: Yup.string().when('priceType', {
        is: (priceType: CreateContractFormShape['priceType']) =>
          priceType === SimpleAgreementPriceType.OTHER,
        then: (schema) =>
          schema.required(t('general.form.validation.required')),
        otherwise: (schema) => schema.nullable().notRequired(),
      }),
    },
    [['start', 'end']]
  );

export const contractFormInitValues: CreateContractFormShape = {
  start: null,
  end: null,
  priceType: SimpleAgreementPriceType.FIXED,
  price: undefined,
  description: '',
  businessEmail: '',
  businessPhone: '',
};
