import { FC } from 'react';

import { PillNavigationButton } from '@components/elements/PillNavigation/PillNavigationButton';
import { usePillNavigationScroll } from '@components/elements/PillNavigation/usePillNavigationScroll';
import {
  PillNavigationItem,
  PillNavigationItemType,
} from '@components/elements/PillNavigationItem';
import { StyledPillNavigationItemContainer } from '@components/elements/PillNavigationItem/styled';
import { Div } from '@schibsted-smb/fireball';

interface PillNavigationProps {
  items: PillNavigationItemType[];
}

export const PillNavigation: FC<PillNavigationProps> = ({ items }) => {
  const {
    itemsContainerRef,
    itemRefs,
    showLeftButton,
    showRightButton,
    handleScroll,
    stopScroll,
  } = usePillNavigationScroll(items);

  return (
    <Div position="relative">
      {showLeftButton && (
        <PillNavigationButton
          position="left"
          onMousePressStart={() => handleScroll('left')}
          onMousePressEnd={stopScroll}
        />
      )}
      <Div overflowX="hidden" ref={itemsContainerRef}>
        <Div display="flex" alignItems="center" minHeight="42px">
          {items.map((item, index) => (
            <StyledPillNavigationItemContainer
              key={item.id}
              ref={(el) => {
                itemRefs.current[index] = el;
              }}
            >
              <PillNavigationItem {...item} />
            </StyledPillNavigationItemContainer>
          ))}
        </Div>
      </Div>
      {showRightButton && (
        <PillNavigationButton
          position="right"
          onMousePressStart={() => handleScroll('right')}
          onMousePressEnd={stopScroll}
        />
      )}
    </Div>
  );
};
