import { useEffect, useRef } from 'react';

import { useSavedFiltersContext } from '@internals/business-shared/src/components/SavedFilters/context';
import Paths from '@router/paths';
import { useLocation } from 'react-router-dom';

const usePreviousLocation = () => {
  const location = useLocation();
  const previousLocation = useRef(location);

  useEffect(() => {
    previousLocation.current = location;
  }, [location]);

  return previousLocation.current;
};

export const useRouteChanged = (): boolean => {
  const location = useLocation();
  const previousLocation = usePreviousLocation();
  return previousLocation.pathname !== location.pathname;
};

export const useRouteChangedToJobs = (): boolean => {
  const { pathname } = useLocation();
  const { pathname: previousPathname } = usePreviousLocation() || {};
  const isCurrentRouteJobList =
    pathname === Paths.Homepage || pathname.includes(Paths.JobsList);
  const isPreviousRouteJobList =
    previousPathname === Paths.Homepage ||
    previousPathname?.includes(Paths.JobsList);

  return isCurrentRouteJobList && previousPathname && !isPreviousRouteJobList;
};
