import { FC } from 'react';

import {
  StyledPillNavigationItem,
  StyledPillNavigationItemContent,
} from '@components/elements/PillNavigationItem/styled';

export interface PillNavigationItemType {
  id: string;
  label: string;
  link: string;
  isActive: boolean;
  testId?: string;
  onClick?: () => void;
  withIndicator?: boolean;
}

export const PillNavigationItem: FC<PillNavigationItemType> = ({
  id,
  label,
  link,
  isActive,
  onClick,
  withIndicator,
  testId,
}) => {
  return (
    <StyledPillNavigationItem
      id={id}
      to={link}
      className={isActive ? 'is-active' : ''}
      data-testid={testId ?? `nav-item-${id}`}
      data-tooltip-id={id}
      onClick={onClick}
    >
      <StyledPillNavigationItemContent withIndicator={withIndicator}>
        {label}
      </StyledPillNavigationItemContent>
    </StyledPillNavigationItem>
  );
};
