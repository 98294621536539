import * as React from 'react';

import { useFlagEnabled } from '@components/base/CheckFlag';
import { GoogleMapsEmbed } from '@components/base/GoogleMapsEmbed';
import { useUserContext } from '@contexts/UserContext';
import { useJobOwnerQuery } from '@internals/business-shared/src/hooks/query/useJobOwnerQuery';
import { useJobQuery } from '@internals/business-shared/src/hooks/query/useJobQuery';
import { useJobTraitsQuery } from '@internals/business-shared/src/hooks/query/useJobTraitsQuery';
import useBoolean from '@internals/business-shared/src/hooks/useBoolean';
import { useLoadAllChatConversation } from '@internals/business-shared/src/hooks/useLoadAllChatConversation';
import {
  getMessageAuthorType,
  getMessageText,
  getMessageWriterName,
} from '@internals/business-shared/src/utils/ChatUtils';
import FeatureFlags from '@internals/business-shared/src/utils/constants/FeatureFlags';
import { ImageFragment } from '@internals/business-shared/src/utils/generated/generated';
import { getValidRegFormQuestions } from '@internals/business-shared/src/utils/jobTraits';
import { ChatMessageEdge } from '@internals/business-shared/src/utils/query/ChatConversation/ChatConversation';
import { Div, Heading, Image, Spinner, Text } from '@schibsted-smb/fireball';
import { getAddressForMaps, getAddressString } from '@utils/address';
import { getProduct } from '@utils/product';
import { getUserName } from '@utils/user';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import JobInfoContactRow from './JobInfoContactRow';
import MessageBubble from './MessageBubble';
import PrintPageHeader from './PrintPageHeader';
import {
  ImagesContainer,
  MessagesContainer,
  PrintCard,
  PrintContainer,
  PrintDataContainer,
} from './styled';

const INCH_IN_MM = 25.4;
const DPI = 96;
const PAGE_WIDTH = 210;
const PAGE_HEIGHT = 297;
const PAGE_HEIGHT_IN_PIXELS = (PAGE_HEIGHT * DPI) / INCH_IN_MM;
const MAP_MIN_HEIGHT = 188;

interface PrintJobProps {
  id: string;
}

const PrintJob: React.FC<PrintJobProps> = ({ id }) => {
  const { t } = useTranslation();
  const themeContext = useTheme();
  const tableJobInfoRowRef = React.useRef<HTMLTableRowElement>(null);
  const printContainerRef = React.useRef<HTMLDivElement>(null);
  const [jobInfoHeight, setJobInfoHeight] = React.useState(0);
  const [isMapLoaded, setMapLoaded] = useBoolean(false);
  const [imagesLoaded, setImagesLoaded] = React.useState<boolean[]>([]);
  const user = useUserContext();
  const { name: productName } = getProduct();
  const reqFormAnswersEnabled = useFlagEnabled(
    FeatureFlags.BizRegFromAnswersJobCard
  );

  const { loading: jobLoading, data: jobData } = useJobQuery(id);
  const { data: ownerData, loading: ownerLoading } = useJobOwnerQuery(id);
  const { data: jobTraitsData, loading: jobTraitsLoading } = useJobTraitsQuery({
    variables: { jobId: id },
  });

  const { data: conversationData, loading: chatConversationLoading } =
    useLoadAllChatConversation(jobData?.jobChatMeta?.chatId);

  const setImageAsLoaded = (i: number) =>
    setImagesLoaded((prevState) =>
      prevState.map((item, index) => (index === i ? true : item))
    );

  const isDataLoading = React.useMemo(
    () =>
      !(
        jobData &&
        !jobLoading &&
        ownerData &&
        !ownerLoading &&
        !chatConversationLoading &&
        !jobTraitsLoading
      ),
    [
      jobData,
      jobLoading,
      ownerData,
      ownerLoading,
      chatConversationLoading,
      jobTraitsLoading,
    ]
  );

  React.useEffect(() => {
    if (!imagesLoaded.length && jobData?.job?.images.length) {
      setImagesLoaded(Array(jobData?.job?.images.length).fill(false, 0));
    }
  }, [imagesLoaded.length, jobData]);

  React.useLayoutEffect(() => {
    if (tableJobInfoRowRef?.current) {
      setJobInfoHeight(tableJobInfoRowRef.current.offsetHeight);
    }
    if (
      !isDataLoading &&
      isMapLoaded &&
      imagesLoaded.every((isImageLoaded) => isImageLoaded)
    ) {
      if (jobData?.job) {
        document.title = `${jobData.job.title} #${jobData.job.id}`;
      }
      setTimeout(() => window.print(), 500);
    }
  }, [isDataLoading, isMapLoaded, imagesLoaded]);

  if (isDataLoading) {
    return (
      <PrintContainer
        pageWidth={PAGE_WIDTH}
        pageHeight={PAGE_HEIGHT}
        display="flex"
        justifyContent="center"
        width={['100%', null, null, null, null, null, null, 1180]}
      >
        <Div width="100%" height="100%" py={6}>
          <Spinner />
        </Div>
      </PrintContainer>
    );
  }

  return (
    <PrintContainer
      pageWidth={PAGE_WIDTH}
      pageHeight={PAGE_HEIGHT}
      display="flex"
      justifyContent="center"
      width={['100%', null, null, null, null, null, null, 1180]}
    >
      <PrintCard
        width="100%"
        borderRadius={themeContext.radii[3]}
        px={7}
        mx={4}
        my={6}
        pb={3}
        boxShadow="l"
      >
        <PrintDataContainer ref={printContainerRef}>
          {jobData?.job && (
            <Div width="100%">
              <Div width="100%" ref={tableJobInfoRowRef}>
                <PrintPageHeader title={t('job.item.print.title')} />
                <Heading.h1 mb={5}>{jobData.job.title}</Heading.h1>
                <Text.p fontSize={2} mb={7} mr="70px">
                  {jobData.job.description}
                </Text.p>
                {reqFormAnswersEnabled &&
                  getValidRegFormQuestions(jobTraitsData?.questionsRegForm).map(
                    (item) => (
                      <JobInfoContactRow
                        key={item.question}
                        category={item.question}
                        text={item.answer}
                        display="list"
                      />
                    )
                  )}
                <JobInfoContactRow
                  category={t('job.item.print.jobId')}
                  text={jobData.job.id}
                />

                {jobData.jobBusinessRelationState.isAnswered && (
                  <>
                    <Heading.h3 mb={6}>{t('general.label.contact')}</Heading.h3>
                    <JobInfoContactRow
                      category={t('general.label.name')}
                      text={getUserName(ownerData?.owner)}
                    />
                    <JobInfoContactRow
                      category={t('general.label.phone')}
                      text={ownerData?.contactInfo?.phone || ''}
                    />
                    {jobData.job?.address && (
                      <JobInfoContactRow
                        category={t('general.label.address')}
                        text={getAddressString(jobData.job.address)}
                        mb="25px"
                      />
                    )}
                  </>
                )}
              </Div>
              <GoogleMapsEmbed
                mapParams={
                  jobData.job.jobBusinessRelationState.isAnswered
                    ? getAddressForMaps(
                        jobData.job.addressHint?.city,
                        jobData.job.addressHint?.postalCode,
                        jobData?.job?.address?.street
                      )
                    : getAddressForMaps(
                        jobData.job.addressHint?.city,
                        jobData.job.addressHint?.postalCode
                      )
                }
                onLoad={setMapLoaded}
                minWidth={`calc(210mm - ${themeContext.space[7] * 2}px)`}
                minHeight={
                  jobInfoHeight < PAGE_HEIGHT_IN_PIXELS - MAP_MIN_HEIGHT
                    ? `calc(197mm - ${
                        themeContext.space[7] * 2
                      }px - 25px - ${jobInfoHeight}px)`
                    : `calc(197mm - ${themeContext.space[7] * 2}px)`
                }
              />
            </Div>
          )}
          {jobData?.job?.images.length > 0 && (
            <Div>
              <PrintPageHeader title={t('general.label.pictures')} />
              <ImagesContainer>
                {jobData.job?.images.map(
                  (image: ImageFragment, index: number) =>
                    image.dimensions.width > image.dimensions.height ? (
                      <Div key={image.id} width="39.25%" mb={7}>
                        <Image
                          src={image.bigUrl}
                          width="100%"
                          onLoad={(_e) => setImageAsLoaded(index)}
                        />
                      </Div>
                    ) : (
                      <Div key={image.id} width="29.43%" mb={7}>
                        <Image
                          src={image.bigUrl}
                          width="100%"
                          onLoad={(_e) => setImageAsLoaded(index)}
                        />
                      </Div>
                    )
                )}
              </ImagesContainer>
            </Div>
          )}
          {conversationData?.edges.length > 0 && (
            <Div>
              <PrintPageHeader title={t('job.item.print.messages.title')} />
              <MessagesContainer>
                {conversationData.edges.map((message: ChatMessageEdge) => (
                  <MessageBubble
                    key={message.node.id}
                    type={getMessageAuthorType(message.node)}
                    text={getMessageText({
                      message: message.node,
                      businessName: user.operatingAs.name,
                      consumer: ownerData.owner,
                    })}
                    author={getMessageWriterName({
                      message: message.node,
                      consumer: ownerData.owner,
                      businessName: user.operatingAs.name,
                      productName,
                    })}
                    dateTimestamp={message.node.regTs}
                  />
                ))}
              </MessagesContainer>
            </Div>
          )}
        </PrintDataContainer>
      </PrintCard>
    </PrintContainer>
  );
};

export default PrintJob;
