import { ApolloCache } from '@apollo/client';

import { MARK_SAVED_FILTER_JOB_LIST_AS_READ } from '../../utils/generated/generated';
import { MarkSavedFilterJobListAsReadPayload } from '../../utils/mutation/MarkSavedFilterJobListAsRead/MarkSavedFilterJobListAsReadMutation';

export const markSavedFilterJobListAsReadUpdate =
  (savedFilterId: string) =>
  (
    cache: ApolloCache<MARK_SAVED_FILTER_JOB_LIST_AS_READ>,
    { data }: { data?: MarkSavedFilterJobListAsReadPayload | null }
  ) => {
    if (!data?.markJobWatchlistAsRead) return;

    cache.modify({
      id: cache.identify({ id: savedFilterId, __typename: 'JobWatchlist' }),
      fields: {
        readTime() {
          const nowInMs = new Date().getTime();
          return nowInMs / 1000;
        },
      },
    });
  };

export const markSavedFilterJobListAsReadOptimisticResponse: MarkSavedFilterJobListAsReadPayload =
  {
    markJobWatchlistAsRead: true,
  };
