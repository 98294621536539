import { JobBusinessRelationStateFragment } from './generated/generated';
import { JobTraitsQueryData } from './query/JobTraits/JobTraits';

export type TagsJobTraits = Partial<
  Pick<JobTraitsQueryData, 'big' | 'ended' | 'fromVerifiedUser'>
>;

export type TagsJobBusinessRelationState = Partial<
  Pick<
    JobBusinessRelationStateFragment,
    | 'isDeleted'
    | 'isFavourite'
    | 'isFree'
    | 'isWon'
    | 'isOneOnOneReceiver'
    | 'hasRequestedEvaluation'
    | 'hasRequestedSelection'
    | 'isRejected'
  >
>;

type TagsJobExtra = {
  isEvaluated?: boolean;
};

export type JobTagsType = TagsJobTraits &
  TagsJobBusinessRelationState &
  TagsJobExtra;

export type JobTagName = keyof JobTagsType;

export enum JobTagLabel {
  BIG = 'BIG',
  DELETED = 'DELETED',
  DIRECT_REGISTRATION = 'DIRECT_REGISTRATION',
  ENDED = 'ENDED',
  EVALUATION = 'EVALUATION',
  EVALUATION_REQUESTED = 'EVALUATION_REQUESTED',
  FAVOURITE = 'FAVOURITE',
  FOLDER = 'FOLDER',
  FREE = 'FREE',
  SELECTION_REQUESTED = 'SELECTION_REQUESTED',
  VERIFIED_SECURE_ID = 'VERIFIED_SECURE_ID',
  WON = 'WON',
  REJECTED = 'REJECTED',
}

const EXCLUDED_LABELS = [JobTagLabel.EVALUATION, JobTagLabel.FOLDER] as const;

export type DisplayedJobTagLabel = Exclude<
  JobTagLabel,
  (typeof EXCLUDED_LABELS)[number]
>;

export const JobTagMap: Record<JobTagName, JobTagLabel> = {
  big: JobTagLabel.BIG,
  ended: JobTagLabel.ENDED,
  fromVerifiedUser: JobTagLabel.VERIFIED_SECURE_ID,
  hasRequestedEvaluation: JobTagLabel.EVALUATION_REQUESTED,
  hasRequestedSelection: JobTagLabel.SELECTION_REQUESTED,
  isDeleted: JobTagLabel.DELETED,
  isEvaluated: JobTagLabel.EVALUATION,
  isFavourite: JobTagLabel.FAVOURITE,
  isFree: JobTagLabel.FREE,
  isOneOnOneReceiver: JobTagLabel.DIRECT_REGISTRATION,
  isRejected: JobTagLabel.REJECTED,
  isWon: JobTagLabel.WON,
};

export const extractTrueValues = (tags: JobTagsType): JobTagName[] =>
  Object.keys(tags).filter((key) => tags[key as JobTagName]) as JobTagName[];

/*
 * This function sorts tags based on a predefined order
 */
export const sortTags = (
  tags: JobTagName[],
  sortOrder: JobTagName[] = []
): JobTagName[] => {
  if (sortOrder.length === 0) {
    return tags;
  }
  // Just to avoid to add extra tags from sortOrder, we have to check that they exist  in tags array
  return [
    ...sortOrder.filter((tag) => tags.includes(tag)),
    ...tags.filter((tag) => !sortOrder.includes(tag)),
  ];
};

/*
 * This function filters out tags that should not be displayed together
 */
export const filterTags = (
  tags: JobTagName[],
  excluded: JobTagName[] = []
): JobTagName[] => {
  const excludedTags: JobTagName[] = [...excluded];

  /* We could introduce Map here, for simple conditions, but I'm afraid, with the time it will be not enough to handle our logic */
  if (tags.includes('isWon')) {
    excludedTags.push('hasRequestedSelection');
  }

  if (tags.includes('isEvaluated')) {
    excludedTags.push('hasRequestedEvaluation', 'isWon');
  }

  // If nothing is excluded, return the original list
  if (excludedTags.length === 0) {
    return tags;
  }

  return tags.filter((tag) => !excludedTags.includes(tag));
};

export const getJobTags = (
  tags: JobTagsType,
  excluded: JobTagName[] = []
): JobTagLabel[] => {
  const existingTags: JobTagName[] = extractTrueValues(tags);
  const filteredTags = filterTags(existingTags, excluded);
  const sortedTags = sortTags(filteredTags, [
    'fromVerifiedUser',
    'isFavourite',
  ]);
  const tagsToDisplay = sortedTags.map((tag) => JobTagMap[tag]);

  return tagsToDisplay;
};

export const JobToTagsAdapter = (
  businessRelationState: TagsJobBusinessRelationState,
  jobTraits?: TagsJobTraits,
  isEvaluated: boolean = false
): JobTagsType => ({
  big: jobTraits?.big ?? false,
  ended: jobTraits?.ended ?? false,
  fromVerifiedUser: jobTraits?.fromVerifiedUser ?? false,
  isDeleted: businessRelationState?.isDeleted ?? false,
  isFavourite: businessRelationState?.isFavourite ?? false,
  isFree: businessRelationState?.isFree ?? false,
  isWon: businessRelationState?.isWon ?? false,
  isOneOnOneReceiver: businessRelationState?.isOneOnOneReceiver ?? false,
  hasRequestedEvaluation:
    businessRelationState?.hasRequestedEvaluation ?? false,
  hasRequestedSelection: businessRelationState?.hasRequestedSelection ?? false,
  isRejected: businessRelationState?.isRejected ?? false,
  isEvaluated,
});
