import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import { JobTagLabel } from '@internals/business-shared/src/utils/JobTags';
import { TagVariants } from '@schibsted-smb/fireball/lib/components/elements/Tag/types';
import { t } from '@utils/i18n';

export type LabelInfo = {
  variant: TagVariants;
  text: string;
  icon: IconPickerName;
  iconPosition: 'left' | 'right';
  tooltipText?: string;
};

export const getJobTagInfo = (labelName: JobTagLabel): LabelInfo => {
  const labelInfo: Record<JobTagLabel, LabelInfo> = {
    [JobTagLabel.FAVOURITE]: {
      text: t('job.label.FAVOURITE'),
      variant: 'orange',
      icon: IconPickerName.Favourite,
      iconPosition: 'left',
    },
    [JobTagLabel.WON]: {
      text: t('job.label.WON'),
      variant: 'orange',
      icon: IconPickerName.Won,
      iconPosition: 'left',
      tooltipText: t('job.label.WON_TOOLTIP'),
    },
    [JobTagLabel.ENDED]: {
      text: t('job.label.ENDED'),
      variant: 'red',
      icon: IconPickerName.Ended,
      iconPosition: 'left',
      tooltipText: t('job.label.ENDED_TOOLTIP'),
    },
    [JobTagLabel.DELETED]: {
      text: t('job.label.DELETED'),
      variant: 'red',
      icon: IconPickerName.Deleted,
      iconPosition: 'left',
    },
    [JobTagLabel.FREE]: {
      text: t('job.label.FREE'),
      variant: 'yellow',
      icon: IconPickerName.Free,
      iconPosition: 'left',
    },
    [JobTagLabel.BIG]: {
      text: t('job.label.BIG'),
      variant: 'primary',
      icon: IconPickerName.Big,
      iconPosition: 'left',
    },
    [JobTagLabel.EVALUATION_REQUESTED]: {
      text: t('job.label.EVALUATION_REQUESTED'),
      variant: 'yellow',
      icon: IconPickerName.StarRequested,
      iconPosition: 'left',
    },

    [JobTagLabel.SELECTION_REQUESTED]: {
      text: t('job.label.SELECTION_REQUESTED'),
      variant: 'yellow',
      icon: IconPickerName.TrophyRequested,
      iconPosition: 'left',
    },
    [JobTagLabel.DIRECT_REGISTRATION]: {
      text: t('job.label.DIRECT_REGISTRATION'),
      variant: 'green',
      icon: IconPickerName.Info,
      iconPosition: 'right',
      tooltipText: t('job.label.DIRECT_REGISTRATION_TOOLTIP'),
    },
    [JobTagLabel.VERIFIED_SECURE_ID]: {
      text: t('job.label.VERIFIED_SECURE_ID'),
      variant: 'primary',
      icon: IconPickerName.ShieldCheck,
      iconPosition: 'left',
    },
    [JobTagLabel.REJECTED]: {
      text: t('job.label.REJECTED'),
      variant: 'gray',
      icon: IconPickerName.Info,
      iconPosition: 'right',
      tooltipText: t('job.label.REJECTED_TOOLTIP'),
    },
    [JobTagLabel.FOLDER]: {
      text: '',
      variant: 'gray',
      icon: IconPickerName.Folder,
      iconPosition: 'left',
    },
    [JobTagLabel.EVALUATION]: {
      text: '',
      variant: 'gray',
      icon: IconPickerName.Star,
      iconPosition: 'left',
    },
  };

  return labelInfo[labelName];
};
