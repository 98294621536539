/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CHAT_IS_UNREAD
// ====================================================

export interface CHAT_IS_UNREAD_chat_ChatInputError {
  __typename: "ChatInputError" | "ChatNotFoundError" | "ChatAccessDeniedError";
}

export interface CHAT_IS_UNREAD_chat_Chat {
  __typename: "Chat";
  id: string;
  isUnread: boolean | null;
}

export type CHAT_IS_UNREAD_chat = CHAT_IS_UNREAD_chat_ChatInputError | CHAT_IS_UNREAD_chat_Chat;

export interface CHAT_IS_UNREAD {
  chat: CHAT_IS_UNREAD_chat;
}

export interface CHAT_IS_UNREADVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CHAT_LATEST_MESSAGE_QUERY
// ====================================================

export interface CHAT_LATEST_MESSAGE_QUERY_chat_ChatInputError {
  __typename: "ChatInputError" | "ChatNotFoundError" | "ChatAccessDeniedError";
}

export interface CHAT_LATEST_MESSAGE_QUERY_chat_Chat_latestMessage_business {
  __typename: "Business";
  id: string;
}

export interface CHAT_LATEST_MESSAGE_QUERY_chat_Chat_latestMessage_writer {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  isDeleted: boolean | null;
  isVerifiedWithSecureId: boolean;
}

export interface CHAT_LATEST_MESSAGE_QUERY_chat_Chat_latestMessage_images_dimensions {
  __typename: "Dimension";
  width: number;
  height: number;
}

export interface CHAT_LATEST_MESSAGE_QUERY_chat_Chat_latestMessage_images {
  __typename: "Image";
  bigUrl: string;
  bigPreviewUrl: string;
  description: string | null;
  dimensions: CHAT_LATEST_MESSAGE_QUERY_chat_Chat_latestMessage_images_dimensions;
  id: string;
  mediumPreviewUrl: string;
  mediumUrl: string;
  name: string;
  size: number;
  smallPreviewUrl: string;
  smallUrl: string;
  url: string;
  fileType: string;
}

export interface CHAT_LATEST_MESSAGE_QUERY_chat_Chat_latestMessage_files {
  __typename: "File";
  id: string;
  name: string;
  url: string;
  fileType: string;
}

export interface CHAT_LATEST_MESSAGE_QUERY_chat_Chat_latestMessage {
  __typename: "Message";
  id: string;
  text: string | null;
  type: MessageType;
  business: CHAT_LATEST_MESSAGE_QUERY_chat_Chat_latestMessage_business | null;
  status: MessageStatus;
  writer: CHAT_LATEST_MESSAGE_QUERY_chat_Chat_latestMessage_writer;
  regTs: number;
  images: CHAT_LATEST_MESSAGE_QUERY_chat_Chat_latestMessage_images[] | null;
  files: CHAT_LATEST_MESSAGE_QUERY_chat_Chat_latestMessage_files[] | null;
}

export interface CHAT_LATEST_MESSAGE_QUERY_chat_Chat {
  __typename: "Chat";
  id: string;
  /**
   *  latest message in chat. use this when presenting in a list. 
   */
  latestMessage: CHAT_LATEST_MESSAGE_QUERY_chat_Chat_latestMessage | null;
  latestUpdateTs: number | null;
}

export type CHAT_LATEST_MESSAGE_QUERY_chat = CHAT_LATEST_MESSAGE_QUERY_chat_ChatInputError | CHAT_LATEST_MESSAGE_QUERY_chat_Chat;

export interface CHAT_LATEST_MESSAGE_QUERY {
  chat: CHAT_LATEST_MESSAGE_QUERY_chat;
}

export interface CHAT_LATEST_MESSAGE_QUERYVariables {
  id?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: JOB_EVALUATION
// ====================================================

export interface JOB_EVALUATION_job_JobAccessDeniedError {
  __typename: "JobAccessDeniedError" | "JobNotFoundError";
}

export interface JOB_EVALUATION_job_Job_evaluation {
  __typename: "Evaluation";
  id: string;
  score: number;
}

export interface JOB_EVALUATION_job_Job {
  __typename: "Job";
  /**
   *  job ID 
   */
  id: string;
  evaluation: JOB_EVALUATION_job_Job_evaluation | null;
}

export type JOB_EVALUATION_job = JOB_EVALUATION_job_JobAccessDeniedError | JOB_EVALUATION_job_Job;

export interface JOB_EVALUATION {
  job: JOB_EVALUATION_job;
}

export interface JOB_EVALUATIONVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: JOB_FILES
// ====================================================

export interface JOB_FILES_job_JobAccessDeniedError {
  __typename: "JobAccessDeniedError" | "JobNotFoundError";
}

export interface JOB_FILES_job_Job_files {
  __typename: "File";
  description: string | null;
  iconUrl: string;
  id: string;
  name: string;
  size: number;
  url: string;
  fileType: string;
}

export interface JOB_FILES_job_Job {
  __typename: "Job";
  /**
   *  job ID 
   */
  id: string;
  files: JOB_FILES_job_Job_files[];
}

export type JOB_FILES_job = JOB_FILES_job_JobAccessDeniedError | JOB_FILES_job_Job;

export interface JOB_FILES {
  job: JOB_FILES_job;
}

export interface JOB_FILESVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: JOB_IMAGES
// ====================================================

export interface JOB_IMAGES_job_JobAccessDeniedError {
  __typename: "JobAccessDeniedError" | "JobNotFoundError";
}

export interface JOB_IMAGES_job_Job_images_dimensions {
  __typename: "Dimension";
  width: number;
  height: number;
}

export interface JOB_IMAGES_job_Job_images {
  __typename: "Image";
  bigUrl: string;
  bigPreviewUrl: string;
  description: string | null;
  dimensions: JOB_IMAGES_job_Job_images_dimensions;
  id: string;
  mediumPreviewUrl: string;
  mediumUrl: string;
  name: string;
  size: number;
  smallPreviewUrl: string;
  smallUrl: string;
  url: string;
  fileType: string;
}

export interface JOB_IMAGES_job_Job {
  __typename: "Job";
  /**
   *  job ID 
   */
  id: string;
  images: JOB_IMAGES_job_Job_images[];
}

export type JOB_IMAGES_job = JOB_IMAGES_job_JobAccessDeniedError | JOB_IMAGES_job_Job;

export interface JOB_IMAGES {
  job: JOB_IMAGES_job;
}

export interface JOB_IMAGESVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: JOB_BUSINESS_RELATION_STATE
// ====================================================

export interface JOB_BUSINESS_RELATION_STATE_job_JobAccessDeniedError {
  __typename: "JobAccessDeniedError" | "JobNotFoundError";
}

export interface JOB_BUSINESS_RELATION_STATE_job_Job_jobBusinessRelationState {
  __typename: "JobBusinessRelationState";
  jobId: string;
  clipsSpent: number | null;
  hasRequestedEvaluation: boolean;
  hasRequestedSelection: boolean;
  isAnswered: boolean;
  isDeleted: boolean;
  isFavourite: boolean;
  isLost: boolean;
  isRead: boolean;
  isWon: boolean;
  isFree: boolean;
  isOneOnOneReceiver: boolean | null;
  isRejected: boolean;
}

export interface JOB_BUSINESS_RELATION_STATE_job_Job {
  __typename: "Job";
  /**
   *  job ID 
   */
  id: string;
  jobBusinessRelationState: JOB_BUSINESS_RELATION_STATE_job_Job_jobBusinessRelationState;
}

export type JOB_BUSINESS_RELATION_STATE_job = JOB_BUSINESS_RELATION_STATE_job_JobAccessDeniedError | JOB_BUSINESS_RELATION_STATE_job_Job;

export interface JOB_BUSINESS_RELATION_STATE {
  job: JOB_BUSINESS_RELATION_STATE_job;
}

export interface JOB_BUSINESS_RELATION_STATEVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: JOB_META
// ====================================================

export interface JOB_META_job_JobAccessDeniedError {
  __typename: "JobAccessDeniedError" | "JobNotFoundError";
}

export interface JOB_META_job_Job_meta_requestedStart {
  __typename: "JobStart";
  type: StartAlternative;
  relatedTime: number | null;
}

export interface JOB_META_job_Job_meta_generics {
  __typename: "GenericJobMeta";
  /**
   *  Should equal to one of JobMetaSlug as kebab case. but we need to 'expect the unexpected' as new ones will be added from time to time. 
   */
  slug: string | null;
  /**
   *  Use this in case slugs are not translated in front end 
   */
  title: string | null;
  value: string | null;
}

export interface JOB_META_job_Job_meta_car_info {
  __typename: "CarInfo";
  VIMNumber: string | null;
  brand: string | null;
  licencePlate: string | null;
  registrationYear: string | null;
  model: string | null;
  lastRoadWorthinessTestDate: string | null;
  nextRoadWorthinessTestDate: string | null;
  shaftCount: number | null;
  width: number | null;
  co2Emissions: number | null;
  fuelType: FuelType | null;
  weight: number | null;
  factoryInstalledParticleFilter: boolean | null;
  vehicleGroup: string | null;
  length: number | null;
  towbarMaxLoad: number | null;
  maxRoofLoad: number | null;
  engineHectowatt: number | null;
  maxSeats: number | null;
  engineSize: number | null;
  trailerMaxWeightWithBreak: number | null;
  trailerMaxWeightWithoutBreak: number | null;
  maxTotalWeight: number | null;
  taxCode: string | null;
  hybrid: HybridCarType | null;
  hybridCatagory: HybridCarCatagory | null;
  seatsFront: number | null;
}

export interface JOB_META_job_Job_meta_car {
  __typename: "Car";
  id: string;
  /**
   * regNr shouldn't be requested because we cannot hide it as it's a key. It should also be @inaccessible
   */
  regNr: string;
  info: JOB_META_job_Job_meta_car_info | null;
}

export interface JOB_META_job_Job_meta_toAddress {
  __typename: "Address";
  city: string | null;
  description: string | null;
  id: string;
  lat: string | null;
  lon: string | null;
  street: string | null;
  type: number;
  zip: string | null;
}

export interface JOB_META_job_Job_meta {
  __typename: "JobMeta";
  actualStartDate: number | null;
  customerType: JobCustomerType | null;
  contactPreference: JobContactPreference | null;
  requestedStart: JOB_META_job_Job_meta_requestedStart | null;
  generics: JOB_META_job_Job_meta_generics[];
  car: JOB_META_job_Job_meta_car | null;
  toAddress: JOB_META_job_Job_meta_toAddress | null;
}

export interface JOB_META_job_Job {
  __typename: "Job";
  /**
   *  job ID 
   */
  id: string;
  meta: JOB_META_job_Job_meta;
}

export type JOB_META_job = JOB_META_job_JobAccessDeniedError | JOB_META_job_Job;

export interface JOB_META {
  job: JOB_META_job;
}

export interface JOB_METAVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ANSWER_JOB_RESPONSE_QUERY
// ====================================================

export interface ANSWER_JOB_RESPONSE_QUERY_job_JobAccessDeniedError {
  __typename: "JobAccessDeniedError" | "JobNotFoundError";
}

export interface ANSWER_JOB_RESPONSE_QUERY_job_Job_latestMessage_writer {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
}

export interface ANSWER_JOB_RESPONSE_QUERY_job_Job_latestMessage {
  __typename: "Message";
  id: string;
  regTs: number;
  text: string | null;
  writer: ANSWER_JOB_RESPONSE_QUERY_job_Job_latestMessage_writer;
}

export interface ANSWER_JOB_RESPONSE_QUERY_job_Job_firstMessage_writer {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
}

export interface ANSWER_JOB_RESPONSE_QUERY_job_Job_firstMessage {
  __typename: "Message";
  id: string;
  regTs: number;
  text: string | null;
  writer: ANSWER_JOB_RESPONSE_QUERY_job_Job_firstMessage_writer;
}

export interface ANSWER_JOB_RESPONSE_QUERY_job_Job {
  __typename: "Job";
  /**
   *  job ID 
   */
  id: string;
  /**
   *  number of answers related to a job 
   */
  answerCount: number | null;
  latestMessage: ANSWER_JOB_RESPONSE_QUERY_job_Job_latestMessage | null;
  firstMessage: ANSWER_JOB_RESPONSE_QUERY_job_Job_firstMessage | null;
}

export type ANSWER_JOB_RESPONSE_QUERY_job = ANSWER_JOB_RESPONSE_QUERY_job_JobAccessDeniedError | ANSWER_JOB_RESPONSE_QUERY_job_Job;

export interface ANSWER_JOB_RESPONSE_QUERY_jobChatMeta {
  __typename: "ChatMeta";
  chatId: string;
  firstMessageTs: number;
}

export interface ANSWER_JOB_RESPONSE_QUERY_jobAccess_JobAccessInputError {
  __typename: "JobAccessInputError" | "LoginRequiredError";
}

export interface ANSWER_JOB_RESPONSE_QUERY_jobAccess_JobAccessPayload_access_missingCertificates {
  __typename: "Certificate";
  id: string;
  name: string | null;
}

export interface ANSWER_JOB_RESPONSE_QUERY_jobAccess_JobAccessPayload_access {
  __typename: "AnswerAccess";
  code: AnswerAccessCode;
  isOpen: boolean;
  clip: number | null;
  missingCertificates: ANSWER_JOB_RESPONSE_QUERY_jobAccess_JobAccessPayload_access_missingCertificates[];
}

export interface ANSWER_JOB_RESPONSE_QUERY_jobAccess_JobAccessPayload {
  __typename: "JobAccessPayload";
  access: ANSWER_JOB_RESPONSE_QUERY_jobAccess_JobAccessPayload_access | null;
}

export type ANSWER_JOB_RESPONSE_QUERY_jobAccess = ANSWER_JOB_RESPONSE_QUERY_jobAccess_JobAccessInputError | ANSWER_JOB_RESPONSE_QUERY_jobAccess_JobAccessPayload;

export interface ANSWER_JOB_RESPONSE_QUERY_jobBusinessRelationState {
  __typename: "JobBusinessRelationState";
  jobId: string;
  isAnswered: boolean;
  clipsSpent: number | null;
}

export interface ANSWER_JOB_RESPONSE_QUERY {
  job: ANSWER_JOB_RESPONSE_QUERY_job;
  jobChatMeta: ANSWER_JOB_RESPONSE_QUERY_jobChatMeta | null;
  jobAccess: ANSWER_JOB_RESPONSE_QUERY_jobAccess;
  jobBusinessRelationState: ANSWER_JOB_RESPONSE_QUERY_jobBusinessRelationState;
}

export interface ANSWER_JOB_RESPONSE_QUERYVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ADD_BUSINESS_EMPLOYEE
// ====================================================

export interface ADD_BUSINESS_EMPLOYEE_addBusinessEmployee_AddBusinessEmployeePayload_employee_image {
  __typename: "Image";
  id: string;
  name: string;
}

export interface ADD_BUSINESS_EMPLOYEE_addBusinessEmployee_AddBusinessEmployeePayload_employee {
  __typename: "BusinessEmployee";
  id: string;
  position: string;
  firstName: string;
  lastName: string;
  phoneNr: string;
  email: string;
  imageUrl: string | null;
  image: ADD_BUSINESS_EMPLOYEE_addBusinessEmployee_AddBusinessEmployeePayload_employee_image | null;
}

export interface ADD_BUSINESS_EMPLOYEE_addBusinessEmployee_AddBusinessEmployeePayload {
  __typename: "AddBusinessEmployeePayload";
  employee: ADD_BUSINESS_EMPLOYEE_addBusinessEmployee_AddBusinessEmployeePayload_employee;
}

export interface ADD_BUSINESS_EMPLOYEE_addBusinessEmployee_AddBusinessEmployeeInputError_businessId {
  __typename: "BasicInputError";
  message: string;
}

export interface ADD_BUSINESS_EMPLOYEE_addBusinessEmployee_AddBusinessEmployeeInputError_position {
  __typename: "BasicInputError";
  message: string;
}

export interface ADD_BUSINESS_EMPLOYEE_addBusinessEmployee_AddBusinessEmployeeInputError_firstName {
  __typename: "BasicInputError";
  message: string;
}

export interface ADD_BUSINESS_EMPLOYEE_addBusinessEmployee_AddBusinessEmployeeInputError_lastName {
  __typename: "BasicInputError";
  message: string;
}

export interface ADD_BUSINESS_EMPLOYEE_addBusinessEmployee_AddBusinessEmployeeInputError_phoneNr {
  __typename: "BasicInputError";
  message: string;
}

export interface ADD_BUSINESS_EMPLOYEE_addBusinessEmployee_AddBusinessEmployeeInputError_email {
  __typename: "BasicInputError";
  message: string;
}

export interface ADD_BUSINESS_EMPLOYEE_addBusinessEmployee_AddBusinessEmployeeInputError_image {
  __typename: "BasicInputError";
  message: string;
}

export interface ADD_BUSINESS_EMPLOYEE_addBusinessEmployee_AddBusinessEmployeeInputError {
  __typename: "AddBusinessEmployeeInputError";
  message: string;
  businessId: ADD_BUSINESS_EMPLOYEE_addBusinessEmployee_AddBusinessEmployeeInputError_businessId | null;
  position: ADD_BUSINESS_EMPLOYEE_addBusinessEmployee_AddBusinessEmployeeInputError_position | null;
  firstName: ADD_BUSINESS_EMPLOYEE_addBusinessEmployee_AddBusinessEmployeeInputError_firstName | null;
  lastName: ADD_BUSINESS_EMPLOYEE_addBusinessEmployee_AddBusinessEmployeeInputError_lastName | null;
  phoneNr: ADD_BUSINESS_EMPLOYEE_addBusinessEmployee_AddBusinessEmployeeInputError_phoneNr | null;
  email: ADD_BUSINESS_EMPLOYEE_addBusinessEmployee_AddBusinessEmployeeInputError_email | null;
  image: ADD_BUSINESS_EMPLOYEE_addBusinessEmployee_AddBusinessEmployeeInputError_image | null;
}

export interface ADD_BUSINESS_EMPLOYEE_addBusinessEmployee_AddBusinessEmployeeNoAccessError {
  __typename: "AddBusinessEmployeeNoAccessError";
  message: string;
}

export type ADD_BUSINESS_EMPLOYEE_addBusinessEmployee = ADD_BUSINESS_EMPLOYEE_addBusinessEmployee_AddBusinessEmployeePayload | ADD_BUSINESS_EMPLOYEE_addBusinessEmployee_AddBusinessEmployeeInputError | ADD_BUSINESS_EMPLOYEE_addBusinessEmployee_AddBusinessEmployeeNoAccessError;

export interface ADD_BUSINESS_EMPLOYEE {
  addBusinessEmployee: ADD_BUSINESS_EMPLOYEE_addBusinessEmployee;
}

export interface ADD_BUSINESS_EMPLOYEEVariables {
  input: AddBusinessEmployeeInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ADD_JOB_TO_JOB_FOLDER
// ====================================================

export interface ADD_JOB_TO_JOB_FOLDER {
  addJobToJobFolder: boolean;
}

export interface ADD_JOB_TO_JOB_FOLDERVariables {
  folderId: string;
  jobId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ADD_PUSH_NOTIFICATION_TOKEN
// ====================================================

export interface ADD_PUSH_NOTIFICATION_TOKEN_addPushNotificationToken_AddPushNotificationTokenPayload {
  __typename: "AddPushNotificationTokenPayload";
  success: boolean;
}

export interface ADD_PUSH_NOTIFICATION_TOKEN_addPushNotificationToken_AddPushNotificationTokenAlreadyExistsError {
  __typename: "AddPushNotificationTokenAlreadyExistsError";
  message: string;
  code: number | null;
}

export type ADD_PUSH_NOTIFICATION_TOKEN_addPushNotificationToken = ADD_PUSH_NOTIFICATION_TOKEN_addPushNotificationToken_AddPushNotificationTokenPayload | ADD_PUSH_NOTIFICATION_TOKEN_addPushNotificationToken_AddPushNotificationTokenAlreadyExistsError;

export interface ADD_PUSH_NOTIFICATION_TOKEN {
  addPushNotificationToken: ADD_PUSH_NOTIFICATION_TOKEN_addPushNotificationToken;
}

export interface ADD_PUSH_NOTIFICATION_TOKENVariables {
  input: AddPushNotificationTokenInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ANSWER_JOB
// ====================================================

export interface ANSWER_JOB_answerJob_AnswerJobPayload_chat {
  __typename: "Chat";
  id: string;
}

export interface ANSWER_JOB_answerJob_AnswerJobPayload_job_firstMessage {
  __typename: "Message";
  id: string;
  regTs: number;
  text: string | null;
}

export interface ANSWER_JOB_answerJob_AnswerJobPayload_job {
  __typename: "Job";
  /**
   *  job ID 
   */
  id: string;
  firstMessage: ANSWER_JOB_answerJob_AnswerJobPayload_job_firstMessage | null;
}

export interface ANSWER_JOB_answerJob_AnswerJobPayload {
  __typename: "AnswerJobPayload";
  chat: ANSWER_JOB_answerJob_AnswerJobPayload_chat;
  job: ANSWER_JOB_answerJob_AnswerJobPayload_job;
}

export interface ANSWER_JOB_answerJob_AnswerJobInputError_jobId {
  __typename: "BasicInputError";
  message: string;
}

export interface ANSWER_JOB_answerJob_AnswerJobInputError_text {
  __typename: "BasicInputError";
  message: string;
}

export interface ANSWER_JOB_answerJob_AnswerJobInputError {
  __typename: "AnswerJobInputError";
  message: string;
  jobId: ANSWER_JOB_answerJob_AnswerJobInputError_jobId | null;
  text: ANSWER_JOB_answerJob_AnswerJobInputError_text | null;
}

export interface ANSWER_JOB_answerJob_AnswerJobAccessError {
  __typename: "AnswerJobAccessError";
  message: string;
  accessCode: AnswerAccessCode | null;
}

export type ANSWER_JOB_answerJob = ANSWER_JOB_answerJob_AnswerJobPayload | ANSWER_JOB_answerJob_AnswerJobInputError | ANSWER_JOB_answerJob_AnswerJobAccessError;

export interface ANSWER_JOB {
  answerJob: ANSWER_JOB_answerJob;
}

export interface ANSWER_JOBVariables {
  jobId: string;
  text: string;
  files?: any[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ASK_TO_BE_EVALUATED
// ====================================================

export interface ASK_TO_BE_EVALUATED {
  businessAskToBeEvaluated: boolean;
}

export interface ASK_TO_BE_EVALUATEDVariables {
  jobId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ASK_TO_BE_SELECTED
// ====================================================

export interface ASK_TO_BE_SELECTED {
  businessAskToBeSelected: boolean;
}

export interface ASK_TO_BE_SELECTEDVariables {
  jobId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AUTHORIZE_BOLIGMAPPA_INTEGRATION
// ====================================================

export interface AUTHORIZE_BOLIGMAPPA_INTEGRATION_authorizeBoligmappaIntegration {
  __typename: "BoligmappaIntegrationState";
  id: string;
  status: BoligmappaIntegrationStateEnum;
}

export interface AUTHORIZE_BOLIGMAPPA_INTEGRATION {
  authorizeBoligmappaIntegration: AUTHORIZE_BOLIGMAPPA_INTEGRATION_authorizeBoligmappaIntegration;
}

export interface AUTHORIZE_BOLIGMAPPA_INTEGRATIONVariables {
  input: AuthorizeBoligmappaIntegrationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: BOLIGMAPPA_ASSOCIATE_ADDRESS
// ====================================================

export interface BOLIGMAPPA_ASSOCIATE_ADDRESS_boligmappaAssociateAddress_address {
  __typename: "BoligmappaBuildingAddress";
  houseNumber: number;
  houseSubNumber: string | null;
  id: string;
  postalCode: string;
  postalPlace: string;
  streetName: string;
}

export interface BOLIGMAPPA_ASSOCIATE_ADDRESS_boligmappaAssociateAddress_associations_documents {
  __typename: "BoligmappaDocument";
  id: string;
  fileName: string;
}

export interface BOLIGMAPPA_ASSOCIATE_ADDRESS_boligmappaAssociateAddress_associations {
  __typename: "BoligmappaAssociation";
  documents: BOLIGMAPPA_ASSOCIATE_ADDRESS_boligmappaAssociateAddress_associations_documents[];
  id: string;
  propertyType: BoligmappaAssociationBuildingType;
  unitNumber: string | null;
}

export interface BOLIGMAPPA_ASSOCIATE_ADDRESS_boligmappaAssociateAddress {
  __typename: "BoligmappaAssociationResponse";
  id: string;
  address: BOLIGMAPPA_ASSOCIATE_ADDRESS_boligmappaAssociateAddress_address;
  associations: BOLIGMAPPA_ASSOCIATE_ADDRESS_boligmappaAssociateAddress_associations[];
}

export interface BOLIGMAPPA_ASSOCIATE_ADDRESS {
  boligmappaAssociateAddress: BOLIGMAPPA_ASSOCIATE_ADDRESS_boligmappaAssociateAddress;
}

export interface BOLIGMAPPA_ASSOCIATE_ADDRESSVariables {
  input: BoligmappaAssociateAddressInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: BOLIGMAPPA_REMOVE_ASSOCIATION
// ====================================================

export interface BOLIGMAPPA_REMOVE_ASSOCIATION_boligmappaRemoveAssociation_address {
  __typename: "BoligmappaBuildingAddress";
  houseNumber: number;
  houseSubNumber: string | null;
  id: string;
  postalCode: string;
  postalPlace: string;
  streetName: string;
}

export interface BOLIGMAPPA_REMOVE_ASSOCIATION_boligmappaRemoveAssociation_associations_documents {
  __typename: "BoligmappaDocument";
  fileName: string;
  id: string;
}

export interface BOLIGMAPPA_REMOVE_ASSOCIATION_boligmappaRemoveAssociation_associations {
  __typename: "BoligmappaAssociation";
  id: string;
  propertyType: BoligmappaAssociationBuildingType;
  unitNumber: string | null;
  documents: BOLIGMAPPA_REMOVE_ASSOCIATION_boligmappaRemoveAssociation_associations_documents[];
}

export interface BOLIGMAPPA_REMOVE_ASSOCIATION_boligmappaRemoveAssociation {
  __typename: "BoligmappaAssociationResponse";
  id: string;
  address: BOLIGMAPPA_REMOVE_ASSOCIATION_boligmappaRemoveAssociation_address;
  associations: BOLIGMAPPA_REMOVE_ASSOCIATION_boligmappaRemoveAssociation_associations[];
}

export interface BOLIGMAPPA_REMOVE_ASSOCIATION {
  boligmappaRemoveAssociation: BOLIGMAPPA_REMOVE_ASSOCIATION_boligmappaRemoveAssociation | null;
}

export interface BOLIGMAPPA_REMOVE_ASSOCIATIONVariables {
  input: BoligmappaRemoveAssociationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CREATE_BUSINESS_ALBUM
// ====================================================

export interface CREATE_BUSINESS_ALBUM_createBusinessAlbum_album {
  __typename: "Album";
  id: string;
  name: string;
}

export interface CREATE_BUSINESS_ALBUM_createBusinessAlbum {
  __typename: "CreateBusinessAlbumPayload";
  album: CREATE_BUSINESS_ALBUM_createBusinessAlbum_album;
}

export interface CREATE_BUSINESS_ALBUM {
  createBusinessAlbum: CREATE_BUSINESS_ALBUM_createBusinessAlbum;
}

export interface CREATE_BUSINESS_ALBUMVariables {
  input: CreateBusinessAlbumInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DELETE_BUSINESS_ALBUM
// ====================================================

export interface DELETE_BUSINESS_ALBUM_deleteBusinessAlbum {
  __typename: "DeleteBusinessAlbumPayload";
  success: boolean | null;
}

export interface DELETE_BUSINESS_ALBUM {
  deleteBusinessAlbum: DELETE_BUSINESS_ALBUM_deleteBusinessAlbum;
}

export interface DELETE_BUSINESS_ALBUMVariables {
  input: DeleteBusinessAlbumInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UPDATE_BUSINESS_ALBUM
// ====================================================

export interface UPDATE_BUSINESS_ALBUM_updateBusinessAlbum_album {
  __typename: "Album";
  id: string;
  name: string;
}

export interface UPDATE_BUSINESS_ALBUM_updateBusinessAlbum {
  __typename: "UpdateBusinessAlbumPayload";
  album: UPDATE_BUSINESS_ALBUM_updateBusinessAlbum_album;
}

export interface UPDATE_BUSINESS_ALBUM {
  updateBusinessAlbum: UPDATE_BUSINESS_ALBUM_updateBusinessAlbum;
}

export interface UPDATE_BUSINESS_ALBUMVariables {
  input: UpdateBusinessAlbumInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DELETE_BUSINESS_ALBUM_IMAGE
// ====================================================

export interface DELETE_BUSINESS_ALBUM_IMAGE_deleteBusinessAlbumImage {
  __typename: "DeleteBusinessAlbumImagePayload";
  success: boolean;
}

export interface DELETE_BUSINESS_ALBUM_IMAGE {
  deleteBusinessAlbumImage: DELETE_BUSINESS_ALBUM_IMAGE_deleteBusinessAlbumImage;
}

export interface DELETE_BUSINESS_ALBUM_IMAGEVariables {
  input: DeleteBusinessAlbumImageInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UPDATE_BUSINESS_ALBUM_IMAGE
// ====================================================

export interface UPDATE_BUSINESS_ALBUM_IMAGE_updateBusinessAlbumImage_albumImage_image_dimensions {
  __typename: "Dimension";
  width: number;
  height: number;
}

export interface UPDATE_BUSINESS_ALBUM_IMAGE_updateBusinessAlbumImage_albumImage_image {
  __typename: "Image";
  bigUrl: string;
  bigPreviewUrl: string;
  description: string | null;
  dimensions: UPDATE_BUSINESS_ALBUM_IMAGE_updateBusinessAlbumImage_albumImage_image_dimensions;
  id: string;
  mediumPreviewUrl: string;
  mediumUrl: string;
  name: string;
  size: number;
  smallPreviewUrl: string;
  smallUrl: string;
  url: string;
  fileType: string;
}

export interface UPDATE_BUSINESS_ALBUM_IMAGE_updateBusinessAlbumImage_albumImage {
  __typename: "AlbumImage";
  id: string;
  description: string | null;
  image: UPDATE_BUSINESS_ALBUM_IMAGE_updateBusinessAlbumImage_albumImage_image;
}

export interface UPDATE_BUSINESS_ALBUM_IMAGE_updateBusinessAlbumImage {
  __typename: "UpdateBusinessAlbumImagePayload";
  albumImage: UPDATE_BUSINESS_ALBUM_IMAGE_updateBusinessAlbumImage_albumImage;
}

export interface UPDATE_BUSINESS_ALBUM_IMAGE {
  updateBusinessAlbumImage: UPDATE_BUSINESS_ALBUM_IMAGE_updateBusinessAlbumImage;
}

export interface UPDATE_BUSINESS_ALBUM_IMAGEVariables {
  input: UpdateBusinessAlbumImageInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UPLOAD_BUSINESS_ALBUM_IMAGES
// ====================================================

export interface UPLOAD_BUSINESS_ALBUM_IMAGES_uploadBusinessAlbumImages_albumImages_image_dimensions {
  __typename: "Dimension";
  width: number;
  height: number;
}

export interface UPLOAD_BUSINESS_ALBUM_IMAGES_uploadBusinessAlbumImages_albumImages_image {
  __typename: "Image";
  bigUrl: string;
  bigPreviewUrl: string;
  description: string | null;
  dimensions: UPLOAD_BUSINESS_ALBUM_IMAGES_uploadBusinessAlbumImages_albumImages_image_dimensions;
  id: string;
  mediumPreviewUrl: string;
  mediumUrl: string;
  name: string;
  size: number;
  smallPreviewUrl: string;
  smallUrl: string;
  url: string;
  fileType: string;
}

export interface UPLOAD_BUSINESS_ALBUM_IMAGES_uploadBusinessAlbumImages_albumImages {
  __typename: "AlbumImage";
  id: string;
  description: string | null;
  image: UPLOAD_BUSINESS_ALBUM_IMAGES_uploadBusinessAlbumImages_albumImages_image;
}

export interface UPLOAD_BUSINESS_ALBUM_IMAGES_uploadBusinessAlbumImages {
  __typename: "UploadBusinessAlbumImagesPayload";
  albumImages: UPLOAD_BUSINESS_ALBUM_IMAGES_uploadBusinessAlbumImages_albumImages[];
}

export interface UPLOAD_BUSINESS_ALBUM_IMAGES {
  uploadBusinessAlbumImages: UPLOAD_BUSINESS_ALBUM_IMAGES_uploadBusinessAlbumImages;
}

export interface UPLOAD_BUSINESS_ALBUM_IMAGESVariables {
  input: UploadBusinessAlbumImagesInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CHOOSE_BUSINESS_WORK_TYPES
// ====================================================

export interface CHOOSE_BUSINESS_WORK_TYPES_chooseBusinessWorkTypes_workTypes {
  __typename: "WorkType";
  /**
   *  worktype ID 
   */
  id: string;
}

export interface CHOOSE_BUSINESS_WORK_TYPES_chooseBusinessWorkTypes {
  __typename: "ChooseBusinessWorkTypesResponse";
  workTypes: CHOOSE_BUSINESS_WORK_TYPES_chooseBusinessWorkTypes_workTypes[];
}

export interface CHOOSE_BUSINESS_WORK_TYPES {
  chooseBusinessWorkTypes: CHOOSE_BUSINESS_WORK_TYPES_chooseBusinessWorkTypes;
}

export interface CHOOSE_BUSINESS_WORK_TYPESVariables {
  businessId: string;
  workTypeIds: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CHOOSE_BUSINESS_WORK_TYPE_SUBSETS
// ====================================================

export interface CHOOSE_BUSINESS_WORK_TYPE_SUBSETS_chooseBusinessWorkTypeSubsets_subsets {
  __typename: "WorkTypeSubset";
  id: string;
}

export interface CHOOSE_BUSINESS_WORK_TYPE_SUBSETS_chooseBusinessWorkTypeSubsets {
  __typename: "ChooseBusinessWorkTypeSubsetsResponse";
  subsets: CHOOSE_BUSINESS_WORK_TYPE_SUBSETS_chooseBusinessWorkTypeSubsets_subsets[];
}

export interface CHOOSE_BUSINESS_WORK_TYPE_SUBSETS {
  chooseBusinessWorkTypeSubsets: CHOOSE_BUSINESS_WORK_TYPE_SUBSETS_chooseBusinessWorkTypeSubsets;
}

export interface CHOOSE_BUSINESS_WORK_TYPE_SUBSETSVariables {
  businessId: string;
  subsetIds: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: COMPANY_WANTS_CONTACT_ABOUT_PRODUCT
// ====================================================

export interface COMPANY_WANTS_CONTACT_ABOUT_PRODUCT {
  companyWantsContactAboutProduct: any | null;
}

export interface COMPANY_WANTS_CONTACT_ABOUT_PRODUCTVariables {
  input: CompanyWantsContactAboutProductInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CONFIRM_BOLIGMAPPA_FILE_UPLOAD
// ====================================================

export interface CONFIRM_BOLIGMAPPA_FILE_UPLOAD_confirmBoligmappaFileUpload_documents {
  __typename: "BoligmappaDocument";
  id: string;
  fileName: string;
}

export interface CONFIRM_BOLIGMAPPA_FILE_UPLOAD_confirmBoligmappaFileUpload {
  __typename: "BoligmappaAssociation";
  id: string;
  propertyType: BoligmappaAssociationBuildingType;
  unitNumber: string | null;
  documents: CONFIRM_BOLIGMAPPA_FILE_UPLOAD_confirmBoligmappaFileUpload_documents[];
}

export interface CONFIRM_BOLIGMAPPA_FILE_UPLOAD {
  confirmBoligmappaFileUpload: CONFIRM_BOLIGMAPPA_FILE_UPLOAD_confirmBoligmappaFileUpload[];
}

export interface CONFIRM_BOLIGMAPPA_FILE_UPLOADVariables {
  input: ConfirmBoligmappaFileUploadInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CREATE_ANSWER_TEMPLATE
// ====================================================

export interface CREATE_ANSWER_TEMPLATE_createAnswerTemplate_template {
  __typename: "AnswerTemplate";
  id: string;
  title: string;
  text: string;
}

export interface CREATE_ANSWER_TEMPLATE_createAnswerTemplate {
  __typename: "CreateAnswerTemplatePayload";
  success: boolean | null;
  /**
   *  Set if success 
   */
  template: CREATE_ANSWER_TEMPLATE_createAnswerTemplate_template | null;
}

export interface CREATE_ANSWER_TEMPLATE {
  createAnswerTemplate: CREATE_ANSWER_TEMPLATE_createAnswerTemplate;
}

export interface CREATE_ANSWER_TEMPLATEVariables {
  input: CreateAnswerTemplateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: BOLIGMAPPA_CREATE_ACCOUNT
// ====================================================

export interface BOLIGMAPPA_CREATE_ACCOUNT {
  boligmappaCreateAccount: boolean;
}

export interface BOLIGMAPPA_CREATE_ACCOUNTVariables {
  input: BoligmappaCreateAccountInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CREATE_JOB_FOLDER
// ====================================================

export interface CREATE_JOB_FOLDER_createJobFolder_CreateJobFolderPayload_folder {
  __typename: "JobFolder";
  id: string;
  title: string;
  color: string | null;
}

export interface CREATE_JOB_FOLDER_createJobFolder_CreateJobFolderPayload {
  __typename: "CreateJobFolderPayload";
  folder: CREATE_JOB_FOLDER_createJobFolder_CreateJobFolderPayload_folder;
}

export interface CREATE_JOB_FOLDER_createJobFolder_CreateJobFolderInputError_businessIdError {
  __typename: "BasicInputError";
  message: string;
}

export interface CREATE_JOB_FOLDER_createJobFolder_CreateJobFolderInputError_titleError {
  __typename: "BasicInputError";
  message: string;
}

export interface CREATE_JOB_FOLDER_createJobFolder_CreateJobFolderInputError_descriptionError {
  __typename: "BasicInputError";
  message: string;
}

export interface CREATE_JOB_FOLDER_createJobFolder_CreateJobFolderInputError_colorError {
  __typename: "BasicInputError";
  message: string;
}

export interface CREATE_JOB_FOLDER_createJobFolder_CreateJobFolderInputError {
  __typename: "CreateJobFolderInputError";
  message: string;
  businessIdError: CREATE_JOB_FOLDER_createJobFolder_CreateJobFolderInputError_businessIdError | null;
  titleError: CREATE_JOB_FOLDER_createJobFolder_CreateJobFolderInputError_titleError | null;
  descriptionError: CREATE_JOB_FOLDER_createJobFolder_CreateJobFolderInputError_descriptionError | null;
  colorError: CREATE_JOB_FOLDER_createJobFolder_CreateJobFolderInputError_colorError | null;
}

export type CREATE_JOB_FOLDER_createJobFolder = CREATE_JOB_FOLDER_createJobFolder_CreateJobFolderPayload | CREATE_JOB_FOLDER_createJobFolder_CreateJobFolderInputError;

export interface CREATE_JOB_FOLDER {
  createJobFolder: CREATE_JOB_FOLDER_createJobFolder;
}

export interface CREATE_JOB_FOLDERVariables {
  businessId: string;
  title: string;
  description: string;
  color?: any | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CREATE_JOBS_SAVED_FILTER_MUTATION
// ====================================================

export interface CREATE_JOBS_SAVED_FILTER_MUTATION_createJobWatchlist_filters {
  __typename: "JobWatchlistFilters";
  countyId: string[];
  districtId: string[];
  industryIds: string[];
  municipalityCode: string[];
  query: string;
  sizes: JobSize[];
  worktypeIds: string[];
  worktypeSubsetGroups: string[];
  worktypeSubsets: string[];
}

export interface CREATE_JOBS_SAVED_FILTER_MUTATION_createJobWatchlist_notificationSettings {
  __typename: "JobWatchlistNotificationSettings";
  id: string;
  email: JobWatchlistNotificationFrequency;
  push: JobWatchlistNotificationFrequency;
}

export interface CREATE_JOBS_SAVED_FILTER_MUTATION_createJobWatchlist {
  __typename: "JobWatchlist";
  id: string;
  title: string;
  filters: CREATE_JOBS_SAVED_FILTER_MUTATION_createJobWatchlist_filters;
  notificationSettings: CREATE_JOBS_SAVED_FILTER_MUTATION_createJobWatchlist_notificationSettings;
  readTime: number | null;
  latestJobTime: number | null;
}

export interface CREATE_JOBS_SAVED_FILTER_MUTATION {
  createJobWatchlist: CREATE_JOBS_SAVED_FILTER_MUTATION_createJobWatchlist;
}

export interface CREATE_JOBS_SAVED_FILTER_MUTATIONVariables {
  input: CreateJobWatchlistInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CREATE_SIMPLE_AGREEMENT_CONTRACT
// ====================================================

export interface CREATE_SIMPLE_AGREEMENT_CONTRACT_createSimpleAgreementContract_statusFlags {
  __typename: "SimpleAgreementContractStatusFlags";
  acceptedBusiness: boolean;
  acceptedConsumer: boolean;
  initialized: boolean;
  invalidated: boolean;
  rejectedBusiness: boolean;
  rejectedConsumer: boolean;
}

export interface CREATE_SIMPLE_AGREEMENT_CONTRACT_createSimpleAgreementContract_newAgreement {
  __typename: "SimpleAgreementContract";
  id: string;
}

export interface CREATE_SIMPLE_AGREEMENT_CONTRACT_createSimpleAgreementContract_oldAgreement {
  __typename: "SimpleAgreementContract";
  id: string;
}

export interface CREATE_SIMPLE_AGREEMENT_CONTRACT_createSimpleAgreementContract {
  __typename: "SimpleAgreementContract";
  id: string;
  endDate: number | null;
  statusEnum: SimpleAgreementContractStatus;
  statusFlags: CREATE_SIMPLE_AGREEMENT_CONTRACT_createSimpleAgreementContract_statusFlags;
  startDate: number | null;
  signedUserTime: number | null;
  signedCompanyTime: number | null;
  /**
   *  Simple agreement only contains one price. This can also be hourly work rates, which doesn't equal to what we regard as contract amount. 
   */
  priceType: ContractPriceType;
  price: number | null;
  description: string | null;
  businessEmail: string | null;
  businessPhone: string | null;
  newAgreement: CREATE_SIMPLE_AGREEMENT_CONTRACT_createSimpleAgreementContract_newAgreement | null;
  oldAgreement: CREATE_SIMPLE_AGREEMENT_CONTRACT_createSimpleAgreementContract_oldAgreement | null;
  regTime: number;
  type: ContractType;
}

export interface CREATE_SIMPLE_AGREEMENT_CONTRACT {
  createSimpleAgreementContract: CREATE_SIMPLE_AGREEMENT_CONTRACT_createSimpleAgreementContract;
}

export interface CREATE_SIMPLE_AGREEMENT_CONTRACTVariables {
  input: CreateSimpleAgreementContractInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CREATE_TICKET_MUTATION
// ====================================================

export interface CREATE_TICKET_MUTATION_createTicket {
  __typename: "Ticket";
  id: string;
  guid: string;
  type: TicketType;
  status: TicketStatus;
  title: string;
}

export interface CREATE_TICKET_MUTATION {
  createTicket: CREATE_TICKET_MUTATION_createTicket;
}

export interface CREATE_TICKET_MUTATIONVariables {
  type: TicketType;
  title: string;
  metadata?: string | null;
  relations?: RelationsInput[] | null;
  entryType: TicketEntryType;
  content: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DELETE_ANSWER_TEMPLATE
// ====================================================

export interface DELETE_ANSWER_TEMPLATE_deleteAnswerTemplate {
  __typename: "DeleteAnswerTemplatePayload";
  success: boolean | null;
}

export interface DELETE_ANSWER_TEMPLATE {
  deleteAnswerTemplate: DELETE_ANSWER_TEMPLATE_deleteAnswerTemplate;
}

export interface DELETE_ANSWER_TEMPLATEVariables {
  input: DeleteAnswerTemplateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DELETE_BUSINESS_EMPLOYEE
// ====================================================

export interface DELETE_BUSINESS_EMPLOYEE_deleteBusinessEmployee {
  __typename: "DeleteBusinessEmployeePayload";
  success: boolean | null;
}

export interface DELETE_BUSINESS_EMPLOYEE {
  deleteBusinessEmployee: DELETE_BUSINESS_EMPLOYEE_deleteBusinessEmployee;
}

export interface DELETE_BUSINESS_EMPLOYEEVariables {
  employeeId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DELETE_BUSINESS_EMPLOYEE_IMAGE
// ====================================================

export interface DELETE_BUSINESS_EMPLOYEE_IMAGE {
  deleteBusinessEmployeeImage: boolean | null;
}

export interface DELETE_BUSINESS_EMPLOYEE_IMAGEVariables {
  employeeId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DELETE_BUSINESS_LOGO_MUTATION
// ====================================================

export interface DELETE_BUSINESS_LOGO_MUTATION {
  deleteBusinessLogo: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DELETE_CHAT
// ====================================================

export interface DELETE_CHAT_deleteChat {
  __typename: "DeleteChatPayload";
  success: boolean | null;
}

export interface DELETE_CHAT {
  deleteChat: DELETE_CHAT_deleteChat;
}

export interface DELETE_CHATVariables {
  chatId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DELETE_JOB
// ====================================================

export interface DELETE_JOB_deleteJob {
  __typename: "DeleteJobPayload";
  success: boolean;
}

export interface DELETE_JOB {
  deleteJob: DELETE_JOB_deleteJob;
}

export interface DELETE_JOBVariables {
  jobId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DELETE_JOB_FOLDER
// ====================================================

export interface DELETE_JOB_FOLDER {
  deleteJobFolder: boolean;
}

export interface DELETE_JOB_FOLDERVariables {
  folderId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DELETE_JOBS_SAVED_FILTER_MUTATION
// ====================================================

export interface DELETE_JOBS_SAVED_FILTER_MUTATION {
  deleteJobWatchlist: boolean;
}

export interface DELETE_JOBS_SAVED_FILTER_MUTATIONVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: EDIT_JOB_FOLDER
// ====================================================

export interface EDIT_JOB_FOLDER_editJobFolder_EditJobFolderPayload_folder {
  __typename: "JobFolder";
  id: string;
  title: string;
  color: string | null;
}

export interface EDIT_JOB_FOLDER_editJobFolder_EditJobFolderPayload {
  __typename: "EditJobFolderPayload";
  folder: EDIT_JOB_FOLDER_editJobFolder_EditJobFolderPayload_folder;
}

export interface EDIT_JOB_FOLDER_editJobFolder_EditJobFolderInputError_titleError {
  __typename: "BasicInputError";
  message: string;
}

export interface EDIT_JOB_FOLDER_editJobFolder_EditJobFolderInputError_descriptionError {
  __typename: "BasicInputError";
  message: string;
}

export interface EDIT_JOB_FOLDER_editJobFolder_EditJobFolderInputError_scoreError {
  __typename: "BasicInputError";
  message: string;
}

export interface EDIT_JOB_FOLDER_editJobFolder_EditJobFolderInputError_colorError {
  __typename: "BasicInputError";
  message: string;
}

export interface EDIT_JOB_FOLDER_editJobFolder_EditJobFolderInputError {
  __typename: "EditJobFolderInputError";
  message: string;
  titleError: EDIT_JOB_FOLDER_editJobFolder_EditJobFolderInputError_titleError | null;
  descriptionError: EDIT_JOB_FOLDER_editJobFolder_EditJobFolderInputError_descriptionError | null;
  scoreError: EDIT_JOB_FOLDER_editJobFolder_EditJobFolderInputError_scoreError | null;
  colorError: EDIT_JOB_FOLDER_editJobFolder_EditJobFolderInputError_colorError | null;
}

export type EDIT_JOB_FOLDER_editJobFolder = EDIT_JOB_FOLDER_editJobFolder_EditJobFolderPayload | EDIT_JOB_FOLDER_editJobFolder_EditJobFolderInputError;

export interface EDIT_JOB_FOLDER {
  editJobFolder: EDIT_JOB_FOLDER_editJobFolder;
}

export interface EDIT_JOB_FOLDERVariables {
  folderId: string;
  title?: string | null;
  description?: string | null;
  score?: number | null;
  color?: any | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: EDIT_SIMPLE_AGREEMENT_CONTRACT
// ====================================================

export interface EDIT_SIMPLE_AGREEMENT_CONTRACT_editSimpleAgreementContract_contract_statusFlags {
  __typename: "SimpleAgreementContractStatusFlags";
  initialized: boolean;
  acceptedConsumer: boolean;
  acceptedBusiness: boolean;
  rejectedConsumer: boolean;
  rejectedBusiness: boolean;
  invalidated: boolean;
}

export interface EDIT_SIMPLE_AGREEMENT_CONTRACT_editSimpleAgreementContract_contract_job_address {
  __typename: "Address";
  id: string;
  street: string | null;
  zip: string | null;
  city: string | null;
}

export interface EDIT_SIMPLE_AGREEMENT_CONTRACT_editSimpleAgreementContract_contract_job_owner {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  isDeleted: boolean | null;
  isVerifiedWithSecureId: boolean;
}

export interface EDIT_SIMPLE_AGREEMENT_CONTRACT_editSimpleAgreementContract_contract_job_contactInfo {
  __typename: "JobContactInfo";
  phone: string | null;
  email: string | null;
}

export interface EDIT_SIMPLE_AGREEMENT_CONTRACT_editSimpleAgreementContract_contract_job {
  __typename: "Job";
  /**
   *  job ID 
   */
  id: string;
  /**
   *  Main title of a job 
   */
  title: string;
  /**
   *  Exact address of job. Available to business after job has been answered. Use postal address until then. 
   */
  address: EDIT_SIMPLE_AGREEMENT_CONTRACT_editSimpleAgreementContract_contract_job_address | null;
  /**
   *  Doffin jobs do not have a registered user in our system. Thus the field is optional. 
   */
  owner: EDIT_SIMPLE_AGREEMENT_CONTRACT_editSimpleAgreementContract_contract_job_owner | null;
  contactInfo: EDIT_SIMPLE_AGREEMENT_CONTRACT_editSimpleAgreementContract_contract_job_contactInfo | null;
}

export interface EDIT_SIMPLE_AGREEMENT_CONTRACT_editSimpleAgreementContract_contract_company {
  __typename: "Business";
  id: string;
  name: string;
}

export interface EDIT_SIMPLE_AGREEMENT_CONTRACT_editSimpleAgreementContract_contract_user {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  isDeleted: boolean | null;
  isVerifiedWithSecureId: boolean;
}

export interface EDIT_SIMPLE_AGREEMENT_CONTRACT_editSimpleAgreementContract_contract_newAgreement {
  __typename: "SimpleAgreementContract";
  id: string;
}

export interface EDIT_SIMPLE_AGREEMENT_CONTRACT_editSimpleAgreementContract_contract_oldAgreement {
  __typename: "SimpleAgreementContract";
  id: string;
}

export interface EDIT_SIMPLE_AGREEMENT_CONTRACT_editSimpleAgreementContract_contract {
  __typename: "SimpleAgreementContract";
  id: string;
  type: ContractType;
  regTime: number;
  statusEnum: SimpleAgreementContractStatus;
  description: string | null;
  consumerEmail: string | null;
  consumerPhone: string | null;
  businessEmail: string | null;
  businessPhone: string | null;
  signedUserTime: number | null;
  signedCompanyTime: number | null;
  /**
   *  Simple agreement only contains one price. This can also be hourly work rates, which doesn't equal to what we regard as contract amount. 
   */
  priceType: ContractPriceType;
  price: number | null;
  startDate: number | null;
  endDate: number | null;
  statusFlags: EDIT_SIMPLE_AGREEMENT_CONTRACT_editSimpleAgreementContract_contract_statusFlags;
  job: EDIT_SIMPLE_AGREEMENT_CONTRACT_editSimpleAgreementContract_contract_job;
  company: EDIT_SIMPLE_AGREEMENT_CONTRACT_editSimpleAgreementContract_contract_company;
  user: EDIT_SIMPLE_AGREEMENT_CONTRACT_editSimpleAgreementContract_contract_user;
  newAgreement: EDIT_SIMPLE_AGREEMENT_CONTRACT_editSimpleAgreementContract_contract_newAgreement | null;
  oldAgreement: EDIT_SIMPLE_AGREEMENT_CONTRACT_editSimpleAgreementContract_contract_oldAgreement | null;
}

export interface EDIT_SIMPLE_AGREEMENT_CONTRACT_editSimpleAgreementContract {
  __typename: "EditSimpleAgreementContractPayload";
  contract: EDIT_SIMPLE_AGREEMENT_CONTRACT_editSimpleAgreementContract_contract | null;
}

export interface EDIT_SIMPLE_AGREEMENT_CONTRACT {
  editSimpleAgreementContract: EDIT_SIMPLE_AGREEMENT_CONTRACT_editSimpleAgreementContract;
}

export interface EDIT_SIMPLE_AGREEMENT_CONTRACTVariables {
  input: EditSimpleAgreementContractInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: FAVOURITE_JOB
// ====================================================

export interface FAVOURITE_JOB_favouriteJob {
  __typename: "FavouriteJobPayload";
  success: boolean;
}

export interface FAVOURITE_JOB {
  favouriteJob: FAVOURITE_JOB_favouriteJob;
}

export interface FAVOURITE_JOBVariables {
  jobId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: INITIATE_BOLIGMAPPA_FILE_UPLOAD
// ====================================================

export interface INITIATE_BOLIGMAPPA_FILE_UPLOAD_initiateBoligmappaFileUpload_uploadTargets {
  __typename: "BoligmappaUploadTarget";
  filename: string;
  id: string;
  uploadLink: string;
  uniqueId: string;
}

export interface INITIATE_BOLIGMAPPA_FILE_UPLOAD_initiateBoligmappaFileUpload {
  __typename: "BoligmappaInitiateFileUploadResponse";
  id: string;
  uploadTargets: INITIATE_BOLIGMAPPA_FILE_UPLOAD_initiateBoligmappaFileUpload_uploadTargets[];
}

export interface INITIATE_BOLIGMAPPA_FILE_UPLOAD {
  initiateBoligmappaFileUpload: INITIATE_BOLIGMAPPA_FILE_UPLOAD_initiateBoligmappaFileUpload[];
}

export interface INITIATE_BOLIGMAPPA_FILE_UPLOADVariables {
  input: InitiateBoligmappaFileUploadInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: INITIATE_BOOST_CREDITS_PAYMENT
// ====================================================

export interface INITIATE_BOOST_CREDITS_PAYMENT_initiateBoostCreditsPayment {
  __typename: "SelfServiceOrderResult";
  id: string;
  resultCode: LatestPaymentAttemptResultCode | null;
  returnUrl: string | null;
}

export interface INITIATE_BOOST_CREDITS_PAYMENT {
  initiateBoostCreditsPayment: INITIATE_BOOST_CREDITS_PAYMENT_initiateBoostCreditsPayment;
}

export interface INITIATE_BOOST_CREDITS_PAYMENTVariables {
  input: SelfServiceOrderInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: INITIATE_SELF_SERVICE_SUBSCRIPTION_PAYMENT
// ====================================================

export interface INITIATE_SELF_SERVICE_SUBSCRIPTION_PAYMENT {
  initiateSelfServiceSubscriptionPayment: string;
}

export interface INITIATE_SELF_SERVICE_SUBSCRIPTION_PAYMENTVariables {
  input: InitiateSelfServiceSubscriptionPaymentInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: LOGIN_MUTATION
// ====================================================

export interface LOGIN_MUTATION_login_LoginPayload_user_operatingAs {
  __typename: "Business";
  id: string;
}

export interface LOGIN_MUTATION_login_LoginPayload_user_companies {
  __typename: "Business";
  id: string;
}

export interface LOGIN_MUTATION_login_LoginPayload_user {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  phone: string | null;
  operatingAs: LOGIN_MUTATION_login_LoginPayload_user_operatingAs | null;
  companies: LOGIN_MUTATION_login_LoginPayload_user_companies[] | null;
  isConsumerUser: boolean | null;
}

export interface LOGIN_MUTATION_login_LoginPayload {
  __typename: "LoginPayload";
  token: string | null;
  user: LOGIN_MUTATION_login_LoginPayload_user | null;
}

export interface LOGIN_MUTATION_login_LoginInputError_email {
  __typename: "BasicInputError";
  message: string;
}

export interface LOGIN_MUTATION_login_LoginInputError_password {
  __typename: "BasicInputError";
  message: string;
}

export interface LOGIN_MUTATION_login_LoginInputError {
  __typename: "LoginInputError";
  message: string;
  email: LOGIN_MUTATION_login_LoginInputError_email | null;
  password: LOGIN_MUTATION_login_LoginInputError_password | null;
}

export interface LOGIN_MUTATION_login_LoginCredentialsError {
  __typename: "LoginCredentialsError";
  message: string;
}

export interface LOGIN_MUTATION_login_UserDisabledError {
  __typename: "UserDisabledError";
  message: string;
}

export interface LOGIN_MUTATION_login_LoginRoleUnavailableToUserError {
  __typename: "LoginRoleUnavailableToUserError";
  message: string;
}

export interface LOGIN_MUTATION_login_LoginRoleUnsupportedInContextError {
  __typename: "LoginRoleUnsupportedInContextError";
  message: string;
}

export interface LOGIN_MUTATION_login_LoginAlreadyLoggedInError {
  __typename: "LoginAlreadyLoggedInError";
  message: string;
}

export interface LOGIN_MUTATION_login_LoginUserNotApartOfCompanyError {
  __typename: "LoginUserNotApartOfCompanyError";
  message: string;
}

export interface LOGIN_MUTATION_login_LoginCompanyNotActiveError {
  __typename: "LoginCompanyNotActiveError";
  message: string;
}

export type LOGIN_MUTATION_login = LOGIN_MUTATION_login_LoginPayload | LOGIN_MUTATION_login_LoginInputError | LOGIN_MUTATION_login_LoginCredentialsError | LOGIN_MUTATION_login_UserDisabledError | LOGIN_MUTATION_login_LoginRoleUnavailableToUserError | LOGIN_MUTATION_login_LoginRoleUnsupportedInContextError | LOGIN_MUTATION_login_LoginAlreadyLoggedInError | LOGIN_MUTATION_login_LoginUserNotApartOfCompanyError | LOGIN_MUTATION_login_LoginCompanyNotActiveError;

export interface LOGIN_MUTATION {
  login: LOGIN_MUTATION_login;
}

export interface LOGIN_MUTATIONVariables {
  email: string;
  password: string;
  intendedRole?: UserRole | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: LOGIN_WITH_ONE_TIME_PASSWORD_MUTATION
// ====================================================

export interface LOGIN_WITH_ONE_TIME_PASSWORD_MUTATION_loginWithOneTimePasswordTmp_user_operatingAs {
  __typename: "Business";
  id: string;
}

export interface LOGIN_WITH_ONE_TIME_PASSWORD_MUTATION_loginWithOneTimePasswordTmp_user_companies {
  __typename: "Business";
  id: string;
}

export interface LOGIN_WITH_ONE_TIME_PASSWORD_MUTATION_loginWithOneTimePasswordTmp_user {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  phone: string | null;
  operatingAs: LOGIN_WITH_ONE_TIME_PASSWORD_MUTATION_loginWithOneTimePasswordTmp_user_operatingAs | null;
  companies: LOGIN_WITH_ONE_TIME_PASSWORD_MUTATION_loginWithOneTimePasswordTmp_user_companies[] | null;
  isConsumerUser: boolean | null;
}

export interface LOGIN_WITH_ONE_TIME_PASSWORD_MUTATION_loginWithOneTimePasswordTmp {
  __typename: "LoginPayload";
  token: string | null;
  user: LOGIN_WITH_ONE_TIME_PASSWORD_MUTATION_loginWithOneTimePasswordTmp_user | null;
}

export interface LOGIN_WITH_ONE_TIME_PASSWORD_MUTATION {
  /**
   * Temporary mutation for updating the 'loginWithOneTimePassword' input type
   */
  loginWithOneTimePasswordTmp: LOGIN_WITH_ONE_TIME_PASSWORD_MUTATION_loginWithOneTimePasswordTmp;
}

export interface LOGIN_WITH_ONE_TIME_PASSWORD_MUTATIONVariables {
  email: string;
  verificationCode: string;
  intendedRole?: UserRole | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: LOGIN_WITH_TOKEN_MUTATION
// ====================================================

export interface LOGIN_WITH_TOKEN_MUTATION_loginWithToken_LoginUserNotApartOfCompanyError {
  __typename: "LoginUserNotApartOfCompanyError" | "LoginCompanyNotActiveError";
}

export interface LOGIN_WITH_TOKEN_MUTATION_loginWithToken_LoginPayload_user_operatingAs {
  __typename: "Business";
  id: string;
}

export interface LOGIN_WITH_TOKEN_MUTATION_loginWithToken_LoginPayload_user_companies {
  __typename: "Business";
  id: string;
}

export interface LOGIN_WITH_TOKEN_MUTATION_loginWithToken_LoginPayload_user {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  phone: string | null;
  operatingAs: LOGIN_WITH_TOKEN_MUTATION_loginWithToken_LoginPayload_user_operatingAs | null;
  companies: LOGIN_WITH_TOKEN_MUTATION_loginWithToken_LoginPayload_user_companies[] | null;
  isConsumerUser: boolean | null;
}

export interface LOGIN_WITH_TOKEN_MUTATION_loginWithToken_LoginPayload {
  __typename: "LoginPayload";
  token: string | null;
  user: LOGIN_WITH_TOKEN_MUTATION_loginWithToken_LoginPayload_user | null;
}

export interface LOGIN_WITH_TOKEN_MUTATION_loginWithToken_LoginWithTokenInputError_tokenError {
  __typename: "BasicInputError";
  message: string;
}

export interface LOGIN_WITH_TOKEN_MUTATION_loginWithToken_LoginWithTokenInputError {
  __typename: "LoginWithTokenInputError";
  message: string;
  tokenError: LOGIN_WITH_TOKEN_MUTATION_loginWithToken_LoginWithTokenInputError_tokenError | null;
}

export interface LOGIN_WITH_TOKEN_MUTATION_loginWithToken_LoginCredentialsError {
  __typename: "LoginCredentialsError";
  message: string;
}

export interface LOGIN_WITH_TOKEN_MUTATION_loginWithToken_UserDisabledError {
  __typename: "UserDisabledError";
  message: string;
}

export interface LOGIN_WITH_TOKEN_MUTATION_loginWithToken_LoginRoleUnavailableToUserError {
  __typename: "LoginRoleUnavailableToUserError";
  message: string;
}

export interface LOGIN_WITH_TOKEN_MUTATION_loginWithToken_LoginRoleUnsupportedInContextError {
  __typename: "LoginRoleUnsupportedInContextError";
  message: string;
}

export interface LOGIN_WITH_TOKEN_MUTATION_loginWithToken_LoginAlreadyLoggedInError {
  __typename: "LoginAlreadyLoggedInError";
  message: string;
}

export interface LOGIN_WITH_TOKEN_MUTATION_loginWithToken_LoginCompanyError {
  __typename: "LoginCompanyError";
  message: string;
}

export type LOGIN_WITH_TOKEN_MUTATION_loginWithToken = LOGIN_WITH_TOKEN_MUTATION_loginWithToken_LoginUserNotApartOfCompanyError | LOGIN_WITH_TOKEN_MUTATION_loginWithToken_LoginPayload | LOGIN_WITH_TOKEN_MUTATION_loginWithToken_LoginWithTokenInputError | LOGIN_WITH_TOKEN_MUTATION_loginWithToken_LoginCredentialsError | LOGIN_WITH_TOKEN_MUTATION_loginWithToken_UserDisabledError | LOGIN_WITH_TOKEN_MUTATION_loginWithToken_LoginRoleUnavailableToUserError | LOGIN_WITH_TOKEN_MUTATION_loginWithToken_LoginRoleUnsupportedInContextError | LOGIN_WITH_TOKEN_MUTATION_loginWithToken_LoginAlreadyLoggedInError | LOGIN_WITH_TOKEN_MUTATION_loginWithToken_LoginCompanyError;

export interface LOGIN_WITH_TOKEN_MUTATION {
  loginWithToken: LOGIN_WITH_TOKEN_MUTATION_loginWithToken;
}

export interface LOGIN_WITH_TOKEN_MUTATIONVariables {
  token: string;
  companyId?: string | null;
  intendedRole?: UserRole | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: LOGOUT_MUTATION
// ====================================================

export interface LOGOUT_MUTATION {
  logout: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: MARK_SAVED_FILTER_JOB_LIST_AS_READ
// ====================================================

export interface MARK_SAVED_FILTER_JOB_LIST_AS_READ {
  markJobWatchlistAsRead: boolean;
}

export interface MARK_SAVED_FILTER_JOB_LIST_AS_READVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: OPERATE_AS_BUSINESS
// ====================================================

export interface OPERATE_AS_BUSINESS_operateAs_LoginRequiredError {
  __typename: "LoginRequiredError";
}

export interface OPERATE_AS_BUSINESS_operateAs_OperateAsPayload_user_operatingAs {
  __typename: "Business";
  id: string;
}

export interface OPERATE_AS_BUSINESS_operateAs_OperateAsPayload_user {
  __typename: "User";
  id: string;
  operatingAs: OPERATE_AS_BUSINESS_operateAs_OperateAsPayload_user_operatingAs | null;
}

export interface OPERATE_AS_BUSINESS_operateAs_OperateAsPayload {
  __typename: "OperateAsPayload";
  user: OPERATE_AS_BUSINESS_operateAs_OperateAsPayload_user;
}

export interface OPERATE_AS_BUSINESS_operateAs_OperateAsInputError_businessId {
  __typename: "BasicInputError";
  message: string;
}

export interface OPERATE_AS_BUSINESS_operateAs_OperateAsInputError {
  __typename: "OperateAsInputError";
  businessId: OPERATE_AS_BUSINESS_operateAs_OperateAsInputError_businessId | null;
}

export type OPERATE_AS_BUSINESS_operateAs = OPERATE_AS_BUSINESS_operateAs_LoginRequiredError | OPERATE_AS_BUSINESS_operateAs_OperateAsPayload | OPERATE_AS_BUSINESS_operateAs_OperateAsInputError;

export interface OPERATE_AS_BUSINESS {
  operateAs: OPERATE_AS_BUSINESS_operateAs;
}

export interface OPERATE_AS_BUSINESSVariables {
  businessId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: OPERATE_AS_CONSUMER
// ====================================================

export interface OPERATE_AS_CONSUMER_operateAsConsumer_OperateAsConsumerPayload_user_operatingAs {
  __typename: "Business";
  id: string;
}

export interface OPERATE_AS_CONSUMER_operateAsConsumer_OperateAsConsumerPayload_user {
  __typename: "User";
  id: string;
  operatingAs: OPERATE_AS_CONSUMER_operateAsConsumer_OperateAsConsumerPayload_user_operatingAs | null;
}

export interface OPERATE_AS_CONSUMER_operateAsConsumer_OperateAsConsumerPayload {
  __typename: "OperateAsConsumerPayload";
  user: OPERATE_AS_CONSUMER_operateAsConsumer_OperateAsConsumerPayload_user | null;
}

export interface OPERATE_AS_CONSUMER_operateAsConsumer_LoginRequiredError {
  __typename: "LoginRequiredError";
  message: string;
}

export type OPERATE_AS_CONSUMER_operateAsConsumer = OPERATE_AS_CONSUMER_operateAsConsumer_OperateAsConsumerPayload | OPERATE_AS_CONSUMER_operateAsConsumer_LoginRequiredError;

export interface OPERATE_AS_CONSUMER {
  operateAsConsumer: OPERATE_AS_CONSUMER_operateAsConsumer;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: REJECT_ONE_TO_ONE_JOB
// ====================================================

export interface REJECT_ONE_TO_ONE_JOB_rejectOneToOneJob {
  __typename: "RejectOneToOneJobPayload";
  success: boolean;
}

export interface REJECT_ONE_TO_ONE_JOB {
  rejectOneToOneJob: REJECT_ONE_TO_ONE_JOB_rejectOneToOneJob;
}

export interface REJECT_ONE_TO_ONE_JOBVariables {
  input: RejectOneToOneJobInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: REMOVE_JOB_FROM_JOB_FOLDER
// ====================================================

export interface REMOVE_JOB_FROM_JOB_FOLDER {
  removeJobFromJobFolder: boolean;
}

export interface REMOVE_JOB_FROM_JOB_FOLDERVariables {
  folderId: string;
  jobId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: REPLY_TO_EVALUATION
// ====================================================

export interface REPLY_TO_EVALUATION_replyToEvaluation_ReplyToEvaluationPayload_evaluationReply_evaluation_job_address {
  __typename: "Address";
  city: string | null;
  street: string | null;
  zip: string | null;
}

export interface REPLY_TO_EVALUATION_replyToEvaluation_ReplyToEvaluationPayload_evaluationReply_evaluation_job {
  __typename: "Job";
  /**
   *  job ID 
   */
  id: string;
  /**
   *  Main title of a job 
   */
  title: string;
  /**
   *  Exact address of job. Available to business after job has been answered. Use postal address until then. 
   */
  address: REPLY_TO_EVALUATION_replyToEvaluation_ReplyToEvaluationPayload_evaluationReply_evaluation_job_address | null;
}

export interface REPLY_TO_EVALUATION_replyToEvaluation_ReplyToEvaluationPayload_evaluationReply_evaluation_reply_evaluation {
  __typename: "Evaluation";
  id: string;
}

export interface REPLY_TO_EVALUATION_replyToEvaluation_ReplyToEvaluationPayload_evaluationReply_evaluation_reply_business_logo_dimensions {
  __typename: "Dimension";
  width: number;
  height: number;
}

export interface REPLY_TO_EVALUATION_replyToEvaluation_ReplyToEvaluationPayload_evaluationReply_evaluation_reply_business_logo {
  __typename: "Image";
  bigUrl: string;
  bigPreviewUrl: string;
  description: string | null;
  dimensions: REPLY_TO_EVALUATION_replyToEvaluation_ReplyToEvaluationPayload_evaluationReply_evaluation_reply_business_logo_dimensions;
  id: string;
  mediumPreviewUrl: string;
  mediumUrl: string;
  name: string;
  size: number;
  smallPreviewUrl: string;
  smallUrl: string;
  url: string;
  fileType: string;
}

export interface REPLY_TO_EVALUATION_replyToEvaluation_ReplyToEvaluationPayload_evaluationReply_evaluation_reply_business {
  __typename: "Business";
  id: string;
  initials: string;
  logo: REPLY_TO_EVALUATION_replyToEvaluation_ReplyToEvaluationPayload_evaluationReply_evaluation_reply_business_logo | null;
}

export interface REPLY_TO_EVALUATION_replyToEvaluation_ReplyToEvaluationPayload_evaluationReply_evaluation_reply {
  __typename: "EvaluationReply";
  id: string;
  evaluation: REPLY_TO_EVALUATION_replyToEvaluation_ReplyToEvaluationPayload_evaluationReply_evaluation_reply_evaluation;
  description: string;
  business: REPLY_TO_EVALUATION_replyToEvaluation_ReplyToEvaluationPayload_evaluationReply_evaluation_reply_business;
  publishedTime: number | null;
}

export interface REPLY_TO_EVALUATION_replyToEvaluation_ReplyToEvaluationPayload_evaluationReply_evaluation_business_logo_dimensions {
  __typename: "Dimension";
  width: number;
  height: number;
}

export interface REPLY_TO_EVALUATION_replyToEvaluation_ReplyToEvaluationPayload_evaluationReply_evaluation_business_logo {
  __typename: "Image";
  bigUrl: string;
  bigPreviewUrl: string;
  description: string | null;
  dimensions: REPLY_TO_EVALUATION_replyToEvaluation_ReplyToEvaluationPayload_evaluationReply_evaluation_business_logo_dimensions;
  id: string;
  mediumPreviewUrl: string;
  mediumUrl: string;
  name: string;
  size: number;
  smallPreviewUrl: string;
  smallUrl: string;
  url: string;
  fileType: string;
}

export interface REPLY_TO_EVALUATION_replyToEvaluation_ReplyToEvaluationPayload_evaluationReply_evaluation_business {
  __typename: "Business";
  id: string;
  initials: string;
  logo: REPLY_TO_EVALUATION_replyToEvaluation_ReplyToEvaluationPayload_evaluationReply_evaluation_business_logo | null;
}

export interface REPLY_TO_EVALUATION_replyToEvaluation_ReplyToEvaluationPayload_evaluationReply_evaluation {
  __typename: "Evaluation";
  id: string;
  score: number;
  /**
   *  Contents of the evaluation 
   */
  description: string | null;
  focus: EvaluationFocus | null;
  /**
   *  Name of the person who have evaluated 
   */
  writerName: string;
  publishedTime: number | null;
  /**
   *  Job the evaluation is based upon 
   */
  job: REPLY_TO_EVALUATION_replyToEvaluation_ReplyToEvaluationPayload_evaluationReply_evaluation_job;
  reply: REPLY_TO_EVALUATION_replyToEvaluation_ReplyToEvaluationPayload_evaluationReply_evaluation_reply | null;
  /**
   *  The business evaluated 
   */
  business: REPLY_TO_EVALUATION_replyToEvaluation_ReplyToEvaluationPayload_evaluationReply_evaluation_business;
}

export interface REPLY_TO_EVALUATION_replyToEvaluation_ReplyToEvaluationPayload_evaluationReply_business {
  __typename: "Business";
  id: string;
  initials: string;
}

export interface REPLY_TO_EVALUATION_replyToEvaluation_ReplyToEvaluationPayload_evaluationReply {
  __typename: "EvaluationReply";
  id: string;
  evaluation: REPLY_TO_EVALUATION_replyToEvaluation_ReplyToEvaluationPayload_evaluationReply_evaluation;
  business: REPLY_TO_EVALUATION_replyToEvaluation_ReplyToEvaluationPayload_evaluationReply_business;
  description: string;
  createdTime: number;
  publishedTime: number | null;
}

export interface REPLY_TO_EVALUATION_replyToEvaluation_ReplyToEvaluationPayload {
  __typename: "ReplyToEvaluationPayload";
  evaluationReply: REPLY_TO_EVALUATION_replyToEvaluation_ReplyToEvaluationPayload_evaluationReply;
}

export interface REPLY_TO_EVALUATION_replyToEvaluation_ReplyToEvaluationInputError_evaluationId {
  __typename: "BasicInputError";
  message: string;
}

export interface REPLY_TO_EVALUATION_replyToEvaluation_ReplyToEvaluationInputError_description {
  __typename: "BasicInputError";
  message: string;
}

export interface REPLY_TO_EVALUATION_replyToEvaluation_ReplyToEvaluationInputError {
  __typename: "ReplyToEvaluationInputError";
  message: string;
  evaluationId: REPLY_TO_EVALUATION_replyToEvaluation_ReplyToEvaluationInputError_evaluationId | null;
  description: REPLY_TO_EVALUATION_replyToEvaluation_ReplyToEvaluationInputError_description | null;
}

export interface REPLY_TO_EVALUATION_replyToEvaluation_EvaluationNotFoundError {
  __typename: "EvaluationNotFoundError";
  message: string;
}

export interface REPLY_TO_EVALUATION_replyToEvaluation_AccessDeniedError {
  __typename: "AccessDeniedError";
  message: string;
}

export type REPLY_TO_EVALUATION_replyToEvaluation = REPLY_TO_EVALUATION_replyToEvaluation_ReplyToEvaluationPayload | REPLY_TO_EVALUATION_replyToEvaluation_ReplyToEvaluationInputError | REPLY_TO_EVALUATION_replyToEvaluation_EvaluationNotFoundError | REPLY_TO_EVALUATION_replyToEvaluation_AccessDeniedError;

export interface REPLY_TO_EVALUATION {
  replyToEvaluation: REPLY_TO_EVALUATION_replyToEvaluation;
}

export interface REPLY_TO_EVALUATIONVariables {
  input: ReplyToEvaluationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: REQUEST_DELETION_MUTATION
// ====================================================

export interface REQUEST_DELETION_MUTATION {
  GDPRRequestDeleteUser: any | null;
}

export interface REQUEST_DELETION_MUTATIONVariables {
  from: TicketEntryType;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: REQUEST_LOGIN_TOKEN
// ====================================================

export interface REQUEST_LOGIN_TOKEN_loginWithEmail_LoginWithEmailInputError_email {
  __typename: "BasicInputError";
  message: string;
}

export interface REQUEST_LOGIN_TOKEN_loginWithEmail_LoginWithEmailInputError_domain {
  __typename: "BasicInputError";
  message: string;
}

export interface REQUEST_LOGIN_TOKEN_loginWithEmail_LoginWithEmailInputError_path {
  __typename: "BasicInputError";
  message: string;
}

export interface REQUEST_LOGIN_TOKEN_loginWithEmail_LoginWithEmailInputError {
  __typename: "LoginWithEmailInputError";
  message: string;
  email: REQUEST_LOGIN_TOKEN_loginWithEmail_LoginWithEmailInputError_email | null;
  domain: REQUEST_LOGIN_TOKEN_loginWithEmail_LoginWithEmailInputError_domain | null;
  path: REQUEST_LOGIN_TOKEN_loginWithEmail_LoginWithEmailInputError_path | null;
}

export interface REQUEST_LOGIN_TOKEN_loginWithEmail_LoginWithEmailPayload {
  __typename: "LoginWithEmailPayload";
  success: boolean;
}

export type REQUEST_LOGIN_TOKEN_loginWithEmail = REQUEST_LOGIN_TOKEN_loginWithEmail_LoginWithEmailInputError | REQUEST_LOGIN_TOKEN_loginWithEmail_LoginWithEmailPayload;

export interface REQUEST_LOGIN_TOKEN {
  loginWithEmail: REQUEST_LOGIN_TOKEN_loginWithEmail;
}

export interface REQUEST_LOGIN_TOKENVariables {
  email: string;
  domain?: string | null;
  path?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: REQUEST_SECURE_ID_AUTHENTICATION_URL
// ====================================================

export interface REQUEST_SECURE_ID_AUTHENTICATION_URL_requestSecureIdAuthenticationUrl {
  __typename: "SecureIdAuthenticationUrlPayload";
  id: string;
  authenticationUrl: string;
}

export interface REQUEST_SECURE_ID_AUTHENTICATION_URL {
  requestSecureIdAuthenticationUrl: REQUEST_SECURE_ID_AUTHENTICATION_URL_requestSecureIdAuthenticationUrl;
}

export interface REQUEST_SECURE_ID_AUTHENTICATION_URLVariables {
  input: SecureIdAuthenticationUrlInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: REQUEST_PASSWORD_CHANGE
// ====================================================

export interface REQUEST_PASSWORD_CHANGE {
  requestPasswordChange: any | null;
}

export interface REQUEST_PASSWORD_CHANGEVariables {
  input: RequestPasswordResetInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: REQUEST_PASSWORD_RESET
// ====================================================

export interface REQUEST_PASSWORD_RESET {
  requestPasswordReset: any | null;
}

export interface REQUEST_PASSWORD_RESETVariables {
  input: RequestPasswordResetInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RESTORE_CHAT_MUTATION
// ====================================================

export interface RESTORE_CHAT_MUTATION_restoreChat {
  __typename: "RestoreChatPayload";
  success: boolean | null;
}

export interface RESTORE_CHAT_MUTATION {
  restoreChat: RESTORE_CHAT_MUTATION_restoreChat;
}

export interface RESTORE_CHAT_MUTATIONVariables {
  chatId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RESTORE_JOB
// ====================================================

export interface RESTORE_JOB_restoreJob {
  __typename: "RestoreJobPayload";
  success: boolean;
}

export interface RESTORE_JOB {
  restoreJob: RESTORE_JOB_restoreJob;
}

export interface RESTORE_JOBVariables {
  jobId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SEND_MESSAGE
// ====================================================

export interface SEND_MESSAGE_sendMessage_SendMessagePayload_messages_images_dimensions {
  __typename: "Dimension";
  width: number;
  height: number;
}

export interface SEND_MESSAGE_sendMessage_SendMessagePayload_messages_images {
  __typename: "Image";
  bigUrl: string;
  bigPreviewUrl: string;
  description: string | null;
  dimensions: SEND_MESSAGE_sendMessage_SendMessagePayload_messages_images_dimensions;
  id: string;
  mediumPreviewUrl: string;
  mediumUrl: string;
  name: string;
  size: number;
  smallPreviewUrl: string;
  smallUrl: string;
  url: string;
  fileType: string;
}

export interface SEND_MESSAGE_sendMessage_SendMessagePayload_messages_files {
  __typename: "File";
  id: string;
  name: string;
  url: string;
  fileType: string;
}

export interface SEND_MESSAGE_sendMessage_SendMessagePayload_messages {
  __typename: "Message";
  id: string;
  text: string | null;
  regTs: number;
  images: SEND_MESSAGE_sendMessage_SendMessagePayload_messages_images[] | null;
  files: SEND_MESSAGE_sendMessage_SendMessagePayload_messages_files[] | null;
}

export interface SEND_MESSAGE_sendMessage_SendMessagePayload {
  __typename: "SendMessagePayload";
  /**
   *  Due to lack of support for attachments with text, the payload is a array of messages 
   */
  messages: SEND_MESSAGE_sendMessage_SendMessagePayload_messages[] | null;
}

export interface SEND_MESSAGE_sendMessage_SendMessageInputError {
  __typename: "SendMessageInputError";
  message: string;
}

export interface SEND_MESSAGE_sendMessage_AccessDeniedError {
  __typename: "AccessDeniedError";
  message: string;
}

export type SEND_MESSAGE_sendMessage = SEND_MESSAGE_sendMessage_SendMessagePayload | SEND_MESSAGE_sendMessage_SendMessageInputError | SEND_MESSAGE_sendMessage_AccessDeniedError;

export interface SEND_MESSAGE {
  /**
   *  user sends a message to other part in a chat 
   */
  sendMessage: SEND_MESSAGE_sendMessage;
}

export interface SEND_MESSAGEVariables {
  chatId: string;
  message: string;
  files?: any[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SET_CHAT_AS_READ_MUTATION
// ====================================================

export interface SET_CHAT_AS_READ_MUTATION_setChatAsRead {
  __typename: "SetChatAsReadPayload";
  success: boolean;
}

export interface SET_CHAT_AS_READ_MUTATION {
  setChatAsRead: SET_CHAT_AS_READ_MUTATION_setChatAsRead;
}

export interface SET_CHAT_AS_READ_MUTATIONVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SET_CHAT_AS_UNREAD_MUTATION
// ====================================================

export interface SET_CHAT_AS_UNREAD_MUTATION_setChatAsUnread {
  __typename: "SetChatAsUnreadPayload";
  id: string;
  isUnread: boolean;
}

export interface SET_CHAT_AS_UNREAD_MUTATION {
  setChatAsUnread: SET_CHAT_AS_UNREAD_MUTATION_setChatAsUnread;
}

export interface SET_CHAT_AS_UNREAD_MUTATIONVariables {
  chatId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SET_JOB_AS_READ_MUTATION
// ====================================================

export interface SET_JOB_AS_READ_MUTATION_setJobAsRead {
  __typename: "SetJobAsReadPayload";
  success: boolean;
}

export interface SET_JOB_AS_READ_MUTATION {
  setJobAsRead: SET_JOB_AS_READ_MUTATION_setJobAsRead;
}

export interface SET_JOB_AS_READ_MUTATIONVariables {
  id: string;
  cost?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SET_RECEIVE_NEWSLETTERS
// ====================================================

export interface SET_RECEIVE_NEWSLETTERS {
  setReceiveNewsletters: boolean;
}

export interface SET_RECEIVE_NEWSLETTERSVariables {
  value: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: REJECT_SIMPLE_AGREEMENT_CONTRACT_MUTATION
// ====================================================

export interface REJECT_SIMPLE_AGREEMENT_CONTRACT_MUTATION_rejectSimpleAgreementContract_contract_statusFlags {
  __typename: "SimpleAgreementContractStatusFlags";
  initialized: boolean;
  acceptedConsumer: boolean;
  acceptedBusiness: boolean;
  rejectedConsumer: boolean;
  rejectedBusiness: boolean;
  invalidated: boolean;
}

export interface REJECT_SIMPLE_AGREEMENT_CONTRACT_MUTATION_rejectSimpleAgreementContract_contract_job_address {
  __typename: "Address";
  id: string;
  street: string | null;
  zip: string | null;
  city: string | null;
}

export interface REJECT_SIMPLE_AGREEMENT_CONTRACT_MUTATION_rejectSimpleAgreementContract_contract_job_owner {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  isDeleted: boolean | null;
  isVerifiedWithSecureId: boolean;
}

export interface REJECT_SIMPLE_AGREEMENT_CONTRACT_MUTATION_rejectSimpleAgreementContract_contract_job_contactInfo {
  __typename: "JobContactInfo";
  phone: string | null;
  email: string | null;
}

export interface REJECT_SIMPLE_AGREEMENT_CONTRACT_MUTATION_rejectSimpleAgreementContract_contract_job {
  __typename: "Job";
  /**
   *  job ID 
   */
  id: string;
  /**
   *  Main title of a job 
   */
  title: string;
  /**
   *  Exact address of job. Available to business after job has been answered. Use postal address until then. 
   */
  address: REJECT_SIMPLE_AGREEMENT_CONTRACT_MUTATION_rejectSimpleAgreementContract_contract_job_address | null;
  /**
   *  Doffin jobs do not have a registered user in our system. Thus the field is optional. 
   */
  owner: REJECT_SIMPLE_AGREEMENT_CONTRACT_MUTATION_rejectSimpleAgreementContract_contract_job_owner | null;
  contactInfo: REJECT_SIMPLE_AGREEMENT_CONTRACT_MUTATION_rejectSimpleAgreementContract_contract_job_contactInfo | null;
}

export interface REJECT_SIMPLE_AGREEMENT_CONTRACT_MUTATION_rejectSimpleAgreementContract_contract_company {
  __typename: "Business";
  id: string;
  name: string;
}

export interface REJECT_SIMPLE_AGREEMENT_CONTRACT_MUTATION_rejectSimpleAgreementContract_contract_user {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  isDeleted: boolean | null;
  isVerifiedWithSecureId: boolean;
}

export interface REJECT_SIMPLE_AGREEMENT_CONTRACT_MUTATION_rejectSimpleAgreementContract_contract {
  __typename: "SimpleAgreementContract";
  id: string;
  type: ContractType;
  regTime: number;
  statusEnum: SimpleAgreementContractStatus;
  description: string | null;
  consumerEmail: string | null;
  consumerPhone: string | null;
  businessEmail: string | null;
  businessPhone: string | null;
  signedUserTime: number | null;
  signedCompanyTime: number | null;
  /**
   *  Simple agreement only contains one price. This can also be hourly work rates, which doesn't equal to what we regard as contract amount. 
   */
  priceType: ContractPriceType;
  price: number | null;
  startDate: number | null;
  endDate: number | null;
  statusFlags: REJECT_SIMPLE_AGREEMENT_CONTRACT_MUTATION_rejectSimpleAgreementContract_contract_statusFlags;
  job: REJECT_SIMPLE_AGREEMENT_CONTRACT_MUTATION_rejectSimpleAgreementContract_contract_job;
  company: REJECT_SIMPLE_AGREEMENT_CONTRACT_MUTATION_rejectSimpleAgreementContract_contract_company;
  user: REJECT_SIMPLE_AGREEMENT_CONTRACT_MUTATION_rejectSimpleAgreementContract_contract_user;
}

export interface REJECT_SIMPLE_AGREEMENT_CONTRACT_MUTATION_rejectSimpleAgreementContract {
  __typename: "RejectSimpleAgreementContractPayload";
  contract: REJECT_SIMPLE_AGREEMENT_CONTRACT_MUTATION_rejectSimpleAgreementContract_contract | null;
}

export interface REJECT_SIMPLE_AGREEMENT_CONTRACT_MUTATION {
  rejectSimpleAgreementContract: REJECT_SIMPLE_AGREEMENT_CONTRACT_MUTATION_rejectSimpleAgreementContract;
}

export interface REJECT_SIMPLE_AGREEMENT_CONTRACT_MUTATIONVariables {
  input: RejectSimpleAgreementContractInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SIGN_SIMPLE_AGREEMENT_CONTRACT_MUTATION
// ====================================================

export interface SIGN_SIMPLE_AGREEMENT_CONTRACT_MUTATION_signSimpleAgreementContract_contract_statusFlags {
  __typename: "SimpleAgreementContractStatusFlags";
  initialized: boolean;
  acceptedConsumer: boolean;
  acceptedBusiness: boolean;
  rejectedConsumer: boolean;
  rejectedBusiness: boolean;
  invalidated: boolean;
}

export interface SIGN_SIMPLE_AGREEMENT_CONTRACT_MUTATION_signSimpleAgreementContract_contract_job_address {
  __typename: "Address";
  id: string;
  street: string | null;
  zip: string | null;
  city: string | null;
}

export interface SIGN_SIMPLE_AGREEMENT_CONTRACT_MUTATION_signSimpleAgreementContract_contract_job_owner {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  isDeleted: boolean | null;
  isVerifiedWithSecureId: boolean;
}

export interface SIGN_SIMPLE_AGREEMENT_CONTRACT_MUTATION_signSimpleAgreementContract_contract_job_contactInfo {
  __typename: "JobContactInfo";
  phone: string | null;
  email: string | null;
}

export interface SIGN_SIMPLE_AGREEMENT_CONTRACT_MUTATION_signSimpleAgreementContract_contract_job {
  __typename: "Job";
  /**
   *  job ID 
   */
  id: string;
  /**
   *  Main title of a job 
   */
  title: string;
  /**
   *  Exact address of job. Available to business after job has been answered. Use postal address until then. 
   */
  address: SIGN_SIMPLE_AGREEMENT_CONTRACT_MUTATION_signSimpleAgreementContract_contract_job_address | null;
  /**
   *  Doffin jobs do not have a registered user in our system. Thus the field is optional. 
   */
  owner: SIGN_SIMPLE_AGREEMENT_CONTRACT_MUTATION_signSimpleAgreementContract_contract_job_owner | null;
  contactInfo: SIGN_SIMPLE_AGREEMENT_CONTRACT_MUTATION_signSimpleAgreementContract_contract_job_contactInfo | null;
}

export interface SIGN_SIMPLE_AGREEMENT_CONTRACT_MUTATION_signSimpleAgreementContract_contract_company {
  __typename: "Business";
  id: string;
  name: string;
}

export interface SIGN_SIMPLE_AGREEMENT_CONTRACT_MUTATION_signSimpleAgreementContract_contract_user {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  isDeleted: boolean | null;
  isVerifiedWithSecureId: boolean;
}

export interface SIGN_SIMPLE_AGREEMENT_CONTRACT_MUTATION_signSimpleAgreementContract_contract_chat {
  __typename: "Chat";
  id: string;
}

export interface SIGN_SIMPLE_AGREEMENT_CONTRACT_MUTATION_signSimpleAgreementContract_contract {
  __typename: "SimpleAgreementContract";
  id: string;
  type: ContractType;
  regTime: number;
  statusEnum: SimpleAgreementContractStatus;
  description: string | null;
  consumerEmail: string | null;
  consumerPhone: string | null;
  businessEmail: string | null;
  businessPhone: string | null;
  signedUserTime: number | null;
  signedCompanyTime: number | null;
  /**
   *  Simple agreement only contains one price. This can also be hourly work rates, which doesn't equal to what we regard as contract amount. 
   */
  priceType: ContractPriceType;
  price: number | null;
  startDate: number | null;
  endDate: number | null;
  statusFlags: SIGN_SIMPLE_AGREEMENT_CONTRACT_MUTATION_signSimpleAgreementContract_contract_statusFlags;
  job: SIGN_SIMPLE_AGREEMENT_CONTRACT_MUTATION_signSimpleAgreementContract_contract_job;
  company: SIGN_SIMPLE_AGREEMENT_CONTRACT_MUTATION_signSimpleAgreementContract_contract_company;
  user: SIGN_SIMPLE_AGREEMENT_CONTRACT_MUTATION_signSimpleAgreementContract_contract_user;
  chat: SIGN_SIMPLE_AGREEMENT_CONTRACT_MUTATION_signSimpleAgreementContract_contract_chat | null;
}

export interface SIGN_SIMPLE_AGREEMENT_CONTRACT_MUTATION_signSimpleAgreementContract {
  __typename: "SignSimpleAgreementContractPayload";
  contract: SIGN_SIMPLE_AGREEMENT_CONTRACT_MUTATION_signSimpleAgreementContract_contract | null;
}

export interface SIGN_SIMPLE_AGREEMENT_CONTRACT_MUTATION {
  signSimpleAgreementContract: SIGN_SIMPLE_AGREEMENT_CONTRACT_MUTATION_signSimpleAgreementContract;
}

export interface SIGN_SIMPLE_AGREEMENT_CONTRACT_MUTATIONVariables {
  input: SignSimpleAgreementContractInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: TOGGLE_NOTIFICATION_DESTINATION
// ====================================================

export interface TOGGLE_NOTIFICATION_DESTINATION_toggleNotificationDestination_destinations {
  __typename: "NotificationDestination";
  id: string;
  type: NotificationDestinationType;
  defaultOn: boolean;
  isToggleable: boolean;
  /**
   * Provided as minutes.
   * 
   * If the notification is delayed according to occurence. For instance; nowdays emails
   * are sent 15 minutes after push when a new message – and it's cancelled if the message
   * is read before.
   */
  delay: any | null;
}

export interface TOGGLE_NOTIFICATION_DESTINATION_toggleNotificationDestination {
  __typename: "ToggleNotificationDestinationPayload";
  success: boolean | null;
  destinations: TOGGLE_NOTIFICATION_DESTINATION_toggleNotificationDestination_destinations[];
}

export interface TOGGLE_NOTIFICATION_DESTINATION {
  toggleNotificationDestination: TOGGLE_NOTIFICATION_DESTINATION_toggleNotificationDestination;
}

export interface TOGGLE_NOTIFICATION_DESTINATIONVariables {
  input: ToggleNotificationDestinationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: TRIGGER_EVENT_SELF_SERVICE_PAYMENT
// ====================================================

export interface TRIGGER_EVENT_SELF_SERVICE_PAYMENT {
  triggerEventSelfServicePayment: any | null;
}

export interface TRIGGER_EVENT_SELF_SERVICE_PAYMENTVariables {
  input: TriggerEventSelfServicePaymentInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UNFAVOURITE_JOB
// ====================================================

export interface UNFAVOURITE_JOB_unfavouriteJob {
  __typename: "UnfavouriteJobPayload";
  success: boolean;
}

export interface UNFAVOURITE_JOB {
  unfavouriteJob: UNFAVOURITE_JOB_unfavouriteJob;
}

export interface UNFAVOURITE_JOBVariables {
  jobId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UNLINK_BOLIGMAPPA_INTEGRATION
// ====================================================

export interface UNLINK_BOLIGMAPPA_INTEGRATION_unlinkBoligmappaIntegration {
  __typename: "BoligmappaIntegrationState";
  id: string;
  status: BoligmappaIntegrationStateEnum;
}

export interface UNLINK_BOLIGMAPPA_INTEGRATION {
  unlinkBoligmappaIntegration: UNLINK_BOLIGMAPPA_INTEGRATION_unlinkBoligmappaIntegration;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UPDATE_ANSWER_TEMPLATE
// ====================================================

export interface UPDATE_ANSWER_TEMPLATE_updateAnswerTemplate_template {
  __typename: "AnswerTemplate";
  id: string;
  title: string;
  text: string;
}

export interface UPDATE_ANSWER_TEMPLATE_updateAnswerTemplate {
  __typename: "UpdateAnswerTemplatePayload";
  success: boolean | null;
  template: UPDATE_ANSWER_TEMPLATE_updateAnswerTemplate_template;
}

export interface UPDATE_ANSWER_TEMPLATE {
  updateAnswerTemplate: UPDATE_ANSWER_TEMPLATE_updateAnswerTemplate;
}

export interface UPDATE_ANSWER_TEMPLATEVariables {
  input: UpdateAnswerTemplateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UPDATE_BUSINESS_MUTATION
// ====================================================

export interface UPDATE_BUSINESS_MUTATION_updateBusiness_Business_addresses_billing {
  __typename: "Address";
  id: string;
  type: number;
  description: string | null;
  city: string | null;
  street: string | null;
  lat: string | null;
  lon: string | null;
  zip: string | null;
}

export interface UPDATE_BUSINESS_MUTATION_updateBusiness_Business_addresses_visit {
  __typename: "Address";
  id: string;
  type: number;
  description: string | null;
  city: string | null;
  street: string | null;
  lat: string | null;
  lon: string | null;
  zip: string | null;
}

export interface UPDATE_BUSINESS_MUTATION_updateBusiness_Business_addresses_post {
  __typename: "Address";
  id: string;
  type: number;
  description: string | null;
  city: string | null;
  street: string | null;
  lat: string | null;
  lon: string | null;
  zip: string | null;
}

export interface UPDATE_BUSINESS_MUTATION_updateBusiness_Business_addresses {
  __typename: "BusinessAddresses";
  id: string;
  billing: UPDATE_BUSINESS_MUTATION_updateBusiness_Business_addresses_billing[] | null;
  visit: UPDATE_BUSINESS_MUTATION_updateBusiness_Business_addresses_visit[] | null;
  post: UPDATE_BUSINESS_MUTATION_updateBusiness_Business_addresses_post[] | null;
}

export interface UPDATE_BUSINESS_MUTATION_updateBusiness_Business {
  __typename: "Business";
  id: string;
  name: string;
  phone: string | null;
  email: string | null;
  website: string | null;
  facebook: string | null;
  addresses: UPDATE_BUSINESS_MUTATION_updateBusiness_Business_addresses;
}

export interface UPDATE_BUSINESS_MUTATION_updateBusiness_UpdateBusinessInputError_nameError {
  __typename: "BasicInputError";
  message: string;
}

export interface UPDATE_BUSINESS_MUTATION_updateBusiness_UpdateBusinessInputError_phoneError {
  __typename: "BasicInputError";
  message: string;
}

export interface UPDATE_BUSINESS_MUTATION_updateBusiness_UpdateBusinessInputError_emailError {
  __typename: "BasicInputError";
  message: string;
}

export interface UPDATE_BUSINESS_MUTATION_updateBusiness_UpdateBusinessInputError_descriptionError {
  __typename: "BasicInputError";
  message: string;
}

export interface UPDATE_BUSINESS_MUTATION_updateBusiness_UpdateBusinessInputError_websiteError {
  __typename: "BasicInputError";
  message: string;
}

export interface UPDATE_BUSINESS_MUTATION_updateBusiness_UpdateBusinessInputError_facebookError {
  __typename: "BasicInputError";
  message: string;
}

export interface UPDATE_BUSINESS_MUTATION_updateBusiness_UpdateBusinessInputError_addressError_billing_street {
  __typename: "BasicInputError";
  message: string;
}

export interface UPDATE_BUSINESS_MUTATION_updateBusiness_UpdateBusinessInputError_addressError_billing_zip {
  __typename: "BasicInputError";
  message: string;
}

export interface UPDATE_BUSINESS_MUTATION_updateBusiness_UpdateBusinessInputError_addressError_billing {
  __typename: "AddressInputError";
  message: string;
  street: UPDATE_BUSINESS_MUTATION_updateBusiness_UpdateBusinessInputError_addressError_billing_street | null;
  zip: UPDATE_BUSINESS_MUTATION_updateBusiness_UpdateBusinessInputError_addressError_billing_zip | null;
}

export interface UPDATE_BUSINESS_MUTATION_updateBusiness_UpdateBusinessInputError_addressError_visit_street {
  __typename: "BasicInputError";
  message: string;
}

export interface UPDATE_BUSINESS_MUTATION_updateBusiness_UpdateBusinessInputError_addressError_visit_zip {
  __typename: "BasicInputError";
  message: string;
}

export interface UPDATE_BUSINESS_MUTATION_updateBusiness_UpdateBusinessInputError_addressError_visit {
  __typename: "AddressInputError";
  message: string;
  street: UPDATE_BUSINESS_MUTATION_updateBusiness_UpdateBusinessInputError_addressError_visit_street | null;
  zip: UPDATE_BUSINESS_MUTATION_updateBusiness_UpdateBusinessInputError_addressError_visit_zip | null;
}

export interface UPDATE_BUSINESS_MUTATION_updateBusiness_UpdateBusinessInputError_addressError_postal_street {
  __typename: "BasicInputError";
  message: string;
}

export interface UPDATE_BUSINESS_MUTATION_updateBusiness_UpdateBusinessInputError_addressError_postal_zip {
  __typename: "BasicInputError";
  message: string;
}

export interface UPDATE_BUSINESS_MUTATION_updateBusiness_UpdateBusinessInputError_addressError_postal {
  __typename: "AddressInputError";
  message: string;
  street: UPDATE_BUSINESS_MUTATION_updateBusiness_UpdateBusinessInputError_addressError_postal_street | null;
  zip: UPDATE_BUSINESS_MUTATION_updateBusiness_UpdateBusinessInputError_addressError_postal_zip | null;
}

export interface UPDATE_BUSINESS_MUTATION_updateBusiness_UpdateBusinessInputError_addressError {
  __typename: "BusinessAddressInputError";
  message: string;
  billing: UPDATE_BUSINESS_MUTATION_updateBusiness_UpdateBusinessInputError_addressError_billing | null;
  visit: UPDATE_BUSINESS_MUTATION_updateBusiness_UpdateBusinessInputError_addressError_visit | null;
  postal: UPDATE_BUSINESS_MUTATION_updateBusiness_UpdateBusinessInputError_addressError_postal | null;
}

export interface UPDATE_BUSINESS_MUTATION_updateBusiness_UpdateBusinessInputError {
  __typename: "UpdateBusinessInputError";
  message: string;
  nameError: UPDATE_BUSINESS_MUTATION_updateBusiness_UpdateBusinessInputError_nameError | null;
  phoneError: UPDATE_BUSINESS_MUTATION_updateBusiness_UpdateBusinessInputError_phoneError | null;
  emailError: UPDATE_BUSINESS_MUTATION_updateBusiness_UpdateBusinessInputError_emailError | null;
  descriptionError: UPDATE_BUSINESS_MUTATION_updateBusiness_UpdateBusinessInputError_descriptionError | null;
  websiteError: UPDATE_BUSINESS_MUTATION_updateBusiness_UpdateBusinessInputError_websiteError | null;
  facebookError: UPDATE_BUSINESS_MUTATION_updateBusiness_UpdateBusinessInputError_facebookError | null;
  addressError: UPDATE_BUSINESS_MUTATION_updateBusiness_UpdateBusinessInputError_addressError | null;
}

export type UPDATE_BUSINESS_MUTATION_updateBusiness = UPDATE_BUSINESS_MUTATION_updateBusiness_Business | UPDATE_BUSINESS_MUTATION_updateBusiness_UpdateBusinessInputError;

export interface UPDATE_BUSINESS_MUTATION {
  updateBusiness: UPDATE_BUSINESS_MUTATION_updateBusiness;
}

export interface UPDATE_BUSINESS_MUTATIONVariables {
  data: UpdateBusinessInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UPDATE_BUSINESS_DISTRICTS
// ====================================================

export interface UPDATE_BUSINESS_DISTRICTS_updateBusinessDistricts_districts {
  __typename: "District";
  id: string;
  name: string;
}

export interface UPDATE_BUSINESS_DISTRICTS_updateBusinessDistricts {
  __typename: "UpdateBusinessDistrictsPayload";
  districts: UPDATE_BUSINESS_DISTRICTS_updateBusinessDistricts_districts[] | null;
}

export interface UPDATE_BUSINESS_DISTRICTS {
  updateBusinessDistricts: UPDATE_BUSINESS_DISTRICTS_updateBusinessDistricts;
}

export interface UPDATE_BUSINESS_DISTRICTSVariables {
  toggleDistricts: ToggleDistrictsInput[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UPDATE_BUSINESS_EMPLOYEE
// ====================================================

export interface UPDATE_BUSINESS_EMPLOYEE_updateBusinessEmployee_UpdateBusinessEmployeePayload_employee_image {
  __typename: "Image";
  id: string;
  name: string;
}

export interface UPDATE_BUSINESS_EMPLOYEE_updateBusinessEmployee_UpdateBusinessEmployeePayload_employee {
  __typename: "BusinessEmployee";
  id: string;
  position: string;
  firstName: string;
  lastName: string;
  phoneNr: string;
  email: string;
  imageUrl: string | null;
  image: UPDATE_BUSINESS_EMPLOYEE_updateBusinessEmployee_UpdateBusinessEmployeePayload_employee_image | null;
}

export interface UPDATE_BUSINESS_EMPLOYEE_updateBusinessEmployee_UpdateBusinessEmployeePayload {
  __typename: "UpdateBusinessEmployeePayload";
  employee: UPDATE_BUSINESS_EMPLOYEE_updateBusinessEmployee_UpdateBusinessEmployeePayload_employee;
}

export interface UPDATE_BUSINESS_EMPLOYEE_updateBusinessEmployee_UpdateBusinessEmployeeInputError_employeeId {
  __typename: "BasicInputError";
  message: string;
}

export interface UPDATE_BUSINESS_EMPLOYEE_updateBusinessEmployee_UpdateBusinessEmployeeInputError_position {
  __typename: "BasicInputError";
  message: string;
}

export interface UPDATE_BUSINESS_EMPLOYEE_updateBusinessEmployee_UpdateBusinessEmployeeInputError_firstName {
  __typename: "BasicInputError";
  message: string;
}

export interface UPDATE_BUSINESS_EMPLOYEE_updateBusinessEmployee_UpdateBusinessEmployeeInputError_lastName {
  __typename: "BasicInputError";
  message: string;
}

export interface UPDATE_BUSINESS_EMPLOYEE_updateBusinessEmployee_UpdateBusinessEmployeeInputError_phoneNr {
  __typename: "BasicInputError";
  message: string;
}

export interface UPDATE_BUSINESS_EMPLOYEE_updateBusinessEmployee_UpdateBusinessEmployeeInputError_email {
  __typename: "BasicInputError";
  message: string;
}

export interface UPDATE_BUSINESS_EMPLOYEE_updateBusinessEmployee_UpdateBusinessEmployeeInputError_image {
  __typename: "BasicInputError";
  message: string;
}

export interface UPDATE_BUSINESS_EMPLOYEE_updateBusinessEmployee_UpdateBusinessEmployeeInputError {
  __typename: "UpdateBusinessEmployeeInputError";
  message: string;
  employeeId: UPDATE_BUSINESS_EMPLOYEE_updateBusinessEmployee_UpdateBusinessEmployeeInputError_employeeId | null;
  position: UPDATE_BUSINESS_EMPLOYEE_updateBusinessEmployee_UpdateBusinessEmployeeInputError_position | null;
  firstName: UPDATE_BUSINESS_EMPLOYEE_updateBusinessEmployee_UpdateBusinessEmployeeInputError_firstName | null;
  lastName: UPDATE_BUSINESS_EMPLOYEE_updateBusinessEmployee_UpdateBusinessEmployeeInputError_lastName | null;
  phoneNr: UPDATE_BUSINESS_EMPLOYEE_updateBusinessEmployee_UpdateBusinessEmployeeInputError_phoneNr | null;
  email: UPDATE_BUSINESS_EMPLOYEE_updateBusinessEmployee_UpdateBusinessEmployeeInputError_email | null;
  image: UPDATE_BUSINESS_EMPLOYEE_updateBusinessEmployee_UpdateBusinessEmployeeInputError_image | null;
}

export interface UPDATE_BUSINESS_EMPLOYEE_updateBusinessEmployee_UpdateBusinessEmployeeNoAccessError {
  __typename: "UpdateBusinessEmployeeNoAccessError";
  message: string;
}

export type UPDATE_BUSINESS_EMPLOYEE_updateBusinessEmployee = UPDATE_BUSINESS_EMPLOYEE_updateBusinessEmployee_UpdateBusinessEmployeePayload | UPDATE_BUSINESS_EMPLOYEE_updateBusinessEmployee_UpdateBusinessEmployeeInputError | UPDATE_BUSINESS_EMPLOYEE_updateBusinessEmployee_UpdateBusinessEmployeeNoAccessError;

export interface UPDATE_BUSINESS_EMPLOYEE {
  updateBusinessEmployee: UPDATE_BUSINESS_EMPLOYEE_updateBusinessEmployee;
}

export interface UPDATE_BUSINESS_EMPLOYEEVariables {
  input: UpdateBusinessEmployeeInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UPDATE_BUSINESS_LOGO
// ====================================================

export interface UPDATE_BUSINESS_LOGO_uploadBusinessLogo_ImageUploadPayload_logo_dimensions {
  __typename: "Dimension";
  width: number;
  height: number;
}

export interface UPDATE_BUSINESS_LOGO_uploadBusinessLogo_ImageUploadPayload_logo {
  __typename: "Image";
  bigUrl: string;
  bigPreviewUrl: string;
  description: string | null;
  dimensions: UPDATE_BUSINESS_LOGO_uploadBusinessLogo_ImageUploadPayload_logo_dimensions;
  id: string;
  mediumPreviewUrl: string;
  mediumUrl: string;
  name: string;
  size: number;
  smallPreviewUrl: string;
  smallUrl: string;
  url: string;
  fileType: string;
}

export interface UPDATE_BUSINESS_LOGO_uploadBusinessLogo_ImageUploadPayload {
  __typename: "ImageUploadPayload";
  logo: UPDATE_BUSINESS_LOGO_uploadBusinessLogo_ImageUploadPayload_logo;
}

export interface UPDATE_BUSINESS_LOGO_uploadBusinessLogo_ImageUploadInputError {
  __typename: "ImageUploadInputError";
  message: string;
}

export interface UPDATE_BUSINESS_LOGO_uploadBusinessLogo_LoginRequiredError {
  __typename: "LoginRequiredError";
  message: string;
}

export type UPDATE_BUSINESS_LOGO_uploadBusinessLogo = UPDATE_BUSINESS_LOGO_uploadBusinessLogo_ImageUploadPayload | UPDATE_BUSINESS_LOGO_uploadBusinessLogo_ImageUploadInputError | UPDATE_BUSINESS_LOGO_uploadBusinessLogo_LoginRequiredError;

export interface UPDATE_BUSINESS_LOGO {
  uploadBusinessLogo: UPDATE_BUSINESS_LOGO_uploadBusinessLogo;
}

export interface UPDATE_BUSINESS_LOGOVariables {
  input: ImageUploadInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UPDATE_BUSINESS_MUNICIPALITIES
// ====================================================

export interface UPDATE_BUSINESS_MUNICIPALITIES_updateBusinessMunicipalities_municipalities {
  __typename: "Municipality";
  id: string;
  name: string;
}

export interface UPDATE_BUSINESS_MUNICIPALITIES_updateBusinessMunicipalities {
  __typename: "UpdateBusinessMunicipalitiesPayload";
  municipalities: UPDATE_BUSINESS_MUNICIPALITIES_updateBusinessMunicipalities_municipalities[] | null;
}

export interface UPDATE_BUSINESS_MUNICIPALITIES {
  updateBusinessMunicipalities: UPDATE_BUSINESS_MUNICIPALITIES_updateBusinessMunicipalities;
}

export interface UPDATE_BUSINESS_MUNICIPALITIESVariables {
  toggleMunicipalities: ToggleMunicipalitiesInput[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UPDATE_BUSINESS_PROFILE_INFO
// ====================================================

export interface UPDATE_BUSINESS_PROFILE_INFO_updateBusinessProfileInfo_Business_addresses_visit {
  __typename: "Address";
  id: string;
  street: string | null;
  zip: string | null;
}

export interface UPDATE_BUSINESS_PROFILE_INFO_updateBusinessProfileInfo_Business_addresses {
  __typename: "BusinessAddresses";
  id: string;
  visit: UPDATE_BUSINESS_PROFILE_INFO_updateBusinessProfileInfo_Business_addresses_visit[] | null;
}

export interface UPDATE_BUSINESS_PROFILE_INFO_updateBusinessProfileInfo_Business_socialMedia {
  __typename: "BusinessSocialMedia";
  id: string;
  website: string | null;
  facebook: string | null;
  instagram: string | null;
  youtube: string | null;
  tiktok: string | null;
}

export interface UPDATE_BUSINESS_PROFILE_INFO_updateBusinessProfileInfo_Business_openingHours_monday {
  __typename: "DailyOpeningHours";
  from: any;
  to: any;
}

export interface UPDATE_BUSINESS_PROFILE_INFO_updateBusinessProfileInfo_Business_openingHours_tuesday {
  __typename: "DailyOpeningHours";
  from: any;
  to: any;
}

export interface UPDATE_BUSINESS_PROFILE_INFO_updateBusinessProfileInfo_Business_openingHours_wednesday {
  __typename: "DailyOpeningHours";
  from: any;
  to: any;
}

export interface UPDATE_BUSINESS_PROFILE_INFO_updateBusinessProfileInfo_Business_openingHours_thursday {
  __typename: "DailyOpeningHours";
  from: any;
  to: any;
}

export interface UPDATE_BUSINESS_PROFILE_INFO_updateBusinessProfileInfo_Business_openingHours_friday {
  __typename: "DailyOpeningHours";
  from: any;
  to: any;
}

export interface UPDATE_BUSINESS_PROFILE_INFO_updateBusinessProfileInfo_Business_openingHours_saturday {
  __typename: "DailyOpeningHours";
  from: any;
  to: any;
}

export interface UPDATE_BUSINESS_PROFILE_INFO_updateBusinessProfileInfo_Business_openingHours_sunday {
  __typename: "DailyOpeningHours";
  from: any;
  to: any;
}

export interface UPDATE_BUSINESS_PROFILE_INFO_updateBusinessProfileInfo_Business_openingHours {
  __typename: "WeeklyOpeningHours";
  monday: UPDATE_BUSINESS_PROFILE_INFO_updateBusinessProfileInfo_Business_openingHours_monday | null;
  tuesday: UPDATE_BUSINESS_PROFILE_INFO_updateBusinessProfileInfo_Business_openingHours_tuesday | null;
  wednesday: UPDATE_BUSINESS_PROFILE_INFO_updateBusinessProfileInfo_Business_openingHours_wednesday | null;
  thursday: UPDATE_BUSINESS_PROFILE_INFO_updateBusinessProfileInfo_Business_openingHours_thursday | null;
  friday: UPDATE_BUSINESS_PROFILE_INFO_updateBusinessProfileInfo_Business_openingHours_friday | null;
  saturday: UPDATE_BUSINESS_PROFILE_INFO_updateBusinessProfileInfo_Business_openingHours_saturday | null;
  sunday: UPDATE_BUSINESS_PROFILE_INFO_updateBusinessProfileInfo_Business_openingHours_sunday | null;
}

export interface UPDATE_BUSINESS_PROFILE_INFO_updateBusinessProfileInfo_Business {
  __typename: "Business";
  addresses: UPDATE_BUSINESS_PROFILE_INFO_updateBusinessProfileInfo_Business_addresses;
  profileDescription: string | null;
  id: string;
  phone: string | null;
  socialMedia: UPDATE_BUSINESS_PROFILE_INFO_updateBusinessProfileInfo_Business_socialMedia | null;
  openingHours: UPDATE_BUSINESS_PROFILE_INFO_updateBusinessProfileInfo_Business_openingHours | null;
}

export interface UPDATE_BUSINESS_PROFILE_INFO_updateBusinessProfileInfo_UpdateBusinessProfileInfoInputError_businessIdError {
  __typename: "BasicInputError";
  message: string;
}

export interface UPDATE_BUSINESS_PROFILE_INFO_updateBusinessProfileInfo_UpdateBusinessProfileInfoInputError_phoneError {
  __typename: "BasicInputError";
  message: string;
}

export interface UPDATE_BUSINESS_PROFILE_INFO_updateBusinessProfileInfo_UpdateBusinessProfileInfoInputError_addressError_street {
  __typename: "BasicInputError";
  message: string;
}

export interface UPDATE_BUSINESS_PROFILE_INFO_updateBusinessProfileInfo_UpdateBusinessProfileInfoInputError_addressError_zip {
  __typename: "BasicInputError";
  message: string;
}

export interface UPDATE_BUSINESS_PROFILE_INFO_updateBusinessProfileInfo_UpdateBusinessProfileInfoInputError_addressError {
  __typename: "AddressInputError";
  message: string;
  street: UPDATE_BUSINESS_PROFILE_INFO_updateBusinessProfileInfo_UpdateBusinessProfileInfoInputError_addressError_street | null;
  zip: UPDATE_BUSINESS_PROFILE_INFO_updateBusinessProfileInfo_UpdateBusinessProfileInfoInputError_addressError_zip | null;
}

export interface UPDATE_BUSINESS_PROFILE_INFO_updateBusinessProfileInfo_UpdateBusinessProfileInfoInputError_websiteError {
  __typename: "BasicInputError";
  message: string;
}

export interface UPDATE_BUSINESS_PROFILE_INFO_updateBusinessProfileInfo_UpdateBusinessProfileInfoInputError_facebookError {
  __typename: "BasicInputError";
  message: string;
}

export interface UPDATE_BUSINESS_PROFILE_INFO_updateBusinessProfileInfo_UpdateBusinessProfileInfoInputError_instagramError {
  __typename: "BasicInputError";
  message: string;
}

export interface UPDATE_BUSINESS_PROFILE_INFO_updateBusinessProfileInfo_UpdateBusinessProfileInfoInputError_youtubeError {
  __typename: "BasicInputError";
  message: string;
}

export interface UPDATE_BUSINESS_PROFILE_INFO_updateBusinessProfileInfo_UpdateBusinessProfileInfoInputError_tiktokError {
  __typename: "BasicInputError";
  message: string;
}

export interface UPDATE_BUSINESS_PROFILE_INFO_updateBusinessProfileInfo_UpdateBusinessProfileInfoInputError_titleError {
  __typename: "BasicInputError";
  message: string;
}

export interface UPDATE_BUSINESS_PROFILE_INFO_updateBusinessProfileInfo_UpdateBusinessProfileInfoInputError_descriptionError {
  __typename: "BasicInputError";
  message: string;
}

export interface UPDATE_BUSINESS_PROFILE_INFO_updateBusinessProfileInfo_UpdateBusinessProfileInfoInputError_openingHoursError_monday_from {
  __typename: "BasicInputError";
  message: string;
}

export interface UPDATE_BUSINESS_PROFILE_INFO_updateBusinessProfileInfo_UpdateBusinessProfileInfoInputError_openingHoursError_monday_to {
  __typename: "BasicInputError";
  message: string;
}

export interface UPDATE_BUSINESS_PROFILE_INFO_updateBusinessProfileInfo_UpdateBusinessProfileInfoInputError_openingHoursError_monday {
  __typename: "TimeIntervalInputError";
  message: string;
  from: UPDATE_BUSINESS_PROFILE_INFO_updateBusinessProfileInfo_UpdateBusinessProfileInfoInputError_openingHoursError_monday_from | null;
  to: UPDATE_BUSINESS_PROFILE_INFO_updateBusinessProfileInfo_UpdateBusinessProfileInfoInputError_openingHoursError_monday_to | null;
}

export interface UPDATE_BUSINESS_PROFILE_INFO_updateBusinessProfileInfo_UpdateBusinessProfileInfoInputError_openingHoursError_tuesday_from {
  __typename: "BasicInputError";
  message: string;
}

export interface UPDATE_BUSINESS_PROFILE_INFO_updateBusinessProfileInfo_UpdateBusinessProfileInfoInputError_openingHoursError_tuesday_to {
  __typename: "BasicInputError";
  message: string;
}

export interface UPDATE_BUSINESS_PROFILE_INFO_updateBusinessProfileInfo_UpdateBusinessProfileInfoInputError_openingHoursError_tuesday {
  __typename: "TimeIntervalInputError";
  message: string;
  from: UPDATE_BUSINESS_PROFILE_INFO_updateBusinessProfileInfo_UpdateBusinessProfileInfoInputError_openingHoursError_tuesday_from | null;
  to: UPDATE_BUSINESS_PROFILE_INFO_updateBusinessProfileInfo_UpdateBusinessProfileInfoInputError_openingHoursError_tuesday_to | null;
}

export interface UPDATE_BUSINESS_PROFILE_INFO_updateBusinessProfileInfo_UpdateBusinessProfileInfoInputError_openingHoursError_wednesday_from {
  __typename: "BasicInputError";
  message: string;
}

export interface UPDATE_BUSINESS_PROFILE_INFO_updateBusinessProfileInfo_UpdateBusinessProfileInfoInputError_openingHoursError_wednesday_to {
  __typename: "BasicInputError";
  message: string;
}

export interface UPDATE_BUSINESS_PROFILE_INFO_updateBusinessProfileInfo_UpdateBusinessProfileInfoInputError_openingHoursError_wednesday {
  __typename: "TimeIntervalInputError";
  message: string;
  from: UPDATE_BUSINESS_PROFILE_INFO_updateBusinessProfileInfo_UpdateBusinessProfileInfoInputError_openingHoursError_wednesday_from | null;
  to: UPDATE_BUSINESS_PROFILE_INFO_updateBusinessProfileInfo_UpdateBusinessProfileInfoInputError_openingHoursError_wednesday_to | null;
}

export interface UPDATE_BUSINESS_PROFILE_INFO_updateBusinessProfileInfo_UpdateBusinessProfileInfoInputError_openingHoursError_thursday_from {
  __typename: "BasicInputError";
  message: string;
}

export interface UPDATE_BUSINESS_PROFILE_INFO_updateBusinessProfileInfo_UpdateBusinessProfileInfoInputError_openingHoursError_thursday_to {
  __typename: "BasicInputError";
  message: string;
}

export interface UPDATE_BUSINESS_PROFILE_INFO_updateBusinessProfileInfo_UpdateBusinessProfileInfoInputError_openingHoursError_thursday {
  __typename: "TimeIntervalInputError";
  message: string;
  from: UPDATE_BUSINESS_PROFILE_INFO_updateBusinessProfileInfo_UpdateBusinessProfileInfoInputError_openingHoursError_thursday_from | null;
  to: UPDATE_BUSINESS_PROFILE_INFO_updateBusinessProfileInfo_UpdateBusinessProfileInfoInputError_openingHoursError_thursday_to | null;
}

export interface UPDATE_BUSINESS_PROFILE_INFO_updateBusinessProfileInfo_UpdateBusinessProfileInfoInputError_openingHoursError_friday_from {
  __typename: "BasicInputError";
  message: string;
}

export interface UPDATE_BUSINESS_PROFILE_INFO_updateBusinessProfileInfo_UpdateBusinessProfileInfoInputError_openingHoursError_friday_to {
  __typename: "BasicInputError";
  message: string;
}

export interface UPDATE_BUSINESS_PROFILE_INFO_updateBusinessProfileInfo_UpdateBusinessProfileInfoInputError_openingHoursError_friday {
  __typename: "TimeIntervalInputError";
  message: string;
  from: UPDATE_BUSINESS_PROFILE_INFO_updateBusinessProfileInfo_UpdateBusinessProfileInfoInputError_openingHoursError_friday_from | null;
  to: UPDATE_BUSINESS_PROFILE_INFO_updateBusinessProfileInfo_UpdateBusinessProfileInfoInputError_openingHoursError_friday_to | null;
}

export interface UPDATE_BUSINESS_PROFILE_INFO_updateBusinessProfileInfo_UpdateBusinessProfileInfoInputError_openingHoursError_saturday_from {
  __typename: "BasicInputError";
  message: string;
}

export interface UPDATE_BUSINESS_PROFILE_INFO_updateBusinessProfileInfo_UpdateBusinessProfileInfoInputError_openingHoursError_saturday_to {
  __typename: "BasicInputError";
  message: string;
}

export interface UPDATE_BUSINESS_PROFILE_INFO_updateBusinessProfileInfo_UpdateBusinessProfileInfoInputError_openingHoursError_saturday {
  __typename: "TimeIntervalInputError";
  message: string;
  from: UPDATE_BUSINESS_PROFILE_INFO_updateBusinessProfileInfo_UpdateBusinessProfileInfoInputError_openingHoursError_saturday_from | null;
  to: UPDATE_BUSINESS_PROFILE_INFO_updateBusinessProfileInfo_UpdateBusinessProfileInfoInputError_openingHoursError_saturday_to | null;
}

export interface UPDATE_BUSINESS_PROFILE_INFO_updateBusinessProfileInfo_UpdateBusinessProfileInfoInputError_openingHoursError_sunday_from {
  __typename: "BasicInputError";
  message: string;
}

export interface UPDATE_BUSINESS_PROFILE_INFO_updateBusinessProfileInfo_UpdateBusinessProfileInfoInputError_openingHoursError_sunday_to {
  __typename: "BasicInputError";
  message: string;
}

export interface UPDATE_BUSINESS_PROFILE_INFO_updateBusinessProfileInfo_UpdateBusinessProfileInfoInputError_openingHoursError_sunday {
  __typename: "TimeIntervalInputError";
  message: string;
  from: UPDATE_BUSINESS_PROFILE_INFO_updateBusinessProfileInfo_UpdateBusinessProfileInfoInputError_openingHoursError_sunday_from | null;
  to: UPDATE_BUSINESS_PROFILE_INFO_updateBusinessProfileInfo_UpdateBusinessProfileInfoInputError_openingHoursError_sunday_to | null;
}

export interface UPDATE_BUSINESS_PROFILE_INFO_updateBusinessProfileInfo_UpdateBusinessProfileInfoInputError_openingHoursError {
  __typename: "OpeningHoursInputError";
  message: string;
  monday: UPDATE_BUSINESS_PROFILE_INFO_updateBusinessProfileInfo_UpdateBusinessProfileInfoInputError_openingHoursError_monday | null;
  tuesday: UPDATE_BUSINESS_PROFILE_INFO_updateBusinessProfileInfo_UpdateBusinessProfileInfoInputError_openingHoursError_tuesday | null;
  wednesday: UPDATE_BUSINESS_PROFILE_INFO_updateBusinessProfileInfo_UpdateBusinessProfileInfoInputError_openingHoursError_wednesday | null;
  thursday: UPDATE_BUSINESS_PROFILE_INFO_updateBusinessProfileInfo_UpdateBusinessProfileInfoInputError_openingHoursError_thursday | null;
  friday: UPDATE_BUSINESS_PROFILE_INFO_updateBusinessProfileInfo_UpdateBusinessProfileInfoInputError_openingHoursError_friday | null;
  saturday: UPDATE_BUSINESS_PROFILE_INFO_updateBusinessProfileInfo_UpdateBusinessProfileInfoInputError_openingHoursError_saturday | null;
  sunday: UPDATE_BUSINESS_PROFILE_INFO_updateBusinessProfileInfo_UpdateBusinessProfileInfoInputError_openingHoursError_sunday | null;
}

export interface UPDATE_BUSINESS_PROFILE_INFO_updateBusinessProfileInfo_UpdateBusinessProfileInfoInputError {
  __typename: "UpdateBusinessProfileInfoInputError";
  message: string;
  businessIdError: UPDATE_BUSINESS_PROFILE_INFO_updateBusinessProfileInfo_UpdateBusinessProfileInfoInputError_businessIdError | null;
  phoneError: UPDATE_BUSINESS_PROFILE_INFO_updateBusinessProfileInfo_UpdateBusinessProfileInfoInputError_phoneError | null;
  addressError: UPDATE_BUSINESS_PROFILE_INFO_updateBusinessProfileInfo_UpdateBusinessProfileInfoInputError_addressError | null;
  websiteError: UPDATE_BUSINESS_PROFILE_INFO_updateBusinessProfileInfo_UpdateBusinessProfileInfoInputError_websiteError | null;
  facebookError: UPDATE_BUSINESS_PROFILE_INFO_updateBusinessProfileInfo_UpdateBusinessProfileInfoInputError_facebookError | null;
  instagramError: UPDATE_BUSINESS_PROFILE_INFO_updateBusinessProfileInfo_UpdateBusinessProfileInfoInputError_instagramError | null;
  youtubeError: UPDATE_BUSINESS_PROFILE_INFO_updateBusinessProfileInfo_UpdateBusinessProfileInfoInputError_youtubeError | null;
  tiktokError: UPDATE_BUSINESS_PROFILE_INFO_updateBusinessProfileInfo_UpdateBusinessProfileInfoInputError_tiktokError | null;
  titleError: UPDATE_BUSINESS_PROFILE_INFO_updateBusinessProfileInfo_UpdateBusinessProfileInfoInputError_titleError | null;
  descriptionError: UPDATE_BUSINESS_PROFILE_INFO_updateBusinessProfileInfo_UpdateBusinessProfileInfoInputError_descriptionError | null;
  openingHoursError: UPDATE_BUSINESS_PROFILE_INFO_updateBusinessProfileInfo_UpdateBusinessProfileInfoInputError_openingHoursError | null;
}

export type UPDATE_BUSINESS_PROFILE_INFO_updateBusinessProfileInfo = UPDATE_BUSINESS_PROFILE_INFO_updateBusinessProfileInfo_Business | UPDATE_BUSINESS_PROFILE_INFO_updateBusinessProfileInfo_UpdateBusinessProfileInfoInputError;

export interface UPDATE_BUSINESS_PROFILE_INFO {
  updateBusinessProfileInfo: UPDATE_BUSINESS_PROFILE_INFO_updateBusinessProfileInfo;
}

export interface UPDATE_BUSINESS_PROFILE_INFOVariables {
  input: UpdateBusinessProfileInfoInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UPDATE_JOBS_SAVED_FILTER_MUTATION
// ====================================================

export interface UPDATE_JOBS_SAVED_FILTER_MUTATION_updateJobWatchlist_filters {
  __typename: "JobWatchlistFilters";
  countyId: string[];
  districtId: string[];
  industryIds: string[];
  municipalityCode: string[];
  query: string;
  sizes: JobSize[];
  worktypeIds: string[];
  worktypeSubsetGroups: string[];
  worktypeSubsets: string[];
}

export interface UPDATE_JOBS_SAVED_FILTER_MUTATION_updateJobWatchlist_notificationSettings {
  __typename: "JobWatchlistNotificationSettings";
  id: string;
  email: JobWatchlistNotificationFrequency;
  push: JobWatchlistNotificationFrequency;
}

export interface UPDATE_JOBS_SAVED_FILTER_MUTATION_updateJobWatchlist {
  __typename: "JobWatchlist";
  id: string;
  title: string;
  filters: UPDATE_JOBS_SAVED_FILTER_MUTATION_updateJobWatchlist_filters;
  notificationSettings: UPDATE_JOBS_SAVED_FILTER_MUTATION_updateJobWatchlist_notificationSettings;
  readTime: number | null;
  latestJobTime: number | null;
}

export interface UPDATE_JOBS_SAVED_FILTER_MUTATION {
  updateJobWatchlist: UPDATE_JOBS_SAVED_FILTER_MUTATION_updateJobWatchlist;
}

export interface UPDATE_JOBS_SAVED_FILTER_MUTATIONVariables {
  input: UpdateJobWatchlistInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ANSWER_TEMPLATES_QUERY
// ====================================================

export interface ANSWER_TEMPLATES_QUERY_answerTemplates {
  __typename: "AnswerTemplate";
  id: string;
  title: string;
  text: string;
}

export interface ANSWER_TEMPLATES_QUERY {
  answerTemplates: (ANSWER_TEMPLATES_QUERY_answerTemplates | null)[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: BADGE_NUMBERS_QUERY
// ====================================================

export interface BADGE_NUMBERS_QUERY_badgeNumbers {
  __typename: "BadgeNumbers";
  threads: number | null;
  latestActivityAt: number | null;
  messages: number | null;
}

export interface BADGE_NUMBERS_QUERY {
  badgeNumbers: BADGE_NUMBERS_QUERY_badgeNumbers;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: BOLIGMAPPA_ADDRESS_DETAILS
// ====================================================

export interface BOLIGMAPPA_ADDRESS_DETAILS_boligmappaAddressDetails_buildings_addresses {
  __typename: "BoligmappaBuildingAddress";
  id: string;
  houseNumber: number;
  houseSubNumber: string | null;
  streetName: string;
  postalCode: string;
  postalPlace: string;
}

export interface BOLIGMAPPA_ADDRESS_DETAILS_boligmappaAddressDetails_buildings_properties_address {
  __typename: "BoligmappaBuildingAddress";
  id: string;
  houseNumber: number;
  houseSubNumber: string | null;
  streetName: string;
  postalCode: string;
  postalPlace: string;
}

export interface BOLIGMAPPA_ADDRESS_DETAILS_boligmappaAddressDetails_buildings_properties_propertyFacts {
  __typename: "BoligmappaPropertyFacts";
  id: string;
  usableArea: number;
  numberOfBathrooms: number;
  numberOfWCs: number;
  numberOfRooms: number;
}

export interface BOLIGMAPPA_ADDRESS_DETAILS_boligmappaAddressDetails_buildings_properties_propertyOwners {
  __typename: "BoligmappaPropertyOwner";
  id: string;
  firstName: string;
  lastName: string;
}

export interface BOLIGMAPPA_ADDRESS_DETAILS_boligmappaAddressDetails_buildings_properties {
  __typename: "BoligmappaProperty";
  id: string;
  propertyType: string;
  unitNumber: string;
  address: BOLIGMAPPA_ADDRESS_DETAILS_boligmappaAddressDetails_buildings_properties_address;
  propertyFacts: BOLIGMAPPA_ADDRESS_DETAILS_boligmappaAddressDetails_buildings_properties_propertyFacts;
  /**
   * Property owners can be empty array
   */
  propertyOwners: BOLIGMAPPA_ADDRESS_DETAILS_boligmappaAddressDetails_buildings_properties_propertyOwners[];
}

export interface BOLIGMAPPA_ADDRESS_DETAILS_boligmappaAddressDetails_buildings {
  __typename: "BoligmappaBuilding";
  id: string;
  buildingType: string | null;
  buildingStatus: string;
  floorCount: number;
  propertyCount: number;
  totalBuildingArea: number;
  utilizedBuildingArea: number;
  usedFrom: number | null;
  addresses: BOLIGMAPPA_ADDRESS_DETAILS_boligmappaAddressDetails_buildings_addresses[];
  properties: BOLIGMAPPA_ADDRESS_DETAILS_boligmappaAddressDetails_buildings_properties[];
}

export interface BOLIGMAPPA_ADDRESS_DETAILS_boligmappaAddressDetails {
  __typename: "BoligmappaAddressDetails";
  id: string;
  buildings: BOLIGMAPPA_ADDRESS_DETAILS_boligmappaAddressDetails_buildings[];
}

export interface BOLIGMAPPA_ADDRESS_DETAILS {
  boligmappaAddressDetails: BOLIGMAPPA_ADDRESS_DETAILS_boligmappaAddressDetails;
}

export interface BOLIGMAPPA_ADDRESS_DETAILSVariables {
  input: BoligmappaAddressDetailsInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: BOLIGMAPPA_DOCUMENT_AND_PROFESSION_TYPES
// ====================================================

export interface BOLIGMAPPA_DOCUMENT_AND_PROFESSION_TYPES_boligmappaDocumentAndProfessionTypes_documentTypes {
  __typename: "BoligmappaDocumentType";
  id: string;
  value: string;
  label: string;
}

export interface BOLIGMAPPA_DOCUMENT_AND_PROFESSION_TYPES_boligmappaDocumentAndProfessionTypes_professionTypes {
  __typename: "BoligmappaProfessionType";
  id: string;
  value: string;
  label: string;
}

export interface BOLIGMAPPA_DOCUMENT_AND_PROFESSION_TYPES_boligmappaDocumentAndProfessionTypes {
  __typename: "BoligmappaDocumentAndProfessionTypesResponse";
  documentTypes: BOLIGMAPPA_DOCUMENT_AND_PROFESSION_TYPES_boligmappaDocumentAndProfessionTypes_documentTypes[];
  professionTypes: BOLIGMAPPA_DOCUMENT_AND_PROFESSION_TYPES_boligmappaDocumentAndProfessionTypes_professionTypes[];
}

export interface BOLIGMAPPA_DOCUMENT_AND_PROFESSION_TYPES {
  boligmappaDocumentAndProfessionTypes: BOLIGMAPPA_DOCUMENT_AND_PROFESSION_TYPES_boligmappaDocumentAndProfessionTypes;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: BOLIGMAPPA_DOWNLOAD_FILE
// ====================================================

export interface BOLIGMAPPA_DOWNLOAD_FILE_boligmappaDownloadFile_documentType {
  __typename: "BoligmappaDocumentType";
  id: string;
  name: string;
}

export interface BOLIGMAPPA_DOWNLOAD_FILE_boligmappaDownloadFile {
  __typename: "BoligmappaDownloadFileResponse";
  id: string;
  fileName: string;
  downloadLink: string;
  documentType: BOLIGMAPPA_DOWNLOAD_FILE_boligmappaDownloadFile_documentType;
}

export interface BOLIGMAPPA_DOWNLOAD_FILE {
  boligmappaDownloadFile: BOLIGMAPPA_DOWNLOAD_FILE_boligmappaDownloadFile;
}

export interface BOLIGMAPPA_DOWNLOAD_FILEVariables {
  input: BoligmappaDownloadFileInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: QUERY_BOLIGMAPPA_INTEGRATION_STATE
// ====================================================

export interface QUERY_BOLIGMAPPA_INTEGRATION_STATE_boligmappaIntegrationState {
  __typename: "BoligmappaIntegrationState";
  id: string;
  status: BoligmappaIntegrationStateEnum;
}

export interface QUERY_BOLIGMAPPA_INTEGRATION_STATE {
  boligmappaIntegrationState: QUERY_BOLIGMAPPA_INTEGRATION_STATE_boligmappaIntegrationState;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: BOLIGMAPPA_JOB_ASSOCIATIONS_QUERY
// ====================================================

export interface BOLIGMAPPA_JOB_ASSOCIATIONS_QUERY_boligmappaJobAssociations_address {
  __typename: "BoligmappaBuildingAddress";
  id: string;
  houseNumber: number;
  houseSubNumber: string | null;
  streetName: string;
  postalCode: string;
  postalPlace: string;
}

export interface BOLIGMAPPA_JOB_ASSOCIATIONS_QUERY_boligmappaJobAssociations_associations_documents {
  __typename: "BoligmappaDocument";
  id: string;
  fileName: string;
}

export interface BOLIGMAPPA_JOB_ASSOCIATIONS_QUERY_boligmappaJobAssociations_associations {
  __typename: "BoligmappaAssociation";
  id: string;
  propertyType: BoligmappaAssociationBuildingType;
  unitNumber: string | null;
  documents: BOLIGMAPPA_JOB_ASSOCIATIONS_QUERY_boligmappaJobAssociations_associations_documents[];
}

export interface BOLIGMAPPA_JOB_ASSOCIATIONS_QUERY_boligmappaJobAssociations {
  __typename: "BoligmappaAssociationResponse";
  id: string;
  address: BOLIGMAPPA_JOB_ASSOCIATIONS_QUERY_boligmappaJobAssociations_address;
  associations: BOLIGMAPPA_JOB_ASSOCIATIONS_QUERY_boligmappaJobAssociations_associations[];
}

export interface BOLIGMAPPA_JOB_ASSOCIATIONS_QUERY {
  boligmappaJobAssociations: BOLIGMAPPA_JOB_ASSOCIATIONS_QUERY_boligmappaJobAssociations | null;
}

export interface BOLIGMAPPA_JOB_ASSOCIATIONS_QUERYVariables {
  jobId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: BOLIGMAPPA_LOGIN_URL
// ====================================================

export interface BOLIGMAPPA_LOGIN_URL {
  boligmappaLoginUrl: string;
}

export interface BOLIGMAPPA_LOGIN_URLVariables {
  input: BoligmappaLoginInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: BOOST_CREDITS_PRODUCT_DETAILS
// ====================================================

export interface BOOST_CREDITS_PRODUCT_DETAILS_boostCreditsProductDetails_discount {
  __typename: "SelfServiceProductDiscount";
  discountPercent: number;
  priceAfterDiscount: number;
}

export interface BOOST_CREDITS_PRODUCT_DETAILS_boostCreditsProductDetails {
  __typename: "SelfServiceProduct";
  id: string;
  price: number;
  slug: SelfServiceProductName;
  clips: number;
  /**
   * Only defined if discount was applied
   */
  discount: BOOST_CREDITS_PRODUCT_DETAILS_boostCreditsProductDetails_discount | null;
}

export interface BOOST_CREDITS_PRODUCT_DETAILS {
  boostCreditsProductDetails: BOOST_CREDITS_PRODUCT_DETAILS_boostCreditsProductDetails;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: BOOST_CREDITS_PRODUCTS_PAIR
// ====================================================

export interface BOOST_CREDITS_PRODUCTS_PAIR_boostCreditsProductsPair_discount {
  __typename: "SelfServiceProductDiscount";
  discountPercent: number;
  priceAfterDiscount: number;
}

export interface BOOST_CREDITS_PRODUCTS_PAIR_boostCreditsProductsPair {
  __typename: "SelfServiceProduct";
  id: string;
  price: number;
  slug: SelfServiceProductName;
  clips: number;
  /**
   * Only defined if discount was applied
   */
  discount: BOOST_CREDITS_PRODUCTS_PAIR_boostCreditsProductsPair_discount | null;
}

export interface BOOST_CREDITS_PRODUCTS_PAIR {
  boostCreditsProductsPair: BOOST_CREDITS_PRODUCTS_PAIR_boostCreditsProductsPair[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: BUSINESS_EVALUATIONS_QUERY
// ====================================================

export interface BUSINESS_EVALUATIONS_QUERY_businessEvaluations_pageInfo {
  __typename: "PageInfo";
  /**
   *  cursor to the last element in list 
   */
  endCursor: string | null;
  /**
   *  whether there are items before the current cursor 
   */
  hasNextPage: boolean;
  /**
   *  whether there are items after the current cursor 
   */
  hasPreviousPage: boolean;
  /**
   *  maximum number of nodes to a single page 
   */
  pageSizeLimit: number | null;
  /**
   *  cursor to the first element in list  
   */
  startCursor: string | null;
  /**
   *  total number of entries available 
   */
  totalCount: number | null;
  /**
   *  total number of pages. might be removed due to inclusion of totalCount 
   */
  totalPages: number | null;
}

export interface BUSINESS_EVALUATIONS_QUERY_businessEvaluations_edges_node_business {
  __typename: "Business";
  id: string;
}

export interface BUSINESS_EVALUATIONS_QUERY_businessEvaluations_edges_node_job_address {
  __typename: "Address";
  city: string | null;
  description: string | null;
  id: string;
  lat: string | null;
  lon: string | null;
  street: string | null;
  type: number;
  zip: string | null;
}

export interface BUSINESS_EVALUATIONS_QUERY_businessEvaluations_edges_node_job_owner {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  isDeleted: boolean | null;
  isVerifiedWithSecureId: boolean;
}

export interface BUSINESS_EVALUATIONS_QUERY_businessEvaluations_edges_node_job {
  __typename: "Job";
  /**
   *  job ID 
   */
  id: string;
  /**
   *  Exact address of job. Available to business after job has been answered. Use postal address until then. 
   */
  address: BUSINESS_EVALUATIONS_QUERY_businessEvaluations_edges_node_job_address | null;
  /**
   *  Doffin jobs do not have a registered user in our system. Thus the field is optional. 
   */
  owner: BUSINESS_EVALUATIONS_QUERY_businessEvaluations_edges_node_job_owner | null;
}

export interface BUSINESS_EVALUATIONS_QUERY_businessEvaluations_edges_node_reply {
  __typename: "EvaluationReply";
  id: string;
  description: string;
  createdTime: number;
  publishedTime: number | null;
}

export interface BUSINESS_EVALUATIONS_QUERY_businessEvaluations_edges_node {
  __typename: "Evaluation";
  id: string;
  score: number;
  /**
   *  Contents of the evaluation 
   */
  description: string | null;
  focus: EvaluationFocus | null;
  createdTime: number;
  lastUpdatedTime: number | null;
  approvedTime: number | null;
  publishedTime: number | null;
  verifiedTime: number | null;
  deletedTime: number | null;
  /**
   *  The business evaluated 
   */
  business: BUSINESS_EVALUATIONS_QUERY_businessEvaluations_edges_node_business;
  /**
   *  Job the evaluation is based upon 
   */
  job: BUSINESS_EVALUATIONS_QUERY_businessEvaluations_edges_node_job;
  /**
   *  Name of the person who have evaluated 
   */
  writerName: string;
  reply: BUSINESS_EVALUATIONS_QUERY_businessEvaluations_edges_node_reply | null;
  isPublished: boolean;
}

export interface BUSINESS_EVALUATIONS_QUERY_businessEvaluations_edges {
  __typename: "EvaluationEdge";
  node: BUSINESS_EVALUATIONS_QUERY_businessEvaluations_edges_node;
}

export interface BUSINESS_EVALUATIONS_QUERY_businessEvaluations {
  __typename: "EvaluationConnection";
  pageInfo: BUSINESS_EVALUATIONS_QUERY_businessEvaluations_pageInfo;
  edges: BUSINESS_EVALUATIONS_QUERY_businessEvaluations_edges[];
}

export interface BUSINESS_EVALUATIONS_QUERY {
  /**
   *  List of all evaluations. Defined for displaying evaluations on public front mostly in mind. 
   */
  businessEvaluations: BUSINESS_EVALUATIONS_QUERY_businessEvaluations;
}

export interface BUSINESS_EVALUATIONS_QUERYVariables {
  input: BusinessEvaluationsInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: BUSINESS_NOTIFICATIONS_AVAILABLE_QUERY
// ====================================================

export interface BUSINESS_NOTIFICATIONS_AVAILABLE_QUERY_businessNotificationsAvailable_children_types_destinations {
  __typename: "NotificationDestination";
  id: string;
  type: NotificationDestinationType;
  isToggleable: boolean;
}

export interface BUSINESS_NOTIFICATIONS_AVAILABLE_QUERY_businessNotificationsAvailable_children_types {
  __typename: "NotificationType";
  id: string;
  slug: string;
  name: string;
  destinations: BUSINESS_NOTIFICATIONS_AVAILABLE_QUERY_businessNotificationsAvailable_children_types_destinations[];
}

export interface BUSINESS_NOTIFICATIONS_AVAILABLE_QUERY_businessNotificationsAvailable_children {
  __typename: "NotificationGroup";
  id: string;
  slug: string;
  /**
   *  Should arrive translated from the server 
   */
  name: string;
  types: BUSINESS_NOTIFICATIONS_AVAILABLE_QUERY_businessNotificationsAvailable_children_types[];
}

export interface BUSINESS_NOTIFICATIONS_AVAILABLE_QUERY_businessNotificationsAvailable {
  __typename: "NotificationGroup";
  children: BUSINESS_NOTIFICATIONS_AVAILABLE_QUERY_businessNotificationsAvailable_children[];
}

export interface BUSINESS_NOTIFICATIONS_AVAILABLE_QUERY {
  businessNotificationsAvailable: BUSINESS_NOTIFICATIONS_AVAILABLE_QUERY_businessNotificationsAvailable | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CARD_LIST
// ====================================================

export interface CARD_LIST_cardList {
  __typename: "Card";
  id: string;
  maskedCardNumber: string;
}

export interface CARD_LIST {
  cardList: CARD_LIST_cardList[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CHAT_BY_JOB_ID_QUERY
// ====================================================

export interface CHAT_BY_JOB_ID_QUERY_chatByJobId {
  __typename: "Chat";
  id: string;
}

export interface CHAT_BY_JOB_ID_QUERY {
  chatByJobId: CHAT_BY_JOB_ID_QUERY_chatByJobId;
}

export interface CHAT_BY_JOB_ID_QUERYVariables {
  jobId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CHAT_CONVERSATION_QUERY
// ====================================================

export interface CHAT_CONVERSATION_QUERY_chatMessages_edges_node_business {
  __typename: "Business";
  id: string;
}

export interface CHAT_CONVERSATION_QUERY_chatMessages_edges_node_writer {
  __typename: "User";
  id: string;
}

export interface CHAT_CONVERSATION_QUERY_chatMessages_edges_node_images_dimensions {
  __typename: "Dimension";
  width: number;
  height: number;
}

export interface CHAT_CONVERSATION_QUERY_chatMessages_edges_node_images {
  __typename: "Image";
  bigUrl: string;
  bigPreviewUrl: string;
  description: string | null;
  dimensions: CHAT_CONVERSATION_QUERY_chatMessages_edges_node_images_dimensions;
  id: string;
  mediumPreviewUrl: string;
  mediumUrl: string;
  name: string;
  size: number;
  smallPreviewUrl: string;
  smallUrl: string;
  url: string;
  fileType: string;
}

export interface CHAT_CONVERSATION_QUERY_chatMessages_edges_node_files {
  __typename: "File";
  id: string;
  name: string;
  url: string;
  fileType: string;
}

export interface CHAT_CONVERSATION_QUERY_chatMessages_edges_node {
  __typename: "Message";
  id: string;
  text: string | null;
  type: MessageType;
  business: CHAT_CONVERSATION_QUERY_chatMessages_edges_node_business | null;
  writer: CHAT_CONVERSATION_QUERY_chatMessages_edges_node_writer;
  status: MessageStatus;
  regTs: number;
  images: CHAT_CONVERSATION_QUERY_chatMessages_edges_node_images[] | null;
  files: CHAT_CONVERSATION_QUERY_chatMessages_edges_node_files[] | null;
}

export interface CHAT_CONVERSATION_QUERY_chatMessages_edges {
  __typename: "MessageEdge";
  node: CHAT_CONVERSATION_QUERY_chatMessages_edges_node;
}

export interface CHAT_CONVERSATION_QUERY_chatMessages_pageInfo {
  __typename: "PageInfo";
  /**
   *  total number of pages. might be removed due to inclusion of totalCount 
   */
  totalPages: number | null;
  /**
   *  total number of entries available 
   */
  totalCount: number | null;
  /**
   *  maximum number of nodes to a single page 
   */
  pageSizeLimit: number | null;
  /**
   *  whether there are items before the current cursor 
   */
  hasNextPage: boolean;
  /**
   *  whether there are items after the current cursor 
   */
  hasPreviousPage: boolean;
  /**
   *  cursor to the first element in list  
   */
  startCursor: string | null;
  /**
   *  cursor to the last element in list 
   */
  endCursor: string | null;
}

export interface CHAT_CONVERSATION_QUERY_chatMessages {
  __typename: "MessageConnection";
  edges: CHAT_CONVERSATION_QUERY_chatMessages_edges[];
  pageInfo: CHAT_CONVERSATION_QUERY_chatMessages_pageInfo;
}

export interface CHAT_CONVERSATION_QUERY {
  chatMessages: CHAT_CONVERSATION_QUERY_chatMessages;
}

export interface CHAT_CONVERSATION_QUERYVariables {
  id: string;
  first?: number | null;
  last?: number | null;
  from?: number | null;
  orderByTime?: SortDirection | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CHAT_DETAILS_QUERY
// ====================================================

export interface CHAT_DETAILS_QUERY_chat_Chat_job {
  __typename: "Job";
  /**
   *  job ID 
   */
  id: string;
}

export interface CHAT_DETAILS_QUERY_chat_Chat_participants_User {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  isDeleted: boolean | null;
  isVerifiedWithSecureId: boolean;
}

export interface CHAT_DETAILS_QUERY_chat_Chat_participants_Business {
  __typename: "Business";
  id: string;
}

export type CHAT_DETAILS_QUERY_chat_Chat_participants = CHAT_DETAILS_QUERY_chat_Chat_participants_User | CHAT_DETAILS_QUERY_chat_Chat_participants_Business;

export interface CHAT_DETAILS_QUERY_chat_Chat_latestMessage_business {
  __typename: "Business";
  id: string;
}

export interface CHAT_DETAILS_QUERY_chat_Chat_latestMessage {
  __typename: "Message";
  id: string;
  status: MessageStatus;
  business: CHAT_DETAILS_QUERY_chat_Chat_latestMessage_business | null;
}

export interface CHAT_DETAILS_QUERY_chat_Chat {
  __typename: "Chat";
  id: string;
  /**
   *  the job is done or cancelled 
   */
  isEnded: boolean | null;
  isDeleted: boolean | null;
  isUnread: boolean | null;
  /**
   *  if the user requesting the data has won the job 
   */
  isWon: boolean | null;
  job: CHAT_DETAILS_QUERY_chat_Chat_job | null;
  /**
   * For single chat use q.chatParticipants
   */
  participants: CHAT_DETAILS_QUERY_chat_Chat_participants[];
  /**
   *  latest message in chat. use this when presenting in a list. 
   */
  latestMessage: CHAT_DETAILS_QUERY_chat_Chat_latestMessage | null;
}

export interface CHAT_DETAILS_QUERY_chat_ChatInputError_idError {
  __typename: "BasicInputError";
  message: string;
}

export interface CHAT_DETAILS_QUERY_chat_ChatInputError {
  __typename: "ChatInputError";
  message: string;
  idError: CHAT_DETAILS_QUERY_chat_ChatInputError_idError | null;
}

export interface CHAT_DETAILS_QUERY_chat_ChatNotFoundError {
  __typename: "ChatNotFoundError";
  message: string;
}

export interface CHAT_DETAILS_QUERY_chat_ChatAccessDeniedError {
  __typename: "ChatAccessDeniedError";
  message: string;
}

export type CHAT_DETAILS_QUERY_chat = CHAT_DETAILS_QUERY_chat_Chat | CHAT_DETAILS_QUERY_chat_ChatInputError | CHAT_DETAILS_QUERY_chat_ChatNotFoundError | CHAT_DETAILS_QUERY_chat_ChatAccessDeniedError;

export interface CHAT_DETAILS_QUERY_chatMessages_edges_node_business {
  __typename: "Business";
  id: string;
}

export interface CHAT_DETAILS_QUERY_chatMessages_edges_node_writer {
  __typename: "User";
  id: string;
}

export interface CHAT_DETAILS_QUERY_chatMessages_edges_node_images_dimensions {
  __typename: "Dimension";
  width: number;
  height: number;
}

export interface CHAT_DETAILS_QUERY_chatMessages_edges_node_images {
  __typename: "Image";
  bigUrl: string;
  bigPreviewUrl: string;
  description: string | null;
  dimensions: CHAT_DETAILS_QUERY_chatMessages_edges_node_images_dimensions;
  id: string;
  mediumPreviewUrl: string;
  mediumUrl: string;
  name: string;
  size: number;
  smallPreviewUrl: string;
  smallUrl: string;
  url: string;
  fileType: string;
}

export interface CHAT_DETAILS_QUERY_chatMessages_edges_node_files {
  __typename: "File";
  id: string;
  name: string;
  url: string;
  fileType: string;
}

export interface CHAT_DETAILS_QUERY_chatMessages_edges_node {
  __typename: "Message";
  id: string;
  text: string | null;
  type: MessageType;
  business: CHAT_DETAILS_QUERY_chatMessages_edges_node_business | null;
  writer: CHAT_DETAILS_QUERY_chatMessages_edges_node_writer;
  status: MessageStatus;
  regTs: number;
  images: CHAT_DETAILS_QUERY_chatMessages_edges_node_images[] | null;
  files: CHAT_DETAILS_QUERY_chatMessages_edges_node_files[] | null;
}

export interface CHAT_DETAILS_QUERY_chatMessages_edges {
  __typename: "MessageEdge";
  node: CHAT_DETAILS_QUERY_chatMessages_edges_node;
}

export interface CHAT_DETAILS_QUERY_chatMessages_pageInfo {
  __typename: "PageInfo";
  /**
   *  total number of pages. might be removed due to inclusion of totalCount 
   */
  totalPages: number | null;
  /**
   *  total number of entries available 
   */
  totalCount: number | null;
  /**
   *  maximum number of nodes to a single page 
   */
  pageSizeLimit: number | null;
  /**
   *  whether there are items before the current cursor 
   */
  hasNextPage: boolean;
  /**
   *  whether there are items after the current cursor 
   */
  hasPreviousPage: boolean;
  /**
   *  cursor to the first element in list  
   */
  startCursor: string | null;
  /**
   *  cursor to the last element in list 
   */
  endCursor: string | null;
}

export interface CHAT_DETAILS_QUERY_chatMessages {
  __typename: "MessageConnection";
  edges: CHAT_DETAILS_QUERY_chatMessages_edges[];
  pageInfo: CHAT_DETAILS_QUERY_chatMessages_pageInfo;
}

export interface CHAT_DETAILS_QUERY {
  chat: CHAT_DETAILS_QUERY_chat;
  chatMessages: CHAT_DETAILS_QUERY_chatMessages;
}

export interface CHAT_DETAILS_QUERYVariables {
  id: string;
  first?: number | null;
  from?: number | null;
  last?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CHAT_FIRST_MESSAGE_QUERY
// ====================================================

export interface CHAT_FIRST_MESSAGE_QUERY_chatMessages_edges_node {
  __typename: "Message";
  id: string;
  text: string | null;
}

export interface CHAT_FIRST_MESSAGE_QUERY_chatMessages_edges {
  __typename: "MessageEdge";
  node: CHAT_FIRST_MESSAGE_QUERY_chatMessages_edges_node;
}

export interface CHAT_FIRST_MESSAGE_QUERY_chatMessages {
  __typename: "MessageConnection";
  edges: CHAT_FIRST_MESSAGE_QUERY_chatMessages_edges[];
}

export interface CHAT_FIRST_MESSAGE_QUERY {
  chatMessages: CHAT_FIRST_MESSAGE_QUERY_chatMessages;
}

export interface CHAT_FIRST_MESSAGE_QUERYVariables {
  chatId: string;
  first?: number | null;
  from?: number | null;
  orderByTime?: SortDirection | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CHAT_JOB_QUERY
// ====================================================

export interface CHAT_JOB_QUERY_job_Job_evaluation {
  __typename: "Evaluation";
  id: string;
  score: number;
}

export interface CHAT_JOB_QUERY_job_Job_images_dimensions {
  __typename: "Dimension";
  width: number;
  height: number;
}

export interface CHAT_JOB_QUERY_job_Job_images {
  __typename: "Image";
  bigUrl: string;
  bigPreviewUrl: string;
  description: string | null;
  dimensions: CHAT_JOB_QUERY_job_Job_images_dimensions;
  id: string;
  mediumPreviewUrl: string;
  mediumUrl: string;
  name: string;
  size: number;
  smallPreviewUrl: string;
  smallUrl: string;
  url: string;
  fileType: string;
}

export interface CHAT_JOB_QUERY_job_Job_files {
  __typename: "File";
  description: string | null;
  iconUrl: string;
  id: string;
  name: string;
  size: number;
  url: string;
  fileType: string;
}

export interface CHAT_JOB_QUERY_job_Job_address {
  __typename: "Address";
  city: string | null;
  description: string | null;
  id: string;
  lat: string | null;
  lon: string | null;
  street: string | null;
  type: number;
  zip: string | null;
}

export interface CHAT_JOB_QUERY_job_Job_addressHint {
  __typename: "PostalAddress";
  city: string;
  id: string;
  lat: string | null;
  lon: string | null;
  postalCode: any | null;
}

export interface CHAT_JOB_QUERY_job_Job_owner {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  isDeleted: boolean | null;
  isVerifiedWithSecureId: boolean;
}

export interface CHAT_JOB_QUERY_job_Job_jobBusinessRelationState {
  __typename: "JobBusinessRelationState";
  jobId: string;
  clipsSpent: number | null;
  hasRequestedEvaluation: boolean;
  hasRequestedSelection: boolean;
  isAnswered: boolean;
  isDeleted: boolean;
  isFavourite: boolean;
  isLost: boolean;
  isRead: boolean;
  isWon: boolean;
  isFree: boolean;
  isOneOnOneReceiver: boolean | null;
  isRejected: boolean;
}

export interface CHAT_JOB_QUERY_job_Job_meta {
  __typename: "JobMeta";
  contactPreference: JobContactPreference | null;
}

export interface CHAT_JOB_QUERY_job_Job_contactInfo {
  __typename: "JobContactInfo";
  phone: string | null;
  email: string | null;
}

export interface CHAT_JOB_QUERY_job_Job_workTypes_industries {
  __typename: "Industry";
  id: string;
}

export interface CHAT_JOB_QUERY_job_Job_workTypes {
  __typename: "WorkType";
  /**
   *  worktype ID 
   */
  id: string;
  /**
   *  industries the work type belongs to 
   */
  industries: (CHAT_JOB_QUERY_job_Job_workTypes_industries | null)[] | null;
}

export interface CHAT_JOB_QUERY_job_Job {
  __typename: "Job";
  /**
   *  job ID 
   */
  id: string;
  /**
   *  Main title of a job 
   */
  title: string;
  /**
   *  Description of the job 
   */
  description: string | null;
  evaluation: CHAT_JOB_QUERY_job_Job_evaluation | null;
  /**
   *  If a job has been answered as seen through the eyes of the beholder. 
   */
  isFree: boolean | null;
  images: CHAT_JOB_QUERY_job_Job_images[];
  files: CHAT_JOB_QUERY_job_Job_files[];
  /**
   *  Exact address of job. Available to business after job has been answered. Use postal address until then. 
   */
  address: CHAT_JOB_QUERY_job_Job_address | null;
  /**
   *  Address hinting the jobs location. Useful when the address is not accessible.  
   */
  addressHint: CHAT_JOB_QUERY_job_Job_addressHint | null;
  /**
   *  Doffin jobs do not have a registered user in our system. Thus the field is optional. 
   */
  owner: CHAT_JOB_QUERY_job_Job_owner | null;
  jobBusinessRelationState: CHAT_JOB_QUERY_job_Job_jobBusinessRelationState;
  meta: CHAT_JOB_QUERY_job_Job_meta;
  contactInfo: CHAT_JOB_QUERY_job_Job_contactInfo | null;
  workTypes: CHAT_JOB_QUERY_job_Job_workTypes[];
}

export interface CHAT_JOB_QUERY_job_JobAccessDeniedError {
  __typename: "JobAccessDeniedError";
  message: string;
  reasonCode: JobAccessDeniedCode | null;
}

export interface CHAT_JOB_QUERY_job_JobNotFoundError {
  __typename: "JobNotFoundError";
  message: string;
}

export type CHAT_JOB_QUERY_job = CHAT_JOB_QUERY_job_Job | CHAT_JOB_QUERY_job_JobAccessDeniedError | CHAT_JOB_QUERY_job_JobNotFoundError;

export interface CHAT_JOB_QUERY_jobFiles {
  __typename: "File";
  description: string | null;
  iconUrl: string;
  id: string;
  name: string;
  size: number;
  url: string;
  fileType: string;
}

export interface CHAT_JOB_QUERY_jobImages_dimensions {
  __typename: "Dimension";
  width: number;
  height: number;
}

export interface CHAT_JOB_QUERY_jobImages {
  __typename: "Image";
  bigUrl: string;
  bigPreviewUrl: string;
  description: string | null;
  dimensions: CHAT_JOB_QUERY_jobImages_dimensions;
  id: string;
  mediumPreviewUrl: string;
  mediumUrl: string;
  name: string;
  size: number;
  smallPreviewUrl: string;
  smallUrl: string;
  url: string;
  fileType: string;
}

export interface CHAT_JOB_QUERY_jobEvaluation {
  __typename: "Evaluation";
  id: string;
  score: number;
}

export interface CHAT_JOB_QUERY_jobBusinessRelationState {
  __typename: "JobBusinessRelationState";
  jobId: string;
  clipsSpent: number | null;
  hasRequestedEvaluation: boolean;
  hasRequestedSelection: boolean;
  isAnswered: boolean;
  isDeleted: boolean;
  isFavourite: boolean;
  isLost: boolean;
  isRead: boolean;
  isWon: boolean;
  isFree: boolean;
  isOneOnOneReceiver: boolean | null;
  isRejected: boolean;
}

export interface CHAT_JOB_QUERY_simpleAgreementContractByJobId {
  __typename: "SimpleAgreementContract";
  id: string;
  statusEnum: SimpleAgreementContractStatus;
  type: ContractType;
}

export interface CHAT_JOB_QUERY {
  job: CHAT_JOB_QUERY_job;
  jobFiles: CHAT_JOB_QUERY_jobFiles[];
  jobImages: CHAT_JOB_QUERY_jobImages[];
  jobEvaluation: CHAT_JOB_QUERY_jobEvaluation | null;
  jobBusinessRelationState: CHAT_JOB_QUERY_jobBusinessRelationState;
  simpleAgreementContractByJobId: CHAT_JOB_QUERY_simpleAgreementContractByJobId | null;
}

export interface CHAT_JOB_QUERYVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CHAT_LIST_QUERY
// ====================================================

export interface CHAT_LIST_QUERY_chatList_ChatListPayload_chatConnection_edges_node_job_address {
  __typename: "Address";
  street: string | null;
  id: string;
}

export interface CHAT_LIST_QUERY_chatList_ChatListPayload_chatConnection_edges_node_job_evaluation {
  __typename: "Evaluation";
  id: string;
  score: number;
}

export interface CHAT_LIST_QUERY_chatList_ChatListPayload_chatConnection_edges_node_job_jobBusinessRelationState {
  __typename: "JobBusinessRelationState";
  jobId: string;
  clipsSpent: number | null;
  hasRequestedEvaluation: boolean;
  hasRequestedSelection: boolean;
  isAnswered: boolean;
  isDeleted: boolean;
  isFavourite: boolean;
  isLost: boolean;
  isRead: boolean;
  isWon: boolean;
  isFree: boolean;
  isOneOnOneReceiver: boolean | null;
}

export interface CHAT_LIST_QUERY_chatList_ChatListPayload_chatConnection_edges_node_job {
  __typename: "Job";
  /**
   *  job ID 
   */
  id: string;
  /**
   *  Main title of a job 
   */
  title: string;
  /**
   *  Exact address of job. Available to business after job has been answered. Use postal address until then. 
   */
  address: CHAT_LIST_QUERY_chatList_ChatListPayload_chatConnection_edges_node_job_address | null;
  evaluation: CHAT_LIST_QUERY_chatList_ChatListPayload_chatConnection_edges_node_job_evaluation | null;
  jobBusinessRelationState: CHAT_LIST_QUERY_chatList_ChatListPayload_chatConnection_edges_node_job_jobBusinessRelationState;
}

export interface CHAT_LIST_QUERY_chatList_ChatListPayload_chatConnection_edges_node_latestMessage_writer {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  isDeleted: boolean | null;
  isVerifiedWithSecureId: boolean;
}

export interface CHAT_LIST_QUERY_chatList_ChatListPayload_chatConnection_edges_node_latestMessage {
  __typename: "Message";
  id: string;
  text: string | null;
  type: MessageType;
  regTs: number;
  writer: CHAT_LIST_QUERY_chatList_ChatListPayload_chatConnection_edges_node_latestMessage_writer;
}

export interface CHAT_LIST_QUERY_chatList_ChatListPayload_chatConnection_edges_node_participants_User {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  isDeleted: boolean | null;
  isVerifiedWithSecureId: boolean;
}

export interface CHAT_LIST_QUERY_chatList_ChatListPayload_chatConnection_edges_node_participants_Business {
  __typename: "Business";
  id: string;
}

export type CHAT_LIST_QUERY_chatList_ChatListPayload_chatConnection_edges_node_participants = CHAT_LIST_QUERY_chatList_ChatListPayload_chatConnection_edges_node_participants_User | CHAT_LIST_QUERY_chatList_ChatListPayload_chatConnection_edges_node_participants_Business;

export interface CHAT_LIST_QUERY_chatList_ChatListPayload_chatConnection_edges_node {
  __typename: "Chat";
  id: string;
  /**
   *  if both parts have posted 
   */
  isDialog: boolean | null;
  isDeleted: boolean | null;
  /**
   *  the job is done or cancelled 
   */
  isEnded: boolean | null;
  isUnread: boolean | null;
  job: CHAT_LIST_QUERY_chatList_ChatListPayload_chatConnection_edges_node_job | null;
  /**
   *  latest message in chat. use this when presenting in a list. 
   */
  latestMessage: CHAT_LIST_QUERY_chatList_ChatListPayload_chatConnection_edges_node_latestMessage | null;
  latestUpdateTs: number | null;
  /**
   * For single chat use q.chatParticipants
   */
  participants: CHAT_LIST_QUERY_chatList_ChatListPayload_chatConnection_edges_node_participants[];
}

export interface CHAT_LIST_QUERY_chatList_ChatListPayload_chatConnection_edges {
  __typename: "ChatEdge";
  node: CHAT_LIST_QUERY_chatList_ChatListPayload_chatConnection_edges_node;
}

export interface CHAT_LIST_QUERY_chatList_ChatListPayload_chatConnection_pageInfo {
  __typename: "PageInfo";
  /**
   *  cursor to the last element in list 
   */
  endCursor: string | null;
  /**
   *  whether there are items before the current cursor 
   */
  hasNextPage: boolean;
  /**
   *  whether there are items after the current cursor 
   */
  hasPreviousPage: boolean;
  /**
   *  maximum number of nodes to a single page 
   */
  pageSizeLimit: number | null;
  /**
   *  cursor to the first element in list  
   */
  startCursor: string | null;
  /**
   *  total number of entries available 
   */
  totalCount: number | null;
  /**
   *  total number of pages. might be removed due to inclusion of totalCount 
   */
  totalPages: number | null;
}

export interface CHAT_LIST_QUERY_chatList_ChatListPayload_chatConnection {
  __typename: "ChatConnection";
  edges: CHAT_LIST_QUERY_chatList_ChatListPayload_chatConnection_edges[];
  pageInfo: CHAT_LIST_QUERY_chatList_ChatListPayload_chatConnection_pageInfo;
}

export interface CHAT_LIST_QUERY_chatList_ChatListPayload {
  __typename: "ChatListPayload";
  listType: ChatListType | null;
  chatConnection: CHAT_LIST_QUERY_chatList_ChatListPayload_chatConnection;
}

export interface CHAT_LIST_QUERY_chatList_ChatListFilterError_typeError {
  __typename: "BasicInputError";
  message: string;
}

export interface CHAT_LIST_QUERY_chatList_ChatListFilterError_countError {
  __typename: "BasicInputError";
  message: string;
}

export interface CHAT_LIST_QUERY_chatList_ChatListFilterError_fromError {
  __typename: "BasicInputError";
  message: string;
}

export interface CHAT_LIST_QUERY_chatList_ChatListFilterError_queryError {
  __typename: "BasicInputError";
  message: string;
}

export interface CHAT_LIST_QUERY_chatList_ChatListFilterError_jobIdError {
  __typename: "BasicInputError";
  message: string;
}

export interface CHAT_LIST_QUERY_chatList_ChatListFilterError_businessIdError {
  __typename: "BasicInputError";
  message: string;
}

export interface CHAT_LIST_QUERY_chatList_ChatListFilterError {
  __typename: "ChatListFilterError";
  message: string;
  typeError: CHAT_LIST_QUERY_chatList_ChatListFilterError_typeError | null;
  countError: CHAT_LIST_QUERY_chatList_ChatListFilterError_countError | null;
  fromError: CHAT_LIST_QUERY_chatList_ChatListFilterError_fromError | null;
  queryError: CHAT_LIST_QUERY_chatList_ChatListFilterError_queryError | null;
  jobIdError: CHAT_LIST_QUERY_chatList_ChatListFilterError_jobIdError | null;
  businessIdError: CHAT_LIST_QUERY_chatList_ChatListFilterError_businessIdError | null;
}

export type CHAT_LIST_QUERY_chatList = CHAT_LIST_QUERY_chatList_ChatListPayload | CHAT_LIST_QUERY_chatList_ChatListFilterError;

export interface CHAT_LIST_QUERY {
  chatList: CHAT_LIST_QUERY_chatList;
}

export interface CHAT_LIST_QUERYVariables {
  type?: ChatListType | null;
  from?: number | null;
  count?: number | null;
  query?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: COMPANY_INFO_QUERY
// ====================================================

export interface COMPANY_INFO_QUERY_business_Business_addresses_billing {
  __typename: "Address";
  city: string | null;
  description: string | null;
  id: string;
  lat: string | null;
  lon: string | null;
  street: string | null;
  type: number;
  zip: string | null;
}

export interface COMPANY_INFO_QUERY_business_Business_addresses_post {
  __typename: "Address";
  city: string | null;
  description: string | null;
  id: string;
  lat: string | null;
  lon: string | null;
  street: string | null;
  type: number;
  zip: string | null;
}

export interface COMPANY_INFO_QUERY_business_Business_addresses_visit {
  __typename: "Address";
  city: string | null;
  description: string | null;
  id: string;
  lat: string | null;
  lon: string | null;
  street: string | null;
  type: number;
  zip: string | null;
}

export interface COMPANY_INFO_QUERY_business_Business_addresses {
  __typename: "BusinessAddresses";
  id: string;
  billing: COMPANY_INFO_QUERY_business_Business_addresses_billing[] | null;
  post: COMPANY_INFO_QUERY_business_Business_addresses_post[] | null;
  visit: COMPANY_INFO_QUERY_business_Business_addresses_visit[] | null;
}

export interface COMPANY_INFO_QUERY_business_Business_logo_dimensions {
  __typename: "Dimension";
  width: number;
  height: number;
}

export interface COMPANY_INFO_QUERY_business_Business_logo {
  __typename: "Image";
  bigPreviewUrl: string;
  description: string | null;
  dimensions: COMPANY_INFO_QUERY_business_Business_logo_dimensions;
  iconUrl: string;
  id: string;
  mediumPreviewUrl: string;
  mediumUrl: string;
  name: string;
  size: number;
  smallPreviewUrl: string;
  smallUrl: string;
  url: string;
}

export interface COMPANY_INFO_QUERY_business_Business_openingHours_monday {
  __typename: "DailyOpeningHours";
  from: any;
  to: any;
}

export interface COMPANY_INFO_QUERY_business_Business_openingHours_tuesday {
  __typename: "DailyOpeningHours";
  from: any;
  to: any;
}

export interface COMPANY_INFO_QUERY_business_Business_openingHours_wednesday {
  __typename: "DailyOpeningHours";
  from: any;
  to: any;
}

export interface COMPANY_INFO_QUERY_business_Business_openingHours_thursday {
  __typename: "DailyOpeningHours";
  from: any;
  to: any;
}

export interface COMPANY_INFO_QUERY_business_Business_openingHours_friday {
  __typename: "DailyOpeningHours";
  from: any;
  to: any;
}

export interface COMPANY_INFO_QUERY_business_Business_openingHours_saturday {
  __typename: "DailyOpeningHours";
  from: any;
  to: any;
}

export interface COMPANY_INFO_QUERY_business_Business_openingHours_sunday {
  __typename: "DailyOpeningHours";
  from: any;
  to: any;
}

export interface COMPANY_INFO_QUERY_business_Business_openingHours {
  __typename: "WeeklyOpeningHours";
  monday: COMPANY_INFO_QUERY_business_Business_openingHours_monday | null;
  tuesday: COMPANY_INFO_QUERY_business_Business_openingHours_tuesday | null;
  wednesday: COMPANY_INFO_QUERY_business_Business_openingHours_wednesday | null;
  thursday: COMPANY_INFO_QUERY_business_Business_openingHours_thursday | null;
  friday: COMPANY_INFO_QUERY_business_Business_openingHours_friday | null;
  saturday: COMPANY_INFO_QUERY_business_Business_openingHours_saturday | null;
  sunday: COMPANY_INFO_QUERY_business_Business_openingHours_sunday | null;
}

export interface COMPANY_INFO_QUERY_business_Business {
  __typename: "Business";
  addresses: COMPANY_INFO_QUERY_business_Business_addresses;
  id: string;
  initials: string;
  isActive: boolean | null;
  logo: COMPANY_INFO_QUERY_business_Business_logo | null;
  profileDescription: string | null;
  name: string;
  orgNr: string | null;
  phone: string | null;
  email: string | null;
  openingHours: COMPANY_INFO_QUERY_business_Business_openingHours | null;
}

export interface COMPANY_INFO_QUERY_business_BusinessInputError_idError {
  __typename: "BasicInputError";
  message: string;
}

export interface COMPANY_INFO_QUERY_business_BusinessInputError {
  __typename: "BusinessInputError";
  message: string;
  idError: COMPANY_INFO_QUERY_business_BusinessInputError_idError | null;
}

export interface COMPANY_INFO_QUERY_business_BusinessNotFoundError {
  __typename: "BusinessNotFoundError";
  message: string;
}

export type COMPANY_INFO_QUERY_business = COMPANY_INFO_QUERY_business_Business | COMPANY_INFO_QUERY_business_BusinessInputError | COMPANY_INFO_QUERY_business_BusinessNotFoundError;

export interface COMPANY_INFO_QUERY_businessSocialMedia {
  __typename: "BusinessSocialMedia";
  facebook: string | null;
  id: string;
  instagram: string | null;
  tiktok: string | null;
  website: string | null;
  youtube: string | null;
}

export interface COMPANY_INFO_QUERY {
  business: COMPANY_INFO_QUERY_business;
  businessSocialMedia: COMPANY_INFO_QUERY_businessSocialMedia | null;
}

export interface COMPANY_INFO_QUERYVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: COMPANY_SETTINGS_QUERY
// ====================================================

export interface COMPANY_SETTINGS_QUERY_businessSettings {
  __typename: "CompanySettings";
  id: string;
  JobFolders: boolean | null;
  NumAnswers: number | null;
  NumAnswersRemaining: number | null;
  NumProfilePageVisits: number | null;
  ProfilePage: boolean | null;
  BoostCredits: number | null;
  BoostCreditsRemaining: number | null;
}

export interface COMPANY_SETTINGS_QUERY {
  businessSettings: COMPANY_SETTINGS_QUERY_businessSettings;
}

export interface COMPANY_SETTINGS_QUERYVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: COMPANY_STATE_QUERY
// ====================================================

export interface COMPANY_STATE_QUERY_businessStates {
  __typename: "BusinessStates";
  id: string;
  isVerified: boolean;
  isVerifiedWithSecureId: boolean;
  isDeactivated: boolean;
  isBlacklisted: boolean;
  isOrderUnpaid: boolean;
  isMittanbudXlEligible: boolean;
  hasProducts: boolean;
  hasProductExpired: boolean;
  isNew: boolean;
  products: ProductTemplateSlug[] | null;
  jobAccess: CompanySettingsJobAccess;
  availableProductSlugs: ProductTemplateSlug[];
}

export interface COMPANY_STATE_QUERY {
  businessStates: COMPANY_STATE_QUERY_businessStates;
}

export interface COMPANY_STATE_QUERYVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CURRENT_USER_QUERY
// ====================================================

export interface CURRENT_USER_QUERY_me_operatingAs {
  __typename: "Business";
  id: string;
}

export interface CURRENT_USER_QUERY_me {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  phone: string | null;
  operatingAs: CURRENT_USER_QUERY_me_operatingAs | null;
  isVerifiedWithSecureId: boolean;
}

export interface CURRENT_USER_QUERY {
  me: CURRENT_USER_QUERY_me | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CURRENT_USER_ACCOUNTS_QUERY
// ====================================================

export interface CURRENT_USER_ACCOUNTS_QUERY_me_operatingAs {
  __typename: "Business";
  id: string;
}

export interface CURRENT_USER_ACCOUNTS_QUERY_me_companies_logo {
  __typename: "Image";
  id: string;
  smallPreviewUrl: string;
}

export interface CURRENT_USER_ACCOUNTS_QUERY_me_companies {
  __typename: "Business";
  id: string;
  name: string;
  logo: CURRENT_USER_ACCOUNTS_QUERY_me_companies_logo | null;
  isActive: boolean | null;
}

export interface CURRENT_USER_ACCOUNTS_QUERY_me {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  isDeleted: boolean | null;
  isVerifiedWithSecureId: boolean;
  isConsumerUser: boolean | null;
  operatingAs: CURRENT_USER_ACCOUNTS_QUERY_me_operatingAs | null;
  initials: string | null;
  companies: CURRENT_USER_ACCOUNTS_QUERY_me_companies[] | null;
}

export interface CURRENT_USER_ACCOUNTS_QUERY {
  me: CURRENT_USER_ACCOUNTS_QUERY_me | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CURRENT_USER_COMPANY_QUERY
// ====================================================

export interface CURRENT_USER_COMPANY_QUERY_business_Business_logo_dimensions {
  __typename: "Dimension";
  width: number;
  height: number;
}

export interface CURRENT_USER_COMPANY_QUERY_business_Business_logo {
  __typename: "Image";
  bigUrl: string;
  bigPreviewUrl: string;
  description: string | null;
  dimensions: CURRENT_USER_COMPANY_QUERY_business_Business_logo_dimensions;
  id: string;
  mediumPreviewUrl: string;
  mediumUrl: string;
  name: string;
  size: number;
  smallPreviewUrl: string;
  smallUrl: string;
  url: string;
  fileType: string;
}

export interface CURRENT_USER_COMPANY_QUERY_business_Business {
  __typename: "Business";
  id: string;
  logo: CURRENT_USER_COMPANY_QUERY_business_Business_logo | null;
  profileHeaderImage: string | null;
  name: string;
  orgNr: string | null;
  phone: string | null;
  email: string | null;
}

export interface CURRENT_USER_COMPANY_QUERY_business_BusinessInputError_idError {
  __typename: "BasicInputError";
  message: string;
}

export interface CURRENT_USER_COMPANY_QUERY_business_BusinessInputError {
  __typename: "BusinessInputError";
  message: string;
  idError: CURRENT_USER_COMPANY_QUERY_business_BusinessInputError_idError | null;
}

export interface CURRENT_USER_COMPANY_QUERY_business_BusinessNotFoundError {
  __typename: "BusinessNotFoundError";
  message: string;
}

export type CURRENT_USER_COMPANY_QUERY_business = CURRENT_USER_COMPANY_QUERY_business_Business | CURRENT_USER_COMPANY_QUERY_business_BusinessInputError | CURRENT_USER_COMPANY_QUERY_business_BusinessNotFoundError;

export interface CURRENT_USER_COMPANY_QUERY_businessStates {
  __typename: "BusinessStates";
  id: string;
  isVerified: boolean;
  isVerifiedWithSecureId: boolean;
  isDeactivated: boolean;
  isBlacklisted: boolean;
  isOrderUnpaid: boolean;
  isMittanbudXlEligible: boolean;
  hasProducts: boolean;
  hasProductExpired: boolean;
  isNew: boolean;
  products: ProductTemplateSlug[] | null;
  jobAccess: CompanySettingsJobAccess;
  availableProductSlugs: ProductTemplateSlug[];
}

export interface CURRENT_USER_COMPANY_QUERY_businessSettings {
  __typename: "CompanySettings";
  id: string;
  NumAnswers: number | null;
  NumAnswersRemaining: number | null;
  NumProfilePageVisits: number | null;
  JobFolders: boolean | null;
  ProfilePage: boolean | null;
  BoostCredits: number | null;
  BoostCreditsRemaining: number | null;
}

export interface CURRENT_USER_COMPANY_QUERY {
  business: CURRENT_USER_COMPANY_QUERY_business;
  businessStates: CURRENT_USER_COMPANY_QUERY_businessStates;
  businessSettings: CURRENT_USER_COMPANY_QUERY_businessSettings;
}

export interface CURRENT_USER_COMPANY_QUERYVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: EMPLOYEE_LIST_QUERY
// ====================================================

export interface EMPLOYEE_LIST_QUERY_businessEmployees_image {
  __typename: "Image";
  id: string;
  name: string;
}

export interface EMPLOYEE_LIST_QUERY_businessEmployees {
  __typename: "BusinessEmployee";
  id: string;
  position: string;
  firstName: string;
  lastName: string;
  phoneNr: string;
  email: string;
  imageUrl: string | null;
  image: EMPLOYEE_LIST_QUERY_businessEmployees_image | null;
}

export interface EMPLOYEE_LIST_QUERY {
  businessEmployees: EMPLOYEE_LIST_QUERY_businessEmployees[];
}

export interface EMPLOYEE_LIST_QUERYVariables {
  businessId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: EVALUATION_QUERY
// ====================================================

export interface EVALUATION_QUERY_evaluation_EvaluationPayload_evaluation_job_address {
  __typename: "Address";
  city: string | null;
  street: string | null;
  zip: string | null;
}

export interface EVALUATION_QUERY_evaluation_EvaluationPayload_evaluation_job_owner {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  isDeleted: boolean | null;
  isVerifiedWithSecureId: boolean;
}

export interface EVALUATION_QUERY_evaluation_EvaluationPayload_evaluation_job_images_dimensions {
  __typename: "Dimension";
  width: number;
  height: number;
}

export interface EVALUATION_QUERY_evaluation_EvaluationPayload_evaluation_job_images {
  __typename: "Image";
  bigUrl: string;
  bigPreviewUrl: string;
  description: string | null;
  dimensions: EVALUATION_QUERY_evaluation_EvaluationPayload_evaluation_job_images_dimensions;
  id: string;
  mediumPreviewUrl: string;
  mediumUrl: string;
  name: string;
  size: number;
  smallPreviewUrl: string;
  smallUrl: string;
  url: string;
  fileType: string;
}

export interface EVALUATION_QUERY_evaluation_EvaluationPayload_evaluation_job_files {
  __typename: "File";
  description: string | null;
  iconUrl: string;
  id: string;
  name: string;
  size: number;
  url: string;
  fileType: string;
}

export interface EVALUATION_QUERY_evaluation_EvaluationPayload_evaluation_job {
  __typename: "Job";
  /**
   *  job ID 
   */
  id: string;
  /**
   *  Main title of a job 
   */
  title: string;
  /**
   *  Description of the job 
   */
  description: string | null;
  /**
   *  Exact address of job. Available to business after job has been answered. Use postal address until then. 
   */
  address: EVALUATION_QUERY_evaluation_EvaluationPayload_evaluation_job_address | null;
  /**
   *  Doffin jobs do not have a registered user in our system. Thus the field is optional. 
   */
  owner: EVALUATION_QUERY_evaluation_EvaluationPayload_evaluation_job_owner | null;
  images: EVALUATION_QUERY_evaluation_EvaluationPayload_evaluation_job_images[];
  files: EVALUATION_QUERY_evaluation_EvaluationPayload_evaluation_job_files[];
}

export interface EVALUATION_QUERY_evaluation_EvaluationPayload_evaluation_reply_evaluation {
  __typename: "Evaluation";
  id: string;
}

export interface EVALUATION_QUERY_evaluation_EvaluationPayload_evaluation_reply_business_logo_dimensions {
  __typename: "Dimension";
  width: number;
  height: number;
}

export interface EVALUATION_QUERY_evaluation_EvaluationPayload_evaluation_reply_business_logo {
  __typename: "Image";
  bigUrl: string;
  bigPreviewUrl: string;
  description: string | null;
  dimensions: EVALUATION_QUERY_evaluation_EvaluationPayload_evaluation_reply_business_logo_dimensions;
  id: string;
  mediumPreviewUrl: string;
  mediumUrl: string;
  name: string;
  size: number;
  smallPreviewUrl: string;
  smallUrl: string;
  url: string;
  fileType: string;
}

export interface EVALUATION_QUERY_evaluation_EvaluationPayload_evaluation_reply_business {
  __typename: "Business";
  id: string;
  initials: string;
  logo: EVALUATION_QUERY_evaluation_EvaluationPayload_evaluation_reply_business_logo | null;
}

export interface EVALUATION_QUERY_evaluation_EvaluationPayload_evaluation_reply {
  __typename: "EvaluationReply";
  id: string;
  createdTime: number;
  evaluation: EVALUATION_QUERY_evaluation_EvaluationPayload_evaluation_reply_evaluation;
  description: string;
  business: EVALUATION_QUERY_evaluation_EvaluationPayload_evaluation_reply_business;
  publishedTime: number | null;
}

export interface EVALUATION_QUERY_evaluation_EvaluationPayload_evaluation_business_logo_dimensions {
  __typename: "Dimension";
  width: number;
  height: number;
}

export interface EVALUATION_QUERY_evaluation_EvaluationPayload_evaluation_business_logo {
  __typename: "Image";
  bigUrl: string;
  bigPreviewUrl: string;
  description: string | null;
  dimensions: EVALUATION_QUERY_evaluation_EvaluationPayload_evaluation_business_logo_dimensions;
  id: string;
  mediumPreviewUrl: string;
  mediumUrl: string;
  name: string;
  size: number;
  smallPreviewUrl: string;
  smallUrl: string;
  url: string;
  fileType: string;
}

export interface EVALUATION_QUERY_evaluation_EvaluationPayload_evaluation_business {
  __typename: "Business";
  id: string;
  initials: string;
  logo: EVALUATION_QUERY_evaluation_EvaluationPayload_evaluation_business_logo | null;
}

export interface EVALUATION_QUERY_evaluation_EvaluationPayload_evaluation {
  __typename: "Evaluation";
  id: string;
  score: number;
  /**
   *  Contents of the evaluation 
   */
  description: string | null;
  focus: EvaluationFocus | null;
  /**
   *  Name of the person who have evaluated 
   */
  writerName: string;
  publishedTime: number | null;
  /**
   *  Job the evaluation is based upon 
   */
  job: EVALUATION_QUERY_evaluation_EvaluationPayload_evaluation_job;
  reply: EVALUATION_QUERY_evaluation_EvaluationPayload_evaluation_reply | null;
  /**
   *  The business evaluated 
   */
  business: EVALUATION_QUERY_evaluation_EvaluationPayload_evaluation_business;
}

export interface EVALUATION_QUERY_evaluation_EvaluationPayload {
  __typename: "EvaluationPayload";
  evaluation: EVALUATION_QUERY_evaluation_EvaluationPayload_evaluation | null;
}

export interface EVALUATION_QUERY_evaluation_EvaluationInputError_evaluationId {
  __typename: "BasicInputError";
  message: string;
}

export interface EVALUATION_QUERY_evaluation_EvaluationInputError {
  __typename: "EvaluationInputError";
  message: string;
  evaluationId: EVALUATION_QUERY_evaluation_EvaluationInputError_evaluationId | null;
}

export interface EVALUATION_QUERY_evaluation_EvaluationNotFoundError {
  __typename: "EvaluationNotFoundError";
  message: string;
}

export type EVALUATION_QUERY_evaluation = EVALUATION_QUERY_evaluation_EvaluationPayload | EVALUATION_QUERY_evaluation_EvaluationInputError | EVALUATION_QUERY_evaluation_EvaluationNotFoundError;

export interface EVALUATION_QUERY {
  evaluation: EVALUATION_QUERY_evaluation;
}

export interface EVALUATION_QUERYVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GET_USER_SETTINGS_QUERY
// ====================================================

export interface GET_USER_SETTINGS_QUERY_getUserSettings {
  __typename: "UserSettings";
  ReceiveNewsletters: boolean | null;
}

export interface GET_USER_SETTINGS_QUERY {
  getUserSettings: GET_USER_SETTINGS_QUERY_getUserSettings;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: IDENTITY_QUERY
// ====================================================

export interface IDENTITY_QUERY_businessAlbums_images {
  __typename: "AlbumImage";
  id: string;
}

export interface IDENTITY_QUERY_businessAlbums {
  __typename: "Album";
  id: string;
  images: IDENTITY_QUERY_businessAlbums_images[];
}

export interface IDENTITY_QUERY_businessEvaluationScore {
  __typename: "BusinessEvaluationScore";
  average: number | null;
  totalCount: number | null;
}

export interface IDENTITY_QUERY_businessSettings {
  __typename: "CompanySettings";
  id: string;
  NumAnswers: number | null;
  NumAnswersRemaining: number | null;
  NumProfilePageVisits: number | null;
  JobFolders: boolean | null;
  ProfilePage: boolean | null;
  BoostCredits: number | null;
  BoostCreditsRemaining: number | null;
}

export interface IDENTITY_QUERY_businessStates {
  __typename: "BusinessStates";
  id: string;
  hasProductExpired: boolean;
  hasProducts: boolean;
  hasSubscription: boolean | null;
  isBlacklisted: boolean;
  isDeactivated: boolean;
  isNew: boolean;
  isOrderUnpaid: boolean;
  isSubscriptionExpired: boolean | null;
  isVerified: boolean;
  jobAccess: CompanySettingsJobAccess;
  products: ProductTemplateSlug[] | null;
}

export interface IDENTITY_QUERY_businessWorkTypes_workTypes_industries {
  __typename: "Industry";
  id: string;
  title: string;
}

export interface IDENTITY_QUERY_businessWorkTypes_workTypes {
  __typename: "WorkType";
  /**
   *  worktype ID 
   */
  id: string;
  /**
   *  title of worktype
   */
  title: string;
  /**
   *  industries the work type belongs to 
   */
  industries: (IDENTITY_QUERY_businessWorkTypes_workTypes_industries | null)[] | null;
}

export interface IDENTITY_QUERY_businessWorkTypes {
  __typename: "BusinessWorkTypes";
  id: string;
  workTypes: IDENTITY_QUERY_businessWorkTypes_workTypes[];
}

export interface IDENTITY_QUERY_businessEmployees {
  __typename: "BusinessEmployee";
  id: string;
}

export interface IDENTITY_QUERY_business_Business_logo {
  __typename: "Image";
  url: string;
  id: string;
}

export interface IDENTITY_QUERY_business_Business_products_basicPackage {
  __typename: "SimpleProduct";
  isOwned: boolean;
}

export interface IDENTITY_QUERY_business_Business_products_jobPackage {
  __typename: "SubscriptionProduct";
  isOwned: boolean;
  clips: number | null;
}

export interface IDENTITY_QUERY_business_Business_products_proPackage {
  __typename: "SimpleProduct";
  isOwned: boolean;
}

export interface IDENTITY_QUERY_business_Business_products_advertising {
  __typename: "SimpleProduct";
  isOwned: boolean;
}

export interface IDENTITY_QUERY_business_Business_products_companySearch {
  __typename: "SimpleProduct";
  isOwned: boolean;
}

export interface IDENTITY_QUERY_business_Business_products {
  __typename: "CompanyProducts";
  basicPackage: IDENTITY_QUERY_business_Business_products_basicPackage;
  jobPackage: IDENTITY_QUERY_business_Business_products_jobPackage;
  proPackage: IDENTITY_QUERY_business_Business_products_proPackage;
  advertising: IDENTITY_QUERY_business_Business_products_advertising;
  companySearch: IDENTITY_QUERY_business_Business_products_companySearch;
}

export interface IDENTITY_QUERY_business_Business {
  __typename: "Business";
  id: string;
  name: string;
  profileDescription: string | null;
  email: string | null;
  phone: string | null;
  profileHeaderImage: string | null;
  logo: IDENTITY_QUERY_business_Business_logo | null;
  products: IDENTITY_QUERY_business_Business_products | null;
}

export interface IDENTITY_QUERY_business_BusinessInputError {
  __typename: "BusinessInputError";
  message: string;
}

export interface IDENTITY_QUERY_business_BusinessNotFoundError {
  __typename: "BusinessNotFoundError";
  message: string;
}

export type IDENTITY_QUERY_business = IDENTITY_QUERY_business_Business | IDENTITY_QUERY_business_BusinessInputError | IDENTITY_QUERY_business_BusinessNotFoundError;

export interface IDENTITY_QUERY {
  businessAlbums: IDENTITY_QUERY_businessAlbums[];
  businessEvaluationScore: IDENTITY_QUERY_businessEvaluationScore;
  businessSettings: IDENTITY_QUERY_businessSettings;
  businessStates: IDENTITY_QUERY_businessStates;
  businessWorkTypes: IDENTITY_QUERY_businessWorkTypes;
  businessEmployees: IDENTITY_QUERY_businessEmployees[];
  business: IDENTITY_QUERY_business;
}

export interface IDENTITY_QUERYVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: INVOICE_RECEIPT_LIST_QUERY
// ====================================================

export interface INVOICE_RECEIPT_LIST_QUERY_invoiceReceiptList_pageInfo {
  __typename: "PageInfo";
  /**
   *  cursor to the last element in list 
   */
  endCursor: string | null;
  /**
   *  whether there are items before the current cursor 
   */
  hasNextPage: boolean;
  /**
   *  whether there are items after the current cursor 
   */
  hasPreviousPage: boolean;
  /**
   *  maximum number of nodes to a single page 
   */
  pageSizeLimit: number | null;
  /**
   *  cursor to the first element in list  
   */
  startCursor: string | null;
  /**
   *  total number of entries available 
   */
  totalCount: number | null;
  /**
   *  total number of pages. might be removed due to inclusion of totalCount 
   */
  totalPages: number | null;
}

export interface INVOICE_RECEIPT_LIST_QUERY_invoiceReceiptList_edges_node {
  __typename: "InvoiceReceipt";
  id: string;
  paidDate: number;
  invoiceDate: number;
  dueDate: number;
  pdfUrl: string;
  pdfDownloadUrl: string;
}

export interface INVOICE_RECEIPT_LIST_QUERY_invoiceReceiptList_edges {
  __typename: "InvoiceReceiptEdge";
  node: INVOICE_RECEIPT_LIST_QUERY_invoiceReceiptList_edges_node;
}

export interface INVOICE_RECEIPT_LIST_QUERY_invoiceReceiptList {
  __typename: "InvoiceReceiptConnection";
  pageInfo: INVOICE_RECEIPT_LIST_QUERY_invoiceReceiptList_pageInfo;
  edges: INVOICE_RECEIPT_LIST_QUERY_invoiceReceiptList_edges[];
}

export interface INVOICE_RECEIPT_LIST_QUERY {
  invoiceReceiptList: INVOICE_RECEIPT_LIST_QUERY_invoiceReceiptList | null;
}

export interface INVOICE_RECEIPT_LIST_QUERYVariables {
  input?: ReceiptListInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: JOB_DETAILS_QUERY
// ====================================================

export interface JOB_DETAILS_QUERY_job_Job_address {
  __typename: "Address";
  city: string | null;
  description: string | null;
  id: string;
  lat: string | null;
  lon: string | null;
  street: string | null;
  type: number;
  zip: string | null;
}

export interface JOB_DETAILS_QUERY_job_Job_evaluation {
  __typename: "Evaluation";
  id: string;
  score: number;
}

export interface JOB_DETAILS_QUERY_job_Job_workTypes_industries {
  __typename: "Industry";
  id: string;
}

export interface JOB_DETAILS_QUERY_job_Job_workTypes {
  __typename: "WorkType";
  /**
   *  worktype ID 
   */
  id: string;
  /**
   *  industries the work type belongs to 
   */
  industries: (JOB_DETAILS_QUERY_job_Job_workTypes_industries | null)[] | null;
}

export interface JOB_DETAILS_QUERY_job_Job {
  __typename: "Job";
  /**
   *  Exact address of job. Available to business after job has been answered. Use postal address until then. 
   */
  address: JOB_DETAILS_QUERY_job_Job_address | null;
  /**
   *  number of answers related to a job 
   */
  answerCount: number | null;
  /**
   *  maximum number of answers to display 
   */
  answerCountLimit: number | null;
  /**
   *  job ID 
   */
  id: string;
  evaluation: JOB_DETAILS_QUERY_job_Job_evaluation | null;
  workTypes: JOB_DETAILS_QUERY_job_Job_workTypes[];
}

export interface JOB_DETAILS_QUERY_job_JobAccessDeniedError {
  __typename: "JobAccessDeniedError";
  message: string;
  reasonCode: JobAccessDeniedCode | null;
}

export interface JOB_DETAILS_QUERY_job_JobNotFoundError {
  __typename: "JobNotFoundError";
  message: string;
}

export type JOB_DETAILS_QUERY_job = JOB_DETAILS_QUERY_job_Job | JOB_DETAILS_QUERY_job_JobAccessDeniedError | JOB_DETAILS_QUERY_job_JobNotFoundError;

export interface JOB_DETAILS_QUERY_jobChatMeta {
  __typename: "ChatMeta";
  chatId: string;
  firstMessageTs: number;
}

export interface JOB_DETAILS_QUERY_jobMeta_requestedStart {
  __typename: "JobStart";
  type: StartAlternative;
  relatedTime: number | null;
}

export interface JOB_DETAILS_QUERY_jobMeta_generics {
  __typename: "GenericJobMeta";
  /**
   *  Should equal to one of JobMetaSlug as kebab case. but we need to 'expect the unexpected' as new ones will be added from time to time. 
   */
  slug: string | null;
  /**
   *  Use this in case slugs are not translated in front end 
   */
  title: string | null;
  value: string | null;
}

export interface JOB_DETAILS_QUERY_jobMeta_car_info {
  __typename: "CarInfo";
  VIMNumber: string | null;
  brand: string | null;
  licencePlate: string | null;
  registrationYear: string | null;
  model: string | null;
  lastRoadWorthinessTestDate: string | null;
  nextRoadWorthinessTestDate: string | null;
  shaftCount: number | null;
  width: number | null;
  co2Emissions: number | null;
  fuelType: FuelType | null;
  weight: number | null;
  factoryInstalledParticleFilter: boolean | null;
  vehicleGroup: string | null;
  length: number | null;
  towbarMaxLoad: number | null;
  maxRoofLoad: number | null;
  engineHectowatt: number | null;
  maxSeats: number | null;
  engineSize: number | null;
  trailerMaxWeightWithBreak: number | null;
  trailerMaxWeightWithoutBreak: number | null;
  maxTotalWeight: number | null;
  taxCode: string | null;
  hybrid: HybridCarType | null;
  hybridCatagory: HybridCarCatagory | null;
  seatsFront: number | null;
}

export interface JOB_DETAILS_QUERY_jobMeta_car {
  __typename: "Car";
  id: string;
  /**
   * regNr shouldn't be requested because we cannot hide it as it's a key. It should also be @inaccessible
   */
  regNr: string;
  info: JOB_DETAILS_QUERY_jobMeta_car_info | null;
}

export interface JOB_DETAILS_QUERY_jobMeta_toAddress {
  __typename: "Address";
  city: string | null;
  description: string | null;
  id: string;
  lat: string | null;
  lon: string | null;
  street: string | null;
  type: number;
  zip: string | null;
}

export interface JOB_DETAILS_QUERY_jobMeta {
  __typename: "JobMeta";
  actualStartDate: number | null;
  customerType: JobCustomerType | null;
  contactPreference: JobContactPreference | null;
  requestedStart: JOB_DETAILS_QUERY_jobMeta_requestedStart | null;
  generics: JOB_DETAILS_QUERY_jobMeta_generics[];
  car: JOB_DETAILS_QUERY_jobMeta_car | null;
  toAddress: JOB_DETAILS_QUERY_jobMeta_toAddress | null;
}

export interface JOB_DETAILS_QUERY_jobFiles {
  __typename: "File";
  description: string | null;
  iconUrl: string;
  id: string;
  name: string;
  size: number;
  url: string;
  fileType: string;
}

export interface JOB_DETAILS_QUERY_jobImages_dimensions {
  __typename: "Dimension";
  width: number;
  height: number;
}

export interface JOB_DETAILS_QUERY_jobImages {
  __typename: "Image";
  bigUrl: string;
  bigPreviewUrl: string;
  description: string | null;
  dimensions: JOB_DETAILS_QUERY_jobImages_dimensions;
  id: string;
  mediumPreviewUrl: string;
  mediumUrl: string;
  name: string;
  size: number;
  smallPreviewUrl: string;
  smallUrl: string;
  url: string;
  fileType: string;
}

export interface JOB_DETAILS_QUERY_jobEvaluation {
  __typename: "Evaluation";
  id: string;
  score: number;
}

export interface JOB_DETAILS_QUERY_jobBusinessRelationState {
  __typename: "JobBusinessRelationState";
  jobId: string;
  clipsSpent: number | null;
  hasRequestedEvaluation: boolean;
  hasRequestedSelection: boolean;
  isAnswered: boolean;
  isDeleted: boolean;
  isFavourite: boolean;
  isLost: boolean;
  isRead: boolean;
  isWon: boolean;
  isFree: boolean;
  isOneOnOneReceiver: boolean | null;
  isRejected: boolean;
}

export interface JOB_DETAILS_QUERY {
  job: JOB_DETAILS_QUERY_job;
  jobChatMeta: JOB_DETAILS_QUERY_jobChatMeta | null;
  jobMeta: JOB_DETAILS_QUERY_jobMeta;
  jobFiles: JOB_DETAILS_QUERY_jobFiles[];
  jobImages: JOB_DETAILS_QUERY_jobImages[];
  jobEvaluation: JOB_DETAILS_QUERY_jobEvaluation | null;
  jobBusinessRelationState: JOB_DETAILS_QUERY_jobBusinessRelationState;
}

export interface JOB_DETAILS_QUERYVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: JOB_QUERY
// ====================================================

export interface JOB_QUERY_job_Job_addressHint {
  __typename: "PostalAddress";
  city: string;
  id: string;
  lat: string | null;
  lon: string | null;
  postalCode: any | null;
}

export interface JOB_QUERY_job_Job_files {
  __typename: "File";
  description: string | null;
  iconUrl: string;
  id: string;
  name: string;
  size: number;
  url: string;
  fileType: string;
}

export interface JOB_QUERY_job_Job_images_dimensions {
  __typename: "Dimension";
  width: number;
  height: number;
}

export interface JOB_QUERY_job_Job_images {
  __typename: "Image";
  bigUrl: string;
  bigPreviewUrl: string;
  description: string | null;
  dimensions: JOB_QUERY_job_Job_images_dimensions;
  id: string;
  mediumPreviewUrl: string;
  mediumUrl: string;
  name: string;
  size: number;
  smallPreviewUrl: string;
  smallUrl: string;
  url: string;
  fileType: string;
}

export interface JOB_QUERY_job_Job_folders {
  __typename: "JobFolder";
  id: string;
  title: string;
  color: string | null;
}

export interface JOB_QUERY_job_Job_jobBusinessRelationState {
  __typename: "JobBusinessRelationState";
  jobId: string;
  clipsSpent: number | null;
  hasRequestedEvaluation: boolean;
  hasRequestedSelection: boolean;
  isAnswered: boolean;
  isDeleted: boolean;
  isFavourite: boolean;
  isLost: boolean;
  isRead: boolean;
  isWon: boolean;
  isFree: boolean;
  isOneOnOneReceiver: boolean | null;
  isRejected: boolean;
}

export interface JOB_QUERY_job_Job_address {
  __typename: "Address";
  city: string | null;
  description: string | null;
  id: string;
  lat: string | null;
  lon: string | null;
  street: string | null;
  type: number;
  zip: string | null;
}

export interface JOB_QUERY_job_Job_evaluation {
  __typename: "Evaluation";
  id: string;
  score: number;
}

export interface JOB_QUERY_job_Job_workTypes_industries {
  __typename: "Industry";
  id: string;
}

export interface JOB_QUERY_job_Job_workTypes {
  __typename: "WorkType";
  /**
   *  worktype ID 
   */
  id: string;
  /**
   *  industries the work type belongs to 
   */
  industries: (JOB_QUERY_job_Job_workTypes_industries | null)[] | null;
}

export interface JOB_QUERY_job_Job {
  __typename: "Job";
  /**
   *  Address hinting the jobs location. Useful when the address is not accessible.  
   */
  addressHint: JOB_QUERY_job_Job_addressHint | null;
  /**
   *  approval state of a job 
   */
  approvedAt: number | null;
  /**
   *  Description of the job 
   */
  description: string | null;
  files: JOB_QUERY_job_Job_files[];
  /**
   *  job ID 
   */
  id: string;
  images: JOB_QUERY_job_Job_images[];
  /**
   *  If a job has been answered as seen through the eyes of the beholder. 
   */
  isFree: boolean | null;
  folders: JOB_QUERY_job_Job_folders[] | null;
  jobBusinessRelationState: JOB_QUERY_job_Job_jobBusinessRelationState;
  /**
   *  the size of a job - connects to  
   */
  size: JobSize | null;
  /**
   *  Main title of a job 
   */
  title: string;
  /**
   *  Exact address of job. Available to business after job has been answered. Use postal address until then. 
   */
  address: JOB_QUERY_job_Job_address | null;
  /**
   *  number of answers related to a job 
   */
  answerCount: number | null;
  /**
   *  maximum number of answers to display 
   */
  answerCountLimit: number | null;
  evaluation: JOB_QUERY_job_Job_evaluation | null;
  workTypes: JOB_QUERY_job_Job_workTypes[];
}

export interface JOB_QUERY_job_JobAccessDeniedError {
  __typename: "JobAccessDeniedError";
  message: string;
  reasonCode: JobAccessDeniedCode | null;
}

export interface JOB_QUERY_job_JobNotFoundError {
  __typename: "JobNotFoundError";
  message: string;
}

export type JOB_QUERY_job = JOB_QUERY_job_Job | JOB_QUERY_job_JobAccessDeniedError | JOB_QUERY_job_JobNotFoundError;

export interface JOB_QUERY_jobChatMeta {
  __typename: "ChatMeta";
  chatId: string;
  firstMessageTs: number;
}

export interface JOB_QUERY_jobMeta_requestedStart {
  __typename: "JobStart";
  type: StartAlternative;
  relatedTime: number | null;
}

export interface JOB_QUERY_jobMeta_generics {
  __typename: "GenericJobMeta";
  /**
   *  Should equal to one of JobMetaSlug as kebab case. but we need to 'expect the unexpected' as new ones will be added from time to time. 
   */
  slug: string | null;
  /**
   *  Use this in case slugs are not translated in front end 
   */
  title: string | null;
  value: string | null;
}

export interface JOB_QUERY_jobMeta_car_info {
  __typename: "CarInfo";
  VIMNumber: string | null;
  brand: string | null;
  licencePlate: string | null;
  registrationYear: string | null;
  model: string | null;
  lastRoadWorthinessTestDate: string | null;
  nextRoadWorthinessTestDate: string | null;
  shaftCount: number | null;
  width: number | null;
  co2Emissions: number | null;
  fuelType: FuelType | null;
  weight: number | null;
  factoryInstalledParticleFilter: boolean | null;
  vehicleGroup: string | null;
  length: number | null;
  towbarMaxLoad: number | null;
  maxRoofLoad: number | null;
  engineHectowatt: number | null;
  maxSeats: number | null;
  engineSize: number | null;
  trailerMaxWeightWithBreak: number | null;
  trailerMaxWeightWithoutBreak: number | null;
  maxTotalWeight: number | null;
  taxCode: string | null;
  hybrid: HybridCarType | null;
  hybridCatagory: HybridCarCatagory | null;
  seatsFront: number | null;
}

export interface JOB_QUERY_jobMeta_car {
  __typename: "Car";
  id: string;
  /**
   * regNr shouldn't be requested because we cannot hide it as it's a key. It should also be @inaccessible
   */
  regNr: string;
  info: JOB_QUERY_jobMeta_car_info | null;
}

export interface JOB_QUERY_jobMeta_toAddress {
  __typename: "Address";
  city: string | null;
  description: string | null;
  id: string;
  lat: string | null;
  lon: string | null;
  street: string | null;
  type: number;
  zip: string | null;
}

export interface JOB_QUERY_jobMeta {
  __typename: "JobMeta";
  actualStartDate: number | null;
  customerType: JobCustomerType | null;
  contactPreference: JobContactPreference | null;
  requestedStart: JOB_QUERY_jobMeta_requestedStart | null;
  generics: JOB_QUERY_jobMeta_generics[];
  car: JOB_QUERY_jobMeta_car | null;
  toAddress: JOB_QUERY_jobMeta_toAddress | null;
}

export interface JOB_QUERY_jobFiles {
  __typename: "File";
  description: string | null;
  iconUrl: string;
  id: string;
  name: string;
  size: number;
  url: string;
  fileType: string;
}

export interface JOB_QUERY_jobImages_dimensions {
  __typename: "Dimension";
  width: number;
  height: number;
}

export interface JOB_QUERY_jobImages {
  __typename: "Image";
  bigUrl: string;
  bigPreviewUrl: string;
  description: string | null;
  dimensions: JOB_QUERY_jobImages_dimensions;
  id: string;
  mediumPreviewUrl: string;
  mediumUrl: string;
  name: string;
  size: number;
  smallPreviewUrl: string;
  smallUrl: string;
  url: string;
  fileType: string;
}

export interface JOB_QUERY_jobEvaluation {
  __typename: "Evaluation";
  id: string;
  score: number;
}

export interface JOB_QUERY_jobBusinessRelationState {
  __typename: "JobBusinessRelationState";
  jobId: string;
  clipsSpent: number | null;
  hasRequestedEvaluation: boolean;
  hasRequestedSelection: boolean;
  isAnswered: boolean;
  isDeleted: boolean;
  isFavourite: boolean;
  isLost: boolean;
  isRead: boolean;
  isWon: boolean;
  isFree: boolean;
  isOneOnOneReceiver: boolean | null;
  isRejected: boolean;
}

export interface JOB_QUERY {
  job: JOB_QUERY_job;
  jobChatMeta: JOB_QUERY_jobChatMeta | null;
  jobMeta: JOB_QUERY_jobMeta;
  jobFiles: JOB_QUERY_jobFiles[];
  jobImages: JOB_QUERY_jobImages[];
  jobEvaluation: JOB_QUERY_jobEvaluation | null;
  jobBusinessRelationState: JOB_QUERY_jobBusinessRelationState;
}

export interface JOB_QUERYVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: JOB_ACCESS_QUERY
// ====================================================

export interface JOB_ACCESS_QUERY_jobAccess_LoginRequiredError {
  __typename: "LoginRequiredError";
}

export interface JOB_ACCESS_QUERY_jobAccess_JobAccessPayload_access_missingCertificates {
  __typename: "Certificate";
  id: string;
  name: string | null;
}

export interface JOB_ACCESS_QUERY_jobAccess_JobAccessPayload_access {
  __typename: "AnswerAccess";
  code: AnswerAccessCode;
  isOpen: boolean;
  clip: number | null;
  missingCertificates: JOB_ACCESS_QUERY_jobAccess_JobAccessPayload_access_missingCertificates[];
}

export interface JOB_ACCESS_QUERY_jobAccess_JobAccessPayload {
  __typename: "JobAccessPayload";
  access: JOB_ACCESS_QUERY_jobAccess_JobAccessPayload_access | null;
}

export interface JOB_ACCESS_QUERY_jobAccess_JobAccessInputError {
  __typename: "JobAccessInputError";
  message: string;
}

export type JOB_ACCESS_QUERY_jobAccess = JOB_ACCESS_QUERY_jobAccess_LoginRequiredError | JOB_ACCESS_QUERY_jobAccess_JobAccessPayload | JOB_ACCESS_QUERY_jobAccess_JobAccessInputError;

export interface JOB_ACCESS_QUERY {
  jobAccess: JOB_ACCESS_QUERY_jobAccess;
}

export interface JOB_ACCESS_QUERYVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: JOB_ANALYTICS
// ====================================================

export interface JOB_ANALYTICS_jobBusinessRelationState {
  __typename: "JobBusinessRelationState";
  jobId: string;
  isWon: boolean;
}

export interface JOB_ANALYTICS_chatByJobIdOptional {
  __typename: "Chat";
  id: string;
  /**
   *  if both parts have posted 
   */
  isDialog: boolean | null;
}

export interface JOB_ANALYTICS_simpleAgreementContractByJobId {
  __typename: "SimpleAgreementContract";
  id: string;
}

export interface JOB_ANALYTICS {
  jobBusinessRelationState: JOB_ANALYTICS_jobBusinessRelationState;
  chatByJobIdOptional: JOB_ANALYTICS_chatByJobIdOptional | null;
  simpleAgreementContractByJobId: JOB_ANALYTICS_simpleAgreementContractByJobId | null;
}

export interface JOB_ANALYTICSVariables {
  jobId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: JOB_FOLDERS_QUERY
// ====================================================

export interface JOB_FOLDERS_QUERY_jobFolders_folders {
  __typename: "JobFolder";
  id: string;
  title: string;
  color: string | null;
}

export interface JOB_FOLDERS_QUERY_jobFolders {
  __typename: "JobFolderPayload";
  folders: (JOB_FOLDERS_QUERY_jobFolders_folders | null)[];
}

export interface JOB_FOLDERS_QUERY {
  jobFolders: JOB_FOLDERS_QUERY_jobFolders;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: JOB_LIST_FILTERS_QUERY
// ====================================================

export interface JOB_LIST_FILTERS_QUERY_jobListFilters {
  __typename: "JobListFilter";
  id: string;
  count: number | null;
  listType: JobList | null;
}

export interface JOB_LIST_FILTERS_QUERY {
  jobListFilters: JOB_LIST_FILTERS_QUERY_jobListFilters[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: JOB_OWNER_QUERY
// ====================================================

export interface JOB_OWNER_QUERY_job_JobAccessDeniedError {
  __typename: "JobAccessDeniedError" | "JobNotFoundError";
}

export interface JOB_OWNER_QUERY_job_Job_owner {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  isDeleted: boolean | null;
  isVerifiedWithSecureId: boolean;
}

export interface JOB_OWNER_QUERY_job_Job_address {
  __typename: "Address";
  city: string | null;
  description: string | null;
  id: string;
  lat: string | null;
  lon: string | null;
  street: string | null;
  type: number;
  zip: string | null;
}

export interface JOB_OWNER_QUERY_job_Job_contactInfo {
  __typename: "JobContactInfo";
  email: string | null;
  phone: string | null;
}

export interface JOB_OWNER_QUERY_job_Job {
  __typename: "Job";
  /**
   *  job ID 
   */
  id: string;
  /**
   *  Doffin jobs do not have a registered user in our system. Thus the field is optional. 
   */
  owner: JOB_OWNER_QUERY_job_Job_owner | null;
  /**
   *  Exact address of job. Available to business after job has been answered. Use postal address until then. 
   */
  address: JOB_OWNER_QUERY_job_Job_address | null;
  contactInfo: JOB_OWNER_QUERY_job_Job_contactInfo | null;
}

export type JOB_OWNER_QUERY_job = JOB_OWNER_QUERY_job_JobAccessDeniedError | JOB_OWNER_QUERY_job_Job;

export interface JOB_OWNER_QUERY {
  job: JOB_OWNER_QUERY_job;
}

export interface JOB_OWNER_QUERYVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: JOBS_AREAS_QUERY
// ====================================================

export interface JOBS_AREAS_QUERY_businessJobAreas_districts_municipality {
  __typename: "Municipality";
  id: string;
  name: string;
}

export interface JOBS_AREAS_QUERY_businessJobAreas_districts {
  __typename: "District";
  id: string;
  name: string;
  municipality: JOBS_AREAS_QUERY_businessJobAreas_districts_municipality | null;
}

export interface JOBS_AREAS_QUERY_businessJobAreas_districtsAvailable_municipality {
  __typename: "Municipality";
  id: string;
  name: string;
}

export interface JOBS_AREAS_QUERY_businessJobAreas_districtsAvailable {
  __typename: "District";
  id: string;
  name: string;
  municipality: JOBS_AREAS_QUERY_businessJobAreas_districtsAvailable_municipality | null;
}

export interface JOBS_AREAS_QUERY_businessJobAreas_municipalities_county {
  __typename: "County";
  id: string;
  name: string;
}

export interface JOBS_AREAS_QUERY_businessJobAreas_municipalities_districts {
  __typename: "District";
  id: string;
  name: string;
}

export interface JOBS_AREAS_QUERY_businessJobAreas_municipalities {
  __typename: "Municipality";
  county: JOBS_AREAS_QUERY_businessJobAreas_municipalities_county;
  districts: (JOBS_AREAS_QUERY_businessJobAreas_municipalities_districts | null)[] | null;
  id: string;
  name: string;
  code: number;
}

export interface JOBS_AREAS_QUERY_businessJobAreas_municipalitiesAvailable_county {
  __typename: "County";
  id: string;
  name: string;
}

export interface JOBS_AREAS_QUERY_businessJobAreas_municipalitiesAvailable_districts {
  __typename: "District";
  id: string;
  name: string;
}

export interface JOBS_AREAS_QUERY_businessJobAreas_municipalitiesAvailable {
  __typename: "Municipality";
  county: JOBS_AREAS_QUERY_businessJobAreas_municipalitiesAvailable_county;
  districts: (JOBS_AREAS_QUERY_businessJobAreas_municipalitiesAvailable_districts | null)[] | null;
  id: string;
  name: string;
  code: number;
}

export interface JOBS_AREAS_QUERY_businessJobAreas {
  __typename: "BusinessJobAreas";
  id: string;
  districts: JOBS_AREAS_QUERY_businessJobAreas_districts[];
  districtsAvailable: JOBS_AREAS_QUERY_businessJobAreas_districtsAvailable[];
  municipalities: JOBS_AREAS_QUERY_businessJobAreas_municipalities[];
  municipalitiesAvailable: JOBS_AREAS_QUERY_businessJobAreas_municipalitiesAvailable[];
}

export interface JOBS_AREAS_QUERY {
  businessJobAreas: JOBS_AREAS_QUERY_businessJobAreas;
}

export interface JOBS_AREAS_QUERYVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: JOBS_LIST_QUERY
// ====================================================

export interface JOBS_LIST_QUERY_jobList_JobListPayload_jobConnection_pageInfo_aggregations_values {
  __typename: "AggregationsValue";
  count: number;
  id: string;
}

export interface JOBS_LIST_QUERY_jobList_JobListPayload_jobConnection_pageInfo_aggregations {
  __typename: "Aggregations";
  type: string;
  values: JOBS_LIST_QUERY_jobList_JobListPayload_jobConnection_pageInfo_aggregations_values[];
}

export interface JOBS_LIST_QUERY_jobList_JobListPayload_jobConnection_pageInfo {
  __typename: "PageInfo";
  /**
   *  cursor to the last element in list 
   */
  endCursor: string | null;
  /**
   *  whether there are items before the current cursor 
   */
  hasNextPage: boolean;
  /**
   *  whether there are items after the current cursor 
   */
  hasPreviousPage: boolean;
  /**
   *  maximum number of nodes to a single page 
   */
  pageSizeLimit: number | null;
  /**
   *  cursor to the first element in list  
   */
  startCursor: string | null;
  /**
   *  total number of entries available 
   */
  totalCount: number | null;
  /**
   *  total number of pages. might be removed due to inclusion of totalCount 
   */
  totalPages: number | null;
  /**
   * aggregations
   */
  aggregations: (JOBS_LIST_QUERY_jobList_JobListPayload_jobConnection_pageInfo_aggregations | null)[] | null;
}

export interface JOBS_LIST_QUERY_jobList_JobListPayload_jobConnection_edges_node_addressHint {
  __typename: "PostalAddress";
  city: string;
  id: string;
  lat: string | null;
  lon: string | null;
  postalCode: any | null;
}

export interface JOBS_LIST_QUERY_jobList_JobListPayload_jobConnection_edges_node_files {
  __typename: "File";
  description: string | null;
  iconUrl: string;
  id: string;
  name: string;
  size: number;
  url: string;
  fileType: string;
}

export interface JOBS_LIST_QUERY_jobList_JobListPayload_jobConnection_edges_node_images_dimensions {
  __typename: "Dimension";
  width: number;
  height: number;
}

export interface JOBS_LIST_QUERY_jobList_JobListPayload_jobConnection_edges_node_images {
  __typename: "Image";
  bigUrl: string;
  bigPreviewUrl: string;
  description: string | null;
  dimensions: JOBS_LIST_QUERY_jobList_JobListPayload_jobConnection_edges_node_images_dimensions;
  id: string;
  mediumPreviewUrl: string;
  mediumUrl: string;
  name: string;
  size: number;
  smallPreviewUrl: string;
  smallUrl: string;
  url: string;
  fileType: string;
}

export interface JOBS_LIST_QUERY_jobList_JobListPayload_jobConnection_edges_node_jobBusinessRelationState {
  __typename: "JobBusinessRelationState";
  jobId: string;
  clipsSpent: number | null;
  hasRequestedEvaluation: boolean;
  hasRequestedSelection: boolean;
  isAnswered: boolean;
  isDeleted: boolean;
  isFavourite: boolean;
  isLost: boolean;
  isRead: boolean;
  isWon: boolean;
  isFree: boolean;
  isOneOnOneReceiver: boolean | null;
  isRejected: boolean;
}

export interface JOBS_LIST_QUERY_jobList_JobListPayload_jobConnection_edges_node_folders {
  __typename: "JobFolder";
  id: string;
  title: string;
  color: string | null;
}

export interface JOBS_LIST_QUERY_jobList_JobListPayload_jobConnection_edges_node_traits {
  __typename: "JobTraits";
  id: string;
  fromVerifiedUser: boolean;
  big: boolean;
  ended: boolean;
  estimatedSquareMeters: string | null;
}

export interface JOBS_LIST_QUERY_jobList_JobListPayload_jobConnection_edges_node_latestMessage_writer {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  isDeleted: boolean | null;
  isVerifiedWithSecureId: boolean;
}

export interface JOBS_LIST_QUERY_jobList_JobListPayload_jobConnection_edges_node_latestMessage {
  __typename: "Message";
  id: string;
  text: string | null;
  writer: JOBS_LIST_QUERY_jobList_JobListPayload_jobConnection_edges_node_latestMessage_writer;
  regTs: number;
  type: MessageType;
}

export interface JOBS_LIST_QUERY_jobList_JobListPayload_jobConnection_edges_node_evaluation {
  __typename: "Evaluation";
  id: string;
  score: number;
}

export interface JOBS_LIST_QUERY_jobList_JobListPayload_jobConnection_edges_node {
  __typename: "Job";
  /**
   *  Address hinting the jobs location. Useful when the address is not accessible.  
   */
  addressHint: JOBS_LIST_QUERY_jobList_JobListPayload_jobConnection_edges_node_addressHint | null;
  /**
   *  approval state of a job 
   */
  approvedAt: number | null;
  /**
   *  Description of the job 
   */
  description: string | null;
  files: JOBS_LIST_QUERY_jobList_JobListPayload_jobConnection_edges_node_files[];
  /**
   *  job ID 
   */
  id: string;
  images: JOBS_LIST_QUERY_jobList_JobListPayload_jobConnection_edges_node_images[];
  /**
   *  If a job has been answered as seen through the eyes of the beholder. 
   */
  isFree: boolean | null;
  jobBusinessRelationState: JOBS_LIST_QUERY_jobList_JobListPayload_jobConnection_edges_node_jobBusinessRelationState;
  folders: JOBS_LIST_QUERY_jobList_JobListPayload_jobConnection_edges_node_folders[] | null;
  /**
   *  the size of a job - connects to  
   */
  size: JobSize | null;
  /**
   *  Main title of a job 
   */
  title: string;
  /**
   * Use this only on list views. On single job view use query jobTraits 
   */
  traits: JOBS_LIST_QUERY_jobList_JobListPayload_jobConnection_edges_node_traits;
  latestMessage: JOBS_LIST_QUERY_jobList_JobListPayload_jobConnection_edges_node_latestMessage | null;
  evaluation: JOBS_LIST_QUERY_jobList_JobListPayload_jobConnection_edges_node_evaluation | null;
}

export interface JOBS_LIST_QUERY_jobList_JobListPayload_jobConnection_edges {
  __typename: "JobEdge";
  node: JOBS_LIST_QUERY_jobList_JobListPayload_jobConnection_edges_node;
}

export interface JOBS_LIST_QUERY_jobList_JobListPayload_jobConnection {
  __typename: "JobConnection";
  pageInfo: JOBS_LIST_QUERY_jobList_JobListPayload_jobConnection_pageInfo;
  edges: JOBS_LIST_QUERY_jobList_JobListPayload_jobConnection_edges[];
}

export interface JOBS_LIST_QUERY_jobList_JobListPayload {
  __typename: "JobListPayload";
  listId: string | null;
  jobConnection: JOBS_LIST_QUERY_jobList_JobListPayload_jobConnection;
}

export interface JOBS_LIST_QUERY_jobList_PageOutOfRangeError {
  __typename: "PageOutOfRangeError";
  message: string;
}

export type JOBS_LIST_QUERY_jobList = JOBS_LIST_QUERY_jobList_JobListPayload | JOBS_LIST_QUERY_jobList_PageOutOfRangeError;

export interface JOBS_LIST_QUERY {
  /**
   *  Provided as JobListPayload in order to support available facets and such 
   */
  jobList: JOBS_LIST_QUERY_jobList;
}

export interface JOBS_LIST_QUERYVariables {
  query?: string | null;
  page?: number | null;
  count?: number | null;
  size?: JobSize | null;
  sizes?: JobSize[] | null;
  listId?: string | null;
  districtId?: string[] | null;
  countyId?: string[] | null;
  municipalityCode?: string[] | null;
  folderId?: string[] | null;
  worktypeIds?: string[] | null;
  worktypeSubsets?: string[] | null;
  worktypeSubsetGroups?: string[] | null;
  industryIds?: string[] | null;
  skipAnsweredOnlyFields: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: JOBS_SAVED_FILTERS_QUERY
// ====================================================

export interface JOBS_SAVED_FILTERS_QUERY_jobWatchlistList_filters {
  __typename: "JobWatchlistFilters";
  countyId: string[];
  districtId: string[];
  industryIds: string[];
  municipalityCode: string[];
  query: string;
  sizes: JobSize[];
  worktypeIds: string[];
  worktypeSubsetGroups: string[];
  worktypeSubsets: string[];
}

export interface JOBS_SAVED_FILTERS_QUERY_jobWatchlistList_notificationSettings {
  __typename: "JobWatchlistNotificationSettings";
  id: string;
  email: JobWatchlistNotificationFrequency;
  push: JobWatchlistNotificationFrequency;
}

export interface JOBS_SAVED_FILTERS_QUERY_jobWatchlistList {
  __typename: "JobWatchlist";
  id: string;
  title: string;
  filters: JOBS_SAVED_FILTERS_QUERY_jobWatchlistList_filters;
  notificationSettings: JOBS_SAVED_FILTERS_QUERY_jobWatchlistList_notificationSettings;
  readTime: number | null;
  latestJobTime: number | null;
}

export interface JOBS_SAVED_FILTERS_QUERY {
  jobWatchlistList: JOBS_SAVED_FILTERS_QUERY_jobWatchlistList[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: JOB_SEARCH_SETTINGS_QUERY
// ====================================================

export interface JOB_SEARCH_SETTINGS_QUERY_businessJobAreas_districts_municipality {
  __typename: "Municipality";
  id: string;
  name: string;
}

export interface JOB_SEARCH_SETTINGS_QUERY_businessJobAreas_districts {
  __typename: "District";
  id: string;
  name: string;
  municipality: JOB_SEARCH_SETTINGS_QUERY_businessJobAreas_districts_municipality | null;
}

export interface JOB_SEARCH_SETTINGS_QUERY_businessJobAreas_districtsAvailable_municipality {
  __typename: "Municipality";
  id: string;
  name: string;
}

export interface JOB_SEARCH_SETTINGS_QUERY_businessJobAreas_districtsAvailable {
  __typename: "District";
  id: string;
  name: string;
  municipality: JOB_SEARCH_SETTINGS_QUERY_businessJobAreas_districtsAvailable_municipality | null;
}

export interface JOB_SEARCH_SETTINGS_QUERY_businessJobAreas_municipalities_county {
  __typename: "County";
  id: string;
  name: string;
}

export interface JOB_SEARCH_SETTINGS_QUERY_businessJobAreas_municipalities_districts {
  __typename: "District";
  id: string;
  name: string;
}

export interface JOB_SEARCH_SETTINGS_QUERY_businessJobAreas_municipalities {
  __typename: "Municipality";
  county: JOB_SEARCH_SETTINGS_QUERY_businessJobAreas_municipalities_county;
  districts: (JOB_SEARCH_SETTINGS_QUERY_businessJobAreas_municipalities_districts | null)[] | null;
  id: string;
  name: string;
  code: number;
}

export interface JOB_SEARCH_SETTINGS_QUERY_businessJobAreas_municipalitiesAvailable_county {
  __typename: "County";
  id: string;
  name: string;
}

export interface JOB_SEARCH_SETTINGS_QUERY_businessJobAreas_municipalitiesAvailable_districts {
  __typename: "District";
  id: string;
  name: string;
}

export interface JOB_SEARCH_SETTINGS_QUERY_businessJobAreas_municipalitiesAvailable {
  __typename: "Municipality";
  county: JOB_SEARCH_SETTINGS_QUERY_businessJobAreas_municipalitiesAvailable_county;
  districts: (JOB_SEARCH_SETTINGS_QUERY_businessJobAreas_municipalitiesAvailable_districts | null)[] | null;
  id: string;
  name: string;
  code: number;
}

export interface JOB_SEARCH_SETTINGS_QUERY_businessJobAreas {
  __typename: "BusinessJobAreas";
  id: string;
  districts: JOB_SEARCH_SETTINGS_QUERY_businessJobAreas_districts[];
  districtsAvailable: JOB_SEARCH_SETTINGS_QUERY_businessJobAreas_districtsAvailable[];
  municipalities: JOB_SEARCH_SETTINGS_QUERY_businessJobAreas_municipalities[];
  municipalitiesAvailable: JOB_SEARCH_SETTINGS_QUERY_businessJobAreas_municipalitiesAvailable[];
}

export interface JOB_SEARCH_SETTINGS_QUERY_businessWorkTypes_workTypes_industries_workTypes {
  __typename: "WorkType";
  /**
   *  worktype ID 
   */
  id: string;
  /**
   *  title of worktype
   */
  title: string;
  /**
   *  worktype identifyer in string format 
   */
  slug: string;
  /**
   *  if the worktype is currently in use (inactive=0, active=1) 
   */
  active: boolean | null;
}

export interface JOB_SEARCH_SETTINGS_QUERY_businessWorkTypes_workTypes_industries {
  __typename: "Industry";
  id: string;
  title: string;
  slug: string;
  active: number;
  workTypes: (JOB_SEARCH_SETTINGS_QUERY_businessWorkTypes_workTypes_industries_workTypes | null)[] | null;
}

export interface JOB_SEARCH_SETTINGS_QUERY_businessWorkTypes_workTypes {
  __typename: "WorkType";
  /**
   *  worktype ID 
   */
  id: string;
  /**
   *  title of worktype
   */
  title: string;
  /**
   *  industries the work type belongs to 
   */
  industries: (JOB_SEARCH_SETTINGS_QUERY_businessWorkTypes_workTypes_industries | null)[] | null;
}

export interface JOB_SEARCH_SETTINGS_QUERY_businessWorkTypes_workTypesAvailable_industries_workTypes {
  __typename: "WorkType";
  /**
   *  worktype ID 
   */
  id: string;
  /**
   *  title of worktype
   */
  title: string;
  /**
   *  worktype identifyer in string format 
   */
  slug: string;
  /**
   *  if the worktype is currently in use (inactive=0, active=1) 
   */
  active: boolean | null;
}

export interface JOB_SEARCH_SETTINGS_QUERY_businessWorkTypes_workTypesAvailable_industries {
  __typename: "Industry";
  id: string;
  title: string;
  slug: string;
  active: number;
  workTypes: (JOB_SEARCH_SETTINGS_QUERY_businessWorkTypes_workTypesAvailable_industries_workTypes | null)[] | null;
}

export interface JOB_SEARCH_SETTINGS_QUERY_businessWorkTypes_workTypesAvailable {
  __typename: "WorkType";
  /**
   *  worktype ID 
   */
  id: string;
  /**
   *  title of worktype
   */
  title: string;
  /**
   *  worktype identifyer in string format 
   */
  slug: string;
  /**
   *  if the worktype is currently in use (inactive=0, active=1) 
   */
  active: boolean | null;
  /**
   *  industries the work type belongs to 
   */
  industries: (JOB_SEARCH_SETTINGS_QUERY_businessWorkTypes_workTypesAvailable_industries | null)[] | null;
}

export interface JOB_SEARCH_SETTINGS_QUERY_businessWorkTypes_subsets_partOf {
  __typename: "WorkTypeSubsetGroup";
  id: string;
  /**
   *  E.g. title: 'Car Brands' -> worktypes: [LoadsOfCarWorktypes] 
   */
  slug: string;
  consumerTitle: string;
}

export interface JOB_SEARCH_SETTINGS_QUERY_businessWorkTypes_subsets {
  __typename: "WorkTypeSubset";
  id: string;
  /**
   * Title of workTypeSubset 
   */
  title: string;
  /**
   *  If subset should be displayed to the user 
   */
  visible: boolean;
  /**
   *  Group that the work type is part of. E.g. CarBrands, Fuel 
   */
  partOf: JOB_SEARCH_SETTINGS_QUERY_businessWorkTypes_subsets_partOf | null;
}

export interface JOB_SEARCH_SETTINGS_QUERY_businessWorkTypes_subsetsAvailable_partOf {
  __typename: "WorkTypeSubsetGroup";
  id: string;
  consumerTitle: string;
  /**
   *  E.g. title: 'Car Brands' -> worktypes: [LoadsOfCarWorktypes] 
   */
  slug: string;
}

export interface JOB_SEARCH_SETTINGS_QUERY_businessWorkTypes_subsetsAvailable {
  __typename: "WorkTypeSubset";
  id: string;
  /**
   * Title of workTypeSubset 
   */
  title: string;
  /**
   *  If subset should be displayed to the user 
   */
  visible: boolean;
  /**
   *  Group that the work type is part of. E.g. CarBrands, Fuel 
   */
  partOf: JOB_SEARCH_SETTINGS_QUERY_businessWorkTypes_subsetsAvailable_partOf | null;
}

export interface JOB_SEARCH_SETTINGS_QUERY_businessWorkTypes {
  __typename: "BusinessWorkTypes";
  id: string;
  workTypes: JOB_SEARCH_SETTINGS_QUERY_businessWorkTypes_workTypes[];
  workTypesAvailable: JOB_SEARCH_SETTINGS_QUERY_businessWorkTypes_workTypesAvailable[];
  subsets: JOB_SEARCH_SETTINGS_QUERY_businessWorkTypes_subsets[] | null;
  subsetsAvailable: JOB_SEARCH_SETTINGS_QUERY_businessWorkTypes_subsetsAvailable[] | null;
}

export interface JOB_SEARCH_SETTINGS_QUERY {
  businessJobAreas: JOB_SEARCH_SETTINGS_QUERY_businessJobAreas;
  businessWorkTypes: JOB_SEARCH_SETTINGS_QUERY_businessWorkTypes;
}

export interface JOB_SEARCH_SETTINGS_QUERYVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: JOBS_TOTAL_COUNT_QUERY
// ====================================================

export interface JOBS_TOTAL_COUNT_QUERY_jobList_PageOutOfRangeError {
  __typename: "PageOutOfRangeError";
}

export interface JOBS_TOTAL_COUNT_QUERY_jobList_JobListPayload_jobConnection_pageInfo {
  __typename: "PageInfo";
  /**
   *  total number of entries available 
   */
  totalCount: number | null;
}

export interface JOBS_TOTAL_COUNT_QUERY_jobList_JobListPayload_jobConnection {
  __typename: "JobConnection";
  pageInfo: JOBS_TOTAL_COUNT_QUERY_jobList_JobListPayload_jobConnection_pageInfo;
}

export interface JOBS_TOTAL_COUNT_QUERY_jobList_JobListPayload {
  __typename: "JobListPayload";
  jobConnection: JOBS_TOTAL_COUNT_QUERY_jobList_JobListPayload_jobConnection;
}

export type JOBS_TOTAL_COUNT_QUERY_jobList = JOBS_TOTAL_COUNT_QUERY_jobList_PageOutOfRangeError | JOBS_TOTAL_COUNT_QUERY_jobList_JobListPayload;

export interface JOBS_TOTAL_COUNT_QUERY {
  /**
   *  Provided as JobListPayload in order to support available facets and such 
   */
  jobList: JOBS_TOTAL_COUNT_QUERY_jobList;
}

export interface JOBS_TOTAL_COUNT_QUERYVariables {
  count?: number | null;
  query?: string | null;
  size?: JobSize | null;
  sizes?: JobSize[] | null;
  listId?: string | null;
  districtId?: string[] | null;
  countyId?: string[] | null;
  municipalityCode?: string[] | null;
  folderId?: string[] | null;
  worktypeIds?: string[] | null;
  worktypeSubsets?: string[] | null;
  worktypeSubsetGroups?: string[] | null;
  industryIds?: string[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: JOB_TO_EVALUATE_LIST_QUERY
// ====================================================

export interface JOB_TO_EVALUATE_LIST_QUERY_jobList_JobListPayload_jobConnection_pageInfo {
  __typename: "PageInfo";
  /**
   *  cursor to the last element in list 
   */
  endCursor: string | null;
  /**
   *  whether there are items before the current cursor 
   */
  hasNextPage: boolean;
  /**
   *  whether there are items after the current cursor 
   */
  hasPreviousPage: boolean;
  /**
   *  maximum number of nodes to a single page 
   */
  pageSizeLimit: number | null;
  /**
   *  cursor to the first element in list  
   */
  startCursor: string | null;
  /**
   *  total number of entries available 
   */
  totalCount: number | null;
  /**
   *  total number of pages. might be removed due to inclusion of totalCount 
   */
  totalPages: number | null;
}

export interface JOB_TO_EVALUATE_LIST_QUERY_jobList_JobListPayload_jobConnection_edges_node_evaluation {
  __typename: "Evaluation";
  id: string;
}

export interface JOB_TO_EVALUATE_LIST_QUERY_jobList_JobListPayload_jobConnection_edges_node_jobBusinessRelationState {
  __typename: "JobBusinessRelationState";
  hasRequestedEvaluation: boolean;
  jobId: string;
}

export interface JOB_TO_EVALUATE_LIST_QUERY_jobList_JobListPayload_jobConnection_edges_node_owner {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  isDeleted: boolean | null;
  isVerifiedWithSecureId: boolean;
}

export interface JOB_TO_EVALUATE_LIST_QUERY_jobList_JobListPayload_jobConnection_edges_node {
  __typename: "Job";
  /**
   *  job ID 
   */
  id: string;
  evaluation: JOB_TO_EVALUATE_LIST_QUERY_jobList_JobListPayload_jobConnection_edges_node_evaluation | null;
  jobBusinessRelationState: JOB_TO_EVALUATE_LIST_QUERY_jobList_JobListPayload_jobConnection_edges_node_jobBusinessRelationState;
  /**
   *  Doffin jobs do not have a registered user in our system. Thus the field is optional. 
   */
  owner: JOB_TO_EVALUATE_LIST_QUERY_jobList_JobListPayload_jobConnection_edges_node_owner | null;
  /**
   *  Main title of a job 
   */
  title: string;
}

export interface JOB_TO_EVALUATE_LIST_QUERY_jobList_JobListPayload_jobConnection_edges {
  __typename: "JobEdge";
  node: JOB_TO_EVALUATE_LIST_QUERY_jobList_JobListPayload_jobConnection_edges_node;
}

export interface JOB_TO_EVALUATE_LIST_QUERY_jobList_JobListPayload_jobConnection {
  __typename: "JobConnection";
  pageInfo: JOB_TO_EVALUATE_LIST_QUERY_jobList_JobListPayload_jobConnection_pageInfo;
  edges: JOB_TO_EVALUATE_LIST_QUERY_jobList_JobListPayload_jobConnection_edges[];
}

export interface JOB_TO_EVALUATE_LIST_QUERY_jobList_JobListPayload {
  __typename: "JobListPayload";
  jobConnection: JOB_TO_EVALUATE_LIST_QUERY_jobList_JobListPayload_jobConnection;
}

export interface JOB_TO_EVALUATE_LIST_QUERY_jobList_PageOutOfRangeError {
  __typename: "PageOutOfRangeError";
  message: string;
}

export type JOB_TO_EVALUATE_LIST_QUERY_jobList = JOB_TO_EVALUATE_LIST_QUERY_jobList_JobListPayload | JOB_TO_EVALUATE_LIST_QUERY_jobList_PageOutOfRangeError;

export interface JOB_TO_EVALUATE_LIST_QUERY {
  /**
   *  Provided as JobListPayload in order to support available facets and such 
   */
  jobList: JOB_TO_EVALUATE_LIST_QUERY_jobList;
}

export interface JOB_TO_EVALUATE_LIST_QUERYVariables {
  query?: string | null;
  page?: number | null;
  count?: number | null;
  listId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: JOB_TRAITS_QUERY
// ====================================================

export interface JOB_TRAITS_QUERY_jobTraits_questionsRegForm {
  __typename: "QuestionRegForm";
  type: string;
  question: string;
  answer: string;
}

export interface JOB_TRAITS_QUERY_jobTraits {
  __typename: "JobTraits";
  id: string;
  big: boolean;
  ended: boolean;
  estimatedSquareMeters: string | null;
  fromVerifiedUser: boolean;
  questionsRegForm: JOB_TRAITS_QUERY_jobTraits_questionsRegForm[] | null;
}

export interface JOB_TRAITS_QUERY {
  jobTraits: JOB_TRAITS_QUERY_jobTraits;
}

export interface JOB_TRAITS_QUERYVariables {
  jobId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: LOGGED_IN_STATUS_QUERY
// ====================================================

export interface LOGGED_IN_STATUS_QUERY_loggedInStatus {
  __typename: "LoggedInStatus";
  loggedIn: boolean;
  as: UserRole | null;
}

export interface LOGGED_IN_STATUS_QUERY {
  loggedInStatus: LOGGED_IN_STATUS_QUERY_loggedInStatus;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: LOGGED_IN_STATUS_CHECK_QUERY
// ====================================================

export interface LOGGED_IN_STATUS_CHECK_QUERY_loggedInStatus {
  __typename: "LoggedInStatus";
  loggedIn: boolean;
  as: UserRole | null;
}

export interface LOGGED_IN_STATUS_CHECK_QUERY {
  loggedInStatus: LOGGED_IN_STATUS_CHECK_QUERY_loggedInStatus;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: NOTIFICATION_DESTINATIONS_ENABLED_QUERY
// ====================================================

export interface NOTIFICATION_DESTINATIONS_ENABLED_QUERY_notificationDestinationsEnabled {
  __typename: "NotificationDestination";
  id: string;
  type: NotificationDestinationType;
  defaultOn: boolean;
  isToggleable: boolean;
  /**
   * Provided as minutes.
   * 
   * If the notification is delayed according to occurence. For instance; nowdays emails
   * are sent 15 minutes after push when a new message – and it's cancelled if the message
   * is read before.
   */
  delay: any | null;
}

export interface NOTIFICATION_DESTINATIONS_ENABLED_QUERY {
  /**
   *  Current notification destinations enabled by user - seen in context of current login state. Less hassle if a user switches between several companies. 
   */
  notificationDestinationsEnabled: NOTIFICATION_DESTINATIONS_ENABLED_QUERY_notificationDestinationsEnabled[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: POSTAL_ADDRESS_QUERY
// ====================================================

export interface POSTAL_ADDRESS_QUERY_postalAddress_PostalAddress_municipality_county {
  __typename: "County";
  id: string;
  name: string;
}

export interface POSTAL_ADDRESS_QUERY_postalAddress_PostalAddress_municipality {
  __typename: "Municipality";
  id: string;
  name: string;
  county: POSTAL_ADDRESS_QUERY_postalAddress_PostalAddress_municipality_county;
}

export interface POSTAL_ADDRESS_QUERY_postalAddress_PostalAddress {
  __typename: "PostalAddress";
  id: string;
  postalCode: any | null;
  city: string;
  /**
   *  Same name available through District type. We haven't made these as an entitiy in the API as of now. 
   */
  district: string | null;
  region: string | null;
  lat: string | null;
  lon: string | null;
  municipality: POSTAL_ADDRESS_QUERY_postalAddress_PostalAddress_municipality;
}

export interface POSTAL_ADDRESS_QUERY_postalAddress_PostalAddressNotFoundError {
  __typename: "PostalAddressNotFoundError";
  code: number | null;
  message: string;
}

export type POSTAL_ADDRESS_QUERY_postalAddress = POSTAL_ADDRESS_QUERY_postalAddress_PostalAddress | POSTAL_ADDRESS_QUERY_postalAddress_PostalAddressNotFoundError;

export interface POSTAL_ADDRESS_QUERY {
  postalAddress: POSTAL_ADDRESS_QUERY_postalAddress;
}

export interface POSTAL_ADDRESS_QUERYVariables {
  postalCode: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PRIMARY_BUSINESS_CONTACT
// ====================================================

export interface PRIMARY_BUSINESS_CONTACT_primaryBusinessContact {
  __typename: "PrimaryBusinessContactPayload";
  id: string;
  isCurrentUserPrimaryContact: boolean;
  primaryContactEmail: string;
}

export interface PRIMARY_BUSINESS_CONTACT {
  primaryBusinessContact: PRIMARY_BUSINESS_CONTACT_primaryBusinessContact;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PRODUCTS_QUERY
// ====================================================

export interface PRODUCTS_QUERY_business_Business_products_basicPackage {
  __typename: "SimpleProduct";
  isOwned: boolean;
}

export interface PRODUCTS_QUERY_business_Business_products_jobPackage {
  __typename: "SubscriptionProduct";
  isOwned: boolean;
  clips: number | null;
}

export interface PRODUCTS_QUERY_business_Business_products_proPackage {
  __typename: "SimpleProduct";
  isOwned: boolean;
}

export interface PRODUCTS_QUERY_business_Business_products_advertising {
  __typename: "SimpleProduct";
  isOwned: boolean;
}

export interface PRODUCTS_QUERY_business_Business_products_companySearch {
  __typename: "SimpleProduct";
  isOwned: boolean;
}

export interface PRODUCTS_QUERY_business_Business_products_homepage {
  __typename: "SimpleProduct";
  isOwned: boolean;
}

export interface PRODUCTS_QUERY_business_Business_products {
  __typename: "CompanyProducts";
  basicPackage: PRODUCTS_QUERY_business_Business_products_basicPackage;
  jobPackage: PRODUCTS_QUERY_business_Business_products_jobPackage;
  proPackage: PRODUCTS_QUERY_business_Business_products_proPackage;
  advertising: PRODUCTS_QUERY_business_Business_products_advertising;
  companySearch: PRODUCTS_QUERY_business_Business_products_companySearch;
  homepage: PRODUCTS_QUERY_business_Business_products_homepage;
}

export interface PRODUCTS_QUERY_business_Business {
  __typename: "Business";
  id: string;
  products: PRODUCTS_QUERY_business_Business_products | null;
}

export interface PRODUCTS_QUERY_business_BusinessInputError_idError {
  __typename: "BasicInputError";
  message: string;
  code: number | null;
}

export interface PRODUCTS_QUERY_business_BusinessInputError {
  __typename: "BusinessInputError";
  message: string;
  code: number | null;
  idError: PRODUCTS_QUERY_business_BusinessInputError_idError | null;
}

export interface PRODUCTS_QUERY_business_BusinessNotFoundError {
  __typename: "BusinessNotFoundError";
  message: string;
}

export type PRODUCTS_QUERY_business = PRODUCTS_QUERY_business_Business | PRODUCTS_QUERY_business_BusinessInputError | PRODUCTS_QUERY_business_BusinessNotFoundError;

export interface PRODUCTS_QUERY {
  business: PRODUCTS_QUERY_business;
}

export interface PRODUCTS_QUERYVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PROFILE_PICTURES_QUERY
// ====================================================

export interface PROFILE_PICTURES_QUERY_businessAlbums_images_album {
  __typename: "Album";
  id: string;
}

export interface PROFILE_PICTURES_QUERY_businessAlbums_images_image_dimensions {
  __typename: "Dimension";
  width: number;
  height: number;
}

export interface PROFILE_PICTURES_QUERY_businessAlbums_images_image {
  __typename: "Image";
  bigUrl: string;
  bigPreviewUrl: string;
  description: string | null;
  dimensions: PROFILE_PICTURES_QUERY_businessAlbums_images_image_dimensions;
  id: string;
  mediumPreviewUrl: string;
  mediumUrl: string;
  name: string;
  size: number;
  smallPreviewUrl: string;
  smallUrl: string;
  url: string;
  fileType: string;
}

export interface PROFILE_PICTURES_QUERY_businessAlbums_images {
  __typename: "AlbumImage";
  id: string;
  description: string | null;
  sort: number;
  album: PROFILE_PICTURES_QUERY_businessAlbums_images_album;
  image: PROFILE_PICTURES_QUERY_businessAlbums_images_image;
}

export interface PROFILE_PICTURES_QUERY_businessAlbums {
  __typename: "Album";
  id: string;
  images: PROFILE_PICTURES_QUERY_businessAlbums_images[];
  name: string;
}

export interface PROFILE_PICTURES_QUERY {
  businessAlbums: PROFILE_PICTURES_QUERY_businessAlbums[];
}

export interface PROFILE_PICTURES_QUERYVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: QUALIFICATION_LIST_QUERY
// ====================================================

export interface QUALIFICATION_LIST_QUERY_business_Business_certificateConnection_edges_node_logos_big_dimensions {
  __typename: "Dimension";
  width: number;
  height: number;
}

export interface QUALIFICATION_LIST_QUERY_business_Business_certificateConnection_edges_node_logos_big {
  __typename: "Image";
  bigUrl: string;
  bigPreviewUrl: string;
  description: string | null;
  dimensions: QUALIFICATION_LIST_QUERY_business_Business_certificateConnection_edges_node_logos_big_dimensions;
  id: string;
  mediumPreviewUrl: string;
  mediumUrl: string;
  name: string;
  size: number;
  smallPreviewUrl: string;
  smallUrl: string;
  url: string;
  fileType: string;
}

export interface QUALIFICATION_LIST_QUERY_business_Business_certificateConnection_edges_node_logos_small_dimensions {
  __typename: "Dimension";
  width: number;
  height: number;
}

export interface QUALIFICATION_LIST_QUERY_business_Business_certificateConnection_edges_node_logos_small {
  __typename: "Image";
  bigUrl: string;
  bigPreviewUrl: string;
  description: string | null;
  dimensions: QUALIFICATION_LIST_QUERY_business_Business_certificateConnection_edges_node_logos_small_dimensions;
  id: string;
  mediumPreviewUrl: string;
  mediumUrl: string;
  name: string;
  size: number;
  smallPreviewUrl: string;
  smallUrl: string;
  url: string;
  fileType: string;
}

export interface QUALIFICATION_LIST_QUERY_business_Business_certificateConnection_edges_node_logos_icon_dimensions {
  __typename: "Dimension";
  width: number;
  height: number;
}

export interface QUALIFICATION_LIST_QUERY_business_Business_certificateConnection_edges_node_logos_icon {
  __typename: "Image";
  bigUrl: string;
  bigPreviewUrl: string;
  description: string | null;
  dimensions: QUALIFICATION_LIST_QUERY_business_Business_certificateConnection_edges_node_logos_icon_dimensions;
  id: string;
  mediumPreviewUrl: string;
  mediumUrl: string;
  name: string;
  size: number;
  smallPreviewUrl: string;
  smallUrl: string;
  url: string;
  fileType: string;
}

export interface QUALIFICATION_LIST_QUERY_business_Business_certificateConnection_edges_node_logos {
  __typename: "CertificateLogos";
  big: QUALIFICATION_LIST_QUERY_business_Business_certificateConnection_edges_node_logos_big | null;
  small: QUALIFICATION_LIST_QUERY_business_Business_certificateConnection_edges_node_logos_small | null;
  icon: QUALIFICATION_LIST_QUERY_business_Business_certificateConnection_edges_node_logos_icon | null;
}

export interface QUALIFICATION_LIST_QUERY_business_Business_certificateConnection_edges_node {
  __typename: "Certificate";
  description: string | null;
  id: string;
  name: string | null;
  logos: QUALIFICATION_LIST_QUERY_business_Business_certificateConnection_edges_node_logos | null;
}

export interface QUALIFICATION_LIST_QUERY_business_Business_certificateConnection_edges {
  __typename: "CertificateEdge";
  node: QUALIFICATION_LIST_QUERY_business_Business_certificateConnection_edges_node;
}

export interface QUALIFICATION_LIST_QUERY_business_Business_certificateConnection {
  __typename: "CertificateConnection";
  edges: QUALIFICATION_LIST_QUERY_business_Business_certificateConnection_edges[];
}

export interface QUALIFICATION_LIST_QUERY_business_Business {
  __typename: "Business";
  id: string;
  certificateConnection: QUALIFICATION_LIST_QUERY_business_Business_certificateConnection | null;
}

export interface QUALIFICATION_LIST_QUERY_business_BusinessInputError_idError {
  __typename: "BasicInputError";
  message: string;
}

export interface QUALIFICATION_LIST_QUERY_business_BusinessInputError {
  __typename: "BusinessInputError";
  message: string;
  idError: QUALIFICATION_LIST_QUERY_business_BusinessInputError_idError | null;
}

export interface QUALIFICATION_LIST_QUERY_business_BusinessNotFoundError {
  __typename: "BusinessNotFoundError";
  message: string;
}

export type QUALIFICATION_LIST_QUERY_business = QUALIFICATION_LIST_QUERY_business_Business | QUALIFICATION_LIST_QUERY_business_BusinessInputError | QUALIFICATION_LIST_QUERY_business_BusinessNotFoundError;

export interface QUALIFICATION_LIST_QUERY {
  business: QUALIFICATION_LIST_QUERY_business;
}

export interface QUALIFICATION_LIST_QUERYVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SEARCH_BOLIGMAPPA_ADDRESS
// ====================================================

export interface SEARCH_BOLIGMAPPA_ADDRESS_searchBoligmappaAddress {
  __typename: "BoligmappaBuildingAddress";
  id: string;
  houseNumber: number;
  houseSubNumber: string | null;
  streetName: string;
  postalCode: string;
  postalPlace: string;
}

export interface SEARCH_BOLIGMAPPA_ADDRESS {
  searchBoligmappaAddress: SEARCH_BOLIGMAPPA_ADDRESS_searchBoligmappaAddress[];
}

export interface SEARCH_BOLIGMAPPA_ADDRESSVariables {
  input: SearchBoligmappaAddressInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SELF_SERVICE_SUBSCRIPTIONS
// ====================================================

export interface SELF_SERVICE_SUBSCRIPTIONS_selfServiceSubscriptions {
  __typename: "SelfServiceSubscriptionOption";
  id: string;
  price: number;
  slug: SelfServiceSubscriptionName;
  clips: number;
}

export interface SELF_SERVICE_SUBSCRIPTIONS {
  selfServiceSubscriptions: SELF_SERVICE_SUBSCRIPTIONS_selfServiceSubscriptions[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SIMPLE_CONTRACT_QUERY
// ====================================================

export interface SIMPLE_CONTRACT_QUERY_simpleAgreementContract_statusFlags {
  __typename: "SimpleAgreementContractStatusFlags";
  initialized: boolean;
  acceptedConsumer: boolean;
  acceptedBusiness: boolean;
  rejectedConsumer: boolean;
  rejectedBusiness: boolean;
  invalidated: boolean;
}

export interface SIMPLE_CONTRACT_QUERY_simpleAgreementContract_job_address {
  __typename: "Address";
  id: string;
  street: string | null;
  zip: string | null;
  city: string | null;
}

export interface SIMPLE_CONTRACT_QUERY_simpleAgreementContract_job_owner {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  isDeleted: boolean | null;
  isVerifiedWithSecureId: boolean;
}

export interface SIMPLE_CONTRACT_QUERY_simpleAgreementContract_job_contactInfo {
  __typename: "JobContactInfo";
  phone: string | null;
  email: string | null;
}

export interface SIMPLE_CONTRACT_QUERY_simpleAgreementContract_job {
  __typename: "Job";
  /**
   *  job ID 
   */
  id: string;
  /**
   *  Main title of a job 
   */
  title: string;
  /**
   *  Exact address of job. Available to business after job has been answered. Use postal address until then. 
   */
  address: SIMPLE_CONTRACT_QUERY_simpleAgreementContract_job_address | null;
  /**
   *  Doffin jobs do not have a registered user in our system. Thus the field is optional. 
   */
  owner: SIMPLE_CONTRACT_QUERY_simpleAgreementContract_job_owner | null;
  contactInfo: SIMPLE_CONTRACT_QUERY_simpleAgreementContract_job_contactInfo | null;
}

export interface SIMPLE_CONTRACT_QUERY_simpleAgreementContract_company {
  __typename: "Business";
  id: string;
  name: string;
}

export interface SIMPLE_CONTRACT_QUERY_simpleAgreementContract_user {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  isDeleted: boolean | null;
  isVerifiedWithSecureId: boolean;
}

export interface SIMPLE_CONTRACT_QUERY_simpleAgreementContract {
  __typename: "SimpleAgreementContract";
  id: string;
  type: ContractType;
  regTime: number;
  statusEnum: SimpleAgreementContractStatus;
  description: string | null;
  consumerEmail: string | null;
  consumerPhone: string | null;
  businessEmail: string | null;
  businessPhone: string | null;
  signedUserTime: number | null;
  signedCompanyTime: number | null;
  /**
   *  Simple agreement only contains one price. This can also be hourly work rates, which doesn't equal to what we regard as contract amount. 
   */
  priceType: ContractPriceType;
  price: number | null;
  startDate: number | null;
  endDate: number | null;
  statusFlags: SIMPLE_CONTRACT_QUERY_simpleAgreementContract_statusFlags;
  job: SIMPLE_CONTRACT_QUERY_simpleAgreementContract_job;
  company: SIMPLE_CONTRACT_QUERY_simpleAgreementContract_company;
  user: SIMPLE_CONTRACT_QUERY_simpleAgreementContract_user;
}

export interface SIMPLE_CONTRACT_QUERY {
  simpleAgreementContract: SIMPLE_CONTRACT_QUERY_simpleAgreementContract | null;
}

export interface SIMPLE_CONTRACT_QUERYVariables {
  input: SimpleAgreementContractInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SIMPLE_AGREEMENT_CONTRACT_BY_JOB_ID
// ====================================================

export interface SIMPLE_AGREEMENT_CONTRACT_BY_JOB_ID_simpleAgreementContractByJobId_statusFlags {
  __typename: "SimpleAgreementContractStatusFlags";
  initialized: boolean;
  acceptedConsumer: boolean;
  acceptedBusiness: boolean;
  rejectedConsumer: boolean;
  rejectedBusiness: boolean;
  invalidated: boolean;
}

export interface SIMPLE_AGREEMENT_CONTRACT_BY_JOB_ID_simpleAgreementContractByJobId {
  __typename: "SimpleAgreementContract";
  id: string;
  type: ContractType;
  regTime: number;
  statusEnum: SimpleAgreementContractStatus;
  description: string | null;
  consumerEmail: string | null;
  consumerPhone: string | null;
  businessEmail: string | null;
  businessPhone: string | null;
  signedUserTime: number | null;
  signedCompanyTime: number | null;
  /**
   *  Simple agreement only contains one price. This can also be hourly work rates, which doesn't equal to what we regard as contract amount. 
   */
  priceType: ContractPriceType;
  price: number | null;
  startDate: number | null;
  endDate: number | null;
  statusFlags: SIMPLE_AGREEMENT_CONTRACT_BY_JOB_ID_simpleAgreementContractByJobId_statusFlags;
}

export interface SIMPLE_AGREEMENT_CONTRACT_BY_JOB_ID {
  simpleAgreementContractByJobId: SIMPLE_AGREEMENT_CONTRACT_BY_JOB_ID_simpleAgreementContractByJobId | null;
}

export interface SIMPLE_AGREEMENT_CONTRACT_BY_JOB_IDVariables {
  jobId: string;
  companyId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SIMPLE_CONTRACT_LIST_QUERY
// ====================================================

export interface SIMPLE_CONTRACT_LIST_QUERY_simpleAgreementContractList_list_statusFlags {
  __typename: "SimpleAgreementContractStatusFlags";
  initialized: boolean;
  acceptedConsumer: boolean;
  acceptedBusiness: boolean;
  rejectedConsumer: boolean;
  rejectedBusiness: boolean;
  invalidated: boolean;
}

export interface SIMPLE_CONTRACT_LIST_QUERY_simpleAgreementContractList_list_job_address {
  __typename: "Address";
  id: string;
  street: string | null;
  zip: string | null;
  city: string | null;
}

export interface SIMPLE_CONTRACT_LIST_QUERY_simpleAgreementContractList_list_job_owner {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  isDeleted: boolean | null;
  isVerifiedWithSecureId: boolean;
}

export interface SIMPLE_CONTRACT_LIST_QUERY_simpleAgreementContractList_list_job_contactInfo {
  __typename: "JobContactInfo";
  phone: string | null;
  email: string | null;
}

export interface SIMPLE_CONTRACT_LIST_QUERY_simpleAgreementContractList_list_job {
  __typename: "Job";
  /**
   *  job ID 
   */
  id: string;
  /**
   *  Main title of a job 
   */
  title: string;
  /**
   *  Exact address of job. Available to business after job has been answered. Use postal address until then. 
   */
  address: SIMPLE_CONTRACT_LIST_QUERY_simpleAgreementContractList_list_job_address | null;
  /**
   *  Doffin jobs do not have a registered user in our system. Thus the field is optional. 
   */
  owner: SIMPLE_CONTRACT_LIST_QUERY_simpleAgreementContractList_list_job_owner | null;
  contactInfo: SIMPLE_CONTRACT_LIST_QUERY_simpleAgreementContractList_list_job_contactInfo | null;
}

export interface SIMPLE_CONTRACT_LIST_QUERY_simpleAgreementContractList_list_company {
  __typename: "Business";
  id: string;
  name: string;
}

export interface SIMPLE_CONTRACT_LIST_QUERY_simpleAgreementContractList_list_user {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  isDeleted: boolean | null;
  isVerifiedWithSecureId: boolean;
}

export interface SIMPLE_CONTRACT_LIST_QUERY_simpleAgreementContractList_list {
  __typename: "SimpleAgreementContract";
  id: string;
  type: ContractType;
  regTime: number;
  statusEnum: SimpleAgreementContractStatus;
  description: string | null;
  consumerEmail: string | null;
  consumerPhone: string | null;
  businessEmail: string | null;
  businessPhone: string | null;
  signedUserTime: number | null;
  signedCompanyTime: number | null;
  /**
   *  Simple agreement only contains one price. This can also be hourly work rates, which doesn't equal to what we regard as contract amount. 
   */
  priceType: ContractPriceType;
  price: number | null;
  startDate: number | null;
  endDate: number | null;
  statusFlags: SIMPLE_CONTRACT_LIST_QUERY_simpleAgreementContractList_list_statusFlags;
  job: SIMPLE_CONTRACT_LIST_QUERY_simpleAgreementContractList_list_job;
  company: SIMPLE_CONTRACT_LIST_QUERY_simpleAgreementContractList_list_company;
  user: SIMPLE_CONTRACT_LIST_QUERY_simpleAgreementContractList_list_user;
}

export interface SIMPLE_CONTRACT_LIST_QUERY_simpleAgreementContractList_pageInfo {
  __typename: "ContractListPageInfo";
  totalCount: number;
}

export interface SIMPLE_CONTRACT_LIST_QUERY_simpleAgreementContractList {
  __typename: "SimpleAgreementContractListPayload";
  list: SIMPLE_CONTRACT_LIST_QUERY_simpleAgreementContractList_list[];
  pageInfo: SIMPLE_CONTRACT_LIST_QUERY_simpleAgreementContractList_pageInfo | null;
}

export interface SIMPLE_CONTRACT_LIST_QUERY {
  simpleAgreementContractList: SIMPLE_CONTRACT_LIST_QUERY_simpleAgreementContractList;
}

export interface SIMPLE_CONTRACT_LIST_QUERYVariables {
  input: SimpleAgreementContractListInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UNPAID_INVOICES_QUERY
// ====================================================

export interface UNPAID_INVOICES_QUERY_business_Business_invoicesUnpaid {
  __typename: "Invoice";
  id: string;
  dueDate: number;
  payType: PaymentType;
  paymentLink: string | null;
  latestAttempt: InvoiceLatestAttempt | null;
}

export interface UNPAID_INVOICES_QUERY_business_Business {
  __typename: "Business";
  id: string;
  invoicesUnpaid: UNPAID_INVOICES_QUERY_business_Business_invoicesUnpaid[];
}

export interface UNPAID_INVOICES_QUERY_business_BusinessInputError_idError {
  __typename: "BasicInputError";
  message: string;
  code: number | null;
}

export interface UNPAID_INVOICES_QUERY_business_BusinessInputError {
  __typename: "BusinessInputError";
  message: string;
  idError: UNPAID_INVOICES_QUERY_business_BusinessInputError_idError | null;
}

export interface UNPAID_INVOICES_QUERY_business_BusinessNotFoundError {
  __typename: "BusinessNotFoundError";
  message: string;
}

export type UNPAID_INVOICES_QUERY_business = UNPAID_INVOICES_QUERY_business_Business | UNPAID_INVOICES_QUERY_business_BusinessInputError | UNPAID_INVOICES_QUERY_business_BusinessNotFoundError;

export interface UNPAID_INVOICES_QUERY {
  business: UNPAID_INVOICES_QUERY_business;
}

export interface UNPAID_INVOICES_QUERYVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: WORK_TYPES_QUERY
// ====================================================

export interface WORK_TYPES_QUERY_businessWorkTypes_workTypes_industries {
  __typename: "Industry";
  id: string;
  title: string;
}

export interface WORK_TYPES_QUERY_businessWorkTypes_workTypes {
  __typename: "WorkType";
  /**
   *  worktype ID 
   */
  id: string;
  /**
   *  title of worktype
   */
  title: string;
  /**
   *  industries the work type belongs to 
   */
  industries: (WORK_TYPES_QUERY_businessWorkTypes_workTypes_industries | null)[] | null;
}

export interface WORK_TYPES_QUERY_businessWorkTypes_subsets_partOf {
  __typename: "WorkTypeSubsetGroup";
  id: string;
  consumerTitle: string;
}

export interface WORK_TYPES_QUERY_businessWorkTypes_subsets {
  __typename: "WorkTypeSubset";
  id: string;
  /**
   * Title of workTypeSubset 
   */
  title: string;
  /**
   *  Group that the work type is part of. E.g. CarBrands, Fuel 
   */
  partOf: WORK_TYPES_QUERY_businessWorkTypes_subsets_partOf | null;
}

export interface WORK_TYPES_QUERY_businessWorkTypes {
  __typename: "BusinessWorkTypes";
  id: string;
  workTypes: WORK_TYPES_QUERY_businessWorkTypes_workTypes[];
  subsets: WORK_TYPES_QUERY_businessWorkTypes_subsets[] | null;
}

export interface WORK_TYPES_QUERY {
  businessWorkTypes: WORK_TYPES_QUERY_businessWorkTypes;
}

export interface WORK_TYPES_QUERYVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CachedDistrictsAvailable
// ====================================================

export interface CachedDistrictsAvailable_districtsAvailable_municipality {
  __typename: "Municipality";
  id: string;
}

export interface CachedDistrictsAvailable_districtsAvailable {
  __typename: "District";
  id: string;
  municipality: CachedDistrictsAvailable_districtsAvailable_municipality | null;
}

export interface CachedDistrictsAvailable {
  __typename: "BusinessJobAreas";
  districtsAvailable: CachedDistrictsAvailable_districtsAvailable[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: AddressFragment
// ====================================================

export interface AddressFragment {
  __typename: "Address";
  city: string | null;
  description: string | null;
  id: string;
  lat: string | null;
  lon: string | null;
  street: string | null;
  type: number;
  zip: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: BasicUserDataFragment
// ====================================================

export interface BasicUserDataFragment {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  isDeleted: boolean | null;
  isVerifiedWithSecureId: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CompanyProfileFragment
// ====================================================

export interface CompanyProfileFragment_logo_dimensions {
  __typename: "Dimension";
  width: number;
  height: number;
}

export interface CompanyProfileFragment_logo {
  __typename: "Image";
  bigUrl: string;
  bigPreviewUrl: string;
  description: string | null;
  dimensions: CompanyProfileFragment_logo_dimensions;
  id: string;
  mediumPreviewUrl: string;
  mediumUrl: string;
  name: string;
  size: number;
  smallPreviewUrl: string;
  smallUrl: string;
  url: string;
  fileType: string;
}

export interface CompanyProfileFragment {
  __typename: "Business";
  id: string;
  logo: CompanyProfileFragment_logo | null;
  profileHeaderImage: string | null;
  name: string;
  orgNr: string | null;
  phone: string | null;
  email: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CompanyStateFragment
// ====================================================

export interface CompanyStateFragment {
  __typename: "BusinessStates";
  id: string;
  isVerified: boolean;
  isVerifiedWithSecureId: boolean;
  isDeactivated: boolean;
  isBlacklisted: boolean;
  isOrderUnpaid: boolean;
  isMittanbudXlEligible: boolean;
  hasProducts: boolean;
  hasProductExpired: boolean;
  isNew: boolean;
  products: ProductTemplateSlug[] | null;
  jobAccess: CompanySettingsJobAccess;
  availableProductSlugs: ProductTemplateSlug[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: EmployeeFragment
// ====================================================

export interface EmployeeFragment_image {
  __typename: "Image";
  id: string;
  name: string;
}

export interface EmployeeFragment {
  __typename: "BusinessEmployee";
  id: string;
  position: string;
  firstName: string;
  lastName: string;
  phoneNr: string;
  email: string;
  imageUrl: string | null;
  image: EmployeeFragment_image | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: EvaluationFragment
// ====================================================

export interface EvaluationFragment {
  __typename: "Evaluation";
  id: string;
  score: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: FileFragment
// ====================================================

export interface FileFragment {
  __typename: "File";
  description: string | null;
  iconUrl: string;
  id: string;
  name: string;
  size: number;
  url: string;
  fileType: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ImageFragment
// ====================================================

export interface ImageFragment_dimensions {
  __typename: "Dimension";
  width: number;
  height: number;
}

export interface ImageFragment {
  __typename: "Image";
  bigUrl: string;
  bigPreviewUrl: string;
  description: string | null;
  dimensions: ImageFragment_dimensions;
  id: string;
  mediumPreviewUrl: string;
  mediumUrl: string;
  name: string;
  size: number;
  smallPreviewUrl: string;
  smallUrl: string;
  url: string;
  fileType: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: JobAccessCertificateFragment
// ====================================================

export interface JobAccessCertificateFragment {
  __typename: "Certificate";
  id: string;
  name: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: JobAccessFragment
// ====================================================

export interface JobAccessFragment_missingCertificates {
  __typename: "Certificate";
  id: string;
  name: string | null;
}

export interface JobAccessFragment {
  __typename: "AnswerAccess";
  code: AnswerAccessCode;
  isOpen: boolean;
  clip: number | null;
  missingCertificates: JobAccessFragment_missingCertificates[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: JobBasicDataFragment
// ====================================================

export interface JobBasicDataFragment_addressHint {
  __typename: "PostalAddress";
  city: string;
  id: string;
  lat: string | null;
  lon: string | null;
  postalCode: any | null;
}

export interface JobBasicDataFragment_files {
  __typename: "File";
  description: string | null;
  iconUrl: string;
  id: string;
  name: string;
  size: number;
  url: string;
  fileType: string;
}

export interface JobBasicDataFragment_images_dimensions {
  __typename: "Dimension";
  width: number;
  height: number;
}

export interface JobBasicDataFragment_images {
  __typename: "Image";
  bigUrl: string;
  bigPreviewUrl: string;
  description: string | null;
  dimensions: JobBasicDataFragment_images_dimensions;
  id: string;
  mediumPreviewUrl: string;
  mediumUrl: string;
  name: string;
  size: number;
  smallPreviewUrl: string;
  smallUrl: string;
  url: string;
  fileType: string;
}

export interface JobBasicDataFragment_jobBusinessRelationState {
  __typename: "JobBusinessRelationState";
  jobId: string;
  clipsSpent: number | null;
  hasRequestedEvaluation: boolean;
  hasRequestedSelection: boolean;
  isAnswered: boolean;
  isDeleted: boolean;
  isFavourite: boolean;
  isLost: boolean;
  isRead: boolean;
  isWon: boolean;
  isFree: boolean;
  isOneOnOneReceiver: boolean | null;
  isRejected: boolean;
}

export interface JobBasicDataFragment_folders {
  __typename: "JobFolder";
  id: string;
  title: string;
  color: string | null;
}

export interface JobBasicDataFragment {
  __typename: "Job";
  /**
   *  Address hinting the jobs location. Useful when the address is not accessible.  
   */
  addressHint: JobBasicDataFragment_addressHint | null;
  /**
   *  approval state of a job 
   */
  approvedAt: number | null;
  /**
   *  Description of the job 
   */
  description: string | null;
  files: JobBasicDataFragment_files[];
  /**
   *  job ID 
   */
  id: string;
  images: JobBasicDataFragment_images[];
  /**
   *  If a job has been answered as seen through the eyes of the beholder. 
   */
  isFree: boolean | null;
  jobBusinessRelationState: JobBasicDataFragment_jobBusinessRelationState;
  folders: JobBasicDataFragment_folders[] | null;
  /**
   *  the size of a job - connects to  
   */
  size: JobSize | null;
  /**
   *  Main title of a job 
   */
  title: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: JobBusinessRelationStateFragment
// ====================================================

export interface JobBusinessRelationStateFragment {
  __typename: "JobBusinessRelationState";
  jobId: string;
  clipsSpent: number | null;
  hasRequestedEvaluation: boolean;
  hasRequestedSelection: boolean;
  isAnswered: boolean;
  isDeleted: boolean;
  isFavourite: boolean;
  isLost: boolean;
  isRead: boolean;
  isWon: boolean;
  isFree: boolean;
  isOneOnOneReceiver: boolean | null;
  isRejected: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: JobChatMetaFragment
// ====================================================

export interface JobChatMetaFragment {
  __typename: "ChatMeta";
  chatId: string;
  firstMessageTs: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: JobDetailsFragment
// ====================================================

export interface JobDetailsFragment_address {
  __typename: "Address";
  city: string | null;
  description: string | null;
  id: string;
  lat: string | null;
  lon: string | null;
  street: string | null;
  type: number;
  zip: string | null;
}

export interface JobDetailsFragment_evaluation {
  __typename: "Evaluation";
  id: string;
  score: number;
}

export interface JobDetailsFragment_workTypes_industries {
  __typename: "Industry";
  id: string;
}

export interface JobDetailsFragment_workTypes {
  __typename: "WorkType";
  /**
   *  worktype ID 
   */
  id: string;
  /**
   *  industries the work type belongs to 
   */
  industries: (JobDetailsFragment_workTypes_industries | null)[] | null;
}

export interface JobDetailsFragment {
  __typename: "Job";
  /**
   *  Exact address of job. Available to business after job has been answered. Use postal address until then. 
   */
  address: JobDetailsFragment_address | null;
  /**
   *  number of answers related to a job 
   */
  answerCount: number | null;
  /**
   *  maximum number of answers to display 
   */
  answerCountLimit: number | null;
  /**
   *  job ID 
   */
  id: string;
  evaluation: JobDetailsFragment_evaluation | null;
  workTypes: JobDetailsFragment_workTypes[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: JobFolderFragment
// ====================================================

export interface JobFolderFragment {
  __typename: "JobFolder";
  id: string;
  title: string;
  color: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: JobMetaFragment
// ====================================================

export interface JobMetaFragment_requestedStart {
  __typename: "JobStart";
  type: StartAlternative;
  relatedTime: number | null;
}

export interface JobMetaFragment_generics {
  __typename: "GenericJobMeta";
  /**
   *  Should equal to one of JobMetaSlug as kebab case. but we need to 'expect the unexpected' as new ones will be added from time to time. 
   */
  slug: string | null;
  /**
   *  Use this in case slugs are not translated in front end 
   */
  title: string | null;
  value: string | null;
}

export interface JobMetaFragment_car_info {
  __typename: "CarInfo";
  VIMNumber: string | null;
  brand: string | null;
  licencePlate: string | null;
  registrationYear: string | null;
  model: string | null;
  lastRoadWorthinessTestDate: string | null;
  nextRoadWorthinessTestDate: string | null;
  shaftCount: number | null;
  width: number | null;
  co2Emissions: number | null;
  fuelType: FuelType | null;
  weight: number | null;
  factoryInstalledParticleFilter: boolean | null;
  vehicleGroup: string | null;
  length: number | null;
  towbarMaxLoad: number | null;
  maxRoofLoad: number | null;
  engineHectowatt: number | null;
  maxSeats: number | null;
  engineSize: number | null;
  trailerMaxWeightWithBreak: number | null;
  trailerMaxWeightWithoutBreak: number | null;
  maxTotalWeight: number | null;
  taxCode: string | null;
  hybrid: HybridCarType | null;
  hybridCatagory: HybridCarCatagory | null;
  seatsFront: number | null;
}

export interface JobMetaFragment_car {
  __typename: "Car";
  id: string;
  /**
   * regNr shouldn't be requested because we cannot hide it as it's a key. It should also be @inaccessible
   */
  regNr: string;
  info: JobMetaFragment_car_info | null;
}

export interface JobMetaFragment_toAddress {
  __typename: "Address";
  city: string | null;
  description: string | null;
  id: string;
  lat: string | null;
  lon: string | null;
  street: string | null;
  type: number;
  zip: string | null;
}

export interface JobMetaFragment {
  __typename: "JobMeta";
  actualStartDate: number | null;
  customerType: JobCustomerType | null;
  contactPreference: JobContactPreference | null;
  requestedStart: JobMetaFragment_requestedStart | null;
  generics: JobMetaFragment_generics[];
  car: JobMetaFragment_car | null;
  toAddress: JobMetaFragment_toAddress | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: JobOwnerFragment
// ====================================================

export interface JobOwnerFragment_owner {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  isDeleted: boolean | null;
  isVerifiedWithSecureId: boolean;
}

export interface JobOwnerFragment {
  __typename: "Job";
  /**
   *  Doffin jobs do not have a registered user in our system. Thus the field is optional. 
   */
  owner: JobOwnerFragment_owner | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: JobsSavedFilterFragment
// ====================================================

export interface JobsSavedFilterFragment_filters {
  __typename: "JobWatchlistFilters";
  countyId: string[];
  districtId: string[];
  industryIds: string[];
  municipalityCode: string[];
  query: string;
  sizes: JobSize[];
  worktypeIds: string[];
  worktypeSubsetGroups: string[];
  worktypeSubsets: string[];
}

export interface JobsSavedFilterFragment_notificationSettings {
  __typename: "JobWatchlistNotificationSettings";
  id: string;
  email: JobWatchlistNotificationFrequency;
  push: JobWatchlistNotificationFrequency;
}

export interface JobsSavedFilterFragment {
  __typename: "JobWatchlist";
  id: string;
  title: string;
  filters: JobsSavedFilterFragment_filters;
  notificationSettings: JobsSavedFilterFragment_notificationSettings;
  readTime: number | null;
  latestJobTime: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: LatestMessageFragment
// ====================================================

export interface LatestMessageFragment_business {
  __typename: "Business";
  id: string;
}

export interface LatestMessageFragment_writer {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  isDeleted: boolean | null;
  isVerifiedWithSecureId: boolean;
}

export interface LatestMessageFragment_images_dimensions {
  __typename: "Dimension";
  width: number;
  height: number;
}

export interface LatestMessageFragment_images {
  __typename: "Image";
  bigUrl: string;
  bigPreviewUrl: string;
  description: string | null;
  dimensions: LatestMessageFragment_images_dimensions;
  id: string;
  mediumPreviewUrl: string;
  mediumUrl: string;
  name: string;
  size: number;
  smallPreviewUrl: string;
  smallUrl: string;
  url: string;
  fileType: string;
}

export interface LatestMessageFragment_files {
  __typename: "File";
  id: string;
  name: string;
  url: string;
  fileType: string;
}

export interface LatestMessageFragment {
  __typename: "Message";
  id: string;
  text: string | null;
  type: MessageType;
  business: LatestMessageFragment_business | null;
  status: MessageStatus;
  writer: LatestMessageFragment_writer;
  regTs: number;
  images: LatestMessageFragment_images[] | null;
  files: LatestMessageFragment_files[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: MessageFragment
// ====================================================

export interface MessageFragment_business {
  __typename: "Business";
  id: string;
}

export interface MessageFragment_writer {
  __typename: "User";
  id: string;
}

export interface MessageFragment_images_dimensions {
  __typename: "Dimension";
  width: number;
  height: number;
}

export interface MessageFragment_images {
  __typename: "Image";
  bigUrl: string;
  bigPreviewUrl: string;
  description: string | null;
  dimensions: MessageFragment_images_dimensions;
  id: string;
  mediumPreviewUrl: string;
  mediumUrl: string;
  name: string;
  size: number;
  smallPreviewUrl: string;
  smallUrl: string;
  url: string;
  fileType: string;
}

export interface MessageFragment_files {
  __typename: "File";
  id: string;
  name: string;
  url: string;
  fileType: string;
}

export interface MessageFragment {
  __typename: "Message";
  id: string;
  text: string | null;
  type: MessageType;
  business: MessageFragment_business | null;
  writer: MessageFragment_writer;
  status: MessageStatus;
  regTs: number;
  images: MessageFragment_images[] | null;
  files: MessageFragment_files[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: MetaCarFragment
// ====================================================

export interface MetaCarFragment_info {
  __typename: "CarInfo";
  VIMNumber: string | null;
  brand: string | null;
  licencePlate: string | null;
  registrationYear: string | null;
  model: string | null;
  lastRoadWorthinessTestDate: string | null;
  nextRoadWorthinessTestDate: string | null;
  shaftCount: number | null;
  width: number | null;
  co2Emissions: number | null;
  fuelType: FuelType | null;
  weight: number | null;
  factoryInstalledParticleFilter: boolean | null;
  vehicleGroup: string | null;
  length: number | null;
  towbarMaxLoad: number | null;
  maxRoofLoad: number | null;
  engineHectowatt: number | null;
  maxSeats: number | null;
  engineSize: number | null;
  trailerMaxWeightWithBreak: number | null;
  trailerMaxWeightWithoutBreak: number | null;
  maxTotalWeight: number | null;
  taxCode: string | null;
  hybrid: HybridCarType | null;
  hybridCatagory: HybridCarCatagory | null;
  seatsFront: number | null;
}

export interface MetaCarFragment {
  __typename: "Car";
  id: string;
  /**
   * regNr shouldn't be requested because we cannot hide it as it's a key. It should also be @inaccessible
   */
  regNr: string;
  info: MetaCarFragment_info | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: OpeningHoursFragment
// ====================================================

export interface OpeningHoursFragment_monday {
  __typename: "DailyOpeningHours";
  from: any;
  to: any;
}

export interface OpeningHoursFragment_tuesday {
  __typename: "DailyOpeningHours";
  from: any;
  to: any;
}

export interface OpeningHoursFragment_wednesday {
  __typename: "DailyOpeningHours";
  from: any;
  to: any;
}

export interface OpeningHoursFragment_thursday {
  __typename: "DailyOpeningHours";
  from: any;
  to: any;
}

export interface OpeningHoursFragment_friday {
  __typename: "DailyOpeningHours";
  from: any;
  to: any;
}

export interface OpeningHoursFragment_saturday {
  __typename: "DailyOpeningHours";
  from: any;
  to: any;
}

export interface OpeningHoursFragment_sunday {
  __typename: "DailyOpeningHours";
  from: any;
  to: any;
}

export interface OpeningHoursFragment {
  __typename: "WeeklyOpeningHours";
  monday: OpeningHoursFragment_monday | null;
  tuesday: OpeningHoursFragment_tuesday | null;
  wednesday: OpeningHoursFragment_wednesday | null;
  thursday: OpeningHoursFragment_thursday | null;
  friday: OpeningHoursFragment_friday | null;
  saturday: OpeningHoursFragment_saturday | null;
  sunday: OpeningHoursFragment_sunday | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: PageInfoFragment
// ====================================================

export interface PageInfoFragment {
  __typename: "PageInfo";
  /**
   *  cursor to the last element in list 
   */
  endCursor: string | null;
  /**
   *  whether there are items before the current cursor 
   */
  hasNextPage: boolean;
  /**
   *  whether there are items after the current cursor 
   */
  hasPreviousPage: boolean;
  /**
   *  maximum number of nodes to a single page 
   */
  pageSizeLimit: number | null;
  /**
   *  cursor to the first element in list  
   */
  startCursor: string | null;
  /**
   *  total number of entries available 
   */
  totalCount: number | null;
  /**
   *  total number of pages. might be removed due to inclusion of totalCount 
   */
  totalPages: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: SimpleAgreementContractBasicFragment
// ====================================================

export interface SimpleAgreementContractBasicFragment_statusFlags {
  __typename: "SimpleAgreementContractStatusFlags";
  initialized: boolean;
  acceptedConsumer: boolean;
  acceptedBusiness: boolean;
  rejectedConsumer: boolean;
  rejectedBusiness: boolean;
  invalidated: boolean;
}

export interface SimpleAgreementContractBasicFragment {
  __typename: "SimpleAgreementContract";
  id: string;
  type: ContractType;
  regTime: number;
  statusEnum: SimpleAgreementContractStatus;
  description: string | null;
  consumerEmail: string | null;
  consumerPhone: string | null;
  businessEmail: string | null;
  businessPhone: string | null;
  signedUserTime: number | null;
  signedCompanyTime: number | null;
  /**
   *  Simple agreement only contains one price. This can also be hourly work rates, which doesn't equal to what we regard as contract amount. 
   */
  priceType: ContractPriceType;
  price: number | null;
  startDate: number | null;
  endDate: number | null;
  statusFlags: SimpleAgreementContractBasicFragment_statusFlags;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: SimpleAgreementContractFragment
// ====================================================

export interface SimpleAgreementContractFragment_statusFlags {
  __typename: "SimpleAgreementContractStatusFlags";
  initialized: boolean;
  acceptedConsumer: boolean;
  acceptedBusiness: boolean;
  rejectedConsumer: boolean;
  rejectedBusiness: boolean;
  invalidated: boolean;
}

export interface SimpleAgreementContractFragment_job_address {
  __typename: "Address";
  id: string;
  street: string | null;
  zip: string | null;
  city: string | null;
}

export interface SimpleAgreementContractFragment_job_owner {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  isDeleted: boolean | null;
  isVerifiedWithSecureId: boolean;
}

export interface SimpleAgreementContractFragment_job_contactInfo {
  __typename: "JobContactInfo";
  phone: string | null;
  email: string | null;
}

export interface SimpleAgreementContractFragment_job {
  __typename: "Job";
  /**
   *  job ID 
   */
  id: string;
  /**
   *  Main title of a job 
   */
  title: string;
  /**
   *  Exact address of job. Available to business after job has been answered. Use postal address until then. 
   */
  address: SimpleAgreementContractFragment_job_address | null;
  /**
   *  Doffin jobs do not have a registered user in our system. Thus the field is optional. 
   */
  owner: SimpleAgreementContractFragment_job_owner | null;
  contactInfo: SimpleAgreementContractFragment_job_contactInfo | null;
}

export interface SimpleAgreementContractFragment_company {
  __typename: "Business";
  id: string;
  name: string;
}

export interface SimpleAgreementContractFragment_user {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  isDeleted: boolean | null;
  isVerifiedWithSecureId: boolean;
}

export interface SimpleAgreementContractFragment {
  __typename: "SimpleAgreementContract";
  id: string;
  type: ContractType;
  regTime: number;
  statusEnum: SimpleAgreementContractStatus;
  description: string | null;
  consumerEmail: string | null;
  consumerPhone: string | null;
  businessEmail: string | null;
  businessPhone: string | null;
  signedUserTime: number | null;
  signedCompanyTime: number | null;
  /**
   *  Simple agreement only contains one price. This can also be hourly work rates, which doesn't equal to what we regard as contract amount. 
   */
  priceType: ContractPriceType;
  price: number | null;
  startDate: number | null;
  endDate: number | null;
  statusFlags: SimpleAgreementContractFragment_statusFlags;
  job: SimpleAgreementContractFragment_job;
  company: SimpleAgreementContractFragment_company;
  user: SimpleAgreementContractFragment_user;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: SocialMediaFragment
// ====================================================

export interface SocialMediaFragment {
  __typename: "BusinessSocialMedia";
  facebook: string | null;
  id: string;
  instagram: string | null;
  tiktok: string | null;
  website: string | null;
  youtube: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: UserFragment
// ====================================================

export interface UserFragment_operatingAs {
  __typename: "Business";
  id: string;
}

export interface UserFragment {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  phone: string | null;
  operatingAs: UserFragment_operatingAs | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ERROR_FRAGMENT
// ====================================================

export interface ERROR_FRAGMENT {
  __typename: "AccessDeniedError" | "AddBusinessEmployeeInputError" | "AddBusinessEmployeeNoAccessError" | "AddExpoPushNotificationTokenAlreadyExistsError" | "AddPushNotificationTokenAlreadyExistsError" | "AddressInputError" | "AnswerJobAccessError" | "AnswerJobInputError" | "BasicInputError" | "BusinessAddressInputError" | "BusinessInputError" | "BusinessNotFoundError" | "ChatAccessDeniedError" | "ChatInputError" | "ChatListFilterError" | "ChatNotFoundError" | "CompanyAlreadyExistsError" | "CreateJobFolderInputError" | "EditJobFolderInputError" | "EvaluationInputError" | "EvaluationListInputError" | "EvaluationNotFoundError" | "FailedToSendWelcomeEmailError" | "ForgotUsernameInputError" | "GenericJobMetaInputError" | "ImageUploadInputError" | "JobAccessDeniedError" | "JobAccessInputError" | "JobContactPreferenceInputError" | "JobCustomerTypeInputError" | "JobIdTranslatedNotFoundError" | "JobNotFoundError" | "LoginAlreadyLoggedInError" | "LoginCompanyError" | "LoginCompanyNotActiveError" | "LoginCredentialsError" | "LoginInputError" | "LoginRequiredError" | "LoginRoleUnavailableToUserError" | "LoginRoleUnsupportedInContextError" | "LoginUserNotApartOfCompanyError" | "LoginWithEmailInputError" | "LoginWithTokenInputError" | "LoginWithVerificationCodeInputError" | "OpeningHoursInputError" | "PageOutOfRangeError" | "PageSelectInputError" | "PostalAddressNotFoundError" | "PostJobDataInputError" | "PostJobMetaInputError" | "PostJobOwnerInputError" | "RegisterTrialCompanyInputError" | "ReplyToEvaluationInputError" | "RequestedStartDateInputError" | "SendMessageAccessDeniedError" | "SendMessageInputError" | "SubmitJobInputError" | "TicketNotFoundError" | "TimeIntervalInputError" | "UpdateBusinessEmployeeInputError" | "UpdateBusinessEmployeeNoAccessError" | "UpdateBusinessInputError" | "UpdateBusinessProfileInfoInputError" | "UserDisabledError" | "UserNotFoundError";
  message: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AnswerAccessCode {
  ALLOWED = "ALLOWED",
  ANSWER_DENIED_COMPANY_NOT_IN_RESTRICTION_LIST = "ANSWER_DENIED_COMPANY_NOT_IN_RESTRICTION_LIST",
  DENIED_ALREADY_ANSWERED = "DENIED_ALREADY_ANSWERED",
  DENIED_ANSWERING_OWN_JOB = "DENIED_ANSWERING_OWN_JOB",
  DENIED_BIG_JOB_NOT_ACCESSIBLE = "DENIED_BIG_JOB_NOT_ACCESSIBLE",
  DENIED_BLOCKED_BY_OWNER = "DENIED_BLOCKED_BY_OWNER",
  DENIED_BUSINESS_CHOSEN = "DENIED_BUSINESS_CHOSEN",
  DENIED_COMPANY_LACKS_FREE_ANSWERS = "DENIED_COMPANY_LACKS_FREE_ANSWERS",
  DENIED_COMPANY_LACKS_PRODUCT = "DENIED_COMPANY_LACKS_PRODUCT",
  DENIED_COMPANY_LACKS_SUBSCRIPTION = "DENIED_COMPANY_LACKS_SUBSCRIPTION",
  DENIED_COMPANY_NOT_ACCESSIBLE = "DENIED_COMPANY_NOT_ACCESSIBLE",
  DENIED_DEMO_BUSINESS = "DENIED_DEMO_BUSINESS",
  DENIED_DOFFIN = "DENIED_DOFFIN",
  DENIED_FREE_ANSWER_SUSPENDED = "DENIED_FREE_ANSWER_SUSPENDED",
  DENIED_INSUFFICIENT_FUNDS = "DENIED_INSUFFICIENT_FUNDS",
  DENIED_JOB_AWAITING_APPROVAL = "DENIED_JOB_AWAITING_APPROVAL",
  DENIED_JOB_CLOSED = "DENIED_JOB_CLOSED",
  DENIED_JOB_EXPIRED = "DENIED_JOB_EXPIRED",
  DENIED_JOB_NOT_FOUND = "DENIED_JOB_NOT_FOUND",
  DENIED_JOB_OTHER = "DENIED_JOB_OTHER",
  DENIED_JOB_PAUSED = "DENIED_JOB_PAUSED",
  DENIED_JOB_UNAPPROVED = "DENIED_JOB_UNAPPROVED",
  DENIED_JOB_UNDER_REGISTRATION = "DENIED_JOB_UNDER_REGISTRATION",
  DENIED_LACKING_APPROVAL = "DENIED_LACKING_APPROVAL",
  DENIED_LACKING_CERTIFICATION = "DENIED_LACKING_CERTIFICATION",
  DENIED_LACKING_SUBTYPES = "DENIED_LACKING_SUBTYPES",
  DENIED_NOT_TEST_JOB = "DENIED_NOT_TEST_JOB",
  DENIED_NO_ACCESS = "DENIED_NO_ACCESS",
  DENIED_OTHER_TAG = "DENIED_OTHER_TAG",
  DENIED_PLUS_JOB = "DENIED_PLUS_JOB",
  DENIED_SELF_REGISTERED = "DENIED_SELF_REGISTERED",
  DENIED_TECHNICAL_ISSUE = "DENIED_TECHNICAL_ISSUE",
  DENIED_UNPAID_SUBSCRIPTION = "DENIED_UNPAID_SUBSCRIPTION",
  DENIED_USER_BLOCKED = "DENIED_USER_BLOCKED",
  DENIED_USER_DIFFERENT_JOB_TYPE = "DENIED_USER_DIFFERENT_JOB_TYPE",
  DENIED_USER_DIFFERENT_PERSON = "DENIED_USER_DIFFERENT_PERSON",
  DENIED_USER_NOT_VERIFIED = "DENIED_USER_NOT_VERIFIED",
  DENIED_USER_TIMELIMIT = "DENIED_USER_TIMELIMIT",
  DENIED_WRONG_AREA = "DENIED_WRONG_AREA",
}

export enum BoligmappaAssociationBuildingType {
  BUILDING = "BUILDING",
  PROPERTY = "PROPERTY",
}

export enum BoligmappaIntegrationStateEnum {
  AUTHORIZATION_REVOKED = "AUTHORIZATION_REVOKED",
  AUTHORIZED = "AUTHORIZED",
  NOT_AUTHORIZED = "NOT_AUTHORIZED",
}

export enum ChatListType {
  ACTIVE = "ACTIVE",
  ALL = "ALL",
  CONTRACT = "CONTRACT",
  INACTIVE = "INACTIVE",
  UNREAD = "UNREAD",
  WON = "WON",
}

export enum CompanySettingsJobAccess {
  BLOCKED = "BLOCKED",
  FREE_ONLY = "FREE_ONLY",
  FULL_ACCESS = "FULL_ACCESS",
  ORDER_DECIDES = "ORDER_DECIDES",
}

export enum ContractList {
  ALL = "ALL",
}

export enum ContractPriceType {
  FIXED = "FIXED",
  HOUR_COMPUTATION = "HOUR_COMPUTATION",
  NO_COMPUTATION = "NO_COMPUTATION",
  OTHER = "OTHER",
}

export enum ContractType {
  IN_HOUSE_CONTRACT = "IN_HOUSE_CONTRACT",
  OTHER = "OTHER",
  SIMPLE_AGREEMENT = "SIMPLE_AGREEMENT",
  SWIFTCOURT_CONTRACT = "SWIFTCOURT_CONTRACT",
}

export enum EvaluationFocus {
  COMMUNICATION = "COMMUNICATION",
  PRICE = "PRICE",
  PROFESSIONALISM = "PROFESSIONALISM",
  QUALITY = "QUALITY",
  RELIABILITY = "RELIABILITY",
  TIDINESS = "TIDINESS",
}

export enum FuelType {
  DIESEL = "DIESEL",
  ELECTRICITY = "ELECTRICITY",
  GAS = "GAS",
  OTHER = "OTHER",
}

export enum HybridCarCatagory {
  CHARGABLE = "CHARGABLE",
  NONE = "NONE",
  NOT_CHARGABLE = "NOT_CHARGABLE",
  OTHER = "OTHER",
}

export enum HybridCarType {
  NO = "NO",
  OTHER = "OTHER",
  YES = "YES",
}

export enum InvoiceLatestAttempt {
  ACQUIRER_BAD_GATEWAY = "ACQUIRER_BAD_GATEWAY",
  ACQUIRER_ERROR = "ACQUIRER_ERROR",
  ACQUIRER_GATEWAY_TIMEOUT = "ACQUIRER_GATEWAY_TIMEOUT",
  AGREEMENT_DELETED = "AGREEMENT_DELETED",
  AGREEMENT_NOT_FOUND = "AGREEMENT_NOT_FOUND",
  AGREEMENT_NOT_VERIFIED = "AGREEMENT_NOT_VERIFIED",
  BAD_GATEWAY = "BAD_GATEWAY",
  BAD_REQUEST = "BAD_REQUEST",
  CANCELLED = "CANCELLED",
  CARD_BLACKLISTED = "CARD_BLACKLISTED",
  CARD_BLACKLISTED_BY_ACQUIRER = "CARD_BLACKLISTED_BY_ACQUIRER",
  CARD_DECLINED = "CARD_DECLINED",
  CARD_EXPIRED = "CARD_EXPIRED",
  CARD_EXPIRED_OR_WRONG_EXPIRE_DATE = "CARD_EXPIRED_OR_WRONG_EXPIRE_DATE",
  CARD_ISSUER_NOT_AVAILABLE = "CARD_ISSUER_NOT_AVAILABLE",
  CARD_LOST_OR_STOLEN = "CARD_LOST_OR_STOLEN",
  CARD_NOT_SUPPORTED = "CARD_NOT_SUPPORTED",
  CARD_STOLEN = "CARD_STOLEN",
  COMMUNICATION_FAILURE = "COMMUNICATION_FAILURE",
  CONFIGURATION_ERROR = "CONFIGURATION_ERROR",
  CONFIRMATION_FAILED = "CONFIRMATION_FAILED",
  CONNECTION_LOST = "CONNECTION_LOST",
  CREDITCARD_3DSECURE_DECLINED = "CREDITCARD_3DSECURE_DECLINED",
  CREDITCARD_3DSECURE_REQUIRED = "CREDITCARD_3DSECURE_REQUIRED",
  CREDITCARD_ACCOUNT_HOLDER_TYPE_REJECTED = "CREDITCARD_ACCOUNT_HOLDER_TYPE_REJECTED",
  CREDITCARD_AUTHENTICATION_STATUS_REJECTED = "CREDITCARD_AUTHENTICATION_STATUS_REJECTED",
  CREDITCARD_BRAND_DISABLED = "CREDITCARD_BRAND_DISABLED",
  CREDITCARD_FRAUD_DETECTED = "CREDITCARD_FRAUD_DETECTED",
  CREDITCARD_TYPE_REJECTED = "CREDITCARD_TYPE_REJECTED",
  CREDITED = "CREDITED",
  CURRENCY_NOT_SUPPORTED = "CURRENCY_NOT_SUPPORTED",
  DEBT_COLLECTION = "DEBT_COLLECTION",
  DECLINED_DURING_3DSECURE = "DECLINED_DURING_3DSECURE",
  DECLINED_USING_3DSECURE = "DECLINED_USING_3DSECURE",
  FAILED = "FAILED",
  FORBIDDEN = "FORBIDDEN",
  FRAUD_DETECTED = "FRAUD_DETECTED",
  GENERATED = "GENERATED",
  GENERIC_ERROR = "GENERIC_ERROR",
  HOST_OFFLINE = "HOST_OFFLINE",
  INCORRECT_CARD_DETAILS = "INCORRECT_CARD_DETAILS",
  INPUT_ERROR = "INPUT_ERROR",
  INSUFFICIENT_FUNDS = "INSUFFICIENT_FUNDS",
  INTERNAL_SERVER_ERROR = "INTERNAL_SERVER_ERROR",
  INVALID_AMOUNT = "INVALID_AMOUNT",
  INVOICE_DOWNLOADED = "INVOICE_DOWNLOADED",
  NOT_FOUND = "NOT_FOUND",
  NO_CHANGE = "NO_CHANGE",
  PAID = "PAID",
  PARTIALLY_PAID = "PARTIALLY_PAID",
  PAYMENT_TOKEN_ERROR = "PAYMENT_TOKEN_ERROR",
  POSSIBLE_DUPLICATE_TRANSACTION = "POSSIBLE_DUPLICATE_TRANSACTION",
  POSSIBLE_FRAUD = "POSSIBLE_FRAUD",
  PROCESSING = "PROCESSING",
  REJECTED_BY_CARD_ISSUER = "REJECTED_BY_CARD_ISSUER",
  REMINDER_SENT = "REMINDER_SENT",
  REQUIRES_ACTION = "REQUIRES_ACTION",
  REQUIRES_CAPTURE = "REQUIRES_CAPTURE",
  REQUIRES_PAYMENT_METHOD = "REQUIRES_PAYMENT_METHOD",
  SECOND_REMINDER_SENT = "SECOND_REMINDER_SENT",
  THREE_D_SECURE_BAD_GATEWAY = "THREE_D_SECURE_BAD_GATEWAY",
  THREE_D_SECURE_NOT_WORKING = "THREE_D_SECURE_NOT_WORKING",
  THREE_D_SECURE_USER_CANCELLED = "THREE_D_SECURE_USER_CANCELLED",
  UNKNOWN = "UNKNOWN",
  UNKNOWN_ERROR = "UNKNOWN_ERROR",
}

export enum JobAccessDeniedCode {
  AWAITING_APPROVAL = "AWAITING_APPROVAL",
  OTHER = "OTHER",
  PROTECTED = "PROTECTED",
  UNAPPROVED = "UNAPPROVED",
  WITHDRAWN_BY_USER = "WITHDRAWN_BY_USER",
}

export enum JobContactPreference {
  SITE_AND_PHONE = "SITE_AND_PHONE",
  SITE_ONLY = "SITE_ONLY",
}

export enum JobCustomerType {
  COMPANY = "COMPANY",
  CONSUMER = "CONSUMER",
  COOPERATIVE = "COOPERATIVE",
  UNION = "UNION",
}

/**
 *  These enums are defined for front end use 
 */
export enum JobList {
  ANSWERED = "ANSWERED",
  BIG = "BIG",
  DELETED = "DELETED",
  DIRECT = "DIRECT",
  FAVOURITE = "FAVOURITE",
  FOLDERS = "FOLDERS",
  FREE = "FREE",
  OPEN = "OPEN",
  WON = "WON",
}

export enum JobSize {
  BIG = "BIG",
  HUGE = "HUGE",
  NORMAL = "NORMAL",
  SMALL = "SMALL",
  UNDEFINED = "UNDEFINED",
}

export enum JobWatchlistNotificationFrequency {
  DAILY = "DAILY",
  IMMEDIATE = "IMMEDIATE",
  IMMEDIATE_AND_DAILY = "IMMEDIATE_AND_DAILY",
  NEVER = "NEVER",
}

export enum LatestPaymentAttemptResultCode {
  BLACKLISTED = "BLACKLISTED",
  CANCELLED = "CANCELLED",
  CARD_DECLINED = "CARD_DECLINED",
  CARD_EXPIRED = "CARD_EXPIRED",
  CARD_ISSUER_ERROR = "CARD_ISSUER_ERROR",
  CARD_LOST_OR_STOLEN = "CARD_LOST_OR_STOLEN",
  CARD_NOT_SUPPORTED = "CARD_NOT_SUPPORTED",
  CREDITED = "CREDITED",
  CURRENCY_NOT_SUPPORTED = "CURRENCY_NOT_SUPPORTED",
  FRAUD_DETECTED = "FRAUD_DETECTED",
  GENERIC_ERROR = "GENERIC_ERROR",
  INCORRECT_CARD_DETAILS = "INCORRECT_CARD_DETAILS",
  INSUFFICIENT_FUNDS = "INSUFFICIENT_FUNDS",
  PAID = "PAID",
  PARTIALLY_PAID = "PARTIALLY_PAID",
}

export enum MessageStatus {
  FAILED = "FAILED",
  READ = "READ",
  SENDING = "SENDING",
  SENT = "SENT",
}

export enum MessageType {
  ATTACHMENT = "ATTACHMENT",
  SYSTEM = "SYSTEM",
  TEXT = "TEXT",
}

export enum NotificationDestinationType {
  EMAIL = "EMAIL",
  PUSH = "PUSH",
  SMS = "SMS",
  WEB = "WEB",
}

export enum PaymentType {
  CARD = "CARD",
  FREE = "FREE",
  INVOICE = "INVOICE",
}

export enum ProductTemplateSlug {
  ADVERTISEMENT = "ADVERTISEMENT",
  BOOST = "BOOST",
  EVALUATION = "EVALUATION",
  HOMEPAGE = "HOMEPAGE",
  MITTANBUD_XL = "MITTANBUD_XL",
  PRO = "PRO",
  PROFILE_PAGE = "PROFILE_PAGE",
  SEARCH = "SEARCH",
  SUBSCRIPTION = "SUBSCRIPTION",
}

export enum PushNotificationAppName {
  ByggetilbudMatchBusiness = "ByggetilbudMatchBusiness",
  ByggetilbudMatchConsumer = "ByggetilbudMatchConsumer",
  MittanbudBusiness = "MittanbudBusiness",
  MittanbudConsumer = "MittanbudConsumer",
  RemppatoriBusiness = "RemppatoriBusiness",
  RemppatoriConsumer = "RemppatoriConsumer",
  ServicefinderBusiness = "ServicefinderBusiness",
  ServicefinderConsumer = "ServicefinderConsumer",
}

export enum PushNotificationDeviceType {
  ANDROID = "ANDROID",
  IOS = "IOS",
}

export enum PushNotificationTokenType {
  EXPO = "EXPO",
  FCM = "FCM",
}

export enum RotateImageDegrees {
  HUNDRED_EIGHTY = "HUNDRED_EIGHTY",
  NINETY = "NINETY",
  TWO_HUNDRED_SEVENTY = "TWO_HUNDRED_SEVENTY",
}

export enum RotateImageDirection {
  LEFT = "LEFT",
  RIGHT = "RIGHT",
}

export enum SelfPurchaseTriggerSlug {
  SELF_PURCHASE_CANCEL = "SELF_PURCHASE_CANCEL",
  SELF_PURCHASE_OPEN = "SELF_PURCHASE_OPEN",
  SELF_PURCHASE_PURCHASE = "SELF_PURCHASE_PURCHASE",
  SELF_PURCHASE_PURCHASE_CONFIRM = "SELF_PURCHASE_PURCHASE_CONFIRM",
  SELF_PURCHASE_PURCHASE_FAIL = "SELF_PURCHASE_PURCHASE_FAIL",
  SELF_PURCHASE_PURCHASE_SUCCESS = "SELF_PURCHASE_PURCHASE_SUCCESS",
}

export enum SelfServiceProductName {
  BOOST_CREDITS_10 = "BOOST_CREDITS_10",
  BOOST_CREDITS_15 = "BOOST_CREDITS_15",
  BOOST_CREDITS_5 = "BOOST_CREDITS_5",
  SUBSCRIPTION_LARGE = "SUBSCRIPTION_LARGE",
  SUBSCRIPTION_MEDIUM = "SUBSCRIPTION_MEDIUM",
  SUBSCRIPTION_SMALL = "SUBSCRIPTION_SMALL",
}

export enum SelfServiceSubscriptionName {
  SUBSCRIPTION_BIG = "SUBSCRIPTION_BIG",
  SUBSCRIPTION_MEDIUM = "SUBSCRIPTION_MEDIUM",
  SUBSCRIPTION_SMALL = "SUBSCRIPTION_SMALL",
}

export enum SimpleAgreementContractStatus {
  CREATED = "CREATED",
  SEE_FLAGS = "SEE_FLAGS",
  SHARED = "SHARED",
  SIGNED_BOTH = "SIGNED_BOTH",
  SIGNED_BUSINESS = "SIGNED_BUSINESS",
  SIGNED_CONSUMER = "SIGNED_CONSUMER",
  STOPPED = "STOPPED",
}

export enum SimpleAgreementPriceType {
  FIXED = "FIXED",
  HOUR_COMPUTATION = "HOUR_COMPUTATION",
  NO_COMPUTATION = "NO_COMPUTATION",
  OTHER = "OTHER",
}

export enum SortDirection {
  ASC = "ASC",
  DESC = "DESC",
}

export enum StartAlternative {
  AFTER_DATE = "AFTER_DATE",
  BEFORE_DATE = "BEFORE_DATE",
  NOW = "NOW",
  ON_DATE = "ON_DATE",
  WHENEVER = "WHENEVER",
}

export enum TicketEntryType {
  APP = "APP",
  CHAT = "CHAT",
  EMAIL = "EMAIL",
  HELP_PAGE = "HELP_PAGE",
  PHONE = "PHONE",
  SMS = "SMS",
  WEB = "WEB",
}

export enum TicketRelConst {
  EMAIL = "EMAIL",
  EVALUATION = "EVALUATION",
  INVOICE = "INVOICE",
  JOB = "JOB",
  MASTER_ID = "MASTER_ID",
  MITTANBUD = "MITTANBUD",
  ORDER = "ORDER",
  ORDER_LINE = "ORDER_LINE",
  PAYMENTS = "PAYMENTS",
  PAYMENT_DETAILS = "PAYMENT_DETAILS",
  PRODUCT = "PRODUCT",
  SMS = "SMS",
  SYSTEM = "SYSTEM",
  SYSTEM_USER_MASTER_ID = "SYSTEM_USER_MASTER_ID",
  TEMPLATE_PRODUCT = "TEMPLATE_PRODUCT",
}

export enum TicketRelType {
  ABOUT = "ABOUT",
  RECEIVER = "RECEIVER",
  SENDER = "SENDER",
}

export enum TicketStatus {
  FAILED = "FAILED",
  ON_HOLD = "ON_HOLD",
  OPEN = "OPEN",
  REOPENED = "REOPENED",
  RESOLVED = "RESOLVED",
}

export enum TicketType {
  BUG_REPORT = "BUG_REPORT",
  COMPANY_LEAD = "COMPANY_LEAD",
  COMPLAINT = "COMPLAINT",
  FEEDBACK = "FEEDBACK",
  GDPR = "GDPR",
  JOB_REPORT = "JOB_REPORT",
  SUPPORT = "SUPPORT",
  WANTS_CONTACT = "WANTS_CONTACT",
  WANTS_PRODUCT = "WANTS_PRODUCT",
}

export enum UserRole {
  ADMIN = "ADMIN",
  CONSUMER = "CONSUMER",
  EMPLOYEE = "EMPLOYEE",
}

export interface AddBusinessEmployeeInput {
  businessId: string;
  firstName: string;
  lastName: string;
  position?: string | null;
  phoneNr?: string | null;
  email?: string | null;
  image?: any | null;
}

export interface AddPushNotificationTokenInput {
  token: string;
  tokenType: PushNotificationTokenType;
  appName: PushNotificationAppName;
  deviceType?: PushNotificationDeviceType | null;
}

export interface AddressInput {
  id?: string | null;
  street: string;
  zip: string;
}

export interface AuthorizeBoligmappaIntegrationInput {
  code: string;
  redirectUrl: string;
}

export interface BoligmappaAddressDetailsInput {
  buildingId: string;
  buildingPage?: number | null;
  propertyCursor?: string | null;
  pageSize?: number | null;
}

export interface BoligmappaAssociateAddressInput {
  jobId: string;
  addressId: string;
  associations: BoligmappaAssociationAddress[];
}

export interface BoligmappaAssociationAddress {
  id: string;
  propertyType: string;
  unitNumber?: string | null;
}

export interface BoligmappaCreateAccountInput {
  organizationNumber: string;
  organizationName: string;
  email: string;
  phoneNumber: string;
  firstName: string;
  lastName: string;
  acceptTermsAndConditions: string;
  professionId: string;
  numberOfEmployees: string;
  password: string;
  userName: string;
}

export interface BoligmappaDownloadFileInput {
  fileId: string;
  boligmappaNumber: string;
}

export interface BoligmappaFileElementInput {
  fileName: string;
  title: string;
  professionType: BoligmappaIdObject;
  documentType: BoligmappaIdObject;
  uniqueId: string;
}

export interface BoligmappaIdObject {
  id: string;
}

export interface BoligmappaLoginInput {
  redirectUrl: string;
}

export interface BoligmappaRemoveAssociationInput {
  jobId: string;
  boligmappaId: string;
}

export interface BusinessEvaluationsInput {
  businessId: string;
  paging?: PageSelect | null;
}

export interface CompanyWantsContactAboutProductInput {
  productTemplateSlug: ProductTemplateSlug;
  url?: string | null;
  buttonText?: string | null;
  jobId?: string | null;
}

export interface ConfirmBoligmappaFileUploadInput {
  fileIds: string[];
}

export interface CreateAnswerTemplateInput {
  title: string;
  text: string;
}

export interface CreateBusinessAlbumInput {
  name: string;
}

export interface CreateJobWatchlistInput {
  title: string;
  query: string;
  sizes: JobSize[];
  districtId: string[];
  municipalityCode: string[];
  countyId: string[];
  industryIds: string[];
  worktypeIds: string[];
  worktypeSubsetGroups: string[];
  worktypeSubsets: string[];
  notificationSettings?: JobWatchlistNotificationSettingsInput | null;
  clipFree?: boolean | null;
}

export interface CreateSimpleAgreementContractInput {
  jobId: string;
  priceType: SimpleAgreementPriceType;
  description?: string | null;
  businessId?: string | null;
  price?: number | null;
  phone?: string | null;
  sign?: boolean | null;
  startDate?: number | null;
  endDate?: number | null;
  businessEmail?: string | null;
  businessPhone?: string | null;
  consumerEmail?: string | null;
  consumerPhone?: string | null;
}

export interface DeleteAnswerTemplateInput {
  id: string;
}

export interface DeleteBusinessAlbumImageInput {
  albumId: string;
  albumImageId: string;
}

export interface DeleteBusinessAlbumInput {
  albumId: string;
}

export interface EditSimpleAgreementContractInput {
  jobId: string;
  businessId?: string | null;
  priceType?: SimpleAgreementPriceType | null;
  description?: string | null;
  price?: number | null;
  startDate?: number | null;
  endDate?: number | null;
  sign?: boolean | null;
  businessEmail?: string | null;
  businessPhone?: string | null;
  consumerEmail?: string | null;
  consumerPhone?: string | null;
}

export interface ImageUploadInput {
  file: any;
}

export interface InitiateBoligmappaFileUploadInput {
  tenderId: string;
  boligmappaNumber: string;
  files: BoligmappaFileElementInput[];
}

export interface InitiateSelfServiceSubscriptionPaymentConsentInput {
  formId: string;
  iam: boolean;
  acceptBindingContract: boolean;
  readTos: boolean;
}

export interface InitiateSelfServiceSubscriptionPaymentInput {
  consent: InitiateSelfServiceSubscriptionPaymentConsentInput;
  productVariationSlug: SelfServiceSubscriptionName;
  verificationKey: string;
  returnUrl: string;
}

export interface JobWatchlistNotificationSettingsInput {
  email: JobWatchlistNotificationFrequency;
  push: JobWatchlistNotificationFrequency;
}

/**
 * Days without TimeInterval, will be considered as closed
 */
export interface OpeningHoursInput {
  monday?: TimeInterval | null;
  tuesday?: TimeInterval | null;
  wednesday?: TimeInterval | null;
  thursday?: TimeInterval | null;
  friday?: TimeInterval | null;
  saturday?: TimeInterval | null;
  sunday?: TimeInterval | null;
}

export interface PageSelect {
  order?: string | null;
  page?: number | null;
  from?: number | null;
  size?: number | null;
}

export interface ReceiptListInput {
  count?: number | null;
  from?: number | null;
}

export interface RejectOneToOneJobInput {
  jobId: string;
  reason?: string | null;
}

export interface RejectSimpleAgreementContractInput {
  agreementId: string;
}

export interface RelationsInput {
  relId: string;
  relConst: TicketRelConst;
  relType: TicketRelType;
}

export interface ReplyToEvaluationInput {
  evaluationId: string;
  description: string;
}

export interface RequestPasswordResetInput {
  email: string;
  redirectDomain?: string | null;
}

export interface RotateBusinessAlbumImageInput {
  direction?: RotateImageDirection | null;
  degrees?: RotateImageDegrees | null;
}

export interface SearchBoligmappaAddressInput {
  address: string;
  pageSize?: number | null;
}

export interface SecureIdAuthenticationUrlCallbackUrlsInput {
  success: string;
  error: string;
  abort: string;
}

export interface SecureIdAuthenticationUrlInput {
  callbackUrls: SecureIdAuthenticationUrlCallbackUrlsInput;
  businessId?: string | null;
}

export interface SelfServiceOrderInput {
  returnUrl: string;
  cardId?: string | null;
  productName?: SelfServiceProductName | null;
}

export interface SignSimpleAgreementContractInput {
  agreementId: string;
}

export interface SimpleAgreementContractInput {
  id: string;
}

export interface SimpleAgreementContractListInput {
  page?: number | null;
  count?: number | null;
  from?: number | null;
  list: ContractList;
}

export interface TimeInterval {
  from: string;
  to: string;
}

export interface ToggleDistrictsInput {
  districtId: string;
  enable: boolean;
}

export interface ToggleMunicipalitiesInput {
  municipalityId: string;
  enable: boolean;
}

export interface ToggleNotificationDestinationInput {
  slug: string;
  destinationType: NotificationDestinationType;
  enabled?: boolean | null;
}

export interface TriggerEventSelfServicePaymentInput {
  eventSlug: SelfPurchaseTriggerSlug;
  meta: any;
  product: ProductTemplateSlug;
}

export interface UpdateAnswerTemplateInput {
  id: string;
  title?: string | null;
  text?: string | null;
}

export interface UpdateBusinessAdressesInput {
  billing?: AddressInput | null;
  visit?: AddressInput | null;
  postal?: AddressInput | null;
}

export interface UpdateBusinessAlbumImageInput {
  albumId: string;
  albumImageId: string;
  description?: string | null;
  sort?: number | null;
  rotate?: RotateBusinessAlbumImageInput | null;
}

export interface UpdateBusinessAlbumInput {
  albumId: string;
  name?: string | null;
}

export interface UpdateBusinessEmployeeInput {
  employeeId: string;
  position?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  phoneNr?: string | null;
  email?: string | null;
  image?: any | null;
}

export interface UpdateBusinessInput {
  name?: string | null;
  phone?: string | null;
  mobile?: string | null;
  email?: string | null;
  description?: string | null;
  website?: string | null;
  facebook?: string | null;
  address?: UpdateBusinessAdressesInput | null;
}

export interface UpdateBusinessProfileInfoInput {
  businessId: string;
  phone?: string | null;
  address?: AddressInput | null;
  facebook?: string | null;
  instagram?: string | null;
  youtube?: string | null;
  tiktok?: string | null;
  title?: string | null;
  description?: string | null;
  openingHours?: OpeningHoursInput | null;
  headerImage?: any | null;
  profileVideo?: any | null;
  website?: string | null;
}

export interface UpdateJobWatchlistInput {
  id: string;
  title: string;
  query: string;
  sizes: JobSize[];
  districtId: string[];
  municipalityCode: string[];
  countyId: string[];
  industryIds: string[];
  worktypeIds: string[];
  worktypeSubsetGroups: string[];
  worktypeSubsets: string[];
  notificationSettings?: JobWatchlistNotificationSettingsInput | null;
  clipFree?: boolean | null;
}

export interface UploadBusinessAlbumImage {
  image: any;
  description?: string | null;
  rotate?: RotateBusinessAlbumImageInput | null;
}

export interface UploadBusinessAlbumImagesInput {
  albumId: string;
  images: UploadBusinessAlbumImage[];
}

//==============================================================
// END Enums and Input Objects
//==============================================================
