import { FC } from 'react';

import { useFlagEnabled } from '@components/base/CheckFlag';
import { JobDataItem } from '@components/layout/Job/JobDataItem';
import FeatureFlags from '@internals/business-shared/src/utils/constants/FeatureFlags';
import { getValidRegFormQuestions } from '@internals/business-shared/src/utils/jobTraits';
import { JobTraitsQueryData } from '@internals/business-shared/src/utils/query/JobTraits/JobTraits';
import { useTranslation } from 'react-i18next';

interface JobTraitsProps {
  traits: JobTraitsQueryData;
}

export const JobTraits: FC<JobTraitsProps> = ({ traits }) => {
  const { t } = useTranslation();
  const { estimatedSquareMeters, questionsRegForm } = traits;
  const reqFormAnswersEnabled = useFlagEnabled(
    FeatureFlags.BizRegFromAnswersJobCard
  );

  return (
    <>
      {!!estimatedSquareMeters && (
        <JobDataItem
          testId="job-traits-estimatedSquareMeters"
          title={t('job.item.traits.estimatedSquareMeters.title')}
          text={t('general.metrics.inSquareMeters', {
            value: estimatedSquareMeters,
          })}
        />
      )}
      {reqFormAnswersEnabled &&
        getValidRegFormQuestions(questionsRegForm).map((item) => (
          <JobDataItem
            key={item.question}
            title={item.question}
            text={item.answer}
          />
        ))}
    </>
  );
};
