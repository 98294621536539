import { useCallback, useMemo } from 'react';

import {
  FetchResult,
  MutationFunctionOptions,
  MutationResult,
  useMutation,
} from '@apollo/client';

import {
  markSavedFilterJobListAsReadOptimisticResponse,
  markSavedFilterJobListAsReadUpdate,
} from '../../cache/updates/markSavedFilterJobListAsRead';
import { APOLLO_ERROR_HANDLING_HIDE_TOAST } from '../../utils/constants/apollo';
import {
  MARK_SAVED_FILTER_JOB_LIST_AS_READ,
  MarkSavedFilterJobListAsReadPayload,
  MarkSavedFilterJobListAsReadVariables,
} from '../../utils/mutation/MarkSavedFilterJobListAsRead/MarkSavedFilterJobListAsReadMutation';

type useMarkSavedFilterJobListAsReadAction = (
  savedFilterId: string,
  options?: MutationFunctionOptions<
    MarkSavedFilterJobListAsReadPayload,
    MarkSavedFilterJobListAsReadVariables
  >
) => Promise<FetchResult<MarkSavedFilterJobListAsReadPayload>>;

export const useMarkSavedFilterJobListAsReadMutation = (
  mutationHookOptions?: MutationFunctionOptions<
    MarkSavedFilterJobListAsReadPayload,
    MarkSavedFilterJobListAsReadVariables
  >
): [
  useMarkSavedFilterJobListAsReadAction,
  MutationResult<MarkSavedFilterJobListAsReadPayload>
] => {
  const [markSavedFilterJobListAsRead, ...rest] = useMutation(
    MARK_SAVED_FILTER_JOB_LIST_AS_READ,
    {
      ...mutationHookOptions,
      context: {
        [APOLLO_ERROR_HANDLING_HIDE_TOAST]: true,
      },
    }
  );
  const mutationFn: useMarkSavedFilterJobListAsReadAction = useCallback(
    (savedFilterId, options?) => {
      return markSavedFilterJobListAsRead({
        variables: {
          id: savedFilterId,
        },
        optimisticResponse: markSavedFilterJobListAsReadOptimisticResponse,
        update: markSavedFilterJobListAsReadUpdate(savedFilterId),
        ...options,
      });
    },
    [markSavedFilterJobListAsRead]
  );

  return useMemo(() => [mutationFn, ...rest], [mutationFn, rest]);
};
