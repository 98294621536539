export enum ProductTimeUnit {
  Month = 'month',
  Year = 'year',
}

export enum ProductPackageStatus {
  Active = 'active',
  TestPeriod = 'test-period',
  Inactive = 'inactive',
}

export interface ProductPackagePrice {
  currency: string;
  cost: number;
  period: ProductTimeUnit;
}

export type ProductTimeDetails =
  | {
      type: 'renewal';
      date: ProductDate;
    }
  | {
      type: 'expiration';
      date: ProductDate;
    };

export interface ProductPackageDuration {
  value: number;
  unit: ProductTimeUnit;
}

export type ProductDate = number;

export interface BasicProduct {
  id: string;
  slug: string;
  name: string;
  status: ProductPackageStatus;
  startDate: ProductDate;
  expirationDate?: number;
}

export type ProductAddon = BasicProduct;

export interface PaidProduct extends BasicProduct {
  price: ProductPackagePrice;
}

export interface PaidAddon extends ProductAddon {
  price: ProductPackagePrice;
}

export interface JobSubscriptionProduct extends PaidProduct {
  numberOfCredits: number;
  numberOfFreeCredits: number;
  renewalDate: ProductDate;
}

export interface TrialJobSubscriptionProduct
  extends Omit<JobSubscriptionProduct, 'renewalDate'> {
  expirationDate: ProductDate;
}

export enum ActivePackageStatus {
  CompanyNotVerified = 'CompanyNotVerified',
  CompanyVerified = 'CompanyVerified',
  FreemiumActive = 'FreemiumActive',
  FreemiumExpired = 'FreemiumExpired',
  JobPackageActive = 'JobPackageActive',
}

export enum ProductVerificationFailReason {
  EvaluationCount = 'EvaluationCount',
  EvaluationScore = 'EvaluationScore',
  SubscriptionPeriod = 'SubscriptionPeriod',
}

export enum ProductVerificationStatus {
  Unsettled = 'Unsettled',
  InProgress = 'InProgress',
  Error = 'Error',
  Approved = 'Approved',
  Declined = 'Declined',
}

export type ProductVerification =
  | { status: ProductVerificationStatus.Unsettled }
  | { status: ProductVerificationStatus.InProgress }
  | { status: ProductVerificationStatus.Error }
  | { status: ProductVerificationStatus.Approved }
  | {
      status: ProductVerificationStatus.Declined;
      reason: ProductVerificationFailReason;
    };
