import { t } from '@internals/business-translations/src/i18n';

import JobListId from '../../utils/constants/jobListIds';
import { JobList } from '../../utils/generated/generated';
import { DefaultJobListFilter } from './types';

export const getDefaultJobListFiltersConfig = (): DefaultJobListFilter[] => [
  {
    type: 'default',
    id: JobListId.Open,
    listType: JobList.OPEN,
    label: t('job.list.type_OPEN'),
  },
  {
    type: 'default',
    id: JobListId.Direct,
    listType: JobList.DIRECT,
    label: t('job.list.type_DIRECT'),
  },
  {
    type: 'default',
    id: JobListId.Free,
    listType: JobList.FREE,
    label: t('job.list.type_FREE'),
  },
  {
    type: 'default',
    id: JobListId.Favourite,
    listType: JobList.FAVOURITE,
    label: t('job.list.type_FAVOURITE'),
  },
  {
    type: 'default',
    id: JobListId.Answered,
    listType: JobList.ANSWERED,
    label: t('job.list.type_ANSWERED'),
  },
  {
    type: 'default',
    id: JobListId.Won,
    listType: JobList.WON,
    label: t('job.list.type_WON'),
  },
  {
    type: 'default',
    id: JobListId.Deleted,
    listType: JobList.DELETED,
    label: t('job.list.type_DELETED'),
  },
];
