import { FC } from 'react';

import { FireIcon } from '@components/base/FireIcon/FireIcon';
import { getJobTagInfo } from '@components/base/JobLabel/jobLabelConfig';
import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import {
  DisplayedJobTagLabel,
  JobTagLabel,
} from '@internals/business-shared/src/utils/JobTags';
import { Div, hexToRgba, Tag } from '@schibsted-smb/fireball';
import {
  CustomVariantTagProps,
  StandardTagProps,
} from '@schibsted-smb/fireball/lib/components/elements/Tag';
import { t } from '@utils/i18n';
import { useTheme } from 'styled-components';

import { CustomTooltip } from '../CustomTooltip';
import IconContainer from './styled/IconContainer';
import TextContainer from './styled/TextContainer';

const MAX_EVALUATION_VALUE = 5;

interface BasicJobLabelProps {
  isGray?: boolean;
  isSmall?: boolean;
}

interface StandardJobLabelProps extends BasicJobLabelProps {
  labelName: DisplayedJobTagLabel;
  evaluationValue?: never;
  isGreenEvaluation?: never;
  folderName?: never;
  folderColor?: never;
}

interface EvaluationJobLabelProps extends BasicJobLabelProps {
  labelName: JobTagLabel.EVALUATION;
  evaluationValue: number;
  isGreenEvaluation?: boolean;
  folderName?: never;
  folderColor?: never;
}

interface VerifiedSecureIdJobLabelProps extends BasicJobLabelProps {
  labelName: JobTagLabel.VERIFIED_SECURE_ID;
  evaluationValue?: never;
  isGreenEvaluation?: never;
  folderName?: never;
  folderColor?: never;
}

interface FolderJobLabelProps extends BasicJobLabelProps {
  labelName: JobTagLabel.FOLDER;
  folderName: string;
  folderColor: string;
  evaluationValue?: never;
  isGreenEvaluation?: never;
}

export type JobLabelType =
  | StandardJobLabelProps
  | EvaluationJobLabelProps
  | FolderJobLabelProps
  | VerifiedSecureIdJobLabelProps;

const JobLabelComponent: FC<JobLabelType> = ({
  labelName,
  isGray,
  isSmall,
  evaluationValue,
  isGreenEvaluation,
  folderName,
  folderColor = '',
}) => {
  const themeContext = useTheme();
  const commonTagProps = {
    ml: 2,
    px: isSmall ? 1 : 3,
    size: 'small',
    display: 'flex',
    alignItems: 'center',
  };
  const labelSize = isSmall ? '12px' : '14px';

  if (labelName === JobTagLabel.VERIFIED_SECURE_ID) {
    return (
      <Tag
        variant="primary"
        data-testid={`job-label-component-${labelName}`}
        noBorder
        {...(commonTagProps as StandardTagProps)}
        size="small"
      >
        <IconContainer width={labelSize} height={labelSize} mx={0}>
          <FireIcon
            iconName={IconPickerName.ShieldCheck}
            variant="color"
            fill={themeContext.colors.primary.base}
          />
        </IconContainer>
      </Tag>
    );
  }

  if (labelName === JobTagLabel.EVALUATION) {
    const evaluationVariant = isGreenEvaluation ? 'green' : 'yellow';
    const evaluationIcon = isGreenEvaluation
      ? IconPickerName.StarGreen
      : IconPickerName.Star;
    return (
      <Tag
        variant={isGray ? 'gray' : evaluationVariant}
        {...(commonTagProps as StandardTagProps)}
        noBorder
        data-testid={`job-label-component-${labelName}`}
      >
        <IconContainer
          width={labelSize}
          height={labelSize}
          mr={isSmall ? 0 : '3px'}
        >
          <FireIcon
            iconName={evaluationIcon}
            variant={isGray ? 'gray' : 'flat'}
            fill={themeContext.colors[evaluationVariant].dark}
          />
        </IconContainer>
        <TextContainer lineHeight={labelSize}>
          {!isSmall && evaluationValue}
          {isGreenEvaluation &&
            ` ${t('evaluation.item.evaluated.of')} ${MAX_EVALUATION_VALUE}`}
        </TextContainer>
      </Tag>
    );
  }

  if (labelName === JobTagLabel.FOLDER) {
    if (isGray) {
      return (
        <Tag
          variant="gray"
          {...(commonTagProps as StandardTagProps)}
          noBorder
          data-testid={`job-label-component-${labelName}`}
        >
          <IconContainer
            width={labelSize}
            height={labelSize}
            mr={isSmall ? 0 : '3px'}
          >
            <FireIcon iconName={IconPickerName.Folder} variant="gray" />
          </IconContainer>
          <TextContainer lineHeight={labelSize}>
            {!isSmall && folderName && folderName}
          </TextContainer>
        </Tag>
      );
    }
    return (
      <Tag
        {...(commonTagProps as CustomVariantTagProps)}
        variant="custom"
        backgroundColor={hexToRgba(folderColor)}
        borderColor={folderColor}
        textColor={hexToRgba(themeContext.colors.black.black9, 0.7)}
        data-testid={`job-label-component-${labelName}`}
        noBorder
      >
        <IconContainer
          width={labelSize}
          height={labelSize}
          mr={isSmall ? 0 : '3px'}
        >
          <FireIcon
            iconName={IconPickerName.FolderTransparent}
            variant="flat"
            fill={folderColor}
          />
        </IconContainer>
        <TextContainer lineHeight={labelSize}>
          {!isSmall && folderName && folderName}
        </TextContainer>
      </Tag>
    );
  }

  const labelDetails = getJobTagInfo(labelName);

  if (!labelDetails) {
    return null;
  }

  return (
    <Div
      data-tooltip-content={labelDetails.tooltipText}
      data-tooltip-place="bottom"
      data-tooltip-id={labelName}
    >
      <CustomTooltip id={labelName} />

      <Tag
        variant={isGray ? 'gray' : labelDetails.variant}
        {...(commonTagProps as StandardTagProps)}
        px={isSmall ? '2px' : 3}
        py={isSmall ? '2px' : 2}
        data-testid={`job-label-component-${labelName}`}
        noBorder
      >
        {labelDetails.iconPosition === 'right' && (
          <TextContainer lineHeight={labelSize}>
            {!isSmall && labelDetails.text}
          </TextContainer>
        )}
        <IconContainer
          width={labelSize}
          height={labelSize}
          mr={isSmall ? 0 : '3px'}
          ml={isSmall ? 0 : '3px'}
        >
          <FireIcon
            iconName={labelDetails.icon}
            variant={isGray ? 'gray' : 'flat'}
            fill={themeContext.colors[labelDetails.variant].dark}
          />
        </IconContainer>
        {labelDetails.iconPosition === 'left' && (
          <TextContainer lineHeight={labelSize}>
            {!isSmall && labelDetails.text}
          </TextContainer>
        )}
      </Tag>
    </Div>
  );
};

export default JobLabelComponent;
