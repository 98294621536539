import { FC, ReactElement, useEffect, useMemo, useState } from 'react';

import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import ErrorAlert from '@components/base/ErrorAlert';
import { FireIcon } from '@components/base/FireIcon/FireIcon';
import { BoligMappaContentModal } from '@components/elements/BoligMappa/modal/BoligMappaContentModal';
import ChatDetailsLoading from '@components/layout/Messages/ChatDetailsLoading';
import { ChatJobInfoColumn } from '@components/layout/Messages/styled';
import { ChatContainer } from '@components/layout/Messages/styled/ChatContainer';
import { ChatDetailsJobInfo } from '@components/layout/Messages/styled/ChatDetailsJobInfo';
import { useBoligMappaModalContext } from '@contexts/BoligMappaModalProvider';
import useChatJobQuery from '@hooks/useChatJobQuery';
import { writeLatestMessage } from '@internals/business-shared/src/cache/chatWriteQuery/latestMessage';
import {
  setChatAsReadOptimisticResponse,
  setChatAsReadUpdate,
} from '@internals/business-shared/src/cache/updates/setChatAsRead';
import { MESSAGES_INITIAL_COUNT } from '@internals/business-shared/src/hooks/query/useChatConversationQuery';
import { useJobTraitsQuery } from '@internals/business-shared/src/hooks/query/useJobTraitsQuery';
import { isChatUnread } from '@internals/business-shared/src/utils/ChatUtils';
import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import { GQLErrorState } from '@internals/business-shared/src/utils/errors';
import { ChatDetailsResponseError } from '@internals/business-shared/src/utils/errors/ChatDetailsResponseError';
import {
  getJobTags,
  JobToTagsAdapter,
} from '@internals/business-shared/src/utils/JobTags';
import {
  SET_CHAT_AS_READ_MUTATION,
  SetChatAsReadMutationPayload,
  SetChatAsReadMutationVariables,
} from '@internals/business-shared/src/utils/mutation/SetChatAsReadMutation/SetChatAsReadMutation';
import { isOptimisticId } from '@internals/business-shared/src/utils/optimistic/id';
import { BADGE_NUMBERS_QUERY } from '@internals/business-shared/src/utils/query/BadgeNumbers/BadgeNumbersQuery';
import {
  CHAT_DETAILS_QUERY,
  ChatDetailsQueryPayload,
  ChatDetailsQueryVariables,
  isChatDetailsQuerySuccessResponse,
} from '@internals/business-shared/src/utils/query/ChatDetails/ChatDetailsQuery';
import { getCustomerFromParticipants } from '@internals/business-shared/src/utils/user';
import { Div, Drawer, Image } from '@schibsted-smb/fireball';
import { getAddressString } from '@utils/address';
import ContentLoader from '@utils/contentLoaders';
import getJobItemSearchParam from '@utils/url';
import { getUserName } from '@utils/user';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';

import ChatConversation from './ChatConversation';
import ChatDetailsTopBar from './ChatDetailsTopBar';
import ChatJobInfo from './ChatJobInfo';

export interface ChatDetailsProps {
  chatId: string;
  isChatJobInfoVisible: boolean;
  setIsChatJobInfoVisible: (arg: boolean) => void;
  isMobile?: boolean;
  isDesktop?: boolean;
}

const StyledLink = styled(Link)`
  &:hover {
    text-decoration: none;
  }
`;

const ChatDetails: FC<ChatDetailsProps> = ({
  chatId,
  isChatJobInfoVisible,
  setIsChatJobInfoVisible,
  isMobile,
  isDesktop,
}: ChatDetailsProps) => {
  const { t } = useTranslation();
  const themeContext = useTheme();
  const apolloClient = useApolloClient();
  const [errors, setErrors] = useState<GQLErrorState | null>(null);

  const [setChatAsRead] = useMutation<
    SetChatAsReadMutationPayload,
    SetChatAsReadMutationVariables
  >(SET_CHAT_AS_READ_MUTATION, {
    variables: {
      id: chatId,
    },
    optimisticResponse: setChatAsReadOptimisticResponse,
    update: setChatAsReadUpdate(chatId),
    refetchQueries: [BADGE_NUMBERS_QUERY],
  });

  const { loading, data } = useQuery<
    ChatDetailsQueryPayload,
    ChatDetailsQueryVariables
  >(CHAT_DETAILS_QUERY, {
    variables: {
      id: chatId,
      last: MESSAGES_INITIAL_COUNT,
    },
    fetchPolicy: 'cache-and-network',
    skip: !chatId || isOptimisticId(chatId),
    onCompleted: (res) => {
      if (isChatDetailsQuerySuccessResponse(res.chat)) {
        setErrors(null);
      } else {
        const chatDetailsResponseError = new ChatDetailsResponseError('Chat');
        chatDetailsResponseError.hasResponseError(res.chat, setErrors);
      }
    },
    onError: () =>
      setErrors({
        general: { msg: t('chat.item.chatLoadError'), variant: 'danger' },
      }),
  });
  const { data: jobData, loading: jobLoading } = useChatJobQuery(chatId);
  const { data: jobTraits } = useJobTraitsQuery({
    skip: !jobData?.job?.id,
    variables: { jobId: jobData?.job?.id },
  });

  const { job, simpleAgreement } = jobData || {};
  const chat = useMemo(
    () =>
      data && isChatDetailsQuerySuccessResponse(data.chat) ? data.chat : null,
    [data]
  );

  const jobTags = useMemo(
    () =>
      getJobTags(
        JobToTagsAdapter(
          job?.jobBusinessRelationState,
          jobTraits,
          !!job?.evaluation?.id
        ),
        ['isEvaluated', 'fromVerifiedUser']
      ),
    [job?.evaluation, job?.jobBusinessRelationState, jobTraits]
  );

  const { isOpen: isBoligMappaOpen, closeModal: closeBoligMappaModal } =
    useBoligMappaModalContext();

  const customer = chat ? getCustomerFromParticipants(chat.participants) : null;
  const jobImageUrl = job?.images?.[0]?.smallPreviewUrl ?? '';
  const contractId = simpleAgreement?.id ?? '';

  useEffect(() => {
    if (isChatUnread(chat)) {
      setChatAsRead();
    }
  }, [chat?.id, setChatAsRead]);

  useEffect(() => {
    // sync latestMessage field
    const messages = data?.chatMessages.edges;
    if (!messages?.length) return;
    const lastMessage = messages[messages.length - 1].node;
    if (lastMessage) {
      writeLatestMessage(apolloClient, chat.id, lastMessage);
    }
  }, [data, apolloClient]);

  const wrapChatJobInfo = (component: ReactElement) =>
    isDesktop ? (
      component
    ) : (
      <Drawer
        isOpen={isChatJobInfoVisible}
        onClose={() => setIsChatJobInfoVisible(false)}
        position="right"
        modalOverlayStyles={{ height: '100%' }}
      >
        {component}
      </Drawer>
    );

  if (loading && !chat && !errors) {
    return <ChatDetailsLoading />;
  }

  return (
    <ChatContainer>
      <ErrorAlert errors={errors} />
      {chat && (
        <>
          {job?.jobBusinessRelationState.isWon && (
            <BoligMappaContentModal
              job={job}
              isOpen={isBoligMappaOpen}
              onClose={closeBoligMappaModal}
            />
          )}
          <ChatDetailsTopBar
            name={getUserName(customer)}
            address={getAddressString(job?.address)}
            jobTags={jobTags}
            evaluation={job?.evaluation}
            isChatJobInfoVisible={isChatJobInfoVisible}
            setIsChatJobInfoVisible={setIsChatJobInfoVisible}
            isVerifiedWithSecureId={job?.owner?.isVerifiedWithSecureId ?? false}
            isMobile={isMobile ?? false}
          />
          {!isDesktop && job && (
            <StyledLink to={getJobItemSearchParam(chat.job.id)}>
              <Div
                alignItems="center"
                backgroundColor="black.black1"
                display="flex"
                height={themeContext.space[9]}
                px={5}
                width="100%"
              >
                {jobImageUrl && (
                  <Image
                    mr={2}
                    width={themeContext.space[8].toString()}
                    src={jobImageUrl}
                  />
                )}
                <Div display="flex" flexDirection="column" minWidth={0}>
                  <ChatDetailsJobInfo
                    fontWeight={600}
                    color={themeContext.colors.black.black10}
                  >
                    {job.title}
                  </ChatDetailsJobInfo>
                  <ChatDetailsJobInfo color={themeContext.colors.black.black10}>
                    {job.description}
                  </ChatDetailsJobInfo>
                </Div>
                <Div ml="auto">
                  <FireIcon
                    iconName={IconPickerName.ChevronRight}
                    variant="flat"
                    width="18"
                    height="18"
                  />
                </Div>
              </Div>
            </StyledLink>
          )}
          <Div display="flex" height="100%" width="100%" overflow="hidden">
            <ChatConversation
              jobId={job?.id}
              chatId={chat.id}
              customerName={getUserName(customer)}
              chatDisabled={!!customer?.isDeleted}
              contractId={contractId}
            />
            {wrapChatJobInfo(
              // TODO: handle job error
              !jobLoading && job ? (
                <ChatJobInfo
                  job={job}
                  simpleAgreement={simpleAgreement}
                  chatId={chat.id}
                />
              ) : (
                <ChatJobInfoColumn className="no-print">
                  <Div height="100%" width="100%">
                    <ContentLoader.ChatJobInfo uniqueKey="loader-job-info" />
                  </Div>
                </ChatJobInfoColumn>
              )
            )}
          </Div>
        </>
      )}
    </ChatContainer>
  );
};

export default ChatDetails;
