import { FC, RefObject, useState } from 'react';

import { CustomTooltip } from '@components/base/CustomTooltip';
import { FireIcon } from '@components/base/FireIcon/FireIcon';
import JobFolders from '@components/elements/JobFolders';
import { JOB_MODAL_MAX_HEIGHT } from '@components/layout/Job/Job';
import { DivActionButton } from '@components/layout/Job/styled';
import { DivActionButtonProps } from '@components/layout/Job/styled/DivActionButton';
import useMobileDeviceViewport from '@hooks/useMobileDeviceViewport';
import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import { JobListOrJobQueryItem } from '@internals/business-shared/src/utils/interfaces/graphql/JobListOrJobQueryItem.interface';
import { Dropdown } from '@schibsted-smb/fireball';
import { Layer } from '@utils/layer';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import uniqid from 'uniqid';

export interface JobFolderSelectProps {
  job: JobListOrJobQueryItem;
  favouriteAction: VoidFunction;
  onDropdownOpen?: VoidFunction;
  onDropdownClose?: VoidFunction;
  containerRef?: RefObject<HTMLDivElement>;
}

const uniqidConst = uniqid();

const JobFolderSelect: FC<JobFolderSelectProps> = ({
  job,
  favouriteAction,
  onDropdownOpen,
  onDropdownClose,
  containerRef,
}: JobFolderSelectProps) => {
  const themeContext = useTheme();
  const { t } = useTranslation();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const deviceViewportHeight = useMobileDeviceViewport().height;
  const JOB_MODAL_PADDING = 100; // Unfortunately, this is space for the modal header, action button, paddings, that we have to include to the calculations.

  const buttonStyleProps: DivActionButtonProps = isDropdownOpen
    ? {
        background: themeContext.colors.orange.base,
        fill: themeContext.colors.black.black0,
        outlineColor: themeContext.colors.orange.base,
      }
    : {
        background: themeContext.colors.black.black0,
        fill: themeContext.colors.orange.base,
        outlineColor: themeContext.colors.orange.base,
      };

  // The problem with this solution is that the Job modal height affect the dropdown height. Not a big issue when it comes to the Folders dropdown, but in a perfect world, we wouldn't have such dependencies.
  const dropdownMaxHeight = Math.max(
    Math.min(deviceViewportHeight, JOB_MODAL_MAX_HEIGHT) - JOB_MODAL_PADDING,
    0
  );

  return (
    <div>
      <CustomTooltip id={uniqidConst} />
      <Dropdown
        zIndex={Layer.popover + Layer.above}
        item={
          <JobFolders
            job={job}
            favouriteAction={favouriteAction}
            onClose={() => {
              // FIXME: Temporary not in use - need to fix Fireball Dropdown issue
              // setIsDropdownOpen(false)
              // onDropdownClose?.()
            }}
          />
        }
        width={260}
        getIsDropdownOpen={(isOpen) => {
          setIsDropdownOpen(isOpen);
          if (isOpen) {
            onDropdownOpen?.();
          } else {
            onDropdownClose?.();
          }
        }}
        parentElement={containerRef}
        height={dropdownMaxHeight}
      >
        <DivActionButton
          data-tooltip-id={uniqidConst}
          data-tooltip-place="bottom"
          data-tooltip-content={t('job.list.type_FOLDERS')}
          data-testid="job-action-button-folder"
          {...buttonStyleProps}
        >
          <FireIcon iconName={IconPickerName.Folder} variant="flat" />
        </DivActionButton>
      </Dropdown>
    </div>
  );
};

export default JobFolderSelect;
