import { FC, useMemo, useState } from 'react';

import { JobAnsweredMobile } from '@components/layout/Job/JobAnsweredMobile';
import { JobContentProps } from '@components/layout/Job/JobContent';
import { JobOverviewMobile } from '@components/layout/Job/JobOverviewMobile';
import { StyledMobileContentContainer } from '@components/layout/Job/styled/StyledMobileContentContainer';
import { StyledMobileFooterContainer } from '@components/layout/Job/styled/StyledMobileFooterContainer';
import useBoolean from '@internals/business-shared/src/hooks/useBoolean';
import { isOptimisticId } from '@internals/business-shared/src/utils/optimistic/id';
import { Div, TabNavigation, Button } from '@schibsted-smb/fireball';
import { Layer } from '@utils/layer';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import { JobFeedbackModal } from '../../elements/JobFeedbackModal/JobFeedbackModal';

enum JobCardTab {
  Overview = 1,
  Answer = 2,
}

export const JobContentMobile: FC<JobContentProps> = ({
  answerForm,
  alert,
  allowAnswer,
  chatId,
  ...jobOverviewProps
}) => {
  const themeContext = useTheme();
  const { t } = useTranslation();
  const TABS = [
    { id: JobCardTab.Overview, title: t('job.item.overview') },
    { id: JobCardTab.Answer, title: t('job.item.answer.title') },
  ];
  const [activeTab, setActiveTab] = useState(JobCardTab.Overview);
  const hasAnswer =
    jobOverviewProps.job.jobBusinessRelationState.isAnswered &&
    chatId &&
    !isOptimisticId(chatId);

  const [isRejectionModalOpen, openRejectionModal, closeRejectionModal] =
    useBoolean();

  const displayRejectButton = useMemo(
    () =>
      !jobOverviewProps.job.jobBusinessRelationState.isRejected &&
      !!jobOverviewProps.job.jobBusinessRelationState.isOneOnOneReceiver,
    [
      jobOverviewProps.job.jobBusinessRelationState.isRejected,
      jobOverviewProps.job.jobBusinessRelationState.isOneOnOneReceiver,
    ]
  );

  const getContent = () => {
    switch (activeTab) {
      case JobCardTab.Answer: {
        if (hasAnswer) {
          return (
            <>
              <StyledMobileContentContainer>
                <JobAnsweredMobile chatId={chatId} />
              </StyledMobileContentContainer>
              <StyledMobileFooterContainer>{alert}</StyledMobileFooterContainer>
            </>
          );
        }
        return (
          <>
            <Div display="flex" flexGrow={2}>
              {answerForm}
            </Div>
            {!allowAnswer && (
              <StyledMobileFooterContainer>{alert}</StyledMobileFooterContainer>
            )}
          </>
        );
      }
      case JobCardTab.Overview:
      default: {
        return (
          <>
            <JobOverviewMobile {...jobOverviewProps} />
            <StyledMobileFooterContainer>
              {allowAnswer ? (
                <>
                  {displayRejectButton && (
                    <Button
                      my={6}
                      variant="secondary"
                      color="red.base"
                      onClick={() => openRejectionModal()}
                    >
                      {t('job.item.reject.action.reject')}
                    </Button>
                  )}

                  <Button
                    my={6}
                    onClick={() => setActiveTab(JobCardTab.Answer)}
                  >
                    {t('job.action.answer')}
                  </Button>
                </>
              ) : (
                alert
              )}
            </StyledMobileFooterContainer>
            <JobFeedbackModal
              isOpen={isRejectionModalOpen}
              onClose={closeRejectionModal}
              jobId={jobOverviewProps.job.id}
            />
          </>
        );
      }
    }
  };

  return (
    <Div
      display="flex"
      flexDirection="column"
      position="relative"
      minHeight="100%"
    >
      <Div width={1} position="sticky" zIndex={Layer.sticky} top={0}>
        <TabNavigation
          tabs={TABS}
          activeTabId={activeTab}
          setActiveTabId={setActiveTab}
          height="60px"
          tabButtonStyles={{ width: `${100 / TABS.length}%` }}
          containerStyles={{
            backgroundColor: themeContext.background.white,
            borderBottom: `1px solid ${themeContext.colors.black.black3}`,
          }}
        />
      </Div>
      {getContent()}
    </Div>
  );
};
