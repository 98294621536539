import gql from 'graphql-tag';

const JOB_BUSINESS_RELATION_STATE_FRAGMENT = gql`
  fragment JobBusinessRelationStateFragment on JobBusinessRelationState {
    jobId
    clipsSpent
    hasRequestedEvaluation
    hasRequestedSelection
    isAnswered
    isDeleted
    isFavourite
    isLost
    isRead
    isWon
    isFree
    isOneOnOneReceiver
    isRejected
  }
`;

export default JOB_BUSINESS_RELATION_STATE_FRAGMENT;
