import { FC, useEffect, useMemo } from 'react';

import { useFlagEnabled } from '@components/base/CheckFlag';
import { CloseableTooltip } from '@components/base/CloseableTooltip';
import { FeatureFlag } from '@components/elements/FeatureFlag';
import { PillNavigation } from '@components/elements/PillNavigation';
import { PillNavigationMobile } from '@components/elements/PillNavigation/PillNavigationMobile';
import { SavedFilterActionModal } from '@components/layout/Jobs/SavedFilters/SavedFilterActionModal';
import { SavedFilterNavigationAction } from '@components/layout/Jobs/SavedFilters/SavedFilterNavigationAction';
import { useDisplayTooltip } from '@components/layout/Jobs/SavedFilters/useDisplayTooltip';
import { useIsMobile } from '@contexts/DeviceSizeContext';
import useAnalytics from '@hooks/useAnalytics';
import { useJobsTotalCount } from '@hooks/useJobsTotalCount';
import { useSavedFiltersContext } from '@internals/business-shared/src/components/SavedFilters/context';
import {
  isJobListSavedFilter,
  mapSavedSearchToFilter,
} from '@internals/business-shared/src/components/SavedFilters/utils';
import { useMarkSavedFilterJobListAsReadMutation } from '@internals/business-shared/src/hooks/mutation/useMarkSavedFilterJobListAsReadMutation';
import {
  ANEvent,
  ANEventSpace,
  ANObject,
  ANPage,
} from '@internals/business-shared/src/utils/analyticsNamespace';
import FeatureFlags from '@internals/business-shared/src/utils/constants/FeatureFlags';
import { CreatedJobsSavedFilter } from '@internals/business-shared/src/utils/mutation/CreateJobsSavedFilter/CreateJobsSavedFilterMutation';
import { EditedJobsSavedFilter } from '@internals/business-shared/src/utils/mutation/UpdateJobsSavedFilter/UpdateJobsSavedFilterMutation';
import { Div, Heading } from '@schibsted-smb/fireball';
import { createJobListFilterNavigation } from '@utils/jobList';
import { Layer } from '@utils/layer';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigationType } from 'react-router-dom';
import { useTheme } from 'styled-components';

export const JobsListHeaderV2: FC = () => {
  const { t } = useTranslation();
  const totalCount = useJobsTotalCount();
  const isMobile = useIsMobile();
  const themeContext = useTheme();
  const { tooltipId, isTooltipDisplayed, displayTooltip, onTooltipClose } =
    useDisplayTooltip();
  const { navigation, filterState, setActiveFilter, reset } =
    useSavedFiltersContext();
  const { track } = useAnalytics();
  const location = useLocation();
  const navigationType = useNavigationType();
  const unreadIndicatorEnabled = useFlagEnabled(
    FeatureFlags.BizSavedSearchUnreadIndicator
  );
  const [markSavedFilterJobListAsRead] =
    useMarkSavedFilterJobListAsReadMutation();
  const items = useMemo(
    () =>
      createJobListFilterNavigation({
        jobListFilters: navigation,
        activeFilter: filterState.activeFilter,
        onClick: (item) => {
          setActiveFilter(item);
          track(ANEventSpace(ANEvent.Clicked, ANObject.Pill, ANPage.JobList), {
            pillName: item.label,
          });
        },
        indicatorEnabled: unreadIndicatorEnabled,
      }),
    [
      navigation,
      filterState.activeFilter,
      track,
      setActiveFilter,
      unreadIndicatorEnabled,
    ]
  );

  useEffect(() => {
    if (
      isJobListSavedFilter(filterState.activeFilter) &&
      filterState.activeFilter.hasUnreadJobs
    ) {
      markSavedFilterJobListAsRead(filterState.activeFilter.id);
    }
  }, [filterState.activeFilter?.id]);

  useEffect(() => {
    return () => {
      if (isTooltipDisplayed && filterState.activeFilter?.id) {
        onTooltipClose();
      }
    };
  }, [filterState.activeFilter?.id]);

  // reset active filter when user navigates back or when uses link outside navigation
  useEffect(() => {
    if (navigationType === 'POP' || location.state?.resetActiveFilter) {
      reset();
    }
  }, [location, navigationType]);

  const navigationComponent = useMemo(() => {
    if (!items.length) return null;
    if (isMobile) {
      return (
        <PillNavigationMobile
          items={items}
          title={filterState.activeFilter?.label || t('job.list.type_OPEN')}
        />
      );
    }
    return <PillNavigation items={items} />;
  }, [items, isMobile]);

  const onFilterCreated = (newFilter: CreatedJobsSavedFilter) => {
    displayTooltip(newFilter);
    setActiveFilter(mapSavedSearchToFilter(newFilter));
  };

  const onFilterEdited = (editedFilter: EditedJobsSavedFilter) => {
    setActiveFilter(mapSavedSearchToFilter(editedFilter));
  };

  return (
    <Div
      position="sticky"
      top={isMobile ? 0 : '70px'}
      zIndex={Layer.sticky}
      backgroundColor={themeContext.background.white}
    >
      <Div
        borderBottom={`1px solid ${themeContext.colors.black.black4}`}
        py={5}
        px={6}
        {...(isMobile && { py: 5, px: 0, borderBottom: 'none' })}
      >
        {!isMobile && (
          <Heading.h4 my={5} fontWeight={themeContext.fontWeights.medium}>
            {totalCount !== null
              ? t('job.list.titleWithCount', { count: totalCount })
              : t('job.list.title')}
          </Heading.h4>
        )}
        {navigationComponent}
      </Div>
      <FeatureFlag name={FeatureFlags.BizSavedSearchActions}>
        <SavedFilterNavigationAction />
        <SavedFilterActionModal
          onFilterCreated={onFilterCreated}
          onFilterEdited={onFilterEdited}
        />
        {isTooltipDisplayed && (
          <CloseableTooltip
            tooltipId={tooltipId}
            isOpen
            onClose={onTooltipClose}
            style={{ backgroundColor: themeContext.colors.primary.darkest }}
            place="bottom"
          >
            <Div mr={4}>{t('job.list.savedFilters.newSavedFilterInfo')}</Div>
          </CloseableTooltip>
        )}
      </FeatureFlag>
    </Div>
  );
};
