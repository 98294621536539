import { ApolloCache, makeReference } from '@apollo/client';

import {
  addJobToCachedJobList,
  findJobListDestinations,
  removeJobFromCachedJobList,
} from '../../utils/cache';
import JobListId from '../../utils/constants/jobListIds';
import { DELETE_JOB } from '../../utils/generated/generated';
import { JobListOrJobQueryItem } from '../../utils/interfaces/graphql/JobListOrJobQueryItem.interface';

const deleteJobUpdate =
  (job: JobListOrJobQueryItem) =>
  (cache: ApolloCache<DELETE_JOB>, { data }: { data?: DELETE_JOB | null }) => {
    if (data?.deleteJob.success) {
      cache.modify({
        id: cache.identify({
          jobId: job.id,
          __typename: 'JobBusinessRelationState',
        }),
        fields: {
          isDeleted() {
            return true;
          },
        },
      });
      cache.modify({
        id: cache.identify(makeReference('ROOT_QUERY')),
        fields: {
          jobList(existingJobList, { readField }) {
            // add job to cached deleted job list
            if (existingJobList.listId === JobListId.Deleted) {
              return {
                ...existingJobList,
                jobConnection: addJobToCachedJobList(
                  existingJobList.jobConnection,
                  job,
                  readField,
                  false
                ),
              };
            }
            // remove job from others cached job lists
            return {
              ...existingJobList,
              jobConnection: removeJobFromCachedJobList(
                existingJobList.jobConnection,
                job.id,
                readField
              ),
            };
          },
        },
      });

      const removedJobLists = findJobListDestinations(job);

      // update counters for others job lists (not for deleted)
      removedJobLists.forEach((jobListId) =>
        cache.modify({
          id: cache.identify({ id: jobListId, __typename: 'JobListFilter' }),
          fields: {
            count(currentCount) {
              return currentCount - 1 >= 0 ? currentCount - 1 : 0;
            },
          },
        })
      );

      // update counter for deleted job list
      cache.modify({
        id: cache.identify({
          id: JobListId.Deleted,
          __typename: 'JobListFilter',
        }),
        fields: {
          count(currentCount) {
            return currentCount + 1;
          },
        },
      });
    }
  };

const deleteJobOptimisticResponse: DELETE_JOB = {
  deleteJob: {
    __typename: 'DeleteJobPayload',
    success: true,
  },
};

export { deleteJobUpdate, deleteJobOptimisticResponse };
